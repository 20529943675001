import IProductConfiguration from 'interfaces/products/IProductConfiguration';
import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';

import {
  PRODUCT,
  PRODUCT_SELECT,
  SELECT,
} from 'services/products/productConfigurationItemTypes';

import getSelectSelectedProducts from './getSelectSelectedProducts';
import ISelection from './ISelection';

export default (
  selections: ISelection[],
  configuration: IProductConfiguration
): IProductConfigurationItem[] =>
  configuration.items.reduce(
    (current: IProductConfigurationItem[], item: IProductConfigurationItem) => {
      if (item.type === PRODUCT) {
        return [...current, item];
      }

      if (item.type === PRODUCT_SELECT) {
        const currentSelection = selections.find(
          (selection: ISelection) => selection.id === item.id
        );

        const selectedProduct = item.children.find(
          (child) => child.id === currentSelection?.selection
        );

        if (selectedProduct !== undefined) {
          return [...current, selectedProduct];
        }
      }

      if (item.type === SELECT) {
        return [...current, ...getSelectSelectedProducts(item, selections)];
      }

      return current;
    },
    []
  );
