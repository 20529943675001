import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SelectInputFieldNoLabel from 'components/layout/forms/SelectInputFieldNoLabel';
import translations from 'services/translations/translations';
import { fetchPlaceStatusTypes } from 'store/projects/actions';
import 'style/layout/modal/ModalConfirm.css';
import IBuildingStructure from 'interfaces/projects/IBuildingStructure';
import IBuilding from 'interfaces/projects/IBuilding';
import IBasicInfoGet from 'api/interfaces/projects/IBasicInfoGet';
import getTargetValue from 'services/forms/getTargetValue';

import 'style/projects/projectMeasurementTab/measurementList/SearchBars.css';

const Status = ({
  statusList,
  fetchStatusList,
  basicInfo,
  maxFloors,
  filterMeasurements,
}: {
  statusList: string[];
  fetchStatusList: () => void;
  basicInfo: IBasicInfoGet | undefined;
  maxFloors: number;
  filterMeasurements: (filter: any) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.projects, translations.common]);
  const [buildingSpaces, setBuildingSpace] = useState<string[]>([]);
  const [buildings, setBuildings] = useState<string[]>([]);
  const [buildingSections, setBuildingSections] = useState<string[]>([]);
  const [floors, setFloors] = useState<number[]>([]);
  const [filters, setFilters] = useState({
    building: '',
    space: '',
    section: '',
    floor: '',
    status: '',
  });

  const handleFilter = (e: any) => {
    setFilters({
      ...filters,
      [e.target.name]: getTargetValue(e),
    });
  };

  useEffect(() => {
    filterMeasurements(filters);
  }, [filters]);

  useEffect(() => {
    if (basicInfo !== undefined) {
      setBuildings(
        basicInfo.buildings.map((building: IBuilding) => building.name)
      );
      setBuildingSpace(
        basicInfo.buildingSpaces.map((sp: IBuildingStructure) => sp.name)
      );
      setBuildingSections(
        basicInfo.buildingSections.map((sec: IBuildingStructure) => sec.name)
      );
    }
  }, [basicInfo]);

  useEffect(() => {
    fetchStatusList();
  }, [fetchStatusList]);

  useEffect(() => {
    let floorsArray = Array.from(Array(maxFloors + 1).keys());
    floorsArray = floorsArray.slice(1);
    setFloors(floorsArray);
  }, [maxFloors]);

  const getOptionsWithPlaceholder = (
    keyName: string,
    placeholder: string,
    list: string[] | number[],
    translation: string
  ) => {
    const options = [];
    options.push(
      <option key={`${keyName}00`} value="" className="placeholder-select">
        {placeholder}
      </option>
    );
    for (let index = 0; index < list.length; index++) {
      options.push(
        <option key={`${keyName}${index}`} value={list[index]}>
          {t(`${translations.projects}:${translation}${list[index]}`)}
        </option>
      );
    }
    return options;
  };

  return (
    <>
      <Row className="filter-row-mittakortti">
        <Col className="line-elements">
          <SelectInputFieldNoLabel
            id="buildingSpaceSearch"
            name="space"
            required
            onChange={handleFilter}
            options={
              buildingSpaces
                ? getOptionsWithPlaceholder(
                    'buildingSpaceSearch',
                    t(`${translations.projects}:Building Space`),
                    buildingSpaces,
                    ''
                  )
                : ''
            }
          />
        </Col>
        <Col>
          <SelectInputFieldNoLabel
            id="statusSearch"
            name="status"
            required
            onChange={handleFilter}
            options={
              statusList
                ? getOptionsWithPlaceholder(
                    'statusTypesSearch',
                    t(`${translations.projects}:status.Status`),
                    statusList,
                    'status.'
                  )
                : ''
            }
          />
        </Col>
        <Col>
          <SelectInputFieldNoLabel
            id="buildingsSearch"
            name="building"
            required
            onChange={handleFilter}
            options={
              buildings
                ? getOptionsWithPlaceholder(
                    'buildingSearch',
                    t(`${translations.projects}:Buildings`),
                    buildings,
                    ''
                  )
                : ''
            }
          />
        </Col>
        <Col>
          <SelectInputFieldNoLabel
            id="floorsSearch"
            name="floor"
            required
            onChange={handleFilter}
            options={
              floors
                ? getOptionsWithPlaceholder(
                    'floorsSearch',
                    t(`${translations.projects}:Floor`),
                    floors,
                    ''
                  )
                : ''
            }
          />
        </Col>
        <Col>
          <SelectInputFieldNoLabel
            id="buildingSectionSearch"
            name="section"
            required
            onChange={handleFilter}
            options={
              buildingSections
                ? getOptionsWithPlaceholder(
                    'buildingSectionSearch',
                    t(`${translations.projects}:Building Section`),
                    buildingSections,
                    ''
                  )
                : ''
            }
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  statusList: state.projects.placeStatusTypes,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchStatusList: () => dispatch(fetchPlaceStatusTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Status);
