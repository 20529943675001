import { SET_MESSAGE, REMOVE_MESSAGE } from './types';

interface IState {
  text: string;
}

const initialState = {
  text: '',
};

export default (
  state: IState = initialState,
  action: {
    text: string;
    type: string;
  }
): IState => {
  if (action.type === SET_MESSAGE) {
    return {
      ...state,
      text: action.text,
    };
  }

  if (action.type === REMOVE_MESSAGE) {
    return {
      ...state,
      text: '',
    };
  }

  return state;
};
