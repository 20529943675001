import React from 'react';
import { useTranslation } from 'react-i18next';

import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import translations from 'services/translations/translations';

import {
  INSTALLATION,
  PRODUCT,
} from 'services/products/productConfigurationItemTypes';

import AddItem from './AddItem';
import Item from './Item';
import { OTHER_EXPENSE } from '../installationTypes';

import 'style/products/configurations/src/items/Items.css';

const Items = ({
  items,
  type,
  onDelete,
}: {
  items: IProductConfigurationItem[];
  onDelete: (itemId: number) => void;
  type: string;
}): JSX.Element => {
  const { t } = useTranslation([translations.common, translations.products]);

  const getTitle = () => {
    if (type === INSTALLATION) {
      return t(`${translations.productConfigurations}:Installations`);
    }

    if (type === OTHER_EXPENSE) {
      return t(`${translations.productConfigurations}:Other expenses`);
    }

    if (type === PRODUCT) {
      return t(`${translations.productConfigurations}:Materials`);
    }

    return '';
  };

  return (
    <>
      <h3 className="product-configuration-items-title">{getTitle()}</h3>
      <AddItem type={type} />
      <div className="clearfix" />
      <div className="product-configurator-items content-box">
        {items.map((item) => (
          <Item
            onDelete={onDelete}
            key={`product-configurator-item-${item.key}`}
            item={item}
          />
        ))}
      </div>
    </>
  );
};

export default Items;
