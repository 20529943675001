import getFileName from './getFileName';

const downloadFile = (data: any, headers: any) => {
  const anchor = document.createElement('a');

  const blob = new Blob([data], {
    type: headers['content-type'],
  });

  const objectUrl = URL.createObjectURL(blob);
  anchor.href = objectUrl;
  anchor.download = getFileName(headers);
  anchor.click();
  URL.revokeObjectURL(objectUrl);
};

export default downloadFile;
