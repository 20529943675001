import IProductConfigurationsGet from 'api/interfaces/products/IProductConfigurationsGet';
import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';

const getItemConfiguration = (
  item: IProductConfigurationItem
): IProductConfigurationsGet | null => {
  if (item.parentItem !== undefined && item.parentItem !== null) {
    return getItemConfiguration(item.parentItem);
  }

  if (item.parentConfiguration === undefined) {
    return null;
  }

  if (item.parentConfiguration === null) {
    return null;
  }

  return item.parentConfiguration;
};

export default getItemConfiguration;
