import numericStringToFloat from 'services/numbers/numericStringToFloat';

import getSubproductUnitExpense from './getSubproductUnitExpense';
import ISubproduct from './ISubproduct';
import IDetail from './IDetail';

export default (subproduct: ISubproduct | IDetail): number => {
  const unitExpense = getSubproductUnitExpense(subproduct);

  if ([null, ''].includes(subproduct.expenseUnit)) {
    return unitExpense;
  }

  const quantity =
    typeof subproduct.quantity === 'number'
      ? subproduct.quantity
      : numericStringToFloat(subproduct.quantity);

  return unitExpense * quantity;
};
