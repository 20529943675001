import roundTwoDecimals from 'services/numbers/roundTwoDecimals';
import fromMillimitersToMeters from './fromMillimitersToMeters';

const getSquares = (positionId: any, row: any) => {
  return `${roundTwoDecimals(
    fromMillimitersToMeters(row.height) * fromMillimitersToMeters(row.width)
  )} m²`;
};

export default getSquares;
