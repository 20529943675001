import capitalizeFirstLetter from '../forms/capitalizeFirstLetter';

const sortResult = (result: any, t: any) =>
  result.sort((n1: string, n2: string) => {
    if (t(capitalizeFirstLetter(n1)) > t(capitalizeFirstLetter(n2))) return 1;
    if (t(capitalizeFirstLetter(n1)) < t(capitalizeFirstLetter(n2))) return -1;
    return 0;
  });

export default sortResult;
