import React from 'react';

import 'style/layout/form/GreyBlackText.css';

const GreyBlackText = ({
  greyHeader,
  blackText,
}: {
  greyHeader: string | undefined;
  blackText: string | undefined;
}): JSX.Element | null => {
  return (
    <div>
      <p className="grey-text">{greyHeader}</p>
      <p className="black-text-subheader header-text">{blackText}</p>
    </div>
  );
};

export default GreyBlackText;
