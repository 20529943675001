import React from 'react';

import IProductInstallationCategory from 'api/interfaces/products/IProductInstallationCategory';
import translations from 'services/translations/translations';
import sortByTranslation from 'services/sort/sortByTranslation';

const getFilteredCategories = (
  installationCategories: IProductInstallationCategory[],
  type?: string
): string[] => {
  if (type === undefined) {
    return installationCategories.map(
      (installationCategory) => installationCategory.category
    );
  }

  return installationCategories
    .filter((installationCategory) => installationCategory.type === type)
    .map((installationCategory) => installationCategory.category);
};

const getInstallationCategoryOptions = (
  installationCategories: IProductInstallationCategory[],
  t: any,
  type?: string
): JSX.Element[] => {
  const categoriesTranslationLocation = `${translations.products}:installationCategories`;

  const getOption = (installationCategory: string) => (
    <option
      key={`installation-category-${installationCategory}`}
      value={installationCategory}
    >
      {t(`${categoriesTranslationLocation}:${installationCategory}`)}
    </option>
  );

  const categoriesSortedByTranslation = getFilteredCategories(
    installationCategories,
    type
  ).sort(sortByTranslation(t, categoriesTranslationLocation));

  return categoriesSortedByTranslation.map((installationCategory: string) =>
    getOption(installationCategory)
  );
};

export default getInstallationCategoryOptions;
