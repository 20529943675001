import React from 'react';
import { useTranslation } from 'react-i18next';

import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import translations from 'services/translations/translations';

import { APPROVED } from 'services/enums/offers/Statuses';
import getDefaultVersion from './getDefaultVersion';
import IFormState from '../IFormState';
import IVersion from '../IVersion';
import views from '../views';

const Header = ({
  activeVersionIndex,
  formState,
  initialStatus,
  setActiveVersionIndex,
  setVersions,
  setView,
  versions,
}: {
  activeVersionIndex: number;
  formState: IFormState;
  initialStatus: null | string;
  setActiveVersionIndex: (activeVersionIndex: number) => void;
  setVersions: (versions: IVersion[]) => void;
  setView: (view: string) => void;
  versions: IVersion[];
}): JSX.Element => {
  const { t } = useTranslation();

  const translatedUnit = t(`${translations.common}:units:${formState.unit}`);

  const getExistingVersionWithName = (name: string) =>
    versions.find((version) => version.name === name);

  const getNewVersionName = () => {
    let newVersionName = versions.length + 1;

    while (
      getExistingVersionWithName(newVersionName.toString()) !== undefined
    ) {
      newVersionName += 1;
    }

    return newVersionName.toString();
  };

  const addVersion = () => {
    setVersions([
      ...versions,
      {
        ...getDefaultVersion(),
        isPrimary: false,
        name: getNewVersionName(),
      },
    ]);

    setActiveVersionIndex(versions.length);
  };

  const copyVersion = () => {
    const selectedVersion = versions[activeVersionIndex];

    setVersions([
      ...versions,
      {
        ...getDefaultVersion(),
        isPrimary: false,
        name: getNewVersionName(),
        averageHourlyEarnings: selectedVersion.averageHourlyEarnings,
        subproducts: selectedVersion.subproducts,
        installations: selectedVersion.installations,
        offerText: selectedVersion.offerText,
        showInOfferPdf: selectedVersion.showInOfferPdf,
      },
    ]);

    setActiveVersionIndex(versions.length);
  };

  const getPrimaryText = (index: number) => {
    if (!versions[index].isPrimary) {
      return null;
    }

    return ` (${t(`${translations.calculationWindow}:primary`)})`;
  };

  const offerIsApproved = () => initialStatus === APPROVED;

  const onActiveVersionIndexChange = (event: any) => {
    setActiveVersionIndex(parseInt(event.target.value, 10));
  };

  return (
    <>
      <div className="subproducts-header">
        <h2>
          {formState.position} / {formState.name}{' '}
          {`${formState.quantity}${translatedUnit}`} /
        </h2>
        <SelectInputFieldControlled
          formGroupClassName="offer-product-version-select-form-group"
          id="offer-product-version-select"
          name="offer-product-version-select"
          onChange={onActiveVersionIndexChange}
          options={versions.map((version, index) => (
            <option key={`offer-product-version-${version.name}`} value={index}>
              {version.name}
              {getPrimaryText(index)}
            </option>
          ))}
          required
          value={activeVersionIndex}
        />
        <div className="clearfix" />
      </div>
      <div className="calculation-window-header-actions">
        {offerIsApproved() ? null : (
          <PrimaryButton
            className="add-offer-product-version"
            onClick={copyVersion}
          >
            {t(`${translations.calculationWindow}:Copy to new version`)}
          </PrimaryButton>
        )}
        {offerIsApproved() ? null : (
          <PrimaryButton
            className="add-offer-product-version"
            onClick={addVersion}
          >
            {t(`${translations.calculationWindow}:Add version`)}
          </PrimaryButton>
        )}
        {offerIsApproved() ? null : (
          <PrimaryButton
            className="add-configuration-button"
            onClick={() => setView(views.attachConfiguration)}
          >
            {t(`${translations.calculationWindow}:Attach configuration`)}
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

export default Header;
