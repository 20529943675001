import React, { useEffect, useState } from 'react';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BasicTabBar, { tabInfo } from 'components/layout/tabs/BasicTabBar';
import ICurrentUser from 'api/interfaces/users/ICurrentUser';
import PageContainer from 'components/layout/PageContainer';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import PrimaryButtonDropdown from 'components/layout/buttons/PrimaryButtonDropdown';
import InstallationIcon from 'assets/InstallationPrices.svg';
import ProductsIcon from 'assets/Products.svg';
import SettingsIcon from 'assets/Settings.svg';
import translations from 'services/translations/translations';
import {
  INSTALLATION_PRICES,
  PRODUCTS,
  PRODUCT_CONFIGURATIONS,
} from 'services/types/viewTypes';
import { RIGHT } from 'services/enums/sides';

import ConfigurationsList from './src/products/ConfigurationsList';
import InstallationPricesList from './src/products/InstallationPricesList';
import ProductsList from './src/products/ProductsList';

import 'style/products/Products.css';

const Products = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation([translations.products, translations.common]);
  const [selectedTab, setSelectedTab] = useState(PRODUCTS);

  const currentUser: ICurrentUser = useSelector(
    (state: any) => state.users.current
  );

  useEffect(() => {
    if (currentUser.views.includes(PRODUCTS)) {
      return;
    }

    if (selectedTab === PRODUCTS) {
      setSelectedTab(PRODUCT_CONFIGURATIONS);
    }
  }, [currentUser, selectedTab]);

  const tabs: tabInfo[] = [];

  if (currentUser.views.includes(PRODUCTS)) {
    tabs.push({
      tabIcon: ProductsIcon,
      tabLabel: t('Products'),
      tabId: PRODUCTS,
    });
  }

  if (currentUser.views.includes(PRODUCT_CONFIGURATIONS)) {
    tabs.push({
      tabIcon: SettingsIcon,
      tabLabel: t('Configurations'),
      tabId: PRODUCT_CONFIGURATIONS,
    });
  }

  if (currentUser.views.includes(PRODUCTS)) {
    tabs.push({
      tabIcon: InstallationIcon,
      tabLabel: t('Installation prices'),
      tabId: INSTALLATION_PRICES,
    });
  }

  const getTab = () => {
    if (selectedTab === PRODUCT_CONFIGURATIONS) {
      return <ConfigurationsList />;
    }
    if (selectedTab === PRODUCTS) {
      return <ProductsList />;
    }
    return <InstallationPricesList />;
  };

  return (
    <PageContainer className="products-page">
      <p className="header-text">{t(`${translations.products}:Products`)}</p>
      <PrimaryButtonDropdown
        className="add-product-button"
        text={t(`${translations.common}:Add`)}
      >
        <DropdownMenu className="primary-dropdown-menu" right>
          {currentUser.views.includes(PRODUCTS) ? (
            <DropdownItem onClick={() => history.push('/products/create')}>
              {t(`${translations.products}:Product`).toUpperCase()}
            </DropdownItem>
          ) : null}
          {currentUser.views.includes(PRODUCT_CONFIGURATIONS) ? (
            <DropdownItem
              onClick={() => history.push('/products/configurations/create')}
            >
              {t(
                `${translations.products}:configurations:Product configuration`
              ).toUpperCase()}
            </DropdownItem>
          ) : null}
          {currentUser.views.includes(PRODUCTS) ? (
            <DropdownItem
              onClick={() =>
                history.push('/products/installation-prices/create')
              }
            >
              {t(`${translations.products}:Installation price`).toUpperCase()}
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </PrimaryButtonDropdown>
      {currentUser.views.includes(PRODUCTS) ? (
        <PrimaryButton
          className="glass-configurator-button"
          float={RIGHT}
          onClick={() => history.push('/products/glass-configurator')}
        >
          {t('products:Glass Configurator Products')}
        </PrimaryButton>
      ) : null}
      <div className="clearfix" />
      <BasicTabBar
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        className="wide-tab"
      />
      <div className="basic-table table-background">{getTab()}</div>
    </PageContainer>
  );
};

export default Products;
