import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import { PRODUCT } from 'services/products/productConfigurationItemTypes';

import ISelection from './ISelection';

const getSelectSelectedProducts = (
  item: IProductConfigurationItem,
  selections: ISelection[]
): IProductConfigurationItem[] => {
  const currentSelection = selections.find(
    (selection: ISelection) => selection.id === item.id
  );

  if (currentSelection === undefined) {
    return [];
  }

  const selectedOption = item.children.find(
    (option) => option.id === currentSelection.selection
  );

  if (selectedOption === undefined) {
    return [];
  }

  return selectedOption.children.filter(
    (optionChild) => optionChild.type === PRODUCT
  );
};

export default getSelectSelectedProducts;
