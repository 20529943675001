import React from 'react';

import translations from 'services/translations/translations';
import sortByTranslation from 'services/sort/sortByTranslation';

const getProductGroupOptions = (groups: string[], t: any): JSX.Element[] => {
  const groupsTranslationLocation = `${translations.products}:groups`;

  const getOption = (group: null | string): JSX.Element => {
    if (group === null) {
      return (
        <option key="product-group-empty" value="">
          -
        </option>
      );
    }

    return (
      <option key={`product-group-${group}`} value={group}>
        {t(`${groupsTranslationLocation}:${group}`)}
      </option>
    );
  };

  const groupsSortedByTranslation = [...groups].sort(
    sortByTranslation(t, groupsTranslationLocation)
  );

  const groupsWithEmptyOption = [null, ...groupsSortedByTranslation];

  return groupsWithEmptyOption.map((group: null | string) => getOption(group));
};

export default getProductGroupOptions;
