import React from 'react';
import sortByTranslation from 'services/sort/sortByTranslation';

export const showOptionsNoTranslation = (keyName: string, list: string[]) => {
  const options = [];
  options.push(<option key={`${keyName}00`} value={undefined} />);
  for (let index = 0; index < list.length; index++) {
    options.push(
      <option key={`${keyName}${index}`} value={list[index].toString()}>
        {list[index].toString()}
      </option>
    );
  }
  return options;
};

export const showDesigners = (list: any[]) => {
  const options = [];
  options.push(<option key="designers00" value={undefined} />);
  for (let index = 0; index < list.length; index++) {
    options.push(
      <option key={`designers${index}`} value={list[index].id}>
        {list[index].firstName} {list[index].lastName}
      </option>
    );
  }
  return options;
};

export const showOptions = (
  keyName: string,
  list: string[],
  translations: any,
  t: any
) => {
  const options = [];
  for (let index = 0; index < list.length; index++) {
    options.push(
      <option key={`${keyName}${index}`} value={list[index]}>
        {t(`${translations.offers}:${list[index]}`)}
      </option>
    );
  }
  return options;
};

export const showOptionsFirstBlank = (
  keyName: string,
  list: string[],
  translations: any,
  divider: any,
  t: any
): JSX.Element[] => [
  <option key={`${keyName}00`} value="">
    -
  </option>,
  ...[...list].sort(sortByTranslation(t, translations)).map((item) => (
    <option key={`${keyName}${item}`} value={item}>
      {t(`${translations}${divider}${item}`)}
    </option>
  )),
];
