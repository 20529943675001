import React from 'react';
import { Route } from 'react-router-dom';

import AnyPermission from 'components/layout/AnyPermission';
import GlassConfiguratorProducts from 'components/products/GlassConfiguratorProducts';
import Permission from 'components/layout/Permission';
import Product from 'components/products/Product';
import Products from 'components/products/Products';
import InstallationPrice from 'components/products/src/installationPrices/InstallationPrice';
import { PRODUCTS, PRODUCT_CONFIGURATIONS } from 'services/types/viewTypes';

const products = (): JSX.Element[] => [
  <Route exact key="products-route" path="/products">
    <AnyPermission types={[PRODUCT_CONFIGURATIONS, PRODUCTS]}>
      <Products />
    </AnyPermission>
  </Route>,
  <Route exact key="create-product-route" path="/products/create">
    <Permission type={PRODUCTS}>
      <Product />
    </Permission>
  </Route>,
  <Route
    exact
    key="glass-configurator-route"
    path="/products/glass-configurator"
  >
    <Permission type={PRODUCTS}>
      <GlassConfiguratorProducts />
    </Permission>
  </Route>,
  <Route
    exact
    key="create-installation-price-route"
    path="/products/installation-prices/create"
  >
    <Permission type={PRODUCTS}>
      <InstallationPrice />
    </Permission>
  </Route>,
  <Route
    exact
    key="installation-price-route"
    path="/products/installation-prices/:id"
  >
    <Permission type={PRODUCTS}>
      <InstallationPrice />
    </Permission>
  </Route>,
  <Route exact key="product-route" path="/products/:id">
    <Permission type={PRODUCTS}>
      <Product />
    </Permission>
  </Route>,
];

export default products;
