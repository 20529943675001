import React, { useState } from 'react';
import { finishLoading, startLoading } from 'store/loading/actions';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiOffers from 'api/offers';
import translations from 'services/translations/translations';
import { addMessage } from 'store/messages/actions';

import formatToApiFormat from './src/offerProduct/formatToApiFormat';
import getDefaultFormState from './src/offerProduct/getDefaultFormState';
import IFormState from './src/offerProduct/IFormState';
import OfferProduct from './src/offerProduct/OfferProduct';

const CreateOfferProduct = (): JSX.Element => {
  const [formState, setFormState] = useState<IFormState>(getDefaultFormState());

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation([translations.common]);
  const { id, type }: { id: string | undefined; type: string } = useParams();
  let productID = '';

  const cancel = (calculationWindowOpen?: boolean) => {
    if (calculationWindowOpen === true) {
      history.push(
        `/offers/products/${productID}/${type}?calculationWindowOpen=${true}`
      );
    } else {
      history.push(`/offers/${id}/${type}`);
    }
  };

  const save = (calculationWindowOpen: boolean) => {
    if (id === undefined) {
      return;
    }

    const postProduct = async () => {
      dispatch(startLoading('offer-product-post'));

      if (type === 'additional-offer') {
        try {
          await apiOffers.products.postAdditionalOffer(
            parseInt(id, 10),
            formatToApiFormat(formState)
          );
        } catch (error) {
          if (error.response.status === 400) {
            dispatch(finishLoading('offer-product-post'));
            dispatch(addMessage(t(`${translations.common}:Fill all fields`)));
          }
          return;
        }
      } else {
        try {
          const response = await apiOffers.products.post(
            parseInt(id, 10),
            formatToApiFormat(formState)
          );
          if (response) {
            productID = String(response.id);
          }
        } catch (error) {
          if (error.response.status === 400) {
            dispatch(finishLoading('offer-product-post'));
            dispatch(addMessage(t(`${translations.common}:Fill all fields`)));
          }
          return;
        }
      }

      dispatch(finishLoading('offer-product-post'));
      cancel(calculationWindowOpen);
    };
    postProduct();
  };

  return (
    <OfferProduct
      action="create"
      cancel={cancel}
      formState={formState}
      initialStatus={null}
      save={save}
      setFormState={setFormState}
    />
  );
};

export default CreateOfferProduct;
