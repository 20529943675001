import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Label } from 'reactstrap';

import MultiSelectDropdown from 'components/layout/forms/MultiSelectDropdown';
import IProductId from 'interfaces/products/IProductId';
import IContact from 'interfaces/contacts/IContact';
import { showOptionsFirstBlank } from 'components/offers/OfferSelectOptions';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';
import {
  fetchCategories,
  fetchDeliveryCategoryTypes,
  fetchTagTypes,
} from 'store/products/actions';

import getSupplierOptions from './src/product/getSupplierOptions';
import { showCategoryOptions, showTagOptions } from './ProductSelectOptions';
import showIDFields from './showIDFields';

const ProductBasicInfo = ({
  idValues,
  addIdValue,
  formState,
  handleFormState,
  updateSupplier,
  suppliers,
  categories,
  handleCategories,
  categoriesList,
  supplierId,
  deliveryCategory,
  updateDeliveryCategory,
  deliveryCategoryTypesList,
  handleTags,
  tagTypesList,
  tags,
  fetchDeliveryCategoryTypesList,
  fetchCategoryTypesList,
  fetchTagTypesList,
}: {
  idValues: IProductId[];
  addIdValue: any;
  formState: any;
  handleFormState: any;
  updateSupplier: any;
  suppliers: IContact[];
  categories: any;
  handleCategories: any;
  categoriesList: string[];
  supplierId: null | number;
  deliveryCategory: string;
  updateDeliveryCategory: any;
  deliveryCategoryTypesList: string[];
  tags: any;
  handleTags: any;
  tagTypesList: string[];
  fetchDeliveryCategoryTypesList: any;
  fetchCategoryTypesList: any;
  fetchTagTypesList: () => void;
}): JSX.Element | null => {
  const { t } = useTranslation([
    translations.offers,
    translations.common,
    translations.products,
  ]);

  useEffect(() => {
    fetchDeliveryCategoryTypesList();
    fetchCategoryTypesList();
    fetchTagTypesList();
  }, [
    fetchCategoryTypesList,
    fetchDeliveryCategoryTypesList,
    fetchTagTypesList,
  ]);

  return (
    <>
      <Row>{showIDFields(idValues, addIdValue, t, translations)}</Row>
      <Row>
        <Col md={4}>
          <TextInputField
            value={formState.name}
            label={`${t(`${translations.products}:Name`)} *`}
            required
            id="name"
            name="name"
            onChange={handleFormState}
          />
        </Col>
        <Col md={4}>
          <TextInputField
            value={formState.displayName}
            label={`${t(`${translations.products}:Display Name`)} *`}
            required
            id="displayName"
            name="displayName"
            onChange={handleFormState}
          />
        </Col>
        <Col md={4}>
          <TextInputField
            value={formState.sPName}
            label={`${t(`${translations.products}:Supplier Product Name`)} *`}
            required
            id="sPName"
            name="sPName"
            onChange={handleFormState}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label className="label">
              {t(`${translations.products}:Category`)}
            </Label>
            <MultiSelectDropdown
              placeholder={t(`${translations.products}:Select`)}
              required
              name="category"
              value={categories}
              onChange={handleCategories}
              options={
                categoriesList
                  ? showCategoryOptions(categoriesList, translations, t)
                  : ''
              }
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <SelectInputFieldControlled
            label={`${t(`${translations.products}:Supplier`)} *`}
            required
            id="supplierID"
            name="supplierID"
            value={supplierId === null ? '' : supplierId}
            onChange={updateSupplier}
            options={getSupplierOptions(suppliers)}
          />
        </Col>
        <Col md={4}>
          <SelectInputFieldControlled
            label={`${t(`${translations.products}:Delivery category`)} *`}
            required
            id="deliveryCategory"
            name="deliveryCategory"
            value={deliveryCategory}
            onChange={updateDeliveryCategory}
            options={showOptionsFirstBlank(
              'deliveryCategory',
              deliveryCategoryTypesList,
              translations.products,
              ':deliveryCategoryTypes.',
              t
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label className="label">
              {t(`${translations.products}:Tags`)}
            </Label>
            <MultiSelectDropdown
              placeholder={t(`${translations.products}:Select`)}
              required
              name="tags"
              value={tags}
              onChange={handleTags}
              options={
                tagTypesList
                  ? showTagOptions(tagTypesList, translations, t)
                  : ''
              }
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  categoryTypesList: state.products.categoryTypes,
  deliveryCategoryTypesList: state.products.deliveryCategoryTypes,
  tagTypesList: state.products.tagTypes,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchCategoryTypesList: () => dispatch(fetchCategories()),
  fetchDeliveryCategoryTypesList: () => dispatch(fetchDeliveryCategoryTypes()),
  fetchTagTypesList: () => dispatch(fetchTagTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductBasicInfo);
