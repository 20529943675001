import IApiProductConfigurationAttribute from 'api/interfaces/products/IProductConfigurationAttribute';
import IInstallationPriceGet from 'api/interfaces/installationPrices/IInstallationPriceGet';
import IProductGet from 'api/interfaces/products/IProductGet';
import IProductConfigurationAttribute from 'interfaces/products/IProductConfigurationAttribute';

import {
  INSTALLATION_ID,
  PRODUCT_ID,
} from 'services/products/productConfigurationAttributeTypes';

const getDisplayValue = (
  attribute: IApiProductConfigurationAttribute,
  products: IProductGet[],
  installations: IInstallationPriceGet[]
): string | null => {
  if (attribute.type === PRODUCT_ID) {
    if (attribute.value === null) {
      return null;
    }

    const matchingProduct: IProductGet | undefined = products.find(
      (product: IProductGet): boolean =>
        product.id === parseInt(attribute.value || '', 10)
    );

    if (matchingProduct === undefined) {
      return null;
    }

    const name = matchingProduct.name ?? '';

    return ['', null].includes(matchingProduct.displayName)
      ? name
      : matchingProduct.displayName;
  }

  if (attribute.type === INSTALLATION_ID) {
    if (attribute.value === null) {
      return null;
    }

    const matchingInstallation = installations.find(
      (installation: IInstallationPriceGet): boolean =>
        installation.id === parseInt(attribute.value || '', 10)
    );

    return matchingInstallation === undefined
      ? null
      : matchingInstallation.name;
  }

  return null;
};

const formatAttribute = (
  attribute: IApiProductConfigurationAttribute,
  products: IProductGet[],
  installations: IInstallationPriceGet[]
): IProductConfigurationAttribute | undefined => ({
  displayValue: getDisplayValue(attribute, products, installations),
  type: attribute.type,
  value: attribute.value,
});

export default formatAttribute;
