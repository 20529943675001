import React from 'react';

import 'style/layout/BasicFormContainer.css';

const BasicFormContainer = ({
  children,
}: {
  children: JSX.Element | JSX.Element[] | string;
}): JSX.Element => <div className="basic-form-container">{children}</div>;

export default BasicFormContainer;
