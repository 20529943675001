import getInstallationSellingPrice from './getInstallationSellingPrice';
import getSubproductSellingPrice from './getSubproductSellingPrice';
import IInstallation from './IInstallation';
import ISubproduct from './ISubproduct';
import IDetail from './IDetail';

const getSellingPricePerUnit = (
  installations: IInstallation[],
  subproducts: ISubproduct[] | IDetail[]
): number => {
  const subproductsSellingPriceSum = subproducts
    .map((subproduct): number => getSubproductSellingPrice(subproduct))
    .reduce((sum, currentValue) => sum + currentValue, 0);

  const installationsSellingPriceSum = installations
    .map((installation): number => getInstallationSellingPrice(installation))
    .reduce((sum, currentValue) => sum + currentValue, 0);

  return subproductsSellingPriceSum + installationsSellingPriceSum;
};

export default getSellingPricePerUnit;
