import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import EditIcon from 'components/layout/icons/EditIcon';
import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import translations from 'services/translations/translations';

import {
  CUSTOM_PRODUCT,
  IS_CUSTOM_SUBPRODUCT,
  PRODUCT_ID,
} from 'services/products/productConfigurationAttributeTypes';

import getAttribute from '../getAttribute';

import 'style/products/configurations/src/items/ProductsTable.css';
import DeleteIcon from '../../../../layout/icons/DeleteIcon';

interface IRowData {
  id: number;
  key: string;
  name: string;
}

const ProductsTable = ({
  items,
  onDelete,
}: {
  items: IProductConfigurationItem[];
  onDelete: (itemId: number) => void;
}): JSX.Element => {
  const { t } = useTranslation([translations.products]);
  const history = useHistory();

  const getProductName = (item: IProductConfigurationItem) => {
    const isCustomSubproduct = getAttribute(item, IS_CUSTOM_SUBPRODUCT);
    if (isCustomSubproduct?.value === '1') {
      const customSubproduct = getAttribute(item, CUSTOM_PRODUCT);
      return customSubproduct?.value ?? '';
    }

    const product = getAttribute(item, PRODUCT_ID);
    if (product === undefined) {
      return '';
    }

    return product.displayValue;
  };

  const onEditIconClick = (row: IRowData) => () => {
    history.push(`/products/configurations/items/${row.id}`);
  };

  const onDeleteIconClick = (row: IRowData) => () => {
    onDelete(row.id);
  };

  const rows = items.map((item) => ({
    id: item.id,
    key: item.key,
    name: getProductName(item),
  }));

  return (
    <div className="products-table">
      <BootstrapTable
        bordered={false}
        columns={[
          {
            dataField: 'name',
            text: t(`${translations.products}:Product`),
          },
          {
            dataField: 'actions',
            text: '',
            formatter: (cell, row) => {
              return (
                <div style={{ float: 'right' }}>
                  <DeleteIcon
                    className="delete-icon"
                    onClick={onDeleteIconClick(row)}
                  />
                  <EditIcon
                    className="edit-icon"
                    onClick={onEditIconClick(row)}
                  />
                </div>
              );
            },
          },
        ]}
        data={rows}
        keyField="key"
      />
    </div>
  );
};

export default ProductsTable;
