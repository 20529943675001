import { FINISH_LOADING, START_LOADING } from './types';

export const finishLoading = (
  item: string
): {
  type: string;
  item: string;
} => ({
  type: FINISH_LOADING,
  item,
});

export const startLoading = (
  item: string
): {
  type: string;
  item: string;
} => ({
  type: START_LOADING,
  item,
});
