import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTabTypes } from 'store/projects/actions';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BasicTabBarPlus from 'components/layout/tabs/BasicTabBarPlus';
import PageContainer from 'components/layout/PageContainer';

import apiProjects from '../../api/projects';
import BasicInfoTab from './BasicInfoTab';
import formatProjecTabs from './formatProjectTabs';
import IProjectGetSingle from '../../api/interfaces/projects/IProjectGetSingle';
import moveNotesToFirstPosition from './moveNotestoFirstPosition';
import translations from '../../services/translations/translations';
import { BASIC_INFORMATION, MEASUREMENT_CARD, OFFERS } from './tabTypes';
import ProjectMeasurementTab from './projectMeasurementTab/ProjectMeasurementTab';
import ProjectOffersTab from './ProjectOffersTab';

import 'style/projects/Project.css';
import 'style/userManagement/UserManagement.css';

const Project = ({
  tabTypesList,
  fetchTabTypesList,
}: {
  tabTypesList: string[];
  fetchTabTypesList: () => void;
}): JSX.Element => {
  const history = useHistory();
  const { projectId, tab } = useParams();
  const { t } = useTranslation([
    translations.admin,
    translations.common,
    translations.projects,
  ]);
  const [projectTabs, setProjectTabs] = useState<string[]>([]);
  const [availableTabs, setAvailableTabs] = useState<string[]>([]);
  const [newTab, setNewTab] = useState<{ type: string } | undefined>();
  const [project, setProject] = useState<IProjectGetSingle>();

  useEffect(() => {
    const getProject = async () => {
      const result = await apiProjects.getProject(projectId);
      setProject(result);
    };
    getProject().catch((e) => console.log(e));
  }, [projectId]);

  useEffect(() => {
    fetchTabTypesList();
    const getProjectTabs = async () => {
      const result = await apiProjects.tabs.get(projectId);
      const sortedTabs = moveNotesToFirstPosition(result);
      setProjectTabs(sortedTabs);
    };
    getProjectTabs().catch((e) => console.log(e));
  }, [fetchTabTypesList, projectId, newTab]);

  const addTab = (tb: string) => {
    const typeTab = { type: tb };
    const postNewTab = async () => {
      const result = await apiProjects.tabs.post(projectId, typeTab);
      setNewTab(typeTab);
    };
    postNewTab().catch((e) => console.log(e));
  };

  useEffect(() => {
    setAvailableTabs(
      tabTypesList.filter((type) => !projectTabs.includes(type))
    );
  }, [projectTabs, tabTypesList]);

  const getSelectedContent = () => {
    if (tab === BASIC_INFORMATION) {
      return <BasicInfoTab project={project} />;
    }

    if (tab === MEASUREMENT_CARD) {
      return <ProjectMeasurementTab />;
    }

    if (tab === OFFERS) {
      return <ProjectOffersTab />;
    }

    return null;
  };

  const changeTab = (selectedTab: string) => {
    history.push(`/projects/${projectId}/${selectedTab}`);
  };

  const getHeader = (): null | string => {
    if (!project) {
      return null;
    }

    if (project.customerContacts.length === 0) {
      return null;
    }

    return project.customerContacts[0].contact.companyName;
  };

  return (
    <PageContainer>
      <div className="row-content project-header">
        <span className="black-text-subheader header-text">
          {project?.name}
        </span>
        <span className="grey-text customer-grey-header">{getHeader()}</span>
      </div>
      <BasicTabBarPlus
        availableTabs={availableTabs}
        tabs={projectTabs ? formatProjecTabs(projectTabs, t) : []}
        selectedTab={tab}
        setSelectedTab={changeTab}
        addTab={addTab}
      />
      <div className="basic-table table-background">{getSelectedContent()}</div>
    </PageContainer>
  );
};

const mapStateToProps = (state: any) => ({
  tabTypesList: state.projects.tabTypes,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchTabTypesList: () => dispatch(fetchTabTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Project);
