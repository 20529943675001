import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import IPermission from 'interfaces/users/IPermission';
import translations from 'services/translations/translations';

import SelectInput, {
  ISelectInputOption,
} from 'components/layout/forms/SelectInput';

const OwnUser = ({
  permission,
  setPermission,
}: {
  permission: IPermission;
  setPermission: (permission: IPermission) => void;
}): JSX.Element => {
  const { t } = useTranslation([translations.userManagement]);

  const getOptions = () => {
    const options: ISelectInputOption[] = [];

    options.unshift({
      displayValue: t(`${translations.userManagement}:Own user`),
      style: { fontWeight: 500 },
      value: 'own-user',
    });

    options.unshift({ displayValue: '-', value: '' });

    return options;
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPermission({
      ...permission,
      target: e.target.value,
    });
  };

  return (
    <SelectInput
      key="permission-target"
      onChange={onChange}
      options={getOptions()}
      value={permission.target === null ? '' : permission.target}
    />
  );
};

export default OwnUser;
