import React from 'react';
import { useTranslation } from 'react-i18next';

import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import translations from 'services/translations/translations';

import {
  CUSTOM_PRODUCT,
  IS_CUSTOM_SUBPRODUCT,
  PRODUCT_ID,
} from 'services/products/productConfigurationAttributeTypes';

import Actions from './Actions';
import getAttribute from '../../getAttribute';

const ProductItem = ({
  item,
  onDelete,
}: {
  item: IProductConfigurationItem;
  onDelete: (itemId: number) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.products]);

  const getProductName = () => {
    const isCustomSubproduct = getAttribute(item, IS_CUSTOM_SUBPRODUCT);

    if (isCustomSubproduct?.value === '1') {
      const customProduct = getAttribute(item, CUSTOM_PRODUCT);
      return customProduct === undefined ? '' : customProduct.value;
    }

    const product = getAttribute(item, PRODUCT_ID);
    return product === undefined ? '' : product.displayValue;
  };

  return (
    <div className="product-item">
      <div>
        <h4 className="item-title">
          {t(`${translations.products}:Product`)}: {getProductName()}
        </h4>
        <Actions item={item} onDelete={onDelete} />
        <div className="clearfix" />
      </div>
    </div>
  );
};

export default ProductItem;
