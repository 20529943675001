import { SET_MODAL } from '../types';
import IModalAction from '../../interfaces/store/IModalAction';
import IModalState from '../../interfaces/store/IModalState';

const initialState = {
  parameters: null,
  type: null,
};

export default (
  state: IModalState = initialState,
  action: IModalAction
): IModalState => {
  if (action.type === SET_MODAL) {
    return {
      parameters: action.parameters,
      type: action.modal,
    };
  }

  return state;
};
