interface IAsyncAction {
  FAILURE: string;
  PENDING: string;
  SUCCESS: string;
}

const asyncAction = (name: string): IAsyncAction => ({
  FAILURE: `${name}_FAILURE`,
  PENDING: `${name}_PENDING`,
  SUCCESS: `${name}_SUCCESS`,
});

export default asyncAction;
