import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'style/layout/modal/ModalConfirm.css';
import translations from 'services/translations/translations';
import { useTranslation } from 'react-i18next';

const ModalAccept = ({
  showing,
  setShowing,
  header,
  text,
}: {
  showing: boolean;
  setShowing: (showing: boolean) => void;
  header: string;
  text: string;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common]);
  const toggle = () => setShowing(!showing);
  return (
    <>
      <Modal isOpen={showing} toggle={toggle} centered>
        <ModalHeader className="modal-header" toggle={toggle}>
          {header}
        </ModalHeader>
        <ModalBody>{text}</ModalBody>
        <ModalFooter>
          <Button onClick={toggle} className="modal-button">
            {t(`${translations.common}:OK`)}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalAccept;
