import IGlassConfiguratorProduct from 'interfaces/products/IGlassConfiguratorProduct';
import { AMOUNT } from 'services/enums/units';
import { INLOOK, INLOOK_OLD, SUPPLIER } from 'services/enums/products/ids';
import { GLASS_CONFIGURATOR_PRODUCT } from 'services/enums/products/tags';

const getEmptyGlassConfiguratorProduct = (
  productsFormValues: IGlassConfiguratorProduct[]
): IGlassConfiguratorProduct[] => {
  const emptyValues: IGlassConfiguratorProduct = {
    categories: [],
    id: null,
    length: '',
    name: '',
    kgM: '',
    paintingArea: '',
    painting1: '',
    painting2: '',
    painting3: '',
    painting4: '',
    productIds: [
      { type: INLOOK, value: '' },
      { type: INLOOK_OLD, value: '' },
      { type: SUPPLIER, value: '' },
    ],
    purchasePrice: '',
    purchaseUnit: AMOUNT,
    supplierId: null,
    tags: [GLASS_CONFIGURATOR_PRODUCT],
    imageId: null,
    waste: '',
  };
  return [...productsFormValues, emptyValues];
};

export default getEmptyGlassConfiguratorProduct;
