import IOfferGet from 'api/interfaces/offers/IOfferGet';
import IOfferGetSingle from 'api/interfaces/offers/IOfferGetSingle';
import api from './api';
import IOfferProductPost from './interfaces/offers/IOfferProductPost';
import IOfferProductGet from './interfaces/offers/IOfferProductGet';
import IOfferProductsGet from './interfaces/offers/IOfferProductsGet';
import IIdResult from './interfaces/results/IIdResult';
import ISuccessResult from './interfaces/results/ISuccessResult';
import IAdditionalOfferGet from './interfaces/offers/IAdditionalOfferGet';

export default {
  additionalOffers: {
    get: async (id?: number): Promise<IAdditionalOfferGet> =>
      api.get(`/api/v1/additionaloffers/${id}`),
    pdfs: {
      get: async (id?: number): Promise<any> =>
        api.getPdf(`/api/v1/additionaloffers/${id}/pdf`),
    },
    post: async (offer?: any): Promise<any> =>
      api.post('/api/v1/additionaloffers', offer),
    postCopyAdditionalOffer: async (id?: number): Promise<any> => api.post(`/api/v1/copyadditionaloffer/${id}`),
    put: async (offer?: any, id?: number): Promise<any> =>
      api.put(`/api/v1/additionaloffers/${id}`, offer),
  },
  count: async (filters: {
    created?: string;
    createdUserId?: string;
    search?: string;
    orderBy?: string;
    orderByDirection?: string;
    projectId?: number;
    updated?: string;
    updatedUserId?: string;
    contactId?: string;
    type?: string;
    status?: string;
  }): Promise<any> => api.get('/api/v1/offers/count', filters),
  get: async (filters: {
    created?: string;
    createdUserId?: string;
    limit?: number;
    offset?: number;
    search?: string;
    orderBy?: string;
    orderByDirection?: string;
    projectId?: number;
    updated?: string;
    updatedUserId?: string;
    contactId?: string;
    type?: string;
    status?: string;
  }): Promise<IOfferGet[]> => api.get('/api/v1/offers', filters),
  getOffer: async (id?: number): Promise<IOfferGetSingle> =>
    api.get(`/api/v1/offers/${id}`),
  offerTypes: {
    get: async (): Promise<string[]> => api.get('/api/v1/offers/types'),
  },
  pdfs: {
    get: async (id?: number): Promise<any> =>
      api.getPdf(`/api/v1/offers/${id}/pdf`),
  },
  post: async (offer?: any): Promise<any> => api.post('/api/v1/offers', offer),
  postCopyOffer: async (id?: number): Promise<any> => api.post(`/api/v1/copyoffer/${id}`),

  products: {
    delete: (id: number): Promise<ISuccessResult> =>
      api.delete(`/api/v1/offers/products/${id}`),
    get: (id: number): Promise<IOfferProductGet> =>
      api.get(`/api/v1/offers/products/${id}`),
    getForAdditionalOffer: (offerId: number): Promise<IOfferProductsGet[]> =>
      api.get(`/api/v1/additionaloffers/${offerId}/products`),
    getForOffer: (offerId: number): Promise<IOfferProductsGet[]> =>
      api.get(`/api/v1/offers/${offerId}/products`),
    post: async (
      offerId: number,
      offerProduct: IOfferProductPost
    ): Promise<IIdResult> =>
      api.post(`/api/v1/offers/${offerId}/products`, offerProduct),
    postAdditionalOffer: async (
      offerId: number,
      offerProduct: IOfferProductPost
    ): Promise<IIdResult> =>
      api.post(`/api/v1/additionaloffers/${offerId}/products`, offerProduct),
    put: async (
      id: number,
      offerProduct: IOfferProductPost
    ): Promise<IIdResult> =>
      api.put(`/api/v1/offers/products/${id}`, offerProduct),
    putAdditionalOffer: async (
      id: number,
      offerProduct: IOfferProductPost
    ): Promise<IIdResult> =>
      api.put(`/api/v1/offers/products/${id}`, offerProduct),
  },
  put: async (offer?: any, id?: number): Promise<any> =>
    api.put(`/api/v1/offers/${id}`, offer),
  status: {
    get: async (): Promise<string[]> => api.get('/api/v1/offers/types/status'),
  },
};
