import React from 'react';
import { useTranslation } from 'react-i18next';

import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import translations from 'services/translations/translations';
import { APPROVED } from 'services/enums/offers/Statuses';
import { RIGHT } from 'services/enums/sides';

const ActionButtons = ({
  cancel,
  initialStatus,
  remove,
  save,
  saveButtonText,
}: {
  cancel: () => void;
  initialStatus: null | string;
  remove?: () => void | undefined;
  save: () => void;
  saveButtonText: string;
}): JSX.Element => {
  const { t } = useTranslation([translations.common]);

  const offerIsApproved = () => initialStatus === APPROVED;

  return (
    <>
      <PrimaryButton className="button-one-line" float={RIGHT} onClick={cancel}>
        {t(`${translations.common}:Cancel`).toLocaleUpperCase()}
      </PrimaryButton>
      {remove === undefined || offerIsApproved() ? null : (
        <PrimaryButton
          className="button-one-line"
          float={RIGHT}
          onClick={remove}
        >
          {t(`${translations.common}:Remove`).toLocaleUpperCase()}
        </PrimaryButton>
      )}
      {offerIsApproved() ? null : (
        <PrimaryButton className="button-one-line" float={RIGHT} onClick={save}>
          {saveButtonText.toLocaleUpperCase()}
        </PrimaryButton>
      )}
      <div className="clearfix" />
    </>
  );
};

export default ActionButtons;
