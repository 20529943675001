import { AxiosError } from 'axios';

import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  takeEvery,
} from 'redux-saga/effects';

import apiCommon from 'api/common';

import FETCH_SETTINGS from './types';
import { finishLoading, startLoading } from '../loading/actions';

function* fetchSettings(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_SETTINGS.PENDING));
    const settings = yield call(apiCommon.settings.get);
    yield put({ type: FETCH_SETTINGS.SUCCESS, settings });
    yield put(finishLoading(FETCH_SETTINGS.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({ type: FETCH_SETTINGS.FAILURE, message: axiosError.message });
  }
}

function* saga(): IterableIterator<ForkEffect> {
  yield takeEvery(FETCH_SETTINGS.PENDING, fetchSettings);
}

export default saga;
