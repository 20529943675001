import getTargetValue from 'services/forms/getTargetValue';
import IHindrancePost from 'interfaces/projects/IHindrancePost';

const updateHindrance = (
  hindranceValues: IHindrancePost[],
  e: any,
  index: number
) => {
  const newValues = [...hindranceValues];
  const type = e.target.name;
  if (type === 'type') {
    newValues[index].type = getTargetValue(e);
  } else if (type === 'value') {
    newValues[index].value = getTargetValue(e);
  }
  return newValues;
};

export default updateHindrance;
