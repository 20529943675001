import IPlaceMeasurement from 'interfaces/projects/IPlaceMeasurement';
import roundTwoDecimals from 'services/numbers/roundTwoDecimals';
import fromMillimitersToMeters from './fromMillimitersToMeters';

const getAllSquares = (row: any) => {
  return roundTwoDecimals(
    row.measurements
      .map((measurement: IPlaceMeasurement) => {
        return (
          fromMillimitersToMeters(measurement.width) *
          fromMillimitersToMeters(measurement.height)
        );
      })
      .reduce((total: number, value: number) => {
        return total + value;
      }, 0)
  );
};

export default getAllSquares;
