import React from 'react';
import Select from 'react-select';

import 'style/layout/form/MultiSelectDropdown.css';

const MultiSelectDropdown = ({
  className,
  name,
  onChange,
  options,
  placeholder,
  required,
  value,
}: {
  className?: string;
  name: string;
  onChange: any;
  options: any;
  placeholder: string;
  required: boolean;
  value: any;
}): JSX.Element | null => {
  const getClassName = () => {
    const classes = ['text-field', 'search-field'];

    if (className !== undefined) {
      classes.push(className);
    }

    return classes.join(' ');
  };

  return (
    <Select
      className={getClassName()}
      isMulti
      name={name}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      required={required}
      value={value}
    />
  );
};

export default MultiSelectDropdown;
