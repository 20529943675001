import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import 'style/layout/Table.css';

const BasicTableExpandable = ({
  columns,
  data,
  keyField,
  expandRow,
  tableClasses,
}: {
  columns: any;
  data: any;
  keyField: any;
  expandRow: any;
  tableClasses?: string;
}): JSX.Element | null => {
  return (
    <div className="basic-table">
      <BootstrapTable
        classes={tableClasses}
        keyField={keyField}
        headerWrapperClasses="table-header"
        bodyClasses="table-body"
        rowClasses="row-body no-padding-td"
        bordered={false}
        columns={columns}
        data={data || []}
        remote
        expandRow={expandRow}
      />
    </div>
  );
};

export default BasicTableExpandable;
