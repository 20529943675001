import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import apiUserManagement from 'api/userManagement';
import IOrganizationUnit from 'interfaces/users/IOrganizationUnit';
import IPermission from 'interfaces/users/IPermission';
import translations from 'services/translations/translations';

import SelectInput, {
  ISelectInputOption,
} from 'components/layout/forms/SelectInput';

const OrganizationUnit = ({
  permission,
  setPermission,
  withOwnOrganization,
}: {
  permission: IPermission;
  setPermission: (permission: IPermission) => void;
  withOwnOrganization?: boolean;
}): JSX.Element => {
  const { t } = useTranslation([translations.userManagement]);
  const [organizationUnits, setOrganizationUnits] = useState<
    IOrganizationUnit[]
  >([]);

  const initialize = async () => {
    const result = await apiUserManagement.organizationUnits.get();
    setOrganizationUnits(result);
  };

  useEffect(() => {
    initialize();
  }, []);

  const getOptions = () => {
    const options: ISelectInputOption[] = [
      ...organizationUnits.map((organizationUnit) => ({
        displayValue: organizationUnit.name,
        value: organizationUnit.id.toString(),
      })),
    ].sort((a, b) => {
      if (a.displayValue === b.displayValue) {
        return 0;
      }

      return a.displayValue < b.displayValue ? -1 : 1;
    });

    if (withOwnOrganization) {
      options.unshift({
        displayValue: t(
          `${translations.userManagement}:Own root organization unit`
        ),
        style: { fontWeight: 500 },
        value: 'own-root-organization-unit',
      });
    }

    options.unshift({ displayValue: '-', value: '' });

    return options;
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPermission({
      ...permission,
      target: e.target.value,
    });
  };

  return (
    <SelectInput
      key="permission-target"
      onChange={onChange}
      options={getOptions()}
      value={permission.target === null ? '' : permission.target}
    />
  );
};

export default OrganizationUnit;
