import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import Confirm from './Confirm';
import Message from './Message';
import NavMenu from './NavMenu';
import Spinner from './Spinner';
import '../../style/layout/BasicLayout.css';

const Layout = ({
  children,
  user,
}: {
  children: JSX.Element | JSX.Element[];
  user: null | string;
}): JSX.Element => (
  <>
    {user === null ? null : <NavMenu />}
    <Container fluid className="main-container">
      {children}
    </Container>
    <Confirm />
    <Message />
    <Spinner />
  </>
);

const mapStateToProps = (state: { users: { current: null | string } }) => ({
  user: state.users.current,
});

export default connect(mapStateToProps)(Layout);
