import IAttributeType from 'api/interfaces/products/IAttributeType';
import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import IProductState from 'interfaces/store/IProductState';

import {
  FETCH_ABSORPTION_CLASSES,
  FETCH_ATTRIBUTE_TYPES,
  FETCH_CATEGORIES,
  FETCH_CERTIFICATE_TYPES,
  FETCH_CLASSES_OF_FIRE,
  FETCH_DELIVERY_CATEGORY_TYPES,
  FETCH_EDGE_TYPES,
  FETCH_FASTENER_TYPES,
  FETCH_FILE_TYPES,
  FETCH_GROUPS,
  FETCH_INSTALLATION_CATEGORIES,
  FETCH_INSTALLATION_METHOD_TYPES,
  FETCH_INSTALLATION_PRICE_TYPES,
  FETCH_ISO_CLEANROOM_CLASSES,
  FETCH_MATERIAL_SURFACE_TYPES,
  FETCH_MATERIAL_TYPES,
  FETCH_MEASUREMENT_TYPES,
  FETCH_PERFORATION_TYPES,
  FETCH_PRICE_TYPES,
  FETCH_PRODUCT_ID_TYPES,
  FETCH_TAG_TYPES,
  FETCH_TIP_TYPES,
  FETCH_UNITS,
  SET_CONFIGURATION_ITEMS,
} from './types';

const initialState: IProductState = {
  absorptionClasses: [],
  attributeTypes: [],
  categories: [],
  certificateTypes: [],
  classesOfFire: [],
  configurationItems: [],
  deliveryCategoryTypes: [],
  edgeTypes: [],
  fastenerTypes: [],
  fileTypes: [],
  groups: [],
  installationCategories: [],
  installationMethodTypes: [],
  installationPriceTypes: [],
  isoCleanroomClasses: [],
  materialSurfaceTypes: [],
  materialTypes: [],
  measurementTypes: [],
  perforationTypes: [],
  priceTypes: [],
  productTypes: [],
  tagTypes: [],
  tipTypes: [],
  units: [],
};

export default (
  state: IProductState = initialState,
  action: {
    absorptionClasses: string[];
    attributeTypes: IAttributeType[];
    categories: string[];
    certificateTypes: string[];
    classesOfFire: string[];
    configurationItems: IProductConfigurationItem[];
    deliveryCategoryTypes: string[];
    edgeTypes: string[];
    fastenerTypes: string[];
    fileTypes: string[];
    groups: string[];
    installationCategories: string[];
    installationMethodTypes: string[];
    installationPriceTypes: string[];
    isoCleanroomClasses: string[];
    materialSurfaceTypes: string[];
    materialTypes: string[];
    measurementTypes: string[];
    perforationTypes: string[];
    priceTypes: string[];
    productTypes: string[];
    type: string;
    tagTypes: string[];
    tipTypes: string[];
    units: string[];
  }
): IProductState => {
  if (action.type === FETCH_ABSORPTION_CLASSES.SUCCESS) {
    return {
      ...state,
      absorptionClasses: action.absorptionClasses,
    };
  }

  if (action.type === FETCH_ATTRIBUTE_TYPES.SUCCESS) {
    return {
      ...state,
      attributeTypes: action.attributeTypes,
    };
  }

  if (action.type === FETCH_CATEGORIES.SUCCESS) {
    return {
      ...state,
      categories: action.categories,
    };
  }

  if (action.type === FETCH_CERTIFICATE_TYPES.SUCCESS) {
    return {
      ...state,
      certificateTypes: action.certificateTypes,
    };
  }

  if (action.type === FETCH_CLASSES_OF_FIRE.SUCCESS) {
    return {
      ...state,
      classesOfFire: action.classesOfFire,
    };
  }

  if (action.type === FETCH_EDGE_TYPES.SUCCESS) {
    return {
      ...state,
      edgeTypes: action.edgeTypes,
    };
  }

  if (action.type === FETCH_DELIVERY_CATEGORY_TYPES.SUCCESS) {
    return {
      ...state,
      deliveryCategoryTypes: action.deliveryCategoryTypes,
    };
  }

  if (action.type === FETCH_FASTENER_TYPES.SUCCESS) {
    return {
      ...state,
      fastenerTypes: action.fastenerTypes,
    };
  }

  if (action.type === FETCH_FILE_TYPES.SUCCESS) {
    return {
      ...state,
      fileTypes: action.fileTypes,
    };
  }

  if (action.type === FETCH_GROUPS.SUCCESS) {
    return {
      ...state,
      groups: action.groups,
    };
  }

  if (action.type === FETCH_INSTALLATION_CATEGORIES.SUCCESS) {
    return {
      ...state,
      installationCategories: action.installationCategories,
    };
  }

  if (action.type === FETCH_INSTALLATION_METHOD_TYPES.SUCCESS) {
    return {
      ...state,
      installationMethodTypes: action.installationMethodTypes,
    };
  }

  if (action.type === FETCH_INSTALLATION_PRICE_TYPES.SUCCESS) {
    return {
      ...state,
      installationPriceTypes: action.installationPriceTypes,
    };
  }

  if (action.type === FETCH_ISO_CLEANROOM_CLASSES.SUCCESS) {
    return {
      ...state,
      isoCleanroomClasses: action.isoCleanroomClasses,
    };
  }

  if (action.type === FETCH_MATERIAL_SURFACE_TYPES.SUCCESS) {
    return {
      ...state,
      materialSurfaceTypes: action.materialSurfaceTypes,
    };
  }

  if (action.type === FETCH_MATERIAL_TYPES.SUCCESS) {
    return {
      ...state,
      materialTypes: action.materialTypes,
    };
  }

  if (action.type === FETCH_MEASUREMENT_TYPES.SUCCESS) {
    return {
      ...state,
      measurementTypes: action.measurementTypes,
    };
  }

  if (action.type === FETCH_PERFORATION_TYPES.SUCCESS) {
    return {
      ...state,
      perforationTypes: action.perforationTypes,
    };
  }

  if (action.type === FETCH_PRICE_TYPES.SUCCESS) {
    return {
      ...state,
      priceTypes: action.priceTypes,
    };
  }

  if (action.type === FETCH_PRODUCT_ID_TYPES.SUCCESS) {
    return {
      ...state,
      productTypes: action.productTypes,
    };
  }

  if (action.type === FETCH_TAG_TYPES.SUCCESS) {
    return {
      ...state,
      tagTypes: action.tagTypes,
    };
  }

  if (action.type === FETCH_TIP_TYPES.SUCCESS) {
    return {
      ...state,
      tipTypes: action.tipTypes,
    };
  }

  if (action.type === FETCH_UNITS.SUCCESS) {
    return {
      ...state,
      units: action.units,
    };
  }

  if (action.type === SET_CONFIGURATION_ITEMS) {
    return {
      ...state,
      configurationItems: action.configurationItems,
    };
  }

  return state;
};
