import React from 'react';
import { useTranslation } from 'react-i18next';

import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';
import { NAME } from 'services/products/productConfigurationAttributeTypes';

const DefaultItem = ({
  item,
  setItem,
}: {
  item: IProductConfigurationItem;
  setItem: (item: IProductConfigurationItem) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common]);

  const onNameChange = (event: any) => {
    setItem({
      ...item,
      attributes: item.attributes.map((attribute) =>
        attribute.type === NAME
          ? { ...attribute, value: event.target.value }
          : attribute
      ),
    });
  };

  const name = item.attributes.find((attribute) => attribute.type === NAME)
    ?.value;

  if (name === undefined) {
    return null;
  }

  return (
    <TextInputField
      id="name"
      label={t(`${translations.common}:Name`)}
      onChange={onNameChange}
      value={name === null ? '' : name}
    />
  );
};

export default DefaultItem;
