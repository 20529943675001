import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BasicFormContainer from 'components/layout/BasicFormContainer';
import translations from 'services/translations/translations';
import TextInputField from 'components/layout/forms/TextInputField';
import getTargetValue from 'services/forms/getTargetValue';
import { connect } from 'react-redux';
import { fetchOrganizationUnits } from 'store/users/actions';
import IOrganizationUnit from 'interfaces/users/IOrganizationUnit';
import PageContainer from 'components/layout/PageContainer';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import ModalAccept from 'components/layout/modals/ModalAccept';
import ModalConfirm from 'components/layout/modals/ModalConfirm';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import BasicTableNoPagination from 'components/layout/tables/BasicTableNoPagination';
import IOrganizationUnitPost from 'interfaces/users/IOrganizationUnitPost';

import userApi from '../../api/userManagement';
import { showOrganizationUnitsDropdown } from './showOrganizationUnitsDropdown';

import 'style/userManagement/OrganizationUnit.css';

const OrganizationUnit = ({
  organizationUnitTypesList,
  fetchOrganizationUnitTypesList,
}: {
  organizationUnitTypesList: IOrganizationUnit[];
  fetchOrganizationUnitTypesList: () => void;
}): JSX.Element => {
  const { t } = useTranslation([
    translations.common,
    translations.admin,
    translations.userManagement,
    translations.contacts,
  ]);
  const history = useHistory();
  const { orgId } = useParams();
  const [modalDelete, setModalDelete] = useState(false);
  const [parentId, setParentId] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [companyName, setCompanyName] = useState('');
  const [children, setChildren] = useState<{ name: string; id: number }[]>([]);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const [modalDeleteConfirmation, setModalDeleteConfirmation] = useState(false);
  const toggleDeleteConfirmation = () =>
    setModalDeleteConfirmation(!modalDeleteConfirmation);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [orgSaved, setOrgSaved] = useState(false);
  const [success, setSuccess] = useState(false);
  const modalMessage = success
    ? t(`${translations.userManagement}:Organization unit saved`)
    : t(`${translations.userManagement}:Organization unit failed`);
  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchOrganizationUnitTypesList();
  }, [fetchOrganizationUnitTypesList]);

  useEffect(() => {
    // If not undefined there is a user to modify
    if (orgId !== undefined) {
      const submit = async () => {
        try {
          const result: IOrganizationUnit = await userApi.organizationUnits.getSingle(
            orgId
          );
          if (result !== undefined) {
            setName(result.name);
            setChildren(result.children);
            setCompanyName(result.companyName ? result.companyName : '');
          }
        } catch (error) {
          // define action
        }
      };
      submit().catch((e) => console.log(e));
    }
  }, [orgId]);

  const goPreviousPage = () => {
    history.push('/users');
  };

  const addUpdateOrganizationUnit = () => {
    if (name === '') {
      setErrorMessage(t(`${translations.common}:Fill all fields`));
      setFormError(true);
    } else {
      const formattedOrg: IOrganizationUnitPost = {
        companyName: companyName === '' ? null : companyName,
        name,
        parentId: parentId === 0 ? null : parentId,
      };
      const submit = async () => {
        let result;
        if (orgId !== undefined) {
          try {
            result = await userApi.organizationUnits.put(formattedOrg, orgId);
            setOrgSaved(true);
            setSuccess(true);
          } catch (error) {
            setOrgSaved(true);
            setSuccess(false);
          }
        } else {
          try {
            result = await userApi.organizationUnits.post(formattedOrg);
            setOrgSaved(true);
            setSuccess(true);
          } catch (error) {
            setOrgSaved(true);
            setSuccess(false);
          }
        }
        if (result !== undefined && result.succeeded === true) {
          goPreviousPage();
        }
      };
      submit().catch((e) => console.log(e));
    }
  };

  const selectOrganizationUnit = (e: any) => {
    setParentId(parseInt(getTargetValue(e), 10));
  };

  const deleteOrganizationUnit = async () => {
    try {
      const result = await userApi.organizationUnits.delete(orgId);
      if (result !== undefined && result.success === true) {
        toggleDelete();
        toggleDeleteConfirmation();
        setDeleteSuccess(true);
      } else {
        setDeleteSuccess(false);
      }
    } catch (error) {
      setDeleteSuccess(false);
    }
  };

  const setSelectedRow = (selectedRow: any) => {
    if (selectedRow !== undefined) {
      history.push(`/organizationunits/${children[selectedRow].id}`);
    }
  };

  const orgUnitColumns = [
    {
      dataField: 'name',
      text: t(`${translations.common}:Subworkgroup`),
    },
  ];

  const isRootLevel = () =>
    organizationUnitTypesList.some(
      (org: IOrganizationUnit) => org.name === name
    );

  return (
    <>
      <PageContainer>
        <p className="header-text">{t('admin:Organization Units')}</p>
        <BasicFormContainer>
          <Form className="form-spacing">
            <Row>
              <Col>
                <TextInputField
                  value={name}
                  label={t(`${translations.common}:Name`)}
                  required
                  id="name"
                  name="name"
                  onChange={(e: any) => setName(e.target.value)}
                />
              </Col>
              {(orgId !== undefined && isRootLevel()) ||
              (orgId === undefined && parentId === 0) ? (
                <Col>
                  <TextInputField
                    value={companyName}
                    label={t(`${translations.contacts}:Company Name`)}
                    required
                    id="companyName"
                    name="companyName"
                    onChange={(e: any) => setCompanyName(e.target.value)}
                  />
                </Col>
              ) : null}
              {orgId === undefined ? (
                <Col>
                  <SelectInputFieldControlled
                    value={parentId}
                    id="orgUnits"
                    label={t(
                      `${translations.userManagement}:Parent organization unit`
                    )}
                    onChange={selectOrganizationUnit}
                    name="orgUnits"
                    required
                    options={showOrganizationUnitsDropdown(
                      organizationUnitTypesList,
                      t
                    )}
                  />
                </Col>
              ) : (
                ''
              )}
            </Row>
          </Form>
          <Row>
            <Col>
              <PrimaryButton onClick={goPreviousPage}>
                {t(`${translations.common}:Back`)}
              </PrimaryButton>

              {orgId !== undefined ? (
                <PrimaryButton
                  className="add-button-margin"
                  onClick={toggleDelete}
                >
                  {t(`${translations.common}:Remove`)}
                </PrimaryButton>
              ) : (
                ''
              )}

              <PrimaryButton
                className="add-button-margin"
                onClick={addUpdateOrganizationUnit}
              >
                {t(`${translations.common}:Save`)}
              </PrimaryButton>
            </Col>
          </Row>
          <ModalConfirm
            showing={modalDelete}
            setShowing={toggleDelete}
            header={t('common:Confirm Delete')}
            text={t(
              `${translations.userManagement}:Delete organization unit confirmation`
            )}
            yesButton={t('common:Confirm')}
            noButton={t('common:Cancel')}
            confirm={deleteOrganizationUnit}
          />
          <ModalAccept
            showing={modalDeleteConfirmation}
            setShowing={toggleDeleteConfirmation}
            header=""
            text={
              deleteSuccess
                ? t(`${translations.common}:Delete Success`)
                : t(`${translations.common}:Delete Failure`)
            }
          />
          <ModalAccept
            showing={orgSaved}
            setShowing={setOrgSaved}
            header=""
            text={modalMessage}
          />
          <ModalAccept
            showing={formError}
            setShowing={setFormError}
            header=""
            text={errorMessage}
          />
        </BasicFormContainer>
      </PageContainer>
      {orgId !== undefined ? (
        <BasicTableNoPagination
          columns={orgUnitColumns}
          data={children}
          keyField="id"
          setSelectedRow={setSelectedRow}
        />
      ) : null}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  organizationUnitTypesList: state.users.organizationUnits,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchOrganizationUnitTypesList: () => dispatch(fetchOrganizationUnits()),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationUnit);
