import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ICurrentUser from 'api/interfaces/users/ICurrentUser';
import {
  CONTACTS,
  OFFERS,
  PRODUCTS,
  PROJECTS,
  ROLES,
  USERS,
} from 'services/types/viewTypes';
import SidebarLink from './SidebarLink';
import SidebarLogout from './SidebarLogout';
import TopImage from '../../assets/LoginBackground.png';
import translations from '../../services/translations/translations';
import '../../style/layout/Sidebar.css';

const Sidebar = ({
  isOpen,
  closeFunction,
  currentUser,
}: {
  isOpen: boolean;
  closeFunction: (open: boolean) => void;
  currentUser: ICurrentUser;
}): JSX.Element | null => {
  const [animate, setAnimate] = useState(false);
  const { t } = useTranslation(translations.common);
  useEffect(() => setAnimate(true), []);

  return (
    <>
      <div
        className={
          animate === false ? 'sidebar-content' : 'sidebar-content enter'
        }
      >
        <img src={TopImage} alt="" className="sidebar-image" />
        {currentUser.views.includes(PROJECTS) ? (
          <SidebarLink
            label={t('Projects')}
            link="/projects"
            sidebarClose={closeFunction}
          />
        ) : null}

        {currentUser.views.includes(OFFERS) ? (
          <SidebarLink
            label={t('Offers')}
            link="/offers"
            sidebarClose={closeFunction}
          />
        ) : null}

        {currentUser.views.includes(PRODUCTS) ? (
          <SidebarLink
            label={t('Products')}
            link="/products"
            sidebarClose={closeFunction}
          />
        ) : null}

        {currentUser.views.includes(CONTACTS) ? (
          <SidebarLink
            label={t('Contacts')}
            link="/contacts"
            sidebarClose={closeFunction}
          />
        ) : null}

        <SidebarLink
          label={t('Work Safety')}
          link="/NotYetImplemented"
          sidebarClose={closeFunction}
        />
        <SidebarLink
          label={t('Reports')}
          link="/NotYetImplemented"
          sidebarClose={closeFunction}
        />
        {currentUser.views.includes(USERS) ||
        currentUser.views.includes(ROLES) ? (
          <SidebarLink
            label={t('User Management')}
            link="/users"
            sidebarClose={closeFunction}
          />
        ) : null}
        <SidebarLogout />
      </div>
      <div
        className={
          animate === false ? 'sidebar-outside' : 'sidebar-outside sidebar-dark'
        }
        onClick={() => closeFunction(false)}
        aria-hidden="true"
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.users.current,
});

export default connect(mapStateToProps)(Sidebar);
