import asyncAction from '../asyncAction';

const FETCH_PERMISSION_TYPES = asyncAction('FETCH_PERMISSION_TYPES');
const FETCH_ROLES = asyncAction('FETCH_ROLES');
const FETCH_USER = asyncAction('FETCH_USER');
const FETCH_USERS = asyncAction('FETCH_USERS');
const FETCH_ORGANIZATION_UNITS = asyncAction('FETCH_ORGANIZATION_UNITS');
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const SET_MSAL_INSTANCE = 'SET_MSAL_INSTANCE';
const SET_MSAL_ACCOUNT = 'SET_MSAL_ACCOUNT';

export {
  FETCH_PERMISSION_TYPES,
  FETCH_ROLES,
  FETCH_USER,
  FETCH_USERS,
  FETCH_ORGANIZATION_UNITS,
  SET_ACCESS_TOKEN,
  SET_MSAL_INSTANCE,
  SET_MSAL_ACCOUNT,
};
