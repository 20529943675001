import IOffer from './IOffer';

const getDefaultOffer = (): IOffer => ({
  address: '',
  attachmentsNote: '',
  buildingType: '',
  cargo: '',
  city: '',
  collateral: '',
  collateralAmount: '',
  collateralPercentage: '',
  contractType: '',
  country: 'Suomi',
  created: null,
  createdUser: null,
  customer: '',
  customerMark: '',
  deliveryTimeNote: '',
  designer: '',
  electronicInvoicingAddress: '',
  electronicInvoicingOperator: '',
  electronicInvoicingOperatorId: '',
  employeeInformationType: '',
  invoiceNote: '',
  invoiceReference: '',
  jobType: '',
  offerText: '',
  orderNumber: '',
  organizationUnit: null,
  status: '',
  target: '',
  taxRate: '',
  updated: null,
  updatedUser: null,
  validity: '',
  wayOfReceivingType: '',
  worksiteKey: '',
  zipCode: '',
});

export default getDefaultOffer;
