import React from 'react';

import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';

import {
  INSTALLATION,
  PRODUCT,
  PRODUCT_SELECT,
  SELECT,
  SELECT_OPTION,
} from 'services/products/productConfigurationItemTypes';

import InstallationItem from './installationItem/InstallationItem';
import OptionItem from './optionItem/OptionItem';
import ProductItem from './productItem/ProductItem';
import ProductSelectItem from './productSelectItem/ProductSelectItem';
import SelectItem from './selectItem/SelectItem';

const Item = ({
  item,
  onDelete,
}: {
  item: IProductConfigurationItem;
  onDelete: (itemId: number) => void;
}): JSX.Element | null => {
  if (item.type === INSTALLATION) {
    return <InstallationItem item={item} onDelete={onDelete} />;
  }

  if (item.type === PRODUCT) {
    return <ProductItem item={item} onDelete={onDelete} />;
  }

  if (item.type === PRODUCT_SELECT) {
    return <ProductSelectItem item={item} onDelete={onDelete} />;
  }

  if (item.type === SELECT) {
    return <SelectItem item={item} onDelete={onDelete} />;
  }

  if (item.type === SELECT_OPTION) {
    return <OptionItem item={item} onDelete={onDelete} />;
  }

  return null;
};

export default Item;
