import React, { useRef } from 'react';

import apiFiles from 'api/files';
import deleteIcon from 'assets/delete_small_white.svg';
import downloadIcon from 'assets/download_small_white.svg';
import getNextObjectKey from 'services/numbers/getNextObjectKey';
import IFile from 'interfaces/forms/IFile';
import plusIcon from 'assets/plus_small_grey.svg';

import 'style/layout/form/ImagesWithPreviewInput.css';

const ImageWithPreviewInput = ({
  setFile,
  downloadFile,
  file,
  fileType,
  id,
  removeFile,
}: {
  setFile: (file: IFile) => void;
  downloadFile: (file: IFile) => void;
  file?: IFile;
  fileType?: string;
  id: string;
  removeFile: (file: IFile) => void;
}): JSX.Element => {
  const inputElement = useRef<HTMLInputElement>(null);

  const onAddFileClick = () => {
    if (inputElement.current === null) {
      return;
    }

    inputElement.current.click();
  };

  const onFileInput = async (filesToUpload: any) => {
    if (filesToUpload.length !== 1) {
      return;
    }
    const response = await apiFiles.post(filesToUpload[0], fileType);

    if (response.id === null) {
      return;
    }

    const f: IFile = {
      data: filesToUpload[0],
      id: response.id.toString(),
      key: 0,
      name: filesToUpload[0].name,
    };

    setFile(f);
  };

  const getFile = (f: IFile) => {
    return (
      <div className="image-preview" key={`image-preview-${f.key}`}>
        <div className="image-preview-actions">
          <img alt="" onClick={() => downloadFile(f)} src={downloadIcon} />
          <img alt="" onClick={() => removeFile(f)} src={deleteIcon} />
        </div>
        <img
          alt=""
          className="image-preview-image"
          src={URL.createObjectURL(f.data)}
        />
      </div>
    );
  };

  return (
    <div className="images-with-preview-input">
      {file != null ? (
        getFile(file)
      ) : (
        <button
          className="add-image-button"
          onClick={onAddFileClick}
          type="button"
        >
          <img alt="" src={plusIcon} />
        </button>
      )}
      <input
        accept="image/x-png,image/gif,image/jpeg"
        className="image-input"
        multiple={false}
        onChange={(e: any) => {
          onFileInput([...e.target.files]);
        }}
        ref={inputElement}
        type="file"
        id={`file-input-${id}`}
      />
    </div>
  );
};

export default ImageWithPreviewInput;
