import React from 'react';

import editIcon from 'assets/edit_icon.svg';

const EditIcon = ({
  onClick,
  className,
}: {
  onClick: () => void;
  className?: string;
}): JSX.Element => (
  <>
    <img
      src={editIcon}
      alt="edit-icon"
      className={`trash-icon ${className}`}
      onClick={() => onClick()}
    />
  </>
);

export default EditIcon;
