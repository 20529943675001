import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentSizedTextarea from 'components/layout/forms/ContentSizedTextarea';
import { APPROVED } from 'services/enums/offers/Statuses';

import translations from '../../../../../services/translations/translations';

const Memo = ({
  initialStatus,
  memo,
  onTextInputChanged,
}: {
  initialStatus: null | string;
  memo: string;
  onTextInputChanged: (value: string) => void;
}): JSX.Element => {
  const { t } = useTranslation([translations.offers]);

  const offerIsApproved = () => initialStatus === APPROVED;

  return (
    <ContentSizedTextarea
      className="calculation-window-memo"
      disabled={offerIsApproved()}
      id="memo"
      label={t('Memo')}
      name="text"
      onChange={(e) => onTextInputChanged(e.target.value)}
      value={memo === null ? '' : memo}
    />
  );
};

export default Memo;
