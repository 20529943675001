import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import 'style/layout/Table.css';
import MaterialTable, {Column, EditCellColumnDef, Filter} from 'material-table';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import translations from '../../../services/translations/translations';
import PrimaryButton from '../buttons/PrimaryButton';

const BasicMaterialTable = ({
  fetchData,
  columns,
  sizePerPage,
  setSelectedRow,
  localStorageTag,
}: {
  fetchData: any;
  columns: any;
  sizePerPage: any;
  setSelectedRow: any;
  localStorageTag: string;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common]);
  const tableRef: any = React.createRef();
  const [highlightedColumns, setHighlightedColumns] = useState<(string|undefined)[]>(
      []
  );

  const rowEvents = (rowData: any) => {
    setSelectedRow(rowData);
  };

  const refreshQuery = () => {
    if (tableRef != null && tableRef.current != null) {
      tableRef.current.onQueryChange();
    }
  };

  const clearFilter = () => {
    if (tableRef?.current != null) {
      //Needed to reset the filter
      tableRef.current.state.query.filters = [];
      //Needed to reset the UI
      tableRef.current.state.columns.forEach((col: EditCellColumnDef) => {
        col.tableData.filterValue = '';
      });
      refreshQuery();
    }
  };

  const highlightFilteredColumns = (filters: Filter<object>[]) => {
    const filteredColumns = filters.filter((f: Filter<object>) => {
      return f.column !== null && (
          (typeof f.value === 'string' && f.value !== '') ||
          (Array.isArray(f.value) && f.value.length > 0) || 
          (!Array.isArray(f.value) && typeof f.value === 'object')
      );
    }).map((f: Filter<object>) => {
      return f.column.field;
    });
    highlightColumns(filteredColumns);
  };

  const highlightColumns = (columnsToHighlight: (string|undefined)[]) => {
    const toHighlight: string[] = [];
    tableRef?.current?.state.columns.forEach((col: Column<object>) => {
      if (col.field && columnsToHighlight.indexOf(col.field) !== -1) {
        toHighlight.push(col.field);
      }
    });
    setHighlightedColumns(toHighlight);
  };

  const localization = {
    pagination: {
      labelRowsSelect: t(`${translations.common}:Row`),
      firstTooltip: t(`${translations.common}:First Page`),
      lastTooltip: t(`${translations.common}:Last Page`),
      nextTooltip: t(`${translations.common}:Next Page`),
      previousTooltip: t(`${translations.common}:Previous Page`),
    },
    body: {
      emptyDataSourceMessage: t(`${translations.common}:No data to show`),
    },
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#4d8549',
      },
      secondary: {
        main: '#9fd03e',
      },
    },
    typography: {
      fontFamily: ['futura-pt', 'sans-serif'].join(','),
    },
  });

  const onFilterChange = (filters: any) => {
    window.localStorage.setItem(localStorageTag, JSON.stringify(filters));
    highlightFilteredColumns(filters);
  };

  const addHeaderStyle = (column: any) => {
    const style = column.headerStyle;
    column.headerStyle = {
      ...style,
      backgroundColor: highlightedColumns.indexOf(column.field) !== -1?'var(--theme-secondary-color)':'#00000000',
      color: highlightedColumns.indexOf(column.field) !== -1?'var(--theme-background-color)':'',
    };
    return column;
  };

  const columnsForTable:any[] = columns.map((c: any) => {
    return addHeaderStyle(c);
  });

  const sizePerPageList = [10, 50, 100, 200, 250];
  return (
    <div className="basic-material-table">
      <MuiThemeProvider theme={theme}>
        <PrimaryButton onClick={() => {
          clearFilter();
        }}>
          {t(`${translations.common}:Clear`).toLocaleUpperCase()}
        </PrimaryButton>
        <MaterialTable
          key={`basic-material-table-${columns.length}`}
          title=""
          localization={localization}
          tableRef={tableRef}
          columns={columnsForTable}
          data={async (query) => {
            onFilterChange(query.filters);

            const result = await fetchData(query);
            return {
              data: result.data,
              page: result.page,
              totalCount: result.totalCount,
            };
          }}
          onRowClick={(event, rowData) => rowEvents(rowData)}
          options={{
            toolbar: false,
            filtering: true,
            pageSize: sizePerPage,
            emptyRowsWhenPaging: false,
            pageSizeOptions: sizePerPageList,
            search: false,
            headerStyle: {
              backgroundColor: '#00000000',
              fontWeight: 'bold',
            },
          }}
        />
      </MuiThemeProvider>
    </div>
  );
};

export default BasicMaterialTable;
