import apiProjects from 'api/projects';
import getFileName from 'services/files/getFileName';
import IFile from 'interfaces/forms/IFile';

const getPlaceFiles = async (fileIds: string[]): Promise<IFile[]> => {
  const getFileWithData = async (fileId: string) => {
    const result = await apiProjects.files.get(fileId);
    return {
      data: result.data,
      headers: result.headers,
      id: fileId,
    };
  };

  const files = await Promise.all(
    fileIds.map((fileId) => getFileWithData(fileId))
  );

  return files.map((file, index) => ({
    data: new Blob([new Uint8Array(file.data)]),
    id: file.id,
    key: index,
    name: getFileName(file.headers),
  }));
};

export default getPlaceFiles;
