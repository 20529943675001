import React from 'react';

import 'style/layout/PageContainer.css';

const PageContainer = ({
  children,
  className,
  fullWidth,
}: {
  children: JSX.Element | (JSX.Element | null)[] | string;
  className?: string;
  fullWidth?: boolean;
}): JSX.Element => {
  const getClassName = () => {
    const classes = ['page-container'];

    if (className !== undefined) {
      classes.push(className);
    }

    if (fullWidth === true) {
      classes.push('full-width-page-container');
    }

    return classes.join(' ');
  };

  return <div className={getClassName()}>{children}</div>;
};

export default PageContainer;
