import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import ButtonLink from 'components/layout/buttons/ButtonLink';
import IProductConfigurationProduct from 'interfaces/products/IProductConfigurationProduct';
import translations from 'services/translations/translations';
import { addMessage } from 'store/messages/actions';

import ProductsTable from './ProductsTable';

import 'style/products/configurations/src/linksToProducts/LinksToProducts.css';

const LinksToProducts = ({
  products,
  setProducts,
}: {
  products: IProductConfigurationProduct[];
  setProducts: (products: IProductConfigurationProduct[]) => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation([translations.common, translations.products]);

  const configurationId = parseInt(id, 10);

  const deleteProductLink = (productId: number) => async () => {
    try {
      await apiProducts.configurations.links.delete(configurationId, productId);
      setProducts(products.filter((product) => product.id !== productId));
    } catch (e) {
      dispatch(addMessage(t(`${translations.common}:Saving failed`)));
    }
  };

  return (
    <div className="links-to-products">
      <div>
        <h2>{t(`${translations.productConfigurations}:Link to products`)}</h2>
        <ButtonLink
          className="add-product-button"
          to={`/products/configurations/${configurationId}/links/create`}
        >
          {t(`${translations.common}:Add`)}
        </ButtonLink>
      </div>
      <ProductsTable
        deleteProductLink={deleteProductLink}
        products={products}
      />
    </div>
  );
};

export default LinksToProducts;
