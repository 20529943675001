import React from 'react';
import { Route } from 'react-router-dom';

import { OFFERS } from 'services/types/viewTypes';
import CreateOfferProduct from 'components/offers/CreateOfferProduct';
import EditOfferProduct from 'components/offers/EditOfferProduct';
import Offer from 'components/offers/Offer';
import Offers from 'components/offers/Offers';
import Permission from 'components/layout/Permission';

const offers = (): JSX.Element[] => [
  <Route exact key="offers-route" path="/offers">
    <Permission type={OFFERS}>
      <Offers />
    </Permission>
  </Route>,
  <Route exact key="create-offer-route" path="/offers/types/:typeId/create">
    <Permission type={OFFERS}>
      <Offer />
    </Permission>
  </Route>,
  <Route exact key="offer-route" path="/offers/:id/:type">
    <Permission type={OFFERS}>
      <Offer />
    </Permission>
  </Route>,
  <Route
    exact
    key="create-offer-product-route"
    path="/offers/:id/:type/products/create"
  >
    <Permission type={OFFERS}>
      <CreateOfferProduct />
    </Permission>
  </Route>,
  <Route exact key="offer-product-route" path="/offers/products/:id/:type">
    <Permission type={OFFERS}>
      <EditOfferProduct />
    </Permission>
  </Route>,
];

export default offers;
