import React from 'react';
import { Row, Col } from 'reactstrap';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import IPrice from 'interfaces/products/IPrice';
import NumberInputField from 'components/layout/forms/NumberInputField';
import sortByTranslation from 'services/sort/sortByTranslation';
import IMeasurementGroupType from 'interfaces/products/IMeasurementGroupType';
import translations from 'services/translations/translations';
import DeleteIcon from 'components/layout/icons/DeleteIcon';

export const getMeasurementOptions = (
  keyName: string,
  list: string[],
  translations: any,
  t: any
) =>
  [...list].sort(sortByTranslation(t, translations.units)).map((item) => (
    <option key={`${keyName}-${item}`} value={item}>
      {t(`${translations.units}:${item}`)}
    </option>
  ));

const getPriceOptions = (
  keyName: string,
  list: string[],
  translations: any,
  t: any
) => {
  const options = [];
  for (let index = 0; index < list.length; index++) {
    options.push(
      <option key={`${keyName}${index}`} value={list[index]}>
        {t(`${translations.productPriceTypes}:${list[index]}`)}
      </option>
    );
  }
  return options;
};

export const getOptionsForEur = (
  keyName: string,
  list: string[],
  translations: any,
  t: any
) =>
  list
    .filter((item) => !item.includes('/'))
    .sort(sortByTranslation(t, translations.units))
    .map((item) => (
      <option key={`${keyName}${item}`} value={item}>
        €/{t(`${translations.units}:${item}`)}
      </option>
    ));

export const getMeasurementTypeOptions = (
  keyName: string,
  list: IMeasurementGroupType[],
  translations: any,
  t: any
): JSX.Element[] => {
  return list
    .map((item) => item.type)
    .sort(sortByTranslation(t, `${translations.products}:measurementTypes`))
    .map((item) => (
      <option key={`${keyName}${item}`} value={item}>
        {t(`${translations.productMeasurementTypes}:${item}`)}
      </option>
    ));
};

export const getPriceRows = (
  priceTypesList: string[],
  unitsList: string[],
  priceFormValues: IPrice[],
  updatePriceRow: any,
  deleteRow: any,
  t: any,
  translations: any
): JSX.Element => {
  const selectedPriceFormValues = priceFormValues.map((x) => x.type);
  const getPriceTypeOptions = (selected: string) =>
    getPriceOptions(
      'priceT',
      priceTypesList.filter(
        (x) => x === selected || !selectedPriceFormValues.includes(x)
      ),
      translations,
      t
    );
  const priceU = getOptionsForEur('priceU', unitsList, translations, t);
  
  return (
    <div>
      {priceFormValues.map((x, i) => {
        return (
          <Row className="product-price">
            <Col md={3}>
              <NumberInputField
                value={x.value.toString()}
                label={
                  i === 0
                    ? t(`${translations.products}:Price value`)
                    : undefined
                }
                required
                id="priceValue"
                name="priceValue"
                onChange={(e: any) => updatePriceRow(e, i)}
              />
            </Col>
            <Col md={3}>
              <SelectInputFieldControlled
                value={x.type}
                label={
                  i === 0 ? t(`${translations.products}:Price type`) : undefined
                }
                required
                id="priceType"
                name="priceType"
                onChange={(e: any) => updatePriceRow(e, i)}
                options={getPriceTypeOptions(x.type)}
              />
            </Col>
            <Col md={3}>
              <SelectInputFieldControlled
                value={x.siUnit}
                label={
                  i === 0 ? t(`${translations.products}:Price unit`) : undefined
                }
                required
                id="priceUnit"
                name="priceUnit"
                onChange={(e: any) => updatePriceRow(e, i)}
                options={priceU}
              />
            </Col>
            <Col md={3}>
              <DeleteIcon onClick={() => deleteRow(i, priceFormValues)} />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export const showCategoryOptions = (
  categoryOptions: any,
  translations: any,
  t: any
) => {
  const categoryTermsMapped: any = [];
  categoryOptions.map((x: any) =>
    categoryTermsMapped.push({
      value: x,
      label: `${t(`${translations.products}:categories:${x}`)}`,
    })
  );
  return categoryTermsMapped;
};

export const showTagOptions = (tagOptions: any, translations: any, t: any) => {
  const tagTermsMapped: any = [];
  tagOptions.map((x: any) =>
    tagTermsMapped.push({
      value: x,
      label: `${t(`${translations.products}:tags:${x}`)}`,
    })
  );
  return tagTermsMapped;
};
