import React from 'react';

import TextInputField from 'components/layout/forms/TextInputField';

const UpdatedField = ({
  label,
  updated,
  updatedUser,
}: {
  label: string;
  updated: null | string;
  updatedUser: any
}): JSX.Element => {
  const getValue = () => {
    if (updated === null) {
      return '';
    }

    const ymd = updated.split(' ')[0].split('-');
    const hmi = updated.split(' ')[1];
    const hm = hmi.split(':').slice(0, 2).join(':');
    const date = `${ymd[2]}.${ymd[1]}.${ymd[0]} ${hm}`;

    if (updatedUser === null) {
      return date;
    }

    const { firstName, lastName } = updatedUser;
    return `${date}, ${firstName} ${lastName}`;
  };

  return <TextInputField disabled label={label} required value={getValue()} />;
};

export default UpdatedField;
