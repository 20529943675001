import React from 'react';
import { Row, Col, Label } from 'reactstrap';

import IBuildingStructure from 'interfaces/projects/IBuildingStructure';
import TextInputWithSubmit from './TextInputWithSubmit';
import TrashIcon from '../icons/TrashIcon';
import EditIcon from '../icons/EditIcon';

import 'style/layout/form/CreatableList.css';

const CreatableList = ({
  elements,
  title,
  addElement,
  name,
  removeElement,
  updateElement,
}: {
  elements: IBuildingStructure[];
  title: string;
  addElement: (form: any, e: any) => void;
  name: string;
  removeElement: (e: string) => void;
  updateElement: (e: string) => void;
}): JSX.Element | null => {
  const showElements = () => {
    return elements.map((element) => {
      return (
        <Row key={element.name}>
          <Col xs={4} className="grey-text">
            {element.name}
          </Col>
          <Col xs={1}>
            <EditIcon
              onClick={() => {
                updateElement(element.name);
              }}
            />
          </Col>
          <Col xs={1}>
            <TrashIcon
              onClick={() => {
                removeElement(element.name);
              }}
            />
          </Col>
        </Row>
      );
    });
  };

  return (
    <>
      <Row className="button-margin-top">
        <Col>
          <Label className="label">{title}</Label>
        </Col>
      </Row>
      {showElements()}
      <TextInputWithSubmit name={name} required={false} submit={addElement} />
    </>
  );
};

export default CreatableList;
