import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import { PRODUCT_DETAIL } from 'services/products/productConfigurationItemTypes';

import {
  CALCULATE_PRICE_FROM_DETAILS,
  CATEGORY,
  CUSTOM_PRODUCT,
  EXPENSE,
  EXPENSE_UNIT,
  IS_CUSTOM_SUBPRODUCT,
  PRODUCT_ID,
  QUANTITY,
  QUANTITY_UNIT,
  SELLING_PRICE,
  SELLING_PRICE_UNIT,
  WASTE_PERCENTAGE,
} from 'services/products/productConfigurationAttributeTypes';

import getDefaultDetail from '../subproducts/getDefaultDetail';
import getDefaultSubproduct from '../subproducts/getDefaultSubproduct';
import IDetail from '../../IDetail';
import ISubproduct from '../../ISubproduct';

const attributeValueGetter = (item: IProductConfigurationItem) => (
  type: string
): string => {
  const attribute = item.attributes.find((a) => a.type === type);

  if (attribute === undefined) {
    return '';
  }

  if (attribute.value === null) {
    return '';
  }

  return attribute.value;
};

const getProductId = (item: IProductConfigurationItem) => {
  const productId = item.attributes.find(
    (attribute) => attribute.type === PRODUCT_ID
  );

  if (productId === undefined || productId.value === null) {
    return null;
  }

  if (productId.displayValue === undefined) {
    return null;
  }

  if (productId.displayValue === null) {
    return null;
  }

  return {
    label: productId.displayValue,
    value: productId.value,
  };
};

const getDetails = (detailItems: IProductConfigurationItem[]): IDetail[] =>
  detailItems.map((detailItem, index) => {
    const detail = getDefaultDetail(index);
    const getAttributeValue = attributeValueGetter(detailItem);

    return {
      ...detail,
      expense: getAttributeValue(EXPENSE),
      expenseUnit: getAttributeValue(EXPENSE_UNIT),
      product: getProductId(detailItem),
      quantity: getAttributeValue(QUANTITY),
      quantityUnit: getAttributeValue(QUANTITY_UNIT),
      sellingPrice: getAttributeValue(SELLING_PRICE),
      sellingPriceUnit: getAttributeValue(SELLING_PRICE_UNIT),
      wastePercentage: getAttributeValue(WASTE_PERCENTAGE),
    };
  });

const getSubproductsWithProductItems = (
  productItems: IProductConfigurationItem[],
  nextKey: number
): ISubproduct[] =>
  productItems.map((productItem, index) => {
    const subproduct = getDefaultSubproduct(nextKey + index);
    const getAttributeValue = attributeValueGetter(productItem);

    return {
      ...subproduct,
      calculatePriceFromDetails:
        getAttributeValue(CALCULATE_PRICE_FROM_DETAILS) === '1',
      category: getAttributeValue(CATEGORY),
      customSubproduct: getAttributeValue(CUSTOM_PRODUCT),
      details: getDetails(
        productItem.children.filter((child) => child.type === PRODUCT_DETAIL)
      ),
      expense: getAttributeValue(EXPENSE),
      expenseUnit: getAttributeValue(EXPENSE_UNIT),
      isCustomSubproduct: getAttributeValue(IS_CUSTOM_SUBPRODUCT) === '1',
      product: getProductId(productItem),
      quantity: getAttributeValue(QUANTITY),
      quantityUnit: getAttributeValue(QUANTITY_UNIT),
      sellingPrice: getAttributeValue(SELLING_PRICE),
      sellingPriceUnit: getAttributeValue(SELLING_PRICE_UNIT),
      wastePercentage: getAttributeValue(WASTE_PERCENTAGE),
    };
  });

export default getSubproductsWithProductItems;
