import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import 'style/layout/form/TextInputFieldNoLabel.css';

export default ({
  id,
  name,
  onChange,
  disabled,
  required,
  value,
}: {
  id: string;
  name?: string;
  onChange?: any;
  disabled?: boolean;
  required?: boolean;
  value: string | number;
}): JSX.Element => {
  return (
    <FormGroup>
      <div className="text-input-field-no-label">
        <Input
          className="text-field"
          id={id}
          name={name}
          required={required}
          onChange={onChange}
          disabled={disabled}
          value={value}
          type="text"
        />
      </div>
    </FormGroup>
  );
};
