import IMeasurement from 'interfaces/products/IMeasurement';
import getTargetValue from 'services/forms/getTargetValue';

const updateMeasurement = (
  measurFormValues: IMeasurement[],
  e: any,
  index: number
) => {
  const newValues = [...measurFormValues];
  const type = e.target.name;
  if (
    type === 'prodMeasurValue' ||
    type === 'palletMeasurValue' ||
    type === 'packageMeasurValue'
  ) {
    newValues[index].value = getTargetValue(e);
  } else if (
    type === 'prodMeasurType' ||
    type === 'palletMeasurType' ||
    type === 'packageMeasurType'
  ) {
    newValues[index].type = getTargetValue(e);
  } else if (
    type === 'prodMeasurUnit' ||
    type === 'palletMeasurUnit' ||
    type === 'packageMeasurUnit'
  ) {
    newValues[index].siUnit = getTargetValue(e);
  }
  return newValues;
};

export default updateMeasurement;
