import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';

import IProductConfigurationProduct from 'interfaces/products/IProductConfigurationProduct';
import translations from 'services/translations/translations';

import 'style/products/configurations/src/linksToProducts/ProductsTable.css';
import TrashIcon from 'components/layout/icons/TrashIcon';

const ProductsTable = ({
  deleteProductLink,
  products,
}: {
  deleteProductLink: (id: number) => () => void;
  products: IProductConfigurationProduct[];
}): JSX.Element => {
  const { t } = useTranslation([translations.products]);

  return (
    <div className="products-table">
      <BootstrapTable
        bordered={false}
        columns={[
          {
            dataField: 'name',
            text: t(`${translations.products}:Product`),
          },
          {
            dataField: 'id',
            formatter: (cell: any) => (
              <TrashIcon onClick={deleteProductLink(cell)} />
            ),
            text: '',
          },
        ]}
        data={products}
        keyField="id"
      />
    </div>
  );
};

export default ProductsTable;
