import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiOffers from 'api/offers';
import IOfferProductsGet from 'api/interfaces/offers/IOfferProductsGet';
import maximize from 'assets/maximize.svg';
import minimize from 'assets/minimize.svg';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import translations from 'services/translations/translations';
import { RIGHT } from 'services/enums/sides';

import { APPROVED } from 'services/enums/offers/Statuses';
import OfferProductPriceSummary from './OfferProductPriceSummary';
import OfferProductsTable from './OfferProductsTable';

const OfferProducts = ({
  initialStatus,
  offerId,
  offerSubmitted,
  productsIsOpen,
  toggleProducts,
  type,
  products,
  setProducts,
}: {
  initialStatus: null | string;
  offerId: number | undefined;
  offerSubmitted: boolean;
  productsIsOpen: boolean;
  toggleProducts: () => void;
  type: string;
  products: null | IOfferProductsGet[];
  setProducts: (products: IOfferProductsGet[]) => void;
}): JSX.Element | null => {
  const history = useHistory();
  const { t } = useTranslation([translations.offers, translations.products]);

  useEffect(() => {
    if (!productsIsOpen || products !== null || offerId === undefined) {
      return;
    }

    const initializeProducts = async () => {
      if (type === 'additional-offer') {
        setProducts(await apiOffers.products.getForAdditionalOffer(offerId));
        return;
      }
      setProducts(await apiOffers.products.getForOffer(offerId));
    };

    initializeProducts();
  }, [productsIsOpen]);

  const addProduct = () => {
    history.push(`/offers/${offerId}/${type}/products/create`);
  };

  const offerIsApproved = () => initialStatus === APPROVED;

  const getActionButtons = () => (
    <Row>
      <Col>
        {offerIsApproved() ? null : (
          <PrimaryButton
            float={RIGHT}
            className="button-one-line mt-3"
            onClick={addProduct}
          >
            {t(`${translations.products}:Add Product`).toLocaleUpperCase()}
          </PrimaryButton>
        )}
      </Col>
    </Row>
  );

  const getContent = () => {
    if (!offerSubmitted || !productsIsOpen) {
      return null;
    }

    const productsTable = products === null ? [] : products;
    const productGroups = productsTable.reduce(
      (groups: any, product: IOfferProductsGet) => ({
        ...groups,
        [product.group]: [...(groups[product.group] || []), product],
      }),
      {}
    );

    return (
      <>
        <OfferProductsTable products={productsTable} type={type} />
        {getActionButtons()}
        <OfferProductPriceSummary products={productsTable} />
        {Object.keys(productGroups)?.map((group: any) => (
          <OfferProductPriceSummary
            title={t(`${translations.products}:groups:${group}`)}
            products={productGroups[group]}
          />
        ))}
      </>
    );
  };
  return (
    <>
      <Row>
        <Col>
          <div
            className="header-text title-spacing"
            onClick={toggleProducts}
            role="button"
            tabIndex={0}
          >
            {productsIsOpen ? (
              <img
                src={minimize}
                alt="toggle-products"
                className="collapse-icon"
              />
            ) : (
              <img
                src={maximize}
                alt="toggle-products"
                className="collapse-icon"
              />
            )}
            {t(`${translations.products}:Products`).toUpperCase()}
          </div>
        </Col>
      </Row>
      {getContent()}
    </>
  );
};

export default OfferProducts;
