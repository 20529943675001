import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import translations from 'services/translations/translations';
import { closeConfirm } from 'store/messages/actions';

import ModalConfirm from './modals/ModalConfirm';

const Confirm = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const confirms = useSelector((state: any) => state.messages.confirms);
  const { t } = useTranslation([translations.common]);

  if (confirms.length === 0) {
    return null;
  }

  const triggerCloseConfirm = (showing: boolean) => {
    if (!showing) {
      dispatch(closeConfirm());
    }
  };

  const onConfirm = () => {
    confirms[0].callback();
    triggerCloseConfirm(false);
  };

  return (
    <ModalConfirm
      header={confirms[0].header ?? ''}
      confirm={onConfirm}
      noButton={t(`${translations.common}:Cancel`)}
      showing
      setShowing={triggerCloseConfirm}
      text={confirms[0].message}
      yesButton={t(`${translations.common}:Confirm`)}
    />
  );
};

export default Confirm;
