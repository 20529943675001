import IPrice from 'interfaces/products/IPrice';
import replaceCommas from 'services/numbers/replaceCommas';

const formatPriceToNumber = (formValues: IPrice[]) =>
  formValues.map((x: IPrice) => {
    const newValue: IPrice = {
      value: replaceCommas(x.value.toString()),
      siUnit: x.siUnit,
      type: x.type,
      currency: x.currency,
    };
    return newValue;
  });

export default formatPriceToNumber;
