import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import getNextObjectKey from 'services/numbers/getNextObjectKey';
import IProductConfiguration from 'interfaces/products/IProductConfiguration';
import translations from 'services/translations/translations';
import { finishLoading, startLoading } from 'store/loading/actions';

import {
  INSTALLATION,
  PRODUCT,
} from 'services/products/productConfigurationItemTypes';

import ActionButtons from '../../ActionButtons';
import Configuration from './Configuration';
import ConfigurationDropdown from './ConfigurationDropdown';
import getInstallationsWithInstallationItems from './getInstallationsWithInstallationItems';
import getSelectedProducts from './getSelectedProducts';
import getSubproductsWithProductItems from './getSubproductsWithProductItems';
import IFormState from '../../IFormState';
import IInstallation from '../../IInstallation';
import ISelection from './ISelection';
import ISubproduct from '../../ISubproduct';

const AttachConfiguration = ({
  cancel,
  formState,
  initialStatus,
  installations,
  setSubproductsAndInstallations,
  subproducts,
}: {
  cancel: () => void;
  formState: IFormState;
  initialStatus: null | string;
  installations: IInstallation[];
  setSubproductsAndInstallations: (
    subproducts: ISubproduct[],
    installations: IInstallation[],
    offerText: string
  ) => void;
  subproducts: ISubproduct[];
}): JSX.Element => {
  const dispatch = useDispatch();
  const [configurationId, setConfigurationId] = useState<string>('');
  const [selections, setSelections] = useState<ISelection[]>([]);

  const [
    configuration,
    setConfiguration,
  ] = useState<IProductConfiguration | null>(null);

  const { t } = useTranslation([translations.common]);

  const save = () => {
    if (configuration === null) {
      return;
    }

    const productItems = getSelectedProducts(selections, configuration);
    const nextSubproductKey = getNextObjectKey(subproducts, 'key');
    const products = getSubproductsWithProductItems(
      productItems,
      nextSubproductKey
    );

    const installationItems = configuration.items.filter(
      (item) => item.type === INSTALLATION
    );

    const nextInstallationKey = getNextObjectKey(installations, 'key');
    const newInstallations = getInstallationsWithInstallationItems(
      installationItems,
      nextInstallationKey
    );

    setSubproductsAndInstallations(
      [...subproducts, ...products],
      [...installations, ...newInstallations],
      configuration.offerText
    );

    cancel();
  };

  const loadConfiguration = async () => {
    dispatch(startLoading('get-product-configuration'));

    setConfiguration(
      await apiProducts.configurations.get(parseInt(configurationId, 10))
    );

    dispatch(finishLoading('get-product-configuration'));
  };

  useEffect(() => {
    if (configuration === null) {
      setSelections([]);
      return;
    }

    setSelections(
      configuration.items
        .filter((item) => item.type !== PRODUCT)
        .map((item) => ({
          id: item.id,
          selection: null,
        }))
    );
  }, [configuration]);

  useEffect(() => {
    if (configurationId != null && configurationId !== '') {
      loadConfiguration();
    }
  }, [configurationId]);

  return (
    <>
      <ConfigurationDropdown
        configurationId={configurationId}
        formState={formState}
        setConfigurationId={setConfigurationId}
      />
      <Configuration
        configuration={configuration}
        selections={selections}
        setSelections={setSelections}
      />
      <ActionButtons
        cancel={cancel}
        initialStatus={initialStatus}
        save={save}
        saveButtonText={t(`${translations.common}:Attach`)}
      />
    </>
  );
};

export default AttachConfiguration;
