import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getFormStateHandler from 'services/forms/getFormStateHandler';
import InputLabel from 'components/layout/forms/InputLabel';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import translations from 'services/translations/translations';
import { APPROVED } from 'services/enums/offers/Statuses';
import { AMOUNT, H } from 'services/enums/units';
import getVersionInstallationContract from '../getVersionInstallationContract';
import IVersion from '../../IVersion';
import LinkButton from '../../../../../layout/buttons/LinkButton';
import BatchCalculationReview from './BatchCalculationReview';
import ICalculationReview from '../../ICalculationReview';
import getDefaultCalculationReview from './getDefaultCalculationReview';
import getNextObjectKey from '../../../../../../services/numbers/getNextObjectKey';
import IInstallation from '../../IInstallation';
import getPriceSummary from '../../getPriceSummary';

const BatchCalculationReviews = ({
  initialStatus,
  productUnit,
  quantity,
  setCalculationReviews,
  version,
  calculationReviews,
}: {
  initialStatus: null | string;
  productUnit: string;
  quantity: number;
  setCalculationReviews: (version: ICalculationReview[]) => void;
  version: IVersion;
  calculationReviews: ICalculationReview[];
}): JSX.Element => {
  const { t } = useTranslation([translations.offers]);
  const units = useSelector((state: any) => state.products.units);

  const handleFormState = getFormStateHandler(version, setCalculationReviews);

  const offerIsApproved = () => initialStatus === APPROVED;

  const addBatchCalculation = () => {
    const key = getNextObjectKey(calculationReviews, 'key');
    setCalculationReviews([
      ...calculationReviews,
      getDefaultCalculationReview(key, AMOUNT),
    ]);
  };

  const setCalculationReview = (calculationReview: ICalculationReview) => {
    setCalculationReviews(
      calculationReviews.map((existingReview) =>
        existingReview.key === calculationReview.key
          ? calculationReview
          : existingReview
      )
    );
  };

  const removeCalculationReview = (calculationReview: ICalculationReview) => {
    setCalculationReviews(
      calculationReviews.filter(
        (existingReview) => existingReview.key === calculationReview.key
      )
    );
  };

  const installationCategories = useSelector(
    (state: any) => state.products.installationCategories
  );

  const installationContract = getVersionInstallationContract(
    version,
    quantity,
    installationCategories
  );

  const priceSummary = getPriceSummary(
    version.installations,
    version.subproducts,
    quantity
  );

  return (
    <div>
      <h2>{t(`${translations.calculationWindow}:Batch calculation review`)}</h2>
      {calculationReviews?.map((review) => (
        <BatchCalculationReview
          priceSummary={priceSummary}
          initialStatus={initialStatus}
          productUnit={productUnit}
          quantity={quantity}
          version={version}
          setCalculationReview={setCalculationReview}
          removeCalculationReview={removeCalculationReview}
          calculationReview={review}
        />
      ))}
      {offerIsApproved() ? null : (
        <LinkButton onClick={addBatchCalculation}>
          {`+ ${t(`${translations.common}:Add row`)}`}
        </LinkButton>
      )}
    </div>
  );
};

export default BatchCalculationReviews;
