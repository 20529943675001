import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';
import { addMessage } from 'store/messages/actions';

import 'style/products/configurations/CreateConfiguration.css';

const CreateConfiguration = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation([translations.common, translations.products]);
  const [name, setName] = useState('');

  const onCreate = async () => {
    try {
      const result = await apiProducts.configurations.post({
        items: [],
        name,
        products: [],
      });

      dispatch(addMessage(t(`${translations.common}:Saving succeeded`)));
      history.push(`/products/configurations/${result.id}`);
    } catch (e) {
      dispatch(addMessage(t(`${translations.common}:Saving failed`)));
    }
  };

  return (
    <div className="product-configuration">
      <h1>
        {t(`${translations.productConfigurations}:New product configuration`)}
      </h1>
      <div className="basic-information-box">
        <TextInputField
          id="configuration-name"
          label={t(`${translations.common}:Name`)}
          required
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value)
          }
          value={name}
        />
        <PrimaryButton onClick={onCreate}>
          {t(
            `${translations.productConfigurations}:Create and continue to configuration`
          )}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default CreateConfiguration;
