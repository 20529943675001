const fieldsToUpdate = [
  'categories',
  'name',
  'supplierId',
  'tags',
  'imageId',
  'measurements.kg-in-m',
  'measurements.length',
  'measurements.waste',
  'measurements.painting-area',
  'prices.painting-1',
  'prices.painting-2',
  'prices.painting-3',
  'prices.painting-4',
  'prices.purchase-price',
  'productIds.inlook',
  'productIds.inlook-old',
  'productIds.supplier',
];

export default fieldsToUpdate;
