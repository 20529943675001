import React from 'react';
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import isNumber from 'services/numbers/isNumber';
import { useTranslation } from 'react-i18next';
import translations from 'services/translations/translations';

export default ({
  id,
  onChange,
  value,
  name,
  required,
}: {
  id: string;
  onChange: any;
  value: string;
  name: string;
  required: boolean;
}): JSX.Element => {
  const { t } = useTranslation([translations.common]);
  return (
    <FormGroup>
      <Input
        className="text-field"
        id={id}
        name={name}
        required={required}
        onChange={onChange}
        value={value}
        type="text"
        invalid={!isNumber(value)}
      />
      <FormFeedback>{t(`${translations.common}:Input number`)}</FormFeedback>
    </FormGroup>
  );
};
