import numericStringToFloat from 'services/numbers/numericStringToFloat';

import getInstallationUnitExpense from './getInstallationUnitExpense';
import IInstallation from './IInstallation';

export default (installation: IInstallation): number => {
  const unitExpense = getInstallationUnitExpense(installation);

  if ([null, ''].includes(installation.installationPriceUnit)) {
    return unitExpense;
  }

  const quantity =
    typeof installation.quantity === 'number'
      ? installation.quantity
      : numericStringToFloat(installation.quantity);

  return unitExpense * quantity;
};
