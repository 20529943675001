import React from 'react';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import IProduct from 'api/interfaces/products/IProductGet';
import SearchDropdown from 'components/layout/forms/SearchDropdown';
import translations from 'services/translations/translations';
import IProductConfigurationProduct from 'interfaces/products/IProductConfigurationProduct';

import 'style/products/configurations/src/ProductConfigurationProduct.css';

const ProductConfigurationProduct = ({
  configurationProduct,
  setConfigurationProduct,
  showLabel,
}: {
  configurationProduct: IProductConfigurationProduct;
  setConfigurationProduct: (
    configurationProduct: IProductConfigurationProduct
  ) => void;
  showLabel: boolean;
}): JSX.Element => {
  const { t } = useTranslation([translations.common, translations.products]);

  const loadProducts = async (inputValue: string) => {
    const list = (await apiProducts.get(100, 0, inputValue)) as IProduct[];
    return list.map((product: IProduct) => ({
      value: product.id,
      label: [null, ''].includes(product.displayName)
        ? product.name
        : product.displayName,
    }));
  };

  const onChange = (newProduct: { label: string; value: number }) => {
    setConfigurationProduct({
      ...configurationProduct,
      id: newProduct === null ? null : newProduct.value,
      name: newProduct === null ? '' : newProduct.label,
    });
  };

  const getValue = () => ({
    label: configurationProduct.name,
    value: configurationProduct.id,
  });

  return (
    <div className="product-configuration-product">
      {showLabel ? (
        <Label className="label">{t(`${translations.products}:Product`)}</Label>
      ) : null}
      <SearchDropdown
        className="text-field search-field"
        loadOptions={loadProducts}
        onChange={onChange}
        placeholder={t(`${translations.common}:Search`)}
        required
        value={getValue()}
      />
    </div>
  );
};

export default ProductConfigurationProduct;
