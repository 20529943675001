import IVersion from '../IVersion';

const getDefaultVersion = (): IVersion => ({
  averageHourlyEarnings: '',
  id: 0,
  installations: [],
  offerText: '',
  isPrimary: true,
  name: '',
  showInOfferPdf: true,
  subproducts: [],
  calculationReviews: [],
});

export default getDefaultVersion;
