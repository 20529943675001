import React from 'react';

import deleteIcon from 'assets/delete_icon.svg';

import 'style/layout/Icons/DeleteIcon.css';

const DeleteIcon = ({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}): JSX.Element => (
  <>
    <img
      alt="delete-icon"
      className="delete-icon"
      onClick={onClick}
      src={deleteIcon}
    />
  </>
);

export default DeleteIcon;
