import { M2 } from 'services/enums/units';

import IDetail from '../../IDetail';

export default (key: number): IDetail => ({
  customProductName: '',
  expense: '',
  expenseUnit: M2,
  hasCustomProductName: false,
  key,
  product: null,
  quantity: '',
  quantityUnit: M2,
  sellingPrice: '',
  sellingPriceUnit: '',
  wastePercentage: '',
});
