import IBasicInfoGet from 'api/interfaces/projects/IBasicInfoGet';
import IPlaceGet from 'api/interfaces/projects/IPlaceGet';

const getPlaceInfo = (
  measurementsRaw: IPlaceGet[],
  basicInfo: IBasicInfoGet,
  id: number
) => {
  const place = measurementsRaw?.find((i) => i.id === id);
  const building = basicInfo?.buildings.find((i) => i.id === place?.buildingId)
    ?.name;
  const section = basicInfo?.buildingSections.find(
    (i) => i.id === place?.buildingSectionId
  )?.name;
  const space = basicInfo?.buildingSpaces.find(
    (i) => i.id === place?.buildingSpaceId
  )?.name;
  return { place, building, section, space };
};

export default getPlaceInfo;
