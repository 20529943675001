import React, { useState, useEffect } from 'react';
import { fetchStatusTypes } from 'store/offers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BasicFormContainer from 'components/layout/BasicFormContainer';
import IOfferGetSingle from 'api/interfaces/offers/IOfferGetSingle';
import IOfferPost from 'api/interfaces/offers/IOfferPost';
import IAdditionalOfferPost from 'api/interfaces/offers/IAdditionalOfferPost';
import IAdditionalOfferGet from 'api/interfaces/offers/IAdditionalOfferGet';
import PageContainer from 'components/layout/PageContainer';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import getDateFromISO from 'services/dates/getDateISO';
import getOffersDropdown from 'services/offers/getOffersDropdown';
import getTargetValue from 'services/forms/getTargetValue';
import translations from 'services/translations/translations';
import getCustomerDropdown from 'services/offers/getCustomerDropdown';

import { ADDITIONAL_OFFER } from 'services/enums/offers/offerTypes';
import { addMessage } from 'store/messages/actions';
import { RIGHT } from 'services/enums/sides';
import { removeNavMenuText, setNavMenuText } from 'store/navMenu/actions';

import IOfferProductsGet from 'api/interfaces/offers/IOfferProductsGet';
import AdditionalOfferBasicInfo from './src/offer/AdditionalOfferBasicInfo';
import additionalOfferRetrieved from './additionalOfferRetrieved';
import apiOffers from '../../api/offers';
import customerApi from '../../api/contacts';
import getDefaultOffer from './src/offer/getDefaultOffer';
import getOfferType from './getOfferType';
import IOffer from './src/offer/IOffer';
import OfferProducts from './src/offer/OfferProducts';
import OfferBasicInfo from './src/offer/OfferBasicInfo';
import openOfferPdf from './services/openOfferPdf';
import { getOfferPost, getFormState } from './src/offer/OfferUtils';

import 'style/offers/Offer.css';

interface IRouteParams {
  type: string;
  typeId: string;
}

const Offer = (): JSX.Element | null => {
  const { t } = useTranslation([translations.offers, translations.common]);
  const { id }: { id: string | undefined } = useParams();
  const { typeId, type } = useParams<IRouteParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [products, setProducts] = useState<IOfferProductsGet[] | null>(null);

  const [formState, setFormState] = useState<IOffer>(getDefaultOffer());
  const [customerOffice, setCustomerOffice] = useState<{
    label: string;
    value: string;
  }>();
  const [designOffice, setDesignOffice] = useState<{
    label: string;
    value: string;
  }>();
  const paymentTerms = [0, 7, 14, 21, 30, 45, 60, 90];
  const [paymentTerm, setPaymentTerm] = useState<{
    label: string;
    value: string;
  }>();
  const [offerIdConnected, setOfferIdConnected] = useState<{
    label: string;
    value: string;
  }>();
  const [signatureUser, setSignatureUser] = useState<{
    label: string;
    value: string;
  }>();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [offerIsOpen, setOfferIsOpen] = useState(true);
  const [designers, setDesigners] = useState<string[]>();
  const [initialStatus, setInitialStatus] = useState<null | string>(null);
  const [customers, setCustomers] = useState<string[]>();
  const [productsIsOpen, setProductsIsOpen] = useState(false);
  const [offerSubmitted, setOfferSubmitted] = useState(false);
  const [savedId, setSavedId] = useState<number>();
  const [additionalOfferFormState, setAdditionalOfferFormState] = useState<
    IAdditionalOfferGet
  >({
    created: null,
    createdUser: null,
    description: '',
    offerText: '',
    organizationUnit: null,
    signatureUser: null,
    status: '',
    updated: null,
    updatedUser: null,
  });

  useEffect(() => {
    dispatch(fetchStatusTypes());
  }, []);

  useEffect(() => {
    dispatch(setNavMenuText(formState.target));
  }, [formState]);

  useEffect(() => {
    // This is triggered when component is closed
    return () => {
      dispatch(removeNavMenuText());
    };
  }, []);

  const statusTypes = useSelector((state: any) => state.offers.statusTypes);

  const toggleOffer = () => setOfferIsOpen(!offerIsOpen);
  const toggleProducts = () => setProductsIsOpen(!productsIsOpen);

  useEffect(() => {
    // If not undefined there is an offer to modify
    if (id !== undefined) {
      toggleOffer();
      toggleProducts();
      setOfferSubmitted(true);

      if (type === ADDITIONAL_OFFER) {
        const submit = async () => {
          try {
            const result: IAdditionalOfferGet = await apiOffers.additionalOffers.get(
              parseInt(id, 10)
            );

            if (additionalOfferRetrieved(result)) {
              setAdditionalOfferFormState(result);

              const connectedOffer: IOfferGetSingle = await apiOffers.getOffer(
                result.offerId
              );

              setOfferIdConnected({
                label: connectedOffer.targetName,
                value: result.offerId?.toString() ?? '',
              });

              if (result.signatureUser !== null) {
                setSignatureUser({
                  label: `${result.signatureUser.firstName} ${result.signatureUser.lastName}`,
                  value: result.signatureUser.id ?? '',
                });
              }

              setInitialStatus(result?.status);
            }
          } catch (error) {
            // define action
          }
        };
        submit().catch((e) => console.log(e));
        return;
      }
      const submit = async () => {
        try {
          const result: IOfferGetSingle = await apiOffers.getOffer(
            parseInt(id, 10)
          );

          if (
            result !== undefined &&
            result.address !== undefined &&
            result.invoicingInformation !== undefined &&
            result.customerContacts !== undefined
          ) {
            setFormState(getFormState(formState, result));
            setPaymentTerm({
              label: result?.invoicingInformation.paymentTerm
                ? result?.invoicingInformation.paymentTerm.toString()
                : '',
              value: result?.invoicingInformation.paymentTerm
                ? result?.invoicingInformation.paymentTerm.toString()
                : '',
            });

            setInitialStatus(result?.status);

            setStartDate(
              result.workStarts
                ? getDateFromISO(result?.workStarts)
                : new Date()
            );

            setEndDate(
              result.workEnds ? getDateFromISO(result?.workEnds) : new Date()
            );

            if (result.customerContacts.length) {
              setCustomerOffice({
                label: result.customerContacts[0].contact.companyName,
                value: result.customerContacts[0].contact.id.toString(),
              });
            } else {
              setCustomerOffice({
                label: '',
                value: '',
              });
            }

            if (result.designOfficeContacts.length) {
              setDesignOffice({
                label: result.designOfficeContacts[0].contact.companyName,
                value: result.designOfficeContacts[0].contact.id.toString(),
              });
            } else {
              setDesignOffice({
                label: '',
                value: '',
              });
            }

            if (result.signatureUser !== null) {
              setSignatureUser({
                label: `${result.signatureUser.firstName} ${result.signatureUser.lastName}`,
                value: result.signatureUser.id ?? '',
              });
            } else {
              setSignatureUser({
                label: '',
                value: '',
              });
            }
          }
        } catch (error) {
          // define action
        }
      };
      submit().catch((e) => console.log(e));
    }
  }, [id]);

  const isOfferCreation = () =>
    typeId !== undefined &&
    statusTypes.length !== 0 &&
    formState.status === '' &&
    additionalOfferFormState.status === '';

  const offerId =
    savedId === undefined && id !== undefined ? parseInt(id, 10) : savedId;

  useEffect(() => {
    // If it is offer creation, set the offer type and status

    if (isOfferCreation()) {
      const openStatus = statusTypes.find((x: string) => x === 'open');
      setFormState({
        ...formState,
        status: openStatus || '',
        collateral: t(`${translations.common}:no`),
        employeeInformationType: t(
          `${translations.common}:client-stamping-device`
        ),
        taxRate: t(`${translations.common}:0`),
      });
      setAdditionalOfferFormState({
        ...additionalOfferFormState,
        status: openStatus || '',
      });
    }
  }, [additionalOfferFormState, formState, statusTypes, typeId]);

  useEffect(() => {
    if (designOffice !== undefined && designOffice.value !== '') {
      const getContact = async () => {
        const list = await customerApi.getContact(designOffice.value, {
          ownOrganizationUnit: true,
        });

        setDesigners(list.contactPersons);
      };
      getContact();
    }
  }, [designOffice]);

  useEffect(() => {
    if (customerOffice !== undefined && customerOffice.value !== '') {
      const getContact = async () => {
        const list = await customerApi.getContact(customerOffice.value, {
          ownOrganizationUnit: true,
        });

        setCustomers(list.contactPersons);
      };
      getContact();
    }
  }, [customerOffice]);

  const handleFormState = (e: any) => {
    setFormState({
      ...formState,
      [e.target.name]: getTargetValue(e),
    });
  };

  const handleAdditionalOfferFormState = (e: any) => {
    setAdditionalOfferFormState({
      ...additionalOfferFormState,
      [e.target.name]: getTargetValue(e),
    });
  };

  const handleOfferId = (e: any) => {
    if (e === null) {
      setOfferIdConnected({ label: '', value: '' });
    } else {
      setOfferIdConnected({ label: e.label, value: e.value });
    }
  };

  const handleCustomerOffice = (e: any) => {
    if (e === null) {
      setCustomerOffice({ label: '', value: '' });
    } else {
      setCustomerOffice({ label: e.label, value: e.value });
    }
  };

  const handleDesignOffice = (e: any) => {
    if (e === null) {
      setDesignOffice({ label: '', value: '' });
    } else {
      setDesignOffice({ label: e.label, value: e.value });
    }
  };

  const handlePaymentTerm = (e: any) => {
    if (e === null) {
      setPaymentTerm({ label: '', value: '' });
    } else {
      setPaymentTerm({ label: e.label, value: e.value });
    }
  };

  const handleSignatureUser = (
    updatedSignatureUser: { label: string; value: string } | null
  ) => {
    const emptySignatureUser = {
      label: '',
      value: '',
    };

    setSignatureUser(
      updatedSignatureUser === null ? emptySignatureUser : updatedSignatureUser
    );
  };

  const onChangeDate = (date: any, dateType: string) => {
    if (date !== null && dateType === 'start') {
      setStartDate(date);
    } else if (date !== null && dateType === 'end') {
      setEndDate(date);
    }
  };

  const saveOffer = () => {
    if (formState.target === undefined || formState.target === '') {
      dispatch(addMessage(t(`${translations.common}:Fill all fields`)));
      return;
    }
    const offer: IOfferPost = getOfferPost(
      formState,
      customerOffice,
      designOffice,
      signatureUser === undefined || signatureUser.value === ''
        ? null
        : signatureUser.value,
      paymentTerm,
      startDate,
      endDate,
      getOfferType(typeId, type)
    );
    let result;

    if (!offerSubmitted && id === undefined) {
      // Create new offer
      const submit = async () => {
        try {
          result = await apiOffers.post(offer);
          setSavedId(result.id);
          setOfferSubmitted(true);
          toggleOffer(); // Close basic info section
          dispatch(addMessage(t(`${translations.offers}:Offer saved`)));
          history.push(`/offers/${result.id}/${getOfferType(typeId, type)}`);
        } catch (error) {
          dispatch(addMessage(t(`${translations.offers}:Offer failed`)));
        }
      };
      submit().catch((e) => console.log(e));
    } else if (offerSubmitted) {
      const submit = async () => {
        try {
          result = await apiOffers.put(offer, offerId);
          dispatch(addMessage(t(`${translations.offers}:Offer saved`)));
        } catch (error) {
          dispatch(addMessage(t(`${translations.offers}:Offer failed`)));
        }
      };
      submit().catch((e) => console.log(e));
    }
  };

  const copyOffer = () => {
    const saveCopiedOffer = async () => {
      try {
        const result = await apiOffers.postCopyOffer(offerId);
        setSavedId(result.id);
        setOfferSubmitted(true);
        toggleOffer();
        dispatch(addMessage(t(`${translations.offers}:Offer copied`)));
        history.push('/offers');
      } catch (error) {
        console.log(error);
      }
    };
    saveCopiedOffer().catch((e) => console.log(e));
  };

  const copyAddionalOffer = () => {
    if (
      offerIdConnected === undefined ||
      offerIdConnected.value === undefined ||
      offerIdConnected.value === ''
    ) {
      dispatch(addMessage(t(`${translations.offers}:Choose offer`)));
      return;
    }

    const saveCopiedAdditionalOffer = async () => {
      try {
        const result = await apiOffers.additionalOffers.postCopyAdditionalOffer(
          offerId
        );
        setSavedId(result.id);
        setOfferSubmitted(true);
        toggleOffer(); // Close basic info section
        dispatch(addMessage(t(`${translations.offers}:Offer copied`)));
        history.push('/offers');
      } catch (error) {
        console.log(error);
      }
    };
    saveCopiedAdditionalOffer().catch((e) => console.log(e));
  };

  const saveAdditionalOffer = () => {
    if (
      offerIdConnected === undefined ||
      offerIdConnected.value === undefined ||
      offerIdConnected.value === ''
    ) {
      dispatch(addMessage(t(`${translations.offers}:Choose offer`)));
      return;
    }
    const offer: IAdditionalOfferPost = {
      description: additionalOfferFormState.description,
      offerId: parseInt(offerIdConnected.value, 10),
      offerText: additionalOfferFormState.offerText,
      signatureUserId:
        signatureUser === undefined || signatureUser.value === ''
          ? null
          : signatureUser.value,
      status: additionalOfferFormState.status,
    };
    let result;

    if (!offerSubmitted && id === undefined) {
      // Create new offer
      const submit = async () => {
        try {
          result = await apiOffers.additionalOffers.post(offer);
          setSavedId(result.id);
          setOfferSubmitted(true);
          toggleOffer(); // Close basic info section
          dispatch(addMessage(t(`${translations.offers}:Offer saved`)));
          history.push(`/offers/${result.id}/additional-offer`);
        } catch (error) {
          dispatch(addMessage(t(`${translations.offers}:Offer failed`)));
        }
      };
      submit().catch((e) => console.log(e));
    } else if (offerSubmitted) {
      const submit = async () => {
        try {
          result = await apiOffers.additionalOffers.put(offer, offerId);
          dispatch(addMessage(t(`${translations.offers}:Offer saved`)));
        } catch (error) {
          dispatch(addMessage(t(`${translations.offers}:Offer failed`)));
        }
      };
      submit().catch((e) => console.log(e));
    }
  };

  const searchOfferId = async (inputValue: string) =>
    getOffersDropdown(
      await apiOffers.get({
        limit: 100,
        offset: 0,
        search: inputValue,
        type: 'offer',
      })
    );

  const searchCustomerOffice = async (inputValue: string) =>
    getCustomerDropdown(
      await customerApi.get({
        limit: 100,
        offset: 0,
        ownOrganizationUnit: true,
        search: inputValue,
        type: 'customer',
      })
    );

  const searchDesignOffice = async (inputValue: string) =>
    getCustomerDropdown(
      await customerApi.get({
        limit: 100,
        offset: 0,
        ownOrganizationUnit: true,
        search: inputValue,
        type: 'designer',
      })
    );

  const goPreviousPage = () => {
    history.push('/offers');
  };

  return (
    <PageContainer>
      <BasicFormContainer>
        {type === ADDITIONAL_OFFER || typeId === '2' ? (
          <AdditionalOfferBasicInfo
            initialStatus={initialStatus}
            toggleOffer={toggleOffer}
            offerIsOpen={offerIsOpen}
            formState={additionalOfferFormState}
            handleFormState={handleAdditionalOfferFormState}
            saveOffer={saveAdditionalOffer}
            searchOfferId={searchOfferId}
            offerId={offerIdConnected}
            handleOfferId={handleOfferId}
            signatureUser={signatureUser}
            handleSignatureUser={handleSignatureUser}
          />
        ) : (
          <OfferBasicInfo
            initialStatus={initialStatus}
            toggleOffer={toggleOffer}
            offerIsOpen={offerIsOpen}
            formState={formState}
            handleFormState={handleFormState}
            customerOffice={customerOffice}
            handleCustomerOffice={handleCustomerOffice}
            searchCustomerOffice={searchCustomerOffice}
            startDate={startDate}
            onChangeDate={onChangeDate}
            endDate={endDate}
            designOffice={designOffice}
            handleDesignOffice={handleDesignOffice}
            searchDesignOffice={searchDesignOffice}
            designers={designers}
            saveOffer={saveOffer}
            customers={customers}
            paymentTerms={paymentTerms}
            handlePaymentTerm={handlePaymentTerm}
            paymentTerm={paymentTerm}
            signatureUser={signatureUser}
            handleSignatureUser={handleSignatureUser}
          />
        )}

        <hr />
        <OfferProducts
          offerId={
            savedId === undefined && id !== undefined
              ? parseInt(id, 10)
              : savedId
          }
          initialStatus={initialStatus}
          offerSubmitted={offerSubmitted}
          productsIsOpen={productsIsOpen}
          toggleProducts={toggleProducts}
          type={getOfferType(typeId, type)}
          products={products}
          setProducts={setProducts}
        />

        <PrimaryButton float={RIGHT} onClick={goPreviousPage}>
          {t('common:Back').toLocaleUpperCase()}
        </PrimaryButton>
        <PrimaryButton
          disabled={id === undefined || id === null}
          float={RIGHT}
          onClick={() => openOfferPdf(offerId || -1, type)}
        >
          {t('offers:Open PDF').toLocaleUpperCase()}
        </PrimaryButton>
        <PrimaryButton
          float={RIGHT}
          onClick={type === ADDITIONAL_OFFER ? copyAddionalOffer : copyOffer}
        >
          {t('offers:Copy Offer').toLocaleUpperCase()}
        </PrimaryButton>
      </BasicFormContainer>
    </PageContainer>
  );
};

export default Offer;
