import IContactPerson from 'interfaces/offers/IContactPerson';

const filterAndSortPersons = (
  contactPersons: IContactPerson[]
): IContactPerson[] =>
  contactPersons
    .filter((contactPerson: any) => !contactPerson.removed)
    .sort(function compare(a: IContactPerson, b: IContactPerson) {
      return a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
    });

export default filterAndSortPersons;
