import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getContributionMarginPerUnit from 'services/products/calculations/getContributionMarginPerUnit';
import getContributionMarginRatio from 'services/products/calculations/getContributionMarginRatio';
import getSellingPriceUnitOptions from 'components/offers/services/getSellingPriceUnitOptions';
import getSubproductTotalExpense from 'services/products/calculations/getSubproductTotalExpense';
import getUnitOptions from 'components/offers/services/getUnitOptions';
import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import IProductConfigurationProduct from 'interfaces/products/IProductConfigurationProduct';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import NumberWithDropdownInputField from 'components/layout/forms/NumberWithDropdownInputField';
import TrashIcon from 'components/layout/icons/TrashIcon';
import translations from 'services/translations/translations';

import {
  EXPENSE,
  EXPENSE_UNIT,
  QUANTITY,
  QUANTITY_UNIT,
  PRODUCT_ID,
  SELLING_PRICE,
  SELLING_PRICE_UNIT,
  WASTE_PERCENTAGE,
} from 'services/products/productConfigurationAttributeTypes';

import attributeValueUpdater from './attributeValueUpdater';
import getAttributeValue from '../getAttributeValue';
import updateProductUnitsToValidValues from './updateProductUnitsToValidValues';
import ProductConfigurationProduct from '../ProductConfigurationProduct';

const ProductItemDetail = ({
  detail,
  removeDetail,
  setDetail,
}: {
  detail: IProductConfigurationItem;
  removeDetail: () => void;
  setDetail: (detail: IProductConfigurationItem) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common, translations.offers]);
  const units = useSelector((state: any) => state.products.units);

  const [product, setProduct] = useState<IProductConfigurationProduct>({
    id: null,
    key: '',
    name: '',
  });

  const initializeProduct = async (productId: number) => {
    const apiProduct = await apiProducts.getProduct(productId);
    setProduct({
      ...product,
      id: productId,
      name: ['', null].includes(apiProduct.displayName)
        ? apiProduct.name
        : apiProduct.displayName,
    });
  };

  const updateAttributeValues = attributeValueUpdater(detail, setDetail);

  useEffect(() => {
    updateProductUnitsToValidValues(detail, updateAttributeValues);

    if (product.id !== null) {
      return;
    }

    const productId = getAttributeValue(detail, PRODUCT_ID);
    if (productId === '') {
      return;
    }

    initializeProduct(parseInt(productId, 10));
  }, [detail]);

  const sellingPriceUnitOptions = getSellingPriceUnitOptions(
    getAttributeValue(detail, QUANTITY_UNIT),
    detail.sortIndex.toString(),
    t
  );

  const onProductChange = (newProduct: IProductConfigurationProduct) => {
    updateAttributeValues([
      {
        type: PRODUCT_ID,
        value: newProduct.id === null ? '' : newProduct.id.toString(),
      },
    ]);

    setProduct(newProduct);
  };

  const onTextValueChange = (type: string) => (event: any) => {
    updateAttributeValues([{ type, value: event.target.value }]);
  };

  const detailForCalculations = {
    expense: getAttributeValue(detail, EXPENSE),
    expenseUnit: getAttributeValue(detail, EXPENSE_UNIT),
    quantity: getAttributeValue(detail, QUANTITY),
    quantityUnit: getAttributeValue(detail, QUANTITY_UNIT),
    sellingPrice: getAttributeValue(detail, SELLING_PRICE),
    sellingPriceUnit: getAttributeValue(detail, SELLING_PRICE_UNIT),
    wastePercentage: getAttributeValue(detail, WASTE_PERCENTAGE),
  };

  const expense = getSubproductTotalExpense(detailForCalculations);

  const contributionMargin = getContributionMarginPerUnit(
    [],
    [detailForCalculations]
  );

  const contributionMarginRatio = getContributionMarginRatio(
    [],
    [detailForCalculations]
  );

  return (
    <Row>
      <Col xs="2">
        <ProductConfigurationProduct
          configurationProduct={product}
          setConfigurationProduct={onProductChange}
          showLabel={false}
        />
      </Col>
      <Col>
        <NumberWithDropdownInputField
          dropdownName="quantityUnit"
          dropdownOnChange={onTextValueChange(QUANTITY_UNIT)}
          dropdownOptions={getUnitOptions(units, t)}
          dropdownValue={getAttributeValue(detail, QUANTITY_UNIT)}
          numberName="quantity"
          numberOnChange={onTextValueChange(QUANTITY)}
          numberValue={getAttributeValue(detail, QUANTITY)}
          required
        />
      </Col>
      <Col>
        <NumberWithDropdownInputField
          dropdownDisabled
          dropdownName="expenseUnit"
          dropdownOnChange={onTextValueChange(EXPENSE_UNIT)}
          dropdownOptions={sellingPriceUnitOptions}
          dropdownValue={getAttributeValue(detail, EXPENSE_UNIT)}
          numberName="expense"
          numberOnChange={onTextValueChange(EXPENSE)}
          numberValue={getAttributeValue(detail, EXPENSE)}
          required
        />
      </Col>
      <Col>
        <NumberWithUnitInputField
          name="wastePercentage"
          onChange={onTextValueChange(WASTE_PERCENTAGE)}
          required
          unit="%"
          value={getAttributeValue(detail, WASTE_PERCENTAGE)}
        />
      </Col>
      <Col xs="1">
        <NumberWithUnitInputField
          disabled
          unit="€"
          value={formatFloatForDisplay(expense)}
        />
      </Col>
      <Col>
        <NumberWithDropdownInputField
          dropdownDisabled
          dropdownName="sellingPriceUnit"
          dropdownOnChange={onTextValueChange(SELLING_PRICE_UNIT)}
          dropdownOptions={sellingPriceUnitOptions}
          dropdownValue={getAttributeValue(detail, SELLING_PRICE_UNIT)}
          numberName="sellingPrice"
          numberOnChange={onTextValueChange(SELLING_PRICE)}
          numberValue={getAttributeValue(detail, SELLING_PRICE)}
          required
        />
      </Col>
      <Col xs="1">
        <NumberWithUnitInputField
          disabled
          unit="€"
          value={formatFloatForDisplay(contributionMargin)}
        />
      </Col>
      <Col xs="1">
        <NumberWithUnitInputField
          disabled
          unit="%"
          value={formatFloatForDisplay(contributionMarginRatio)}
        />
      </Col>
      <Col className="icon-column" xs="1">
        <TrashIcon onClick={removeDetail} />
      </Col>
    </Row>
  );
};

export default ProductItemDetail;
