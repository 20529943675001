const PROJECTS = 'projects';
const OFFERS = 'offers';
const CONTACTS = 'contacts';
const PRODUCT_CONFIGURATIONS = 'product-configurations';
const PRODUCTS = 'products';
const ROLES = 'roles';
const USERS = 'users';
const INSTALLATION_PRICES = 'installation-prices';

export {
  PROJECTS,
  OFFERS,
  CONTACTS,
  PRODUCT_CONFIGURATIONS,
  PRODUCTS,
  ROLES,
  USERS,
  INSTALLATION_PRICES,
};
