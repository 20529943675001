import React from 'react';

const getOptionNames = (options: any[]) => {
  const names = [];
  names.push(<option key="opt00" value={undefined} />);
  options.map((option) => {
    names.push(
      <option key={`opt${option.name}`} value={option.id}>
        {option.name}
      </option>
    );
  });
  return names;
};

export default getOptionNames;
