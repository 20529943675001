import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ModalAccept from 'components/layout/modals/ModalAccept';
import translations from 'services/translations/translations';
import ApiErrorTypes from 'services/enums/ApiErrorTypes';

const MessageModal = ({
  initializeBasicInfo,
  modalSaved,
  saveError,
  setModalSaved,
  setSaveError,
}: {
  initializeBasicInfo: () => Promise<void>;
  modalSaved: boolean;
  saveError: null | string;
  setModalSaved: (modalSaved: boolean) => void;
  setSaveError: (saveError: null | string) => void;
}): JSX.Element => {
  const { t } = useTranslation([translations.projects]);

  useEffect(() => {
    if (saveError === ApiErrorTypes.RemovedItemConnectedToNotRemovedItem) {
      initializeBasicInfo();
    }
  }, [saveError]);

  const getMessage = (): string => {
    if (saveError === null) {
      return t(`${translations.projects}:Basic info saved`);
    }

    if (saveError === ApiErrorTypes.RemovedItemConnectedToNotRemovedItem) {
      return t(
        `${translations.projects}:Tried to remove an item connected to a measurement`
      );
    }

    return t(`${translations.projects}:Basic info failed`);
  };

  const setShowing = (showing: boolean) => {
    setModalSaved(showing);

    if (!showing) {
      setSaveError(null);
    }
  };

  return (
    <ModalAccept
      showing={modalSaved}
      setShowing={setShowing}
      header=""
      text={getMessage()}
    />
  );
};

export default MessageModal;
