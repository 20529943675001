import IRole from 'interfaces/users/IRole';
import IUserGet from 'api/interfaces/users/IUserGet';
import IUsersState from 'interfaces/store/IUsersState';

import IOrganizationUnit from 'interfaces/users/IOrganizationUnit';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import {
  FETCH_PERMISSION_TYPES,
  FETCH_ROLES,
  FETCH_USER,
  FETCH_USERS,
  SET_ACCESS_TOKEN,
  FETCH_ORGANIZATION_UNITS,
  SET_MSAL_INSTANCE,
  SET_MSAL_ACCOUNT,
} from './types';

const initialState: IUsersState = {
  accessToken: null,
  current: null,
  permissionTypes: [],
  roles: [],
  users: [],
  organizationUnits: [],
  msalInstance: null,
  msalAccount: null,
};

export default (
  state: IUsersState = initialState,
  action: {
    accessToken: null | string;
    permissionTypes: string[];
    roles: IRole[];
    type: string;
    user: IUserGet | null;
    users: IUserGet[];
    organizationUnits: IOrganizationUnit[];
    msalInstance: IPublicClientApplication | null;
    msalAccount: AccountInfo | null;
  }
): IUsersState => {
  if (action.type === FETCH_PERMISSION_TYPES.SUCCESS) {
    return {
      ...state,
      permissionTypes: action.permissionTypes,
    };
  }

  if (action.type === FETCH_ROLES.SUCCESS) {
    return {
      ...state,
      roles: action.roles,
    };
  }

  if (action.type === FETCH_USER.SUCCESS) {
    return {
      ...state,
      current: action.user,
    };
  }

  if (action.type === FETCH_USERS.SUCCESS) {
    return {
      ...state,
      users: action.users,
    };
  }

  if (action.type === FETCH_ORGANIZATION_UNITS.SUCCESS) {
    return {
      ...state,
      organizationUnits: action.organizationUnits,
    };
  }

  if (action.type === SET_ACCESS_TOKEN) {
    return {
      ...state,
      accessToken: action.accessToken,
    };
  }

  if (action.type === SET_MSAL_INSTANCE) {
    return {
      ...state,
      msalInstance: action.msalInstance,
    };
  }

  if (action.type === SET_MSAL_ACCOUNT) {
    return {
      ...state,
      msalAccount: action.msalAccount,
    };
  }

  return state;
};
