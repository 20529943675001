import React from 'react';
import { Route } from 'react-router-dom';

import { CONTACTS } from 'services/types/viewTypes';
import Contacts from 'components/contacts/Contacts';
import CreateContact from 'components/contacts/CreateContact';
import CreateContactPerson from 'components/contacts/CreateContactPerson';
import Permission from 'components/layout/Permission';

const contacts = (): JSX.Element[] => [
  <Route exact key="contacts-route" path="/contacts">
    <Permission type={CONTACTS}>
      <Contacts />
    </Permission>
  </Route>,
  <Route exact key="create-contact-route" path="/contacts/create">
    <Permission type={CONTACTS}>
      <CreateContact />
    </Permission>
  </Route>,
  <Route exact key="contact-route" path="/contacts/:contactId">
    <Permission type={CONTACTS}>
      <CreateContact />
    </Permission>
  </Route>,
  <Route
    exact
    key="create-contact-person-route"
    path="/contacts/:contactId/create"
  >
    <Permission type={CONTACTS}>
      <CreateContactPerson />
    </Permission>
  </Route>,
  <Route exact key="contact-person-route" path="/contacts/persons/:personId">
    <Permission type={CONTACTS}>
      <CreateContactPerson />
    </Permission>
  </Route>,
];

export default contacts;
