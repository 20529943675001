import React from 'react';

import sortByTranslation from 'services/sort/sortByTranslation';
import translations from 'services/translations/translations';

const getUnitOptions = (units: any, t: any) => {
  const unitsTranslationLocation = `${translations.common}:units`;

  return [...units]
    .sort(sortByTranslation(t, unitsTranslationLocation))
    .filter((unit: string) => !unit.includes('/'))
    .map((unit: string) => (
      <option key={`unit-option-${unit}`} value={unit}>
        {t(`${unitsTranslationLocation}:${unit}`)}
      </option>
    ));
};

export default getUnitOptions;
