import IMeasurement from 'interfaces/products/IMeasurement';
import IMeasurementGroupType from 'interfaces/products/IMeasurementGroupType';

const getEmptyMeasurement = (
  measurFormValues: IMeasurement[],
  unitsList: string[],
  measurementTypesList: IMeasurementGroupType[],
  group: string | null
): IMeasurement[] => {
  const groupMeasurementTypes = measurementTypesList.filter(
    (x) => x.group === group
  );

  const firstUnused = groupMeasurementTypes.findIndex(
    (measurementType) =>
      !measurFormValues.find(
        (measurFormValue) => measurFormValue.type === measurementType.type
      )
  );

  const emptyValues: IMeasurement = {
    type: groupMeasurementTypes[firstUnused].type,
    siUnit: unitsList[0],
    value: '0',
  };

  return [...measurFormValues, emptyValues];
};

export default getEmptyMeasurement;
