import React from 'react';
import AsyncSelect from 'react-select/async';
import { Label } from 'reactstrap';
import getCssVariable, { THEME_GREY } from 'services/getCssVariable';

const SearchDropdown = ({
  className,
  disabled,
  id,
  label,
  loadOptions,
  name,
  onChange,
  placeholder,
  required,
  value,
}: {
  className?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  loadOptions: any;
  name?: string;
  onChange: any;
  placeholder: string;
  required?: boolean;
  value: any;
}): JSX.Element | null => {
  return (
    <>
      {label === undefined ? null : (
        <Label className="label" for={id}>
          {label}
        </Label>
      )}
      <AsyncSelect
        className={`text-field search-field ${className}`}
        id={id}
        isClearable
        isDisabled={disabled}
        loadOptions={loadOptions}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        styles={{
          control: (provided, state) =>
            state.isDisabled
              ? {
                  ...provided,
                  backgroundColor: getCssVariable(THEME_GREY),
                }
              : provided,
        }}
        value={value}
      />
    </>
  );
};

export default SearchDropdown;
