import React from 'react';

import deleteIcon from 'assets/delete_icon.svg';

import 'style/layout/Icons/TrashIcon.css';

const TrashIcon = ({
  onClick,
  className,
  style,
}: {
  onClick: () => void;
  className?: string;
  style?: any;
}): JSX.Element => (
  <>
    <img
      alt="delete-icon"
      style={style ?? null}
      className={`trash-icon ${className}`}
      onClick={() => onClick()}
      src={deleteIcon}
    />
  </>
);

export default TrashIcon;
