export default {
  admin: 'admin',
  calculationWindow: 'offers:calculationWindow',
  common: 'common',
  contacts: 'contacts',
  dashboard: 'dashboard',
  productConfigurations: 'products:configurations',
  productFileTypes: 'products:fileTypes',
  productMeasurementTypes: 'products:measurementTypes',
  productPriceTypes: 'products:priceTypes',
  products: 'products',
  offers: 'offers',
  units: 'common:units',
  userManagement: 'userManagement',
  projects: 'projects',
};
