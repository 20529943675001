import numericStringToFloat from 'services/numbers/numericStringToFloat';

import IDetail from './IDetail';

const getSubproductDetailUnitExpense = (detail: IDetail): number => {
  const expense =
    typeof detail.expense === 'number'
      ? detail.expense
      : numericStringToFloat(detail.expense);

  const wastePercentage =
    typeof detail.wastePercentage === 'number'
      ? detail.wastePercentage
      : numericStringToFloat(detail.wastePercentage);

  return expense * (1 + wastePercentage / 100);
};

export default getSubproductDetailUnitExpense;
