import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import contactRoutes from 'routes/contacts';
import ExternalLogin from 'components/userManagement/ExternalLogin';
import Home from 'components/Home';
import Layout from 'components/layout/Layout';
import NotFound from 'components/layout/NotFound';
import offerRoutes from 'routes/offers';
import productConfigurationRoutes from 'routes/productConfigurations';
import productRoutes from 'routes/products';
import projectRoutes from 'routes/projects';
import userRoutes from 'routes/users';

import 'style/theme.css';

const App = (): JSX.Element => (
  <Router>
    <Route exact path="/login">
      <ExternalLogin />
    </Route>
    <Layout>
      <Switch>
        {[
          <Route exact path="/" key="home-route">
            <Home />
          </Route>,
          ...contactRoutes(),
          ...offerRoutes(),
          ...productRoutes(),
          ...projectRoutes(),
          ...userRoutes(),
          <Route exact path="/login" key="login-route" />,
          ...productConfigurationRoutes(),
          <Route component={NotFound} key="not-found-route" />,
        ]}
      </Switch>
    </Layout>
  </Router>
);

export default App;
