import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import 'style/layout/form/Checkbox.css';

const Checkbox = ({
  checked,
  className,
  disabled,
  label,
  onChange,
}: {
  checked: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  onChange: (event: any) => void;
}): JSX.Element => (
  <FormGroup
    check
    className={`${className === undefined ? '' : className} eworkbook-checkbox`}
  >
    <Label check>
      <Input
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        type="checkbox"
      />
      {label}
    </Label>
  </FormGroup>
);

export default Checkbox;
