import asyncAction from '../asyncAction';

const FETCH_ABSORPTION_CLASSES = asyncAction('FETCH_ABSORPTION_CLASSES');
const FETCH_ATTRIBUTE_TYPES = asyncAction('FETCH_ATTRIBUTE_TYPES');
const FETCH_CATEGORIES = asyncAction('FETCH_CATEGORIES');
const FETCH_CERTIFICATE_TYPES = asyncAction('FETCH_CERTIFICATE_TYPES');
const FETCH_CLASSES_OF_FIRE = asyncAction('FETCH_CLASSES_OF_FIRE');
const FETCH_EDGE_TYPES = asyncAction('FETCH_EDGE_TYPES');
const FETCH_FASTENER_TYPES = asyncAction('FETCH_FASTENER_TYPES');
const FETCH_FILE_TYPES = asyncAction('FETCH_FILE_TYPES');
const FETCH_GROUPS = asyncAction('FETCH_GROUPS');
const FETCH_ISO_CLEANROOM_CLASSES = asyncAction('FETCH_ISO_CLEANROOM_CLASSES');
const FETCH_MATERIAL_TYPES = asyncAction('FETCH_MATERIAL_TYPES');
const FETCH_MEASUREMENT_TYPES = asyncAction('FETCH_MEASUREMENT_TYPES');
const FETCH_PERFORATION_TYPES = asyncAction('FETCH_PERFORATION_TYPES');
const FETCH_PRICE_TYPES = asyncAction('FETCH_PRICE_TYPES');
const FETCH_PRODUCT_ID_TYPES = asyncAction('FETCH_PRODUCT_ID_TYPES');
const FETCH_TAG_TYPES = asyncAction('FETCH_TAG_TYPES');
const FETCH_TIP_TYPES = asyncAction('FETCH_TIP_TYPES');
const FETCH_UNITS = asyncAction('FETCH_UNITS');
const SET_CONFIGURATION_ITEMS = 'SET_CONFIGURATION_ITEMS';

const FETCH_DELIVERY_CATEGORY_TYPES = asyncAction(
  'FETCH_DELIVERY_CATEGORY_TYPES'
);

const FETCH_INSTALLATION_CATEGORIES = asyncAction(
  'FETCH_INSTALLATION_CATEGORIES'
);

const FETCH_INSTALLATION_METHOD_TYPES = asyncAction(
  'FETCH_INSTALLATION_METHOD_TYPES'
);

const FETCH_INSTALLATION_PRICE_TYPES = asyncAction(
  'FETCH_INSTALLATION_PRICE_TYPES'
);

const FETCH_MATERIAL_SURFACE_TYPES = asyncAction(
  'FETCH_MATERIAL_SURFACE_TYPES'
);

export {
  FETCH_ABSORPTION_CLASSES,
  FETCH_ATTRIBUTE_TYPES,
  FETCH_CATEGORIES,
  FETCH_CERTIFICATE_TYPES,
  FETCH_CLASSES_OF_FIRE,
  FETCH_DELIVERY_CATEGORY_TYPES,
  FETCH_EDGE_TYPES,
  FETCH_FASTENER_TYPES,
  FETCH_FILE_TYPES,
  FETCH_GROUPS,
  FETCH_INSTALLATION_CATEGORIES,
  FETCH_INSTALLATION_METHOD_TYPES,
  FETCH_INSTALLATION_PRICE_TYPES,
  FETCH_ISO_CLEANROOM_CLASSES,
  FETCH_MATERIAL_SURFACE_TYPES,
  FETCH_MATERIAL_TYPES,
  FETCH_MEASUREMENT_TYPES,
  FETCH_PERFORATION_TYPES,
  FETCH_PRICE_TYPES,
  FETCH_PRODUCT_ID_TYPES,
  FETCH_TAG_TYPES,
  FETCH_TIP_TYPES,
  FETCH_UNITS,
  SET_CONFIGURATION_ITEMS,
};
