import React from 'react';
import { useTranslation } from 'react-i18next';

import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import translations from 'services/translations/translations';

const ProductAttributeDropdown = ({
  index,
  item,
  options,
  showOptionsFirstBlank,
  translationGroupId,
  type,
  updateAttributeRow,
  validator,
}: {
  index: number;
  item: any;
  options: string[];
  showOptionsFirstBlank: any;
  translationGroupId: null | string;
  type: string;
  updateAttributeRow: any;
  validator: (item: any) => boolean;
}): JSX.Element => {
  const { t } = useTranslation([translations.products]);

  const value = !validator(item) ? options[0] : item.value;

  const translationGroup =
    translationGroupId === null
      ? ''
      : `${translations.products}:${translationGroupId}`;

  return (
    <SelectInputFieldControlled
      label={index === 0 ? t(`${translations.products}:Value`) : undefined}
      required
      id={type}
      name="value"
      value={value}
      onChange={(e: any) => updateAttributeRow(e, index)}
      options={showOptionsFirstBlank(type, options, translationGroup, ':', t)}
    />
  );
};

export default ProductAttributeDropdown;
