import IAttribute from 'interfaces/products/IAttribute';

const getEmptyAttribute = (
  attributeFormValues: IAttribute[],
  attributeType: string
) => {
  const emptyValues: IAttribute = {
    value: '',
    type: attributeType,
  };
  return [...attributeFormValues, emptyValues];
};

export default getEmptyAttribute;
