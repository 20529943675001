import getDefaultVersion from './calculationWindow/getDefaultVersion';
import IFormState from './IFormState';

const getDefaultFormState = (): IFormState => ({
  memo: '',
  name: '',
  position: '',
  productGroup: '',
  quantity: '',
  unit: '',
  versions: [{ ...getDefaultVersion(), name: '1' }],
});

export default getDefaultFormState;
