import React from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import translations from 'services/translations/translations';

import views from './views';

const Header = ({
  action,
  view,
}: {
  action: string;
  view: string;
}): JSX.Element => {
  const { t } = useTranslation([translations.offers, translations.products]);

  const calculationWindowIsOpen = [
    views.attachConfiguration,
    views.calculationWindow,
  ].includes(view);

  const getTitle = () => {
    const attachConfigurationText =
      view === views.attachConfiguration
        ? t(`${translations.calculationWindow}:Attach configuration`)
        : null;

    const viewTitle =
      action === 'create'
        ? t(`${translations.products}:Add Product`)
        : t(`${translations.products}:Edit Product`);

    const calculationWindowText = calculationWindowIsOpen
      ? t(`${translations.offers}:Calculation window`)
      : null;

    return [viewTitle, calculationWindowText, attachConfigurationText]
      .filter((text) => text !== null)
      .join(' / ');
  };

  return (
    <Row className="row-content">
      <Col>
        <p className="header-text">{getTitle()}</p>
      </Col>
    </Row>
  );
};

export default Header;
