import React from 'react';
import { Route } from 'react-router-dom';

import AddLinkToProduct from 'components/products/configurations/AddLinkToProduct';
import CreateItem from 'components/products/configurations/CreateItem';
import EditItem from 'components/products/configurations/EditItem';
import EditConfiguration from 'components/products/configurations/EditConfiguration';
import Permission from 'components/layout/Permission';
import { PRODUCT_CONFIGURATIONS } from 'services/types/viewTypes';
import CreateConfiguration from 'components/products/configurations/CreateConfiguration';

const productConfigurations = (): JSX.Element[] => [
  <Route
    exact
    key="create-product-configuration-route"
    path="/products/configurations/create"
  >
    <Permission type={PRODUCT_CONFIGURATIONS}>
      <CreateConfiguration />
    </Permission>
  </Route>,
  <Route
    exact
    key="product-configuration-route"
    path="/products/configurations/:id"
  >
    <Permission type={PRODUCT_CONFIGURATIONS}>
      <EditConfiguration />
    </Permission>
  </Route>,
  <Route
    exact
    path="/products/configurations/:configurationId/items/:type/create"
    key="create-product-configuration-item-route"
  >
    <Permission type={PRODUCT_CONFIGURATIONS}>
      <CreateItem />
    </Permission>
  </Route>,
  <Route
    exact
    key="product-configuration-item-route"
    path="/products/configurations/items/:itemId"
  >
    <Permission type={PRODUCT_CONFIGURATIONS}>
      <EditItem />
    </Permission>
  </Route>,
  <Route
    exact
    key="create-product-configuration-sub-item-route"
    path="/products/configurations/items/:itemId/:type/create"
  >
    <Permission type={PRODUCT_CONFIGURATIONS}>
      <CreateItem />
    </Permission>
  </Route>,
  <Route
    exact
    key="create-product-link-route"
    path="/products/configurations/:id/links/create"
  >
    <Permission type={PRODUCT_CONFIGURATIONS}>
      <AddLinkToProduct />
    </Permission>
  </Route>,
];

export default productConfigurations;
