import { call, put, select, takeLeading } from 'redux-saga/effects';

import apiProducts from 'api/products';

import { finishLoading, startLoading } from '../loading/actions';

import {
  FETCH_ABSORPTION_CLASSES,
  FETCH_ATTRIBUTE_TYPES,
  FETCH_CATEGORIES,
  FETCH_CERTIFICATE_TYPES,
  FETCH_CLASSES_OF_FIRE,
  FETCH_DELIVERY_CATEGORY_TYPES,
  FETCH_EDGE_TYPES,
  FETCH_FASTENER_TYPES,
  FETCH_FILE_TYPES,
  FETCH_GROUPS,
  FETCH_INSTALLATION_CATEGORIES,
  FETCH_INSTALLATION_METHOD_TYPES,
  FETCH_INSTALLATION_PRICE_TYPES,
  FETCH_ISO_CLEANROOM_CLASSES,
  FETCH_MATERIAL_SURFACE_TYPES,
  FETCH_MATERIAL_TYPES,
  FETCH_MEASUREMENT_TYPES,
  FETCH_PERFORATION_TYPES,
  FETCH_PRICE_TYPES,
  FETCH_PRODUCT_ID_TYPES,
  FETCH_TAG_TYPES,
  FETCH_TIP_TYPES,
  FETCH_UNITS,
} from './types';

function* fetchTypes(type: any, variableName: any, apiEndpoint: any) {
  const currentValue = yield select((state) => state.products[variableName]);
  if (currentValue.length > 0) {
    return;
  }

  try {
    yield put(startLoading(type.PENDING));
    const types = yield call(apiEndpoint);
    yield put({ type: type.SUCCESS, [variableName]: types });
    yield put(finishLoading(type.PENDING));
  } catch (error) {
    yield put({
      type: type.FAILURE,
      message: error.message,
    });
  }
}

function* fetchAbsorptionClasses() {
  yield fetchTypes(
    FETCH_ABSORPTION_CLASSES,
    'absorptionClasses',
    apiProducts.attributes.absorptionClasses.get
  );
}

function* fetchAttributeTypes() {
  yield fetchTypes(
    FETCH_ATTRIBUTE_TYPES,
    'attributeTypes',
    apiProducts.attributeTypes.get
  );
}

function* fetchCategories() {
  yield fetchTypes(FETCH_CATEGORIES, 'categories', apiProducts.categories.get);
}

function* fetchCertificateTypes() {
  yield fetchTypes(
    FETCH_CERTIFICATE_TYPES,
    'certificateTypes',
    apiProducts.attributes.certificateTypes.get
  );
}

function* fetchClassesOfFire() {
  yield fetchTypes(
    FETCH_CLASSES_OF_FIRE,
    'classesOfFire',
    apiProducts.attributes.classesOfFire.get
  );
}

function* fetchDeliveryCategoryTypes() {
  yield fetchTypes(
    FETCH_DELIVERY_CATEGORY_TYPES,
    'deliveryCategoryTypes',
    apiProducts.deliveryCategoryTypes.get
  );
}

function* fetchEdgeTypes() {
  yield fetchTypes(FETCH_EDGE_TYPES, 'edgeTypes', apiProducts.edgeTypes.get);
}

function* fetchFastenerTypes() {
  yield fetchTypes(
    FETCH_FASTENER_TYPES,
    'fastenerTypes',
    apiProducts.attributes.fastenerTypes.get
  );
}

function* fetchFileTypes() {
  yield fetchTypes(FETCH_FILE_TYPES, 'fileTypes', apiProducts.fileTypes.get);
}

function* fetchGroups() {
  yield fetchTypes(FETCH_GROUPS, 'groups', apiProducts.groups.get);
}

function* fetchInstallationCategories() {
  yield fetchTypes(
    FETCH_INSTALLATION_CATEGORIES,
    'installationCategories',
    apiProducts.installationCategories.get
  );
}

function* fetchInstallationMethodTypes() {
  yield fetchTypes(
    FETCH_INSTALLATION_METHOD_TYPES,
    'installationMethodTypes',
    apiProducts.attributes.installationMethodTypes.get
  );
}

function* fetchInstallationPriceTypes() {
  yield fetchTypes(
    FETCH_INSTALLATION_PRICE_TYPES,
    'installationPriceTypes',
    apiProducts.installationPriceTypes.get
  );
}

function* fetchIsoCleanroomClasses() {
  yield fetchTypes(
    FETCH_ISO_CLEANROOM_CLASSES,
    'isoCleanroomClasses',
    apiProducts.attributes.isoCleanroomClasses.get
  );
}

function* fetchMaterialSurfaceTypes() {
  yield fetchTypes(
    FETCH_MATERIAL_SURFACE_TYPES,
    'materialSurfaceTypes',
    apiProducts.attributes.materialSurfaceTypes.get
  );
}

function* fetchMaterialTypes() {
  yield fetchTypes(
    FETCH_MATERIAL_TYPES,
    'materialTypes',
    apiProducts.attributes.materials.get
  );
}

function* fetchMeasurementTypes() {
  yield fetchTypes(
    FETCH_MEASUREMENT_TYPES,
    'measurementTypes',
    apiProducts.measurementTypes.get
  );
}

function* fetchPerforationTypes() {
  yield fetchTypes(
    FETCH_PERFORATION_TYPES,
    'perforationTypes',
    apiProducts.attributes.perforations.get
  );
}

function* fetchPriceTypes() {
  yield fetchTypes(FETCH_PRICE_TYPES, 'priceTypes', apiProducts.priceTypes.get);
}

function* fetchProductIdTypes() {
  yield fetchTypes(
    FETCH_PRODUCT_ID_TYPES,
    'productTypes',
    apiProducts.idTypes.get
  );
}

function* fetchTagTypes() {
  yield fetchTypes(FETCH_TAG_TYPES, 'tagTypes', apiProducts.tags.get);
}

function* fetchTipTypes() {
  yield fetchTypes(
    FETCH_TIP_TYPES,
    'tipTypes',
    apiProducts.attributes.tips.get
  );
}

function* fetchUnits() {
  yield fetchTypes(FETCH_UNITS, 'units', apiProducts.units.get);
}

function* saga(): Generator {
  yield takeLeading(FETCH_ABSORPTION_CLASSES.PENDING, fetchAbsorptionClasses);

  yield takeLeading(FETCH_ATTRIBUTE_TYPES.PENDING, fetchAttributeTypes);

  yield takeLeading(FETCH_CATEGORIES.PENDING, fetchCategories);

  yield takeLeading(FETCH_CERTIFICATE_TYPES.PENDING, fetchCertificateTypes);

  yield takeLeading(FETCH_CLASSES_OF_FIRE.PENDING, fetchClassesOfFire);

  yield takeLeading(
    FETCH_DELIVERY_CATEGORY_TYPES.PENDING,
    fetchDeliveryCategoryTypes
  );

  yield takeLeading(FETCH_EDGE_TYPES.PENDING, fetchEdgeTypes);

  yield takeLeading(FETCH_FASTENER_TYPES.PENDING, fetchFastenerTypes);

  yield takeLeading(FETCH_FILE_TYPES.PENDING, fetchFileTypes);

  yield takeLeading(FETCH_GROUPS.PENDING, fetchGroups);

  yield takeLeading(
    FETCH_INSTALLATION_CATEGORIES.PENDING,
    fetchInstallationCategories
  );

  yield takeLeading(
    FETCH_INSTALLATION_METHOD_TYPES.PENDING,
    fetchInstallationMethodTypes
  );

  yield takeLeading(
    FETCH_INSTALLATION_PRICE_TYPES.PENDING,
    fetchInstallationPriceTypes
  );

  yield takeLeading(
    FETCH_ISO_CLEANROOM_CLASSES.PENDING,
    fetchIsoCleanroomClasses
  );

  yield takeLeading(
    FETCH_MATERIAL_SURFACE_TYPES.PENDING,
    fetchMaterialSurfaceTypes
  );

  yield takeLeading(FETCH_MATERIAL_TYPES.PENDING, fetchMaterialTypes);

  yield takeLeading(FETCH_MEASUREMENT_TYPES.PENDING, fetchMeasurementTypes);

  yield takeLeading(FETCH_PERFORATION_TYPES.PENDING, fetchPerforationTypes);

  yield takeLeading(FETCH_PRICE_TYPES.PENDING, fetchPriceTypes);

  yield takeLeading(FETCH_PRODUCT_ID_TYPES.PENDING, fetchProductIdTypes);

  yield takeLeading(FETCH_TAG_TYPES.PENDING, fetchTagTypes);

  yield takeLeading(FETCH_TIP_TYPES.PENDING, fetchTipTypes);

  yield takeLeading(FETCH_UNITS.PENDING, fetchUnits);
}

export default saga;
