import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import translations from 'services/translations/translations';

import 'style/layout/form/SelectInputTranslated.css';

const SelectInputTranslated = ({
  disabled,
  id,
  key,
  label,
  name,
  onChange,
  options,
  required,
  translationPath,
  value,
}: {
  disabled?: boolean;
  id?: string;
  key: string;
  label?: string;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: string[];
  required?: boolean;
  translationPath: string;
  value: string;
}): JSX.Element => {
  const { t } = useTranslation([
    translations.admin,
    translations.common,
    translations.offers,
    translations.products,
    translations.projects,
    translations.userManagement,
  ]);

  return (
    <FormGroup className="select-input-translated">
      {label === undefined ? null : (
        <Label className="label" for={id}>
          {label}
        </Label>
      )}
      <Input
        className="text-field"
        disabled={disabled}
        id={id}
        name={name}
        required={required && value === ''}
        onChange={onChange}
        type="select"
        value={value}
      >
        {options.map((option) =>
          option === '' ? (
            <option key={`${key}-empty`} value="">
              -
            </option>
          ) : (
            <option key={`${key}-${option}`} value={option}>
              {t(`${translationPath}:${option}`)}
            </option>
          )
        )}
      </Input>
    </FormGroup>
  );
};

export default SelectInputTranslated;
