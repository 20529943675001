import asyncAction from '../asyncAction';

const FETCH_CONTRACT_TYPES = asyncAction('FETCH_CONTRACT_TYPES');
const FETCH_BUILDING_TYPES = asyncAction('FETCH_BUILDING_TYPES');
const FETCH_WORK_TYPES = asyncAction('FETCH_WORK_TYPES');
const FETCH_COLLATERAL_TYPES = asyncAction('FETCH_COLLATERAL_TYPES');
const FETCH_EMPLOYEE_INFORMATION_TYPES = asyncAction(
  'FETCH_EMPLOYEE_INFORMATION_TYPES'
);
const FETCH_WAY_OF_RECEIVING_TYPES = asyncAction(
  'FETCH_WAY_OF_RECEIVING_TYPES'
);
const FETCH_TAX_RATE_TYPES = asyncAction('FETCH_TAX_RATE_TYPES');
const FETCH_TAB_TYPES = asyncAction('FETCH_TAB_TYPES');
const FETCH_HINDRANCE_TYPES = asyncAction('FETCH_HINDRANCE_TYPES');
const FETCH_PLACE_STATUS_TYPES = asyncAction('FETCH_PLACE_STATUS_TYPES');

export {
  FETCH_CONTRACT_TYPES,
  FETCH_WORK_TYPES,
  FETCH_BUILDING_TYPES,
  FETCH_COLLATERAL_TYPES,
  FETCH_EMPLOYEE_INFORMATION_TYPES,
  FETCH_WAY_OF_RECEIVING_TYPES,
  FETCH_TAX_RATE_TYPES,
  FETCH_TAB_TYPES,
  FETCH_HINDRANCE_TYPES,
  FETCH_PLACE_STATUS_TYPES,
};
