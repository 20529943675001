import IOrganizationUnit from 'interfaces/users/IOrganizationUnit';
import React from 'react';
import translations from 'services/translations/translations';
import { TFunction } from 'i18next';

export const getOrgUnits = (org: any, depth = 0, t: TFunction) =>
  org.reduce(
    (organizationUnits: IOrganizationUnit[], x: IOrganizationUnit, i: any) => [
      ...organizationUnits,
      <option key={`orgUnits${x.id}`} value={x.id}>
        {'--'.repeat(depth)} {t(`${translations.offers}:${x.name}`)}
      </option>,
      ...getOrgUnits(x.children, depth + 1, t),
    ],
    []
  );

export const showOrganizationUnitsDropdown = (
  organizationUnitTypesList: IOrganizationUnit[],
  t: TFunction
) => {
  const options: JSX.Element[] = [];
  options.push(<option key="orgUnits00" value={0} />);
  return options.concat(getOrgUnits(organizationUnitTypesList, 0, t));
};
