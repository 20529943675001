import { connect } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import IBasicInfoGet from 'api/interfaces/projects/IBasicInfoGet';
import IPlaceGet from 'api/interfaces/projects/IPlaceGet';
import IPlacePost from 'api/interfaces/projects/IPlacePost';
import IHindranceGet from 'api/interfaces/projects/IHindranceGet';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import SquareDisabledField from 'components/layout/forms/SquareDisabledField';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import TrashIcon from 'components/layout/icons/TrashIcon';
import getTargetValue from 'services/forms/getTargetValue';
import IBuilding from 'interfaces/projects/IBuilding';
import IBuildingStructure from 'interfaces/projects/IBuildingStructure';
import IFile from 'interfaces/forms/IFile';
import IPosition from 'interfaces/projects/IPosition';
import IWorkGroupGet from 'interfaces/projects/IWorkGroupGet';
import IPlaceMeasurement from 'interfaces/projects/IPlaceMeasurement';
import IHindrancePost from 'interfaces/projects/IHindrancePost';
import replaceCommas from 'services/numbers/replaceCommas';
import translations from 'services/translations/translations';
import { fetchHindranceTypes } from 'store/projects/actions';
import getNextObjectKey from 'services/numbers/getNextObjectKey';
import ModalAccept from 'components/layout/modals/ModalAccept';
import roundTwoDecimals from 'services/numbers/roundTwoDecimals';
import ModalConfirm from 'components/layout/modals/ModalConfirm';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import { RIGHT } from 'services/enums/sides';

import getPositions from '../basicInfo/getPositions';
import apiProjects from '../../../../api/projects';
import getOptionNames from './getOptionNames';
import ModalCreateWorkGroup from './ModalCreateWorkGroup';
import HindranceRows from './HindranceRows';
import updateHindrance from './updateHindrance';
import BasicTableNoPagination from '../../../layout/tables/BasicTableNoPagination';
import PlaceImages from './PlaceImages';
import { PROJECT_MEASUREMENT_LIST } from '../sectionTypes';
import getColumns from './getColumns';
import getMeasurementsWithId from './getMeasurementsWithId';
import getPlaceFiles from './getPlaceFiles';
import getMillimiterUnit from '../measurementList/getMillimiterUnit';

import 'style/layout/form/AddMeasurementSection.css';

const AddMeasurementSection = ({
  fetchHindranceList,
  hindranceList,
  placeId,
  setPlaceId,
  setSection,
}: {
  hindranceList: IHindranceGet[];
  fetchHindranceList: () => void;
  placeId: number | undefined;
  setPlaceId: (id: number | undefined) => void;
  setSection: (section: string) => void;
}): JSX.Element => {
  const { t } = useTranslation([translations.projects, translations.common]);
  const { projectId } = useParams();
  const [placeInfo, setPlaceInfo] = useState({
    buildingSection: '',
    buildingSpace: '',
    floor: '1',
  });
  const [measurInfo, setMeasurInfo] = useState({
    height: '0',
    width: '0',
    workgroup: '',
    position: '',
  });
  const [placeSavedId, setPlaceSavedId] = useState<number | undefined>(placeId);
  const [building, setBuilding] = useState<string>('');
  const [buildings, setBuildings] = useState<IBuilding[]>([]);
  const [buildingSections, setBuildingSections] = useState<
    IBuildingStructure[]
  >([]);
  const [buildingSpaces, setBuildingSpaces] = useState<IBuildingStructure[]>(
    []
  );
  const [positions, setPositions] = useState<IPosition[]>([]);
  const [hindrances, setHindrances] = useState<IHindrancePost[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [floors, setFloors] = useState<number[]>([]);
  const [workGroups, setWorkGroups] = useState<IWorkGroupGet[]>([]);
  const [workGroupsDropdown, setWorkGroupsDropdown] = useState<IWorkGroupGet[]>(
    []
  );
  const [modalWorkGroup, setModalWorkGroup] = useState(false);
  const [newWorkGroup, setNewWorkGroup] = useState<number>(0);
  const squares: number =
    (replaceCommas(measurInfo.height) / 1000) *
    (replaceCommas(measurInfo.width) / 1000);
  const [placeMeasurements, setPlaceMeasurements] = useState<
    IPlaceMeasurement[]
  >([]);
  const placeMRef = useRef(placeMeasurements);
  const [modalSaved, setModalSaved] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(0);
  const [modalMessage, setModalMessage] = useState('');
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDeleteConfirmation, setModalDeleteConfirmation] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const toggleDeleteConfirmation = () =>
    setModalDeleteConfirmation(!modalDeleteConfirmation);
  const colTitles = {
    position: t(`${translations.projects}:Position`),
    height: t(`${translations.projects}:Height`),
    width: t(`${translations.projects}:Width`),
    squares: t(`${translations.projects}:Squares`),
  };

  const getPositionName = (measurId: any, row: any) =>
    positions.find((pos) => pos.id === row.positionId)?.name;

  const deleteMeasurement = (measurId: any) => {
    const ref = placeMRef.current;
    const list = [...ref];
    const index = ref.findIndex((m) => m.id === measurId);
    list.splice(index, 1);
    setPlaceMeasurements(list);
    placeMRef.current = list;
  };

  const getSquares = (cell: any, row: any, rowIndex: any) => {
    return (
      <div>
        {roundTwoDecimals((row.width / 1000) * (row.height / 1000))}
        {t(`${translations.common}:units.m2`)}
        <TrashIcon
          className="squares-edit"
          onClick={() => deleteMeasurement(row.id)}
        />
      </div>
    );
  };

  useEffect(() => {
    if (saveSuccess === 0) {
      setModalMessage(t(`${translations.projects}:Measurements saved`));
    } else if (saveSuccess === 1) {
      setModalMessage(t(`${translations.projects}:Measurements failed`));
    } else if (saveSuccess === 2) {
      setModalMessage(t(`${translations.projects}:Duplicate measurements`));
    }
  }, [saveSuccess, t]);

  useEffect(() => {
    fetchHindranceList();
  }, [fetchHindranceList]);

  useEffect(() => {
    const getPlace = async () => {
      const result: IPlaceGet = await apiProjects.places.getPlace(placeId);
      setBuilding(result.buildingId.toString());
      setPlaceInfo({
        buildingSection: result.buildingSectionId.toString(),
        buildingSpace: result.buildingSpaceId.toString(),
        floor: result.floor.toString(),
      });
      setPlaceMeasurements(getMeasurementsWithId(result.measurements));
      setFiles(await getPlaceFiles(result.fileIds));
      placeMRef.current = getMeasurementsWithId(result.measurements);
    };

    const getBasicInfo = async () => {
      const result: IBasicInfoGet = await apiProjects.measurementCard.get(
        projectId
      );
      setBuildings(result.buildings);
      setBuildingSections(result.buildingSections);
      setBuildingSpaces(result.buildingSpaces);
      const posTemp = getPositions(result.positions);
      setPositions(posTemp);
      if (placeId !== undefined) {
        getPlace().catch((e) => console.log(e));
      }
    };
    getBasicInfo().catch((e) => console.log(e));
  }, [placeId, projectId]);

  useEffect(() => {
    const getWorkGroups = async () => {
      const result: IBasicInfoGet = await apiProjects.measurementCard.get(
        projectId
      );
      setWorkGroups(result.workgroups);
    };
    getWorkGroups().catch((e) => console.log(e));
  }, [projectId, newWorkGroup]);

  useEffect(() => {
    const maxFloors = buildings.find((b) => b.id?.toString() === building)
      ?.floors;
    if (maxFloors !== undefined) {
      let floorsArray = Array.from(Array(maxFloors + 1).keys());
      floorsArray = floorsArray.slice(1);
      setFloors(floorsArray);
    }
  }, [building, buildings]);

  useEffect(() => {
    const filteredWG = workGroups.filter((wg) =>
      wg.positions.includes(parseInt(measurInfo.position))
    );
    if (filteredWG !== undefined) {
      setWorkGroupsDropdown(filteredWG);
      if (filteredWG[0] !== undefined && measurInfo.workgroup === '') {
        setMeasurInfo({
          ...measurInfo,
          workgroup: filteredWG[0].id ? filteredWG[0].id?.toString() : '',
        });
      }
    }
  }, [measurInfo, workGroups]);

  const handlePlaceInfo = (e: any) => {
    setPlaceInfo({
      ...placeInfo,
      [e.target.name]: getTargetValue(e),
    });
  };

  const handleMeasurInfo = (e: any) => {
    const { name } = e.target;
    if (name === 'position') {
      setMeasurInfo({
        ...measurInfo,
        workgroup: '',
        [name]: getTargetValue(e),
      });
    } else {
      setMeasurInfo({
        ...measurInfo,
        [name]: getTargetValue(e),
      });
    }
  };

  const handleBuilding = (e: any) => {
    setBuilding(getTargetValue(e));
    setPlaceInfo({
      ...placeInfo,
      floor: '1',
    });
  };

  const updateHindranceRow = (e: any, index: any) => {
    let newValues = [];
    newValues = updateHindrance(hindrances, e, index);
    setHindrances(newValues);
  };

  const addHindranceRow = () => {
    setHindrances([...hindrances, { type: '', value: 0 }]);
  };

  const deleteHindranceRow = (index: any, rows: any) => {
    const list = [...rows];
    list.splice(index, 1);
    setHindrances(list);
  };

  const updateWorkGroupList = () => {
    setNewWorkGroup(newWorkGroup + 1);
  };

  const savePlaceMeasurement = () => {
    const placeMeasurement: IPlaceMeasurement = {
      id: getNextObjectKey(placeMeasurements, 'measurId'),
      positionId: replaceCommas(measurInfo.position),
      workgroupId: replaceCommas(measurInfo.workgroup),
      height: replaceCommas(measurInfo.height),
      width: replaceCommas(measurInfo.width),
      hindrances: hindrances.map((h) => {
        const value = replaceCommas(h.value.toString());
        return { type: h.type, value };
      }),
    };
    setPlaceMeasurements([...placeMeasurements, placeMeasurement]);
    placeMRef.current = [...placeMeasurements, placeMeasurement];
    setMeasurInfo({
      ...measurInfo,
      height: '0',
      width: '0',
    });
    setHindrances([]);
  };

  const savePlace = () => {
    const place: IPlacePost = {
      buildingId: replaceCommas(building),
      buildingSectionId: replaceCommas(placeInfo.buildingSection),
      buildingSpaceId: replaceCommas(placeInfo.buildingSpace),
      floor: replaceCommas(placeInfo.floor),
      fileIds: files.map((file: IFile) => file.id),
      measurements: placeMeasurements,
    };

    let result: any;
    if (placeSavedId === undefined) {
      const save = async () => {
        try {
          result = await apiProjects.places.post(projectId, place);
          setPlaceSavedId(result.id);
          setSaveSuccess(0);
          setModalSaved(true);
        } catch (error) {
          setSaveSuccess(1);
          if (error.response.status === 400) {
            setSaveSuccess(2);
          }
          setModalSaved(true);
        }
      };
      save().catch((e) => console.log(e));
    } else {
      const save = async () => {
        try {
          result = await apiProjects.places.put(placeSavedId, place);
          setSaveSuccess(0);
          setModalSaved(true);
        } catch (error) {
          setSaveSuccess(1);
          if (error.response.status === 400) {
            setSaveSuccess(2);
          }
          setModalSaved(true);
        }
      };
      save().catch((e) => console.log(e));
    }
  };

  const goPreviousPage = () => {
    setPlaceId(undefined);
    setSection(PROJECT_MEASUREMENT_LIST);
  };

  const deletePlace = async () => {
    if (placeSavedId !== undefined) {
      try {
        const result = await apiProjects.places.delete(placeSavedId);
        if (result !== undefined && result.success === true) {
          toggleDelete();
          toggleDeleteConfirmation();
          setDeleteSuccess(true);
        } else {
          setDeleteSuccess(false);
        }
      } catch (error) {
        setDeleteSuccess(false);
      }
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <SelectInputFieldControlled
            label={t(`${translations.projects}:Building`)}
            required
            id="buildings"
            name="building"
            value={building}
            onChange={handleBuilding}
            options={getOptionNames(buildings)}
          />
        </Col>
        <Col>
          <SelectInputFieldControlled
            label={t(`${translations.projects}:Floor`)}
            required
            id="floor"
            name="floor"
            value={placeInfo.floor}
            onChange={handlePlaceInfo}
            options={floors.map((floor) => {
              return (
                <option key={`floor${floor}`} value={floor}>
                  {floor}
                  {t(`${translations.projects}:Floors Dropdown`)}
                </option>
              );
            })}
          />
        </Col>
        <Col>
          <SelectInputFieldControlled
            label={t(`${translations.projects}:Building Section`)}
            required
            id="buildingSections"
            name="buildingSection"
            value={placeInfo.buildingSection}
            onChange={handlePlaceInfo}
            options={getOptionNames(buildingSections)}
          />
        </Col>
        <Col>
          <SelectInputFieldControlled
            label={t(`${translations.projects}:Building Space`)}
            required
            id="buildingSpaces"
            name="buildingSpace"
            value={placeInfo.buildingSpace}
            onChange={handlePlaceInfo}
            options={getOptionNames(buildingSpaces)}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h5>{t(`${translations.projects}:Add Measurement`)}</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectInputFieldControlled
            label={t(`${translations.projects}:Position`)}
            required
            id="positions"
            name="position"
            value={measurInfo.position}
            onChange={handleMeasurInfo}
            options={getOptionNames(positions)}
          />
        </Col>
        <Col>
          <SelectInputFieldControlled
            label={t(`${translations.projects}:Workgroup`)}
            required
            id="workgroup"
            name="workgroup"
            value={measurInfo.workgroup}
            onChange={handleMeasurInfo}
            options={workGroupsDropdown.map((wg) => {
              return (
                <option key={`wg${wg.id}`} value={wg.id?.toString()}>
                  {wg.name}
                </option>
              );
            })}
          />
        </Col>
        <Col>
          <PrimaryButton
            className="button-top-margin"
            onClick={() => setModalWorkGroup(true)}
            disabled={measurInfo.position === ''}
          >
            {t(`${translations.projects}:Add Workgroup`).toLocaleUpperCase()}
          </PrimaryButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>{t(`${translations.projects}:Dimensions`)}</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberWithUnitInputField
            id="height"
            name="height"
            label={t(`${translations.projects}:Height`)}
            value={measurInfo.height.toString()}
            required
            onChange={(e: any) => handleMeasurInfo(e)}
            unit={t(`${translations.common}:units.mm`)}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            id="width"
            name="width"
            label={t(`${translations.projects}:Width`)}
            value={measurInfo.width.toString()}
            required
            onChange={(e: any) => handleMeasurInfo(e)}
            unit={t(`${translations.common}:units.mm`)}
          />
        </Col>
        <Col>
          <SquareDisabledField value={roundTwoDecimals(squares).toString()} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>{t(`${translations.projects}:Hindrances`)}</h5>
        </Col>
      </Row>
      <HindranceRows
        hindranceTypesList={hindranceList}
        hindranceFormValues={hindrances}
        updateHindrance={updateHindranceRow}
        deleteRow={deleteHindranceRow}
      />
      <Row>
        <Col>
          <PrimaryButton className="" onClick={addHindranceRow}>
            {t(`${translations.projects}:Add Hindrance`).toLocaleUpperCase()}
          </PrimaryButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <PrimaryButton float={RIGHT} onClick={() => savePlaceMeasurement()}>
            {t(`${translations.projects}:Add Measurement`).toLocaleUpperCase()}
          </PrimaryButton>
        </Col>
      </Row>
      <hr />
      <BasicTableNoPagination
        columns={getColumns(
          colTitles,
          getSquares,
          getPositionName,
          getMillimiterUnit
        )}
        data={placeMeasurements}
        keyField="position"
      />
      <Row>
        <Col>
          <PlaceImages files={files} setFiles={setFiles} />
        </Col>
      </Row>
      <Row>
        <Col>
          <PrimaryButton onClick={goPreviousPage}>
            {t(`${translations.common}:Back`).toLocaleUpperCase()}
          </PrimaryButton>
          {placeSavedId !== undefined ? (
            <PrimaryButton className="add-button-margin" onClick={toggleDelete}>
              {t(`${translations.common}:Remove`).toLocaleUpperCase()}
            </PrimaryButton>
          ) : (
            ''
          )}
          <PrimaryButton float={RIGHT} onClick={() => savePlace()}>
            {t(`${translations.common}:Save`).toLocaleUpperCase()}
          </PrimaryButton>
        </Col>
      </Row>
      <ModalCreateWorkGroup
        showing={modalWorkGroup}
        setShowing={setModalWorkGroup}
        positionId={parseInt(measurInfo.position, 10)}
        projectId={projectId}
        updateWorkGroupList={updateWorkGroupList}
      />
      <ModalAccept
        showing={modalSaved}
        setShowing={setModalSaved}
        header=""
        text={modalMessage}
      />
      <ModalConfirm
        showing={modalDelete}
        setShowing={toggleDelete}
        header={t('common:Confirm Delete')}
        text={t(`${translations.projects}:Delete measurement confirmation`)}
        yesButton={t('common:Confirm')}
        noButton={t('common:Cancel')}
        confirm={deletePlace}
      />
      <ModalAccept
        showing={modalDeleteConfirmation}
        setShowing={toggleDeleteConfirmation}
        header=""
        text={
          deleteSuccess
            ? t(`${translations.common}:Delete Success`)
            : t(`${translations.common}:Delete Failure`)
        }
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  hindranceList: state.projects.hindranceTypes,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchHindranceList: () => dispatch(fetchHindranceTypes()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMeasurementSection);
