import React from 'react';

import 'style/layout/form/InputLabel.css';

const InputLabel = ({
  children,
  htmlFor,
}: {
  children: JSX.Element | string | (JSX.Element | string)[];
  htmlFor?: string;
}): JSX.Element => (
  <div className="label-vertical-align-bottom-wrapper">
    <label className="label" htmlFor={htmlFor}>
      {children}
    </label>
  </div>
);

export default InputLabel;
