import getContributionMarginPerUnit from './getContributionMarginPerUnit';
import getSellingPricePerUnit from './getSellingPricePerUnit';
import IInstallation from './IInstallation';
import ISubproduct from './ISubproduct';
import IDetail from './IDetail';

export default (
  installations: IInstallation[],
  subproducts: ISubproduct[] | IDetail[]
): number => {
  const contributionMargin = getContributionMarginPerUnit(
    installations,
    subproducts
  );

  const sellingPrice = getSellingPricePerUnit(installations, subproducts);

  if (sellingPrice === 0) {
    return 0;
  }

  return (contributionMargin / sellingPrice) * 100;
};
