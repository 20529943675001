import ISettingsGet from 'api/interfaces/common/ISettingsGet';
import FETCH_SETTINGS from './types';
import ISettingsState from '../../interfaces/store/ISettingsState';

const initialState: ISettingsState = {
  settings: null,
};

export default (
  state = initialState,
  action: {
    item: string;
    settings: ISettingsGet;
    type: string;
  }
): ISettingsState => {
  if (action.type === FETCH_SETTINGS.SUCCESS) {
    return {
      ...state,
      settings: action.settings,
    };
  }
  return state;
};
