import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IProjectGet from 'api/interfaces/projects/IProjectGet';
import { Row, Col, FormGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import BasicTable from 'components/layout/tables/BasicTable';
import PageContainer from 'components/layout/PageContainer';
import SearchInput from 'components/layout/forms/SearchInput';
import SearchDropdown from 'components/layout/forms/SearchDropdown';
import getCustomerDropdown from 'services/offers/getCustomerDropdown';
import formatFinnishDate from 'services/dates/formatFinnishDate';
import apiProjects from '../../api/projects';
import customerApi from '../../api/contacts';
import translations from '../../services/translations/translations';
import { BASIC_INFORMATION } from './tabTypes';

import 'style/projects/Projects.css';

const Projects = (): JSX.Element | null => {
  const history = useHistory();
  const { t } = useTranslation([translations.projects, translations.common]);
  const [projects, setProjects] = useState<IProjectGet[]>();
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [offset, setOffset] = useState(0);
  const [totalSize, setTotalSize] = useState<number>();
  const [selectedRow, setSelectedRow] = useState<number>();
  const selectedProject =
    projects !== undefined && selectedRow !== undefined
      ? projects[selectedRow]
      : undefined;
  const [searchTerms, setSearchTerms] = useState<string>('');
  const [customer, setCustomer] = useState<{
    label: string;
    value: string;
  }>();

  useEffect(() => {
    if (selectedRow !== undefined) {
      history.push(`/projects/${selectedProject?.id}/${BASIC_INFORMATION}`);
    }
  }, [history, selectedProject, selectedRow]);

  useEffect(() => {
    const getProjects = async () => {
      try {
        const list = await apiProjects.get(
          sizePerPage,
          offset,
          searchTerms,
          customer ? customer.value : ''
        );
        const count = await apiProjects.count(
          searchTerms,
          customer ? customer.value : ''
        );
        setProjects(list);
        setTotalSize(count.count);
      } catch (error) {
        // define action
      }
    };
    getProjects().catch((e) => console.log(e));
  }, [offset, searchTerms, sizePerPage, customer]);

  const colTitles = {
    name: t(`${translations.projects}:Project`),
    customer: t(`${translations.projects}:Customer`),
    start: t(`${translations.projects}:Start date`),
    end: t(`${translations.projects}:End date`),
  };

  const formatDate = (cell: string, row: any) => {
    return formatFinnishDate(cell);
  };

  const columns = [
    {
      dataField: 'name',
      text: colTitles.name,
    },
    {
      dataField: 'customerContacts[0].contact.companyName',
      text: colTitles.customer,
    },
    {
      dataField: 'start',
      text: colTitles.start,
      formatter: formatDate,
    },
    {
      dataField: 'end',
      text: colTitles.end,
      formatter: formatDate,
    },
  ];

  const handleTableChange = (type: any, { page, sizePerPage }: any) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    setOffset((page - 1) * sizePerPage);
  };

  const handleCustomer = (e: any) => {
    if (e === null) {
      setCustomer({ label: '', value: '' });
    } else {
      setCustomer({ label: e.label, value: e.value });
    }
  };

  const searchCustomer = async (inputValue: string) =>
    getCustomerDropdown(
      await customerApi.get({
        limit: 100,
        offset: 0,
        ownOrganizationUnit: true,
        search: inputValue,
        type: 'customer',
      })
    );

  return (
    <PageContainer>
      <Row className="row-content">
        <Col xs={9}>
          <p className="header-text">{t(`${translations.common}:Projects`)}</p>
        </Col>
      </Row>
      <div className="basic-table table-background-offers">
        <Row>
          <Col xs={4}>
            <SearchInput setSearchTerms={setSearchTerms} />
          </Col>
          <Col xs={4}>
            <FormGroup>
              <SearchDropdown
                value={customer}
                required
                placeholder={t(`${translations.projects}:Customer`)}
                className="text-field search-field"
                name="customer"
                onChange={handleCustomer}
                loadOptions={searchCustomer}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <BasicTable
              columns={columns}
              data={projects}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              handleTableChange={handleTableChange}
              setSelectedRow={setSelectedRow}
              keyField="id"
            />
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
};

export default Projects;
