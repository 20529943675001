import React from 'react';
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import translations from 'services/translations/translations';
import AddIcon from '../../../assets/add.svg';
import '../../../style/layout/BasicTabs.css';

export interface tabInfo {
  tabIcon: any;
  tabLabel: string;
  tabId: string;
}

const BasicTabBarPlus = ({
  tabs,
  selectedTab,
  setSelectedTab,
  addTab,
  availableTabs,
}: {
  tabs: tabInfo[];
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
  addTab: (tab: string) => void;
  availableTabs: string[];
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common, translations.projects]);

  const getAvailableTabs = () => {
    return availableTabs.map((x) => (
      <DropdownItem key={x} onClick={() => addTab(x)}>
        {t(`${translations.projects}:tabs:${x}`).toUpperCase()}
      </DropdownItem>
    ));
  };

  return (
    <>
      <div className="tab-bar">
        {tabs.map((tab) => (
          <div
            className={
              selectedTab === tab.tabId
                ? 'tab-selector tab-selected'
                : 'tab-selector'
            }
            onClick={() => setSelectedTab(tab.tabId)}
            aria-hidden
            key={tab.tabId}
          >
            <img className="tab-icon" src={tab.tabIcon} alt="" />
            <p className="tab-text">{tab.tabLabel}</p>
          </div>
        ))}
        <UncontrolledDropdown>
          <DropdownToggle className="tab-selector-add">
            <div>
              <img className="tab-icon" src={AddIcon} alt="" />
              <p className="tab-text tab-text-add">
                {t(`${translations.common}:Add`)}
              </p>
            </div>
          </DropdownToggle>
          <DropdownMenu aria-hidden className="primary-dropdown-menu">
            {getAvailableTabs()}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </>
  );
};

export default BasicTabBarPlus;
