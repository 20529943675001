import React from 'react';
import { Row, Col, Collapse, Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import ContentSizedTextarea from 'components/layout/forms/ContentSizedTextarea';
import maximize from 'assets/maximize.svg';
import minimize from 'assets/minimize.svg';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import SearchDropdown from 'components/layout/forms/SearchDropdown';
import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';
import UpdatedField from 'components/layout/forms/UpdatedField';
import UserSelect from 'components/layout/forms/UserSelect';
import { APPROVED } from 'services/enums/offers/Statuses';

import Status from './fields/Status';

import 'style/layout/Icons/CollapseIcon.css';
import 'style/offers/src/offer/AdditionalOfferBasicInfo.css';

const AdditionalOfferBasicInfo = ({
  formState,
  handleFormState,
  handleOfferId,
  initialStatus,
  offerId,
  offerIsOpen,
  saveOffer,
  searchOfferId,
  toggleOffer,
  signatureUser,
  handleSignatureUser,
}: {
  formState: any;
  handleFormState: any;
  handleOfferId: any;
  initialStatus: null | string;
  offerId: any;
  offerIsOpen: boolean;
  saveOffer: any;
  searchOfferId: any;
  toggleOffer: any;
  signatureUser: any;
  handleSignatureUser: any;
}): JSX.Element | null => {
  const { t } = useTranslation([
    translations.offers,
    translations.common,
    translations.products,
    translations.userManagement,
  ]);

  const offerIsApproved = () => initialStatus === APPROVED;

  return (
    <>
      <div onClick={toggleOffer} className="header-text title-spacing">
        {offerIsOpen ? (
          <img src={minimize} alt="toggle-offer" className="collapse-icon" />
        ) : (
          <img src={maximize} alt="toggle-offer" className="collapse-icon" />
        )}
        {t(`${translations.offers}:Basic Info`).toUpperCase()}
      </div>
      <Collapse isOpen={offerIsOpen}>
        <Form>
          <Row>
            <Col xs={6}>
              <TextInputField
                value={formState.description}
                label={t(`${translations.common}:Name`)}
                required
                id="description"
                name="description"
                onChange={handleFormState}
                disabled={offerIsApproved()}
              />
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Label className="label">
                  {t(`${translations.offers}:types.offer`)}
                </Label>
                <SearchDropdown
                  value={offerId}
                  required
                  placeholder={t(`${translations.common}:Search`)}
                  className="text-field search-field"
                  name="offerId"
                  onChange={handleOfferId}
                  loadOptions={searchOfferId}
                  disabled={offerIsApproved()}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <UserSelect
                disabled={offerIsApproved()}
                label={t(`${translations.offers}:Signature`)}
                onChange={handleSignatureUser}
                ownOrganizationUnit
                value={signatureUser}
              />
            </Col>
            <Col xs={6}>
              <TextInputField
                disabled
                label={t(`${translations.userManagement}:Organization unit`)}
                required
                value={
                  formState.organizationUnit === null
                    ? ''
                    : formState.organizationUnit.name
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ContentSizedTextarea
                disabled={offerIsApproved()}
                label={t(`${translations.calculationWindow}:Offer text`)}
                name="offerText"
                onChange={handleFormState}
                value={formState.offerText}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Status
                formState={formState}
                handleFormState={handleFormState}
                initialStatus={initialStatus}
              />
            </Col>
            <Col xs={4}>
              <UpdatedField
                label={t(`${translations.common}:Created`)}
                updated={formState.created}
                updatedUser={formState.createdUser}
              />
            </Col>
            <Col xs={4}>
              <UpdatedField
                label={t(`${translations.common}:Updated`)}
                updated={formState.updated}
                updatedUser={formState.updatedUser}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {offerIsApproved() ? null : (
                <PrimaryButton
                  className="submit-button"
                  onClick={() => saveOffer()}
                >
                  {t(`${translations.common}:Save`).toLocaleUpperCase()}
                </PrimaryButton>
              )}
            </Col>
          </Row>
        </Form>
      </Collapse>
    </>
  );
};

export default AdditionalOfferBasicInfo;
