import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonLink from 'components/layout/buttons/ButtonLink';
import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import translations from 'services/translations/translations';

import {
  INSTALLATION,
  PRODUCT,
} from 'services/products/productConfigurationItemTypes';

import DefaultItem from './DefaultItem';
import InstallationItem from './InstallationItem';
import ProductItem from './ProductItem';

import 'style/products/configurations/src/item/Item.css';

const Item = ({
  configurationId,
  configurationName,
  item,
  save,
  setItem,
  title,
}: {
  configurationId: null | number;
  configurationName: string;
  item: IProductConfigurationItem;
  save: () => void;
  setItem: (item: IProductConfigurationItem) => void;
  title: string;
}): JSX.Element => {
  const { t } = useTranslation([translations.common]);

  const getItem = () => {
    if (item.type === PRODUCT) {
      return <ProductItem item={item} setItem={setItem} />;
    }

    if (item.type === INSTALLATION) {
      return <InstallationItem item={item} setItem={setItem} />;
    }

    return <DefaultItem item={item} setItem={setItem} />;
  };

  return (
    <div className="product-configuration-item">
      <h1>
        {t(`${translations.productConfigurations}:Product configuration`)}
        {' / '}
        {configurationName}
      </h1>
      <div className="content-box">
        <h3>{title}</h3>
        {getItem()}
        <div className="buttons">
          <ButtonLink to={`/products/configurations/${configurationId}`}>
            {t(`${translations.common}:Back`)}
          </ButtonLink>
          <PrimaryButton onClick={save}>
            {t(`${translations.common}:Save`)}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default Item;
