import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';

import {
  CATEGORY,
  CUSTOM_INSTALLATION,
  CUSTOM_PRODUCT,
  EXPENSE,
  EXPENSE_UNIT,
  INSTALLATION_ID,
  INSTALLATION_PRICE,
  INSTALLATION_PRICE_UNIT,
  IS_CUSTOM_INSTALLATION,
  IS_CUSTOM_SUBPRODUCT,
  NAME,
  PRODUCT_ID,
  QUANTITY,
  QUANTITY_UNIT,
  SELLING_PRICE,
  SELLING_PRICE_UNIT,
  SOCIAL_SECURITY_COST_PERCENTAGE,
  WASTE_PERCENTAGE,
} from 'services/products/productConfigurationAttributeTypes';

import {
  INSTALLATION,
  PRODUCT,
} from 'services/products/productConfigurationItemTypes';

import { OTHER_EXPENSE } from '../installationTypes';

const getAttribute = (item: IProductConfigurationItem, type: string) =>
  item.attributes.find((a) => a.type === type);

const attributeHasValueOrEmptyValue = (
  item: IProductConfigurationItem,
  type: string
): boolean => {
  const attribute = getAttribute(item, type);

  if (attribute === undefined) {
    return false;
  }

  if (attribute.value === null) {
    return false;
  }

  return true;
};

const attributeHasValue = (
  item: IProductConfigurationItem,
  type: string
): boolean => {
  const attribute = getAttribute(item, type);

  if (attribute === undefined) {
    return false;
  }

  if (attribute.value === null) {
    return false;
  }

  if (attribute.value === '') {
    return false;
  }

  return true;
};

const isCustomInstallation = (item: IProductConfigurationItem) =>
  getAttribute(item, IS_CUSTOM_INSTALLATION)?.value !== '0';

const validInstallation = (item: IProductConfigurationItem) =>
  (!isCustomInstallation(item) && attributeHasValue(item, INSTALLATION_ID)) ||
  (isCustomInstallation(item) && attributeHasValue(item, CUSTOM_INSTALLATION));

const validInstallationItem = (item: IProductConfigurationItem): boolean =>
  validInstallation(item) &&
  attributeHasValue(item, CATEGORY) &&
  attributeHasValue(item, INSTALLATION_PRICE) &&
  attributeHasValueOrEmptyValue(item, INSTALLATION_PRICE_UNIT) &&
  attributeHasValue(item, QUANTITY) &&
  attributeHasValue(item, QUANTITY_UNIT) &&
  attributeHasValue(item, SELLING_PRICE) &&
  attributeHasValueOrEmptyValue(item, SELLING_PRICE_UNIT) &&
  attributeHasValue(item, SOCIAL_SECURITY_COST_PERCENTAGE);

const isCustomSubproduct = (item: IProductConfigurationItem) =>
  getAttribute(item, IS_CUSTOM_SUBPRODUCT)?.value === '1';

const validProduct = (item: IProductConfigurationItem) =>
  (!isCustomSubproduct(item) && attributeHasValue(item, PRODUCT_ID)) ||
  (isCustomSubproduct(item) && attributeHasValue(item, CUSTOM_PRODUCT));

const validProductItemDetail = (item: IProductConfigurationItem): boolean =>
  validProduct(item) &&
  attributeHasValue(item, EXPENSE) &&
  attributeHasValueOrEmptyValue(item, EXPENSE_UNIT) &&
  attributeHasValue(item, QUANTITY) &&
  attributeHasValue(item, QUANTITY_UNIT) &&
  attributeHasValue(item, SELLING_PRICE) &&
  attributeHasValueOrEmptyValue(item, SELLING_PRICE_UNIT) &&
  attributeHasValue(item, WASTE_PERCENTAGE);

const validProductItem = (item: IProductConfigurationItem): boolean => {
  const validDetails = item.children
    .map((child) => validProductItemDetail(child))
    .reduce((allValid, validDetail) => allValid && validDetail, true);

  return (
    validDetails &&
    validProduct(item) &&
    attributeHasValue(item, CATEGORY) &&
    attributeHasValue(item, EXPENSE) &&
    attributeHasValueOrEmptyValue(item, EXPENSE_UNIT) &&
    attributeHasValue(item, QUANTITY) &&
    attributeHasValue(item, QUANTITY_UNIT) &&
    attributeHasValue(item, SELLING_PRICE) &&
    attributeHasValueOrEmptyValue(item, SELLING_PRICE_UNIT) &&
    attributeHasValue(item, WASTE_PERCENTAGE)
  );
};

const validDefaultItem = (item: IProductConfigurationItem): boolean =>
  attributeHasValue(item, NAME);

const validItem = (
  item: IProductConfigurationItem | null,
  type: null | string
): boolean => {
  if (item === null || type === null) {
    return false;
  }

  if (type === INSTALLATION || type === OTHER_EXPENSE) {
    return validInstallationItem(item);
  }

  if (type === PRODUCT) {
    return validProductItem(item);
  }

  return validDefaultItem(item);
};

export default validItem;
