import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import IProductConfiguration from 'interfaces/products/IProductConfiguration';
import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import IProductConfigurationProduct from 'interfaces/products/IProductConfigurationProduct';
import isOtherExpenseItem from 'services/products/configurations/isOtherExpenseItem';
import numericSortByObjectProperty from 'services/sort/numericSortByObjectProperty';
import translations from 'services/translations/translations';
import { fetchInstallationCategories } from 'store/products/actions';
import { finishLoading, startLoading } from 'store/loading/actions';

import {
  INSTALLATION,
  PRODUCT,
} from 'services/products/productConfigurationItemTypes';

import BasicInformation from './src/basicInformation/BasicInformation';
import IConfigurationBasicInformation from './src/IConfigurationBasicInformation';
import Items from './src/items/Items';
import LinksToProducts from './src/linksToProducts/LinksToProducts';
import PrimaryButton from '../../layout/buttons/PrimaryButton';
import ModalEditConfiguration from './ModalEditConfiguration';
import hasPermissionToConfiguration from '../../userManagement/hasPermission';

import { OTHER_EXPENSE } from './src/installationTypes';

import 'style/products/configurations/EditConfiguration.css';
import ModalConfirm from '../../layout/modals/ModalConfirm';
import ContentSizedTextarea from '../../layout/forms/ContentSizedTextarea';

const EditConfiguration = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation([
    translations.common,
    translations.products,
    translations.offers,
    translations.calculationWindow,
  ]);

  const configurationId = parseInt(id, 10);

  const [
    configurationBasicInformation,
    setConfigurationBasicInformation,
  ] = useState<IConfigurationBasicInformation>({
    created: null,
    createdUser: null,
    name: '',
    organizationUnit: null,
    updated: null,
    updatedUser: null,
  });

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [items, setItems] = useState<IProductConfigurationItem[]>([]);
  const [products, setProducts] = useState<IProductConfigurationProduct[]>([]);
  const [offerTextChanged, setOfferTextChanged] = useState<boolean>(false);

  const [modalItemDelete, setModalItemDelete] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<any>();
  const toggleItemDelete = () => setModalItemDelete(!modalItemDelete);

  const [modalConfDelete, setModalConfDelete] = useState(false);
  const toggleConfDelete = () => setModalConfDelete(!modalConfDelete);

  const [currentConfiguration, setCurrentConfiguration] = useState<
    IProductConfiguration
  >();
  const installationCategories = useSelector(
    (state: any) => state.products.installationCategories
  );

  const initializeConfiguration = async () => {
    dispatch(startLoading('product-configuration-products'));
    const configuration: IProductConfiguration = await apiProducts.configurations.get(
      configurationId
    );

    setItemIdToDelete(null);

    setCurrentConfiguration(configuration);
    setItemIdToDelete(null);
    setModalConfDelete(false);
    setModalItemDelete(false);

    dispatch(fetchInstallationCategories());
    dispatch(finishLoading('product-configuration-products'));

    setConfigurationBasicInformation({
      created: configuration.created,
      createdUser: configuration.createdUser,
      name: configuration.name,
      organizationUnit: configuration.organizationUnit,
      updated: configuration.updated,
      updatedUser: configuration.updatedUser,
    });

    setProducts(configuration.products);

    setItems(
      [...configuration.items].sort(numericSortByObjectProperty('sortIndex'))
    );
  };

  useEffect(() => {
    initializeConfiguration();
  }, [id]);

  const installationItems = items.filter(
    (item) =>
      item.type === INSTALLATION &&
      !isOtherExpenseItem(item, installationCategories)
  );

  const otherExpenseItems = items.filter(
    (item) =>
      item.type === INSTALLATION &&
      isOtherExpenseItem(item, installationCategories)
  );

  const productItems = items.filter((item) => item.type !== INSTALLATION);

  const renameConfiguration = () => {
    setShowEditModal(!showEditModal);
  };

  const deleteConfiguration = async () => {
    await apiProducts.configurations.delete(configurationId);
    history.push('/products/configurations');
  };

  const updateConfigurationName = async (newName: string) => {
    await apiProducts.configurations.patch(configurationId, newName);

    setConfigurationBasicInformation({
      ...configurationBasicInformation,
      name: newName,
    });
    setShowEditModal(false);
    initializeConfiguration();
  };

  const onConfigurationItemDelete = (itemId: number) => {
    setItemIdToDelete(itemId);
    toggleItemDelete();
  };

  const deleteItem = async () => {
    await apiProducts.configurations.items.delete(itemIdToDelete);
    initializeConfiguration();
  };

  const copyConfiguration = async () => {
    const result = await apiProducts.configurations.copy(configurationId);
    history.push(`/products/configurations/${result.id}`);
  };

  const handleOfferTextChange = (event: any) => {
    if (currentConfiguration != null) {
      setOfferTextChanged(true);
      setCurrentConfiguration({
        ...currentConfiguration,
        offerText: event.target.value,
      });
    }
  };

  const updateOfferText = async () => {
    if (currentConfiguration != null) {
      await apiProducts.configurations.offerText.patch(
        configurationId,
        currentConfiguration.offerText
      );
      setOfferTextChanged(false);
    }
  };

  return (
    <div className="product-configuration-new">
      <h1>
        {t(`${translations.productConfigurations}:Product configuration`)}
        {' / '}
        {configurationBasicInformation.name}
        <PrimaryButton float="right" onClick={renameConfiguration}>
          {t(`${translations.common}:Edit`)}
        </PrimaryButton>
        <PrimaryButton float="right" onClick={copyConfiguration}>
          {t(`${translations.products}:Create new copy`)}
        </PrimaryButton>
        {hasPermissionToConfiguration(
          currentConfiguration,
          'delete_product_configurations'
        ) ? (
          <PrimaryButton float="right" onClick={() => setModalConfDelete(true)}>
            {t(`${translations.common}:Delete`)}
          </PrimaryButton>
        ) : null}
      </h1>
      <div className="content-box">
        <BasicInformation
          configurationBasicInformation={configurationBasicInformation}
        />
        <LinksToProducts products={products} setProducts={setProducts} />
      </div>
      <Items
        items={productItems}
        type={PRODUCT}
        onDelete={onConfigurationItemDelete}
      />
      <Items
        items={installationItems}
        type={INSTALLATION}
        onDelete={onConfigurationItemDelete}
      />
      <Items
        items={otherExpenseItems}
        type={OTHER_EXPENSE}
        onDelete={onConfigurationItemDelete}
      />
      <ModalEditConfiguration
        showing={showEditModal}
        setShowing={setShowEditModal}
        updateElement={updateConfigurationName}
        configuration={configurationBasicInformation}
      />
      <ModalConfirm
        showing={modalItemDelete}
        setShowing={toggleItemDelete}
        header={t('common:Confirm Delete')}
        text={t(`${translations.products}:Delete item confirmation`)}
        yesButton={t('common:Confirm')}
        noButton={t('common:Cancel')}
        confirm={deleteItem}
      />
      <ModalConfirm
        showing={modalConfDelete}
        setShowing={toggleConfDelete}
        header={t('common:Confirm Delete')}
        text={t(
          `${translations.products}:Delete product configuration confirmation`
        )}
        yesButton={t('common:Confirm')}
        noButton={t('common:Cancel')}
        confirm={deleteConfiguration}
      />

      <ContentSizedTextarea
        label={t(`${translations.calculationWindow}:Offer text`)}
        name="offerText"
        className="edit-configuration-offer-text"
        onChange={handleOfferTextChange}
        value={
          currentConfiguration != null ? currentConfiguration.offerText : ''
        }
      />
      {offerTextChanged ? (
        <PrimaryButton float="right" onClick={updateOfferText}>
          {t(`${translations.common}:Save`)}
        </PrimaryButton>
      ) : null}
    </div>
  );
};

export default EditConfiguration;
