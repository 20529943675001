import React from 'react';
import IProductConfiguration from '../../interfaces/products/IProductConfiguration';
import store from '../../store/store';
import IUserGet from '../../api/interfaces/users/IUserGet';

const hasPermissionToConfiguration = (
  configuration: IProductConfiguration | undefined,
  permissionType: string
): boolean => {
  const { users } = store.getState();

  const currentUser: IUserGet | null = users.current;
  if (currentUser == null || currentUser.permissions == null) {
    return false;
  }

  const currentPermission = currentUser.permissions.find(
    (p) => p.type === permissionType
  );

  if (currentPermission === undefined) {
    return false;
  }

  if (currentPermission.target === '') {
    return true;
  }

  if (configuration == null) {
    return false;
  }

  if (currentPermission.target === 'own-root-organization-unit') {
    return (
      configuration.organizationUnit.name === currentUser.organizationUnit.name
    );
  }

  if (currentPermission.target !== '') {
    return configuration.organizationUnit.id === currentPermission.target;
  }

  return false;
};

export default hasPermissionToConfiguration;
