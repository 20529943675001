import React, { useState } from 'react';
import AddMeasurementSection from './addMeasurement/AddMeasurementSection';
import ProjectMeasurementBasicInfo from './basicInfo/ProjectMeasurementBasicInfo';
import ProjectMeasurementList from './measurementList/ProjectMeasurementList';
import {
  PROJECT_MEASUREMENT_ADD_MEASUREMENT,
  PROJECT_MEASUREMENT_BASIC_INFO,
  PROJECT_MEASUREMENT_LIST,
} from './sectionTypes';

const ProjectMeasurementTab = (): JSX.Element => {
  const [section, setSection] = useState(PROJECT_MEASUREMENT_LIST);
  const [placeId, setPlaceId] = useState<number | undefined>(undefined);

  const showMeasurementSection = () => {
    if (section === PROJECT_MEASUREMENT_LIST) {
      return (
        <ProjectMeasurementList
          setSection={setSection}
          setPlaceId={setPlaceId}
        />
      );
    }
    if (section === PROJECT_MEASUREMENT_BASIC_INFO) {
      return <ProjectMeasurementBasicInfo setSection={setSection} />;
    }
    if (section === PROJECT_MEASUREMENT_ADD_MEASUREMENT) {
      return (
        <AddMeasurementSection
          setSection={setSection}
          setPlaceId={setPlaceId}
          placeId={placeId}
        />
      );
    }
  };

  return <div>{showMeasurementSection()}</div>;
};

export default ProjectMeasurementTab;
