import apiOffers from 'api/offers';
import { finishLoading, startLoading } from 'store/loading/actions';
import store from 'store/store';
import { ADDITIONAL_OFFER } from '../../../services/enums/offers/offerTypes';
import openPdfInNewTab from '../../../services/files/openPdfInNewTab';

const openOfferPdf = async (id: number, offerType: string): Promise<void> => {
  const getResponse = async () =>
    offerType === ADDITIONAL_OFFER
      ? apiOffers.additionalOffers.pdfs.get(id)
      : apiOffers.pdfs.get(id);

  store.dispatch(startLoading('offer-pdf'));
  const response = await getResponse();
  openPdfInNewTab(response.data, response.headers);
  store.dispatch(finishLoading('offer-pdf'));
};
export default openOfferPdf;
