import IPosition from 'interfaces/projects/IPosition';
import IWorkGroupGet from 'interfaces/projects/IWorkGroupGet';
import IWorkGroupPost from 'interfaces/projects/IWorkGroupPost';
import IUserGet from 'api/interfaces/users/IUserGet';

const getUserIds = (users: IUserGet[]): string[] => {
  return users.map((user) => (user.id ? user.id : ''));
};

const getPositionReferences = (
  positionIds: any[],
  positions: IPosition[]
): string[] => {
  const refs: string[] = [];
  for (let index = 0; index < positions.length; index++) {
    if (positionIds.includes(positions[index].id)) {
      refs.push(positions[index].reference);
    }
  }
  return refs;
};

const getWorkGroup = (
  workgroup: IWorkGroupGet,
  positions: IPosition[]
): IWorkGroupPost => ({
  id: workgroup.id,
  name: workgroup.name,
  positions: getPositionReferences(workgroup.positions, positions),
  users: getUserIds(workgroup.users),
});

const getWorkGroups = (
  workgroups: IWorkGroupGet[],
  positions: IPosition[]
): IWorkGroupPost[] =>
  workgroups.map((workgroup) => getWorkGroup(workgroup, positions));

export default getWorkGroups;
