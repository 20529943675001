import numericStringToFloat from 'services/numbers/numericStringToFloat';

import IInstallation from './IInstallation';

export default (installation: IInstallation): number => {
  const sellingPrice =
    typeof installation.sellingPrice === 'number'
      ? installation.sellingPrice
      : numericStringToFloat(installation.sellingPrice);

  if (installation.sellingPriceUnit === null) {
    return sellingPrice;
  }

  if (installation.sellingPriceUnit === '') {
    return sellingPrice;
  }

  const quantity =
    typeof installation.quantity === 'number'
      ? installation.quantity
      : numericStringToFloat(installation.quantity);

  return sellingPrice * quantity;
};
