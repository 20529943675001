import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ExternalLogin = (): null => {
  const history = useHistory();

  const accessToken = useSelector((state: any) => state.users.accessToken);
  const currentUser = useSelector((state: any) => state.users.current);

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const callback = params.get('callback');

  if (callback !== 'glassconfigurator') {
    history.push('/');
    return null;
  }

  if (accessToken !== null && currentUser !== null && currentUser !== '') {
    const form = document.createElement('form');
    form.setAttribute('hidden', 'hidden');
    const redirectUrl = `${currentUser.glassConfigurator}/authenticate`;
    form.setAttribute('action', redirectUrl);
    form.setAttribute('method', 'POST');

    const input = document.createElement('input');
    input.setAttribute('name', 'data');
    input.value = `{"accessToken":"${accessToken}"}`;
    form.appendChild(input);

    const body = document.querySelector('body');
    body?.appendChild(form);

    form.submit();
  }

  return null;
};

export default ExternalLogin;
