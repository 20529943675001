import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Form, FormGroup, Label, Col, Row } from 'reactstrap';
import { useHistory, useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ApiErrorTypes from 'services/enums/ApiErrorTypes';
import ModalAccept from 'components/layout/modals/ModalAccept';
import ModalConfirm from 'components/layout/modals/ModalConfirm';
import PageContainer from 'components/layout/PageContainer';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import MultiSelectDropdown from 'components/layout/forms/MultiSelectDropdown';
import TextInputField from 'components/layout/forms/TextInputField';
import IOrganizationUnit from 'interfaces/users/IOrganizationUnit';
import { fetchOrganizationUnits } from 'store/users/actions';
import { addMessage as addMessageAction } from 'store/messages/actions';
import customerApi from '../../api/contacts';
import translations from '../../services/translations/translations';
import showOrganizationUnits from './showOrganizationUnits';
import capitalizeFirstLetter from '../../services/forms/capitalizeFirstLetter';
import sortResult from '../../services/sort/sortResult';
import '../../style/contacts/Contacts.css';
import '../../style/contacts/CreateContact.css';
import filterAndSortPersons from './filterAndSortPersons';

interface customerType {
  city: string;
  country: string;
  postalNumber: string;
  streetAddress: string;
  businessId: string;
  companyName: string;
  contactTypes: string[];
  emailEnding: string;
  organizationUnitIds: number[];
}

const CreateContact = ({
  addMessage,
  organizationUnitTypesList,
  fetchOrganizationUnitTypesList,
}: {
  addMessage: (message: string) => void;
  organizationUnitTypesList: IOrganizationUnit[];
  fetchOrganizationUnitTypesList: () => void;
}): JSX.Element | null => {
  const { t } = useTranslation([
    translations.contacts,
    translations.common,
    translations.admin,
    translations.products,
  ]);
  const history = useHistory();
  const { contactId } = useParams();
  const [persons, setPersons] = useState<any[]>([]);
  const [contactTypes, setContactTypes] = useState<string[]>([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [modalDeleteConfirmation, setModalDeleteConfirmation] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const toggleDeleteConfirmation = () =>
    setModalDeleteConfirmation(!modalDeleteConfirmation);

  const [customer, setCustomer] = useState<customerType>({
    city: '',
    country: '',
    postalNumber: '',
    streetAddress: '',
    businessId: '',
    companyName: '',
    contactTypes: [],
    emailEnding: '',
    organizationUnitIds: [],
  });

  const [organizationUnits, setOrganizationUnits] = useState<
    { label: string; value: number }[]
  >([]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMultiSelectChange = (e: any) => {
    const { checked: value, name: selected } = e.target;
    const { contactTypes: cTypes } = customer;

    if (value === true) {
      cTypes.push(selected);
    } else {
      const typeIndex = cTypes.findIndex((curr) => curr === selected);
      if (typeIndex >= 0) {
        cTypes.splice(typeIndex, 1);
      }
    }

    setCustomer((prevState) => ({
      ...prevState,
      contactTypes: cTypes,
    }));
  };

  const handleOrganizationUnits = (e: any) => {
    setOrganizationUnits(e);
  };

  const goPreviousPage = () => {
    history.push('/contacts');
  };

  useEffect(() => {
    fetchOrganizationUnitTypesList();
    const getContact = async () => {
      if (contactId !== undefined) {
        try {
          const result: any = await customerApi.getContact(contactId);
          if (result !== undefined && result.removed) {
            goPreviousPage();
          } else if (result !== undefined) {
            setOrganizationUnits(
              showOrganizationUnits(result?.organizationUnits)
            );
            setCustomer({
              city: result.address.city,
              country: result.address.country,
              postalNumber: result.address.postalNumber,
              streetAddress: result.address.streetAddress,
              businessId: result.businessId,
              companyName: result.companyName,
              contactTypes: result.contactTypes,
              emailEnding: result.emailEnding,
              organizationUnitIds: [],
            });

            setPersons(filterAndSortPersons(result.contactPersons));
          }
        } catch (error) {
          // define action
        }
      }
    };
    getContact().catch((e) => console.log(e));
  }, [contactId]);

  const addUpdateContact = async () => {
    const formattedContact = {
      address: {
        city: customer.city,
        country: customer.country,
        postalNumber: customer.postalNumber,
        streetAddress: customer.streetAddress,
      },
      businessId: customer.businessId,
      companyName: customer.companyName,
      contactTypes: customer.contactTypes,
      emailEnding: customer.emailEnding,
      organizationUnitIds: organizationUnits.map((org) => org.value),
    };
    let result;
    try {
      if (contactId === undefined) {
        result = await customerApi.post(formattedContact);
        addMessage(t(`${translations.contacts}:Contact saved`));
        history.push(`/contacts/${result.id}`);
      } else {
        result = await customerApi.putContact(contactId, formattedContact);
        addMessage(t(`${translations.contacts}:Contact saved`));
      }
    } catch (error) {
      const organizationUnitMismatch =
        error.response.status === 400 &&
        error.response.data.message === ApiErrorTypes.OrganizationUnitMismatch;

      if (organizationUnitMismatch) {
        addMessage(t(`${translations.contacts}:Organization unit mismatch`));
      } else {
        addMessage(t(`${translations.contacts}:Contact failed`));
      }
    }
  };

  const deleteContact = async () => {
    try {
      const result = await customerApi.deleteContact(contactId);
      if (result !== undefined && result.success === true) {
        toggleDelete();
        toggleDeleteConfirmation();
        setDeleteSuccess(true);
      } else {
        setDeleteSuccess(false);
      }
    } catch (error) {
      setDeleteSuccess(false);
    }
  };

  const addPerson = () => {
    history.push(`/contacts/${contactId}/create`);
  };

  const typeIncluded = (cType: string) => {
    return customer.contactTypes.findIndex((name) => name === cType) !== -1;
  };

  useEffect(() => {
    const getContactTypes = async () => {
      try {
        const result: string[] = await customerApi.getTypes();
        if (result !== undefined) {
          setContactTypes(sortResult(result, t));
        }
      } catch (error) {
        // define action
      }
    };
    getContactTypes().catch((e) => console.log(e));
  }, []);

  return (
    <PageContainer>
      <h2 className="header-text">{t('Contact Info')}</h2>
      <div className="content-background">
        <Form>
          <Row>
            <Col>
              <TextInputField
                label={t('Company Name')}
                name="companyName"
                onChange={handleChange}
                value={customer.companyName}
              />
              <TextInputField
                label={t('Street Address')}
                name="streetAddress"
                onChange={handleChange}
                value={customer.streetAddress}
              />
              <TextInputField
                label={t('City')}
                name="city"
                onChange={handleChange}
                value={customer.city}
              />
              <TextInputField
                label={t('Country')}
                name="country"
                onChange={handleChange}
                value={customer.country}
              />
              <TextInputField
                label={t('Email Ending')}
                name="emailEnding"
                onChange={handleChange}
                value={customer.emailEnding}
              />
              <FormGroup>
                <Label className="label">
                  {t(`${translations.admin}:Organization Units`)}
                </Label>
                <MultiSelectDropdown
                  placeholder={t(`${translations.products}:Select`)}
                  required
                  name="organizationUnits"
                  value={organizationUnits}
                  onChange={handleOrganizationUnits}
                  options={
                    organizationUnitTypesList
                      ? showOrganizationUnits(organizationUnitTypesList)
                      : ''
                  }
                />
              </FormGroup>
            </Col>
            <Col>
              <TextInputField
                label={t('Business Id')}
                name="businessId"
                onChange={handleChange}
                value={customer.businessId}
              />
              <TextInputField
                label={t('Postal Number')}
                name="postalNumber"
                onChange={handleChange}
                value={customer.postalNumber}
              />

              <FormGroup>
                <Label className="contact-type-label label">
                  {t('Contact Types')}
                </Label>
                <Col sm={{ size: 10 }}>
                  {contactTypes.map((cType: any) => (
                    <FormGroup check key={cType}>
                      <Label className="contact-type-label" check>
                        <Input
                          className="type-checkmark"
                          name={cType}
                          type="checkbox"
                          checked={typeIncluded(cType)}
                          onChange={handleMultiSelectChange}
                        />
                        {t(capitalizeFirstLetter(cType))}
                      </Label>
                    </FormGroup>
                  ))}
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {contactId !== undefined ? (
              <Col>
                <Label className="label block">{t('Persons')}</Label>
                <Button className="contact-button" onClick={addPerson}>
                  {t('Add Person')}
                </Button>
                <Row>
                  {persons.length === 0 ? (
                    <div className="person-box no-touching-box">
                      <h4>{t('No Contacts')}</h4>
                    </div>
                  ) : null}
                  {persons.map((person, index) => {
                    return (
                      <Col>
                        <Link
                          to={`/contacts/persons/${person.id}`}
                          className="person-box"
                        >
                          <h4 className="person-name">
                            {person.firstName} {person.lastName}
                          </h4>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            ) : null}
          </Row>
        </Form>
        <PrimaryButton className="contact-button" onClick={goPreviousPage}>
          {t('common:Back')}
        </PrimaryButton>
        <PrimaryButton
          className="add-button-margin contact-button"
          onClick={addUpdateContact}
        >
          {t('common:Save')}
        </PrimaryButton>
        {contactId !== undefined ? (
          <>
            <PrimaryButton
              className="add-button-margin contact-button"
              onClick={() => toggleDelete()}
            >
              {t('Delete Contact')}
            </PrimaryButton>
          </>
        ) : null}
        <ModalConfirm
          showing={modalDelete}
          setShowing={toggleDelete}
          header={t('common:Confirm Delete')}
          text={t(`${translations.common}:Confirm Delete`)}
          yesButton={t('common:Confirm')}
          noButton={t('common:Cancel')}
          confirm={deleteContact}
        />
        <ModalAccept
          showing={modalDeleteConfirmation}
          setShowing={toggleDeleteConfirmation}
          header=""
          text={
            deleteSuccess
              ? t(`${translations.contacts}:Delete Success`)
              : t(`${translations.contacts}:Delete Failure`)
          }
        />
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state: any) => ({
  organizationUnitTypesList: state.users.organizationUnits,
});

const mapDispatchToProps = (dispatch: any) => ({
  addMessage: (message: string) => dispatch(addMessageAction(message)),
  fetchOrganizationUnitTypesList: () => dispatch(fetchOrganizationUnits()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateContact);
