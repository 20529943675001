import React, { useEffect } from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import translations from 'services/translations/translations';
import CreatableDropdown from 'components/layout/forms/CreatableDropdown';
import TextInputField from 'components/layout/forms/TextInputField';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import { showOptionsFirstBlank } from 'components/offers/OfferSelectOptions';
import { APPROVED } from 'services/enums/offers/Statuses';

import { fetchTaxRateTypes } from 'store/projects/actions';

import IOffer from './IOffer';
import { showPaymentTerms } from './OfferUtils';

const OfferInvoicingInfo = ({
  formState,
  handleFormState,
  initialStatus,
  paymentTerms,
  handlePaymentTerm,
  paymentTerm,
}: {
  formState: IOffer;
  handleFormState: any;
  initialStatus: null | string;
  paymentTerms: any;
  handlePaymentTerm: any;
  paymentTerm: any;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const { t } = useTranslation([
    translations.offers,
    translations.common,
    translations.products,
  ]);

  useEffect(() => {
    dispatch(fetchTaxRateTypes());
  }, []);

  const taxRateTypes = useSelector((state: any) => state.projects.taxRateTypes);

  const offerIsApproved = () => initialStatus === APPROVED;

  return (
    <>
      <Row>
        <Col>
          <h3>{t(`${translations.offers}:Invoicing information`).toUpperCase()}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextInputField
            value={formState.electronicInvoicingAddress}
            label={t(`${translations.offers}:electronicInvoicingAddress`)}
            required
            id="electronicInvoicingAddress"
            name="electronicInvoicingAddress"
            onChange={handleFormState}
            disabled={offerIsApproved()}
          />
        </Col>
        <Col>
          <TextInputField
            value={formState.electronicInvoicingOperator}
            label={t(`${translations.offers}:electronicInvoicingOperator`)}
            required
            id="electronicInvoicingOperator"
            name="electronicInvoicingOperator"
            onChange={handleFormState}
            disabled={offerIsApproved()}
          />
        </Col>
        <Col>
          <TextInputField
            value={formState.electronicInvoicingOperatorId}
            label={t(`${translations.offers}:electronicInvoicingOperatorId`)}
            required
            id="electronicInvoicingOperatorId"
            name="electronicInvoicingOperatorId"
            onChange={handleFormState}
            disabled={offerIsApproved()}
          />
        </Col>
        <Col>
          <TextInputField
            value={formState.invoiceReference}
            label={t(`${translations.offers}:invoiceReference`)}
            required
            id="invoiceReference"
            name="invoiceReference"
            onChange={handleFormState}
            disabled={offerIsApproved()}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectInputFieldControlled
            value={formState.taxRate}
            label={t(`${translations.offers}:Tax Rate`)}
            required
            id="taxRate"
            name="taxRate"
            onChange={handleFormState}
            options={showOptionsFirstBlank(
              'taxRateTypes',
              taxRateTypes,
              translations.offers,
              ':taxRateTypes.',
              t
            )}
            disabled={offerIsApproved()}
          />
        </Col>
        <Col>
          <FormGroup>
            <Label className="label">
              {t(`${translations.offers}:Payment Term`)}
            </Label>
            <CreatableDropdown
              className="text-field search-field"
              placeholder={t(`${translations.offers}:Search or type`)}
              required
              name="paymentTerm"
              value={paymentTerm}
              onChange={handlePaymentTerm}
              options={
                paymentTerms
                  ? showPaymentTerms(paymentTerms, translations, t)
                  : ''
              }
              disabled={offerIsApproved()}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default OfferInvoicingInfo;
