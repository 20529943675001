import numericStringToFloat from 'services/numbers/numericStringToFloat';

import getSubproductDetailUnitExpense from './getSubproductDetailUnitExpense';
import IDetail from './IDetail';

const getSubproductDetailsTotalExpense = (details: IDetail[]): number =>
  details
    .map((detail) => {
      const unitExpense = getSubproductDetailUnitExpense(detail);

      if ([null, ''].includes(detail.expenseUnit)) {
        return unitExpense;
      }

      const quantity =
        typeof detail.quantity === 'number'
          ? detail.quantity
          : numericStringToFloat(detail.quantity);

      return unitExpense * quantity;
    })
    .reduce((sum, detailExpense) => sum + detailExpense, 0);

export default getSubproductDetailsTotalExpense;
