import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import 'style/layout/Table.css';

const BasicTable = ({
  columns,
  data,
  setSelectedRow,
  keyField,
}: {
  columns: any;
  data: any;
  setSelectedRow?: any;
  keyField: any;
}): JSX.Element | null => {
  const rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      if (setSelectedRow !== undefined) {
        setSelectedRow(rowIndex);
      }
    },
  };
  return (
    <div className="basic-table">
      <BootstrapTable
        keyField={keyField}
        headerWrapperClasses="table-header"
        bodyClasses="table-body"
        rowClasses="row-body"
        bordered={false}
        columns={columns}
        data={data || []}
        remote
        rowEvents={rowEvents}
      />
    </div>
  );
};

export default BasicTable;
