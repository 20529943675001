import React, { ChangeEvent, useEffect, useRef } from 'react';
import { FormGroup, Input } from 'reactstrap';

import InputLabel from './InputLabel';

import 'style/layout/form/ContentSizedTextarea.css';

const ContentSizedTextarea = ({
  className,
  disabled,
  id,
  label,
  name,
  onChange,
  value,
}: {
  className?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  name?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}): JSX.Element => {
  const ref = useRef(null);

  const resize = () => {
    const currentRef: any = ref.current;
    if (currentRef === null) {
      return;
    }

    const newValue = `${currentRef.scrollHeight}px`;
    if (newValue === currentRef.style.height) {
      return;
    }

    currentRef.style.height = 'auto';
    currentRef.style.height = newValue;
  };

  useEffect(() => {
    resize();
  });

  const getClassName = () => {
    const classes = ['content-sized-textarea'];

    if (className !== undefined) {
      classes.push(className);
    }

    return classes.join(' ');
  };

  return (
    <FormGroup>
      {label === undefined ? null : <InputLabel>{label}</InputLabel>}
      <Input
        className={getClassName()}
        disabled={disabled}
        id={id}
        innerRef={ref}
        name={name}
        onChange={onChange}
        type="textarea"
        value={value}
      />
    </FormGroup>
  );
};

export default ContentSizedTextarea;
