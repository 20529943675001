import numericStringToFloat from 'services/numbers/numericStringToFloat';

import IDetail from './IDetail';

const getSubproductDetailsTotalSellingPrice = (details: IDetail[]): number =>
  details
    .map((detail) => {
      const quantity =
        typeof detail.quantity === 'number'
          ? detail.quantity
          : numericStringToFloat(detail.quantity);

      const sellingPrice =
        typeof detail.sellingPrice === 'number'
          ? detail.sellingPrice
          : numericStringToFloat(detail.sellingPrice);

      if (detail.sellingPriceUnit === null) {
        return sellingPrice;
      }

      if (detail.sellingPriceUnit === '') {
        return sellingPrice;
      }

      return sellingPrice * quantity;
    })
    .reduce((sum, detailSellingPrice) => sum + detailSellingPrice, 0);

export default getSubproductDetailsTotalSellingPrice;
