import React from 'react';

import translations from 'services/translations/translations';

const getSellingPriceUnitOptions = (
  quantityUnit: string,
  key: string,
  t: any
): JSX.Element[] => {
  const euroOption = (
    <option key={`subproduct-${key}-selling-price-unit-empty`} value="">
      €
    </option>
  );

  if (quantityUnit === '') {
    return [euroOption];
  }

  const quantityUnitOption = (
    <option
      key={`subproduct-${key}-selling-price-unit-${quantityUnit}`}
      value={quantityUnit}
    >
      €/{t(`${translations.common}:units:${quantityUnit}`)}
    </option>
  );

  return [euroOption, quantityUnitOption];
};

export default getSellingPriceUnitOptions;
