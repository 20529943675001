import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BasicFormContainer from 'components/layout/BasicFormContainer';
import IPermission from 'interfaces/users/IPermission';
import IRole from 'interfaces/users/IRole';
import PageContainer from 'components/layout/PageContainer';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';
import { addConfirm, addMessage } from 'store/messages/actions';

import Permissions from './src/role/Permissions';
import userApi from '../../api/userManagement';

import 'style/userManagement/Role.css';

const Role = (): JSX.Element => {
  const { t } = useTranslation([
    translations.common,
    translations.admin,
    translations.userManagement,
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { roleId } = useParams();
  const [name, setName] = useState<string>('');
  const [permissions, setPermissions] = useState<IPermission[]>([]);

  const initializeRole = async () => {
    const result: IRole = await userApi.getRole(roleId);
    if (result === undefined) {
      return;
    }

    setName(result.name);
    setPermissions(result.permissions);
  };

  useEffect(() => {
    // If not undefined there is a user to modify
    if (roleId !== undefined) {
      initializeRole();
    }
  }, [roleId]);

  const goPreviousPage = () => {
    history.push('/users');
  };

  const addUpdateOrganizationUnit = () => {
    const validRole = () => {
      const validPermissions = permissions
        .map(
          (permission) =>
            permission.type !== undefined && permission.type !== ''
        )
        .reduce((allValid, currentValid) => allValid && currentValid, true);

      return name !== '' && permissions.length > 0 && validPermissions;
    };

    if (!validRole()) {
      dispatch(addMessage(t(`${translations.common}:Fill all fields`)));
      return;
    }

    const formattedRole: IRole = {
      name,
      permissions,
    };

    const submit = async () => {
      let result;
      try {
        if (roleId !== undefined) {
          result = await userApi.putRole(formattedRole, roleId);
          dispatch(addMessage(t(`${translations.userManagement}:Role saved`)));
        } else {
          result = await userApi.postRole(formattedRole);
          dispatch(addMessage(t(`${translations.userManagement}:Role saved`)));
          history.push(`/roles/${result.id}`);
        }

        if (result !== undefined && result.succeeded === true) {
          goPreviousPage();
        }
      } catch (error) {
        dispatch(addMessage(t(`${translations.userManagement}:Role failed`)));
      }
    };

    submit();
  };

  const deleteOrganizationUnit = async () => {
    const result = await userApi.deleteRole(roleId);
    if (result !== undefined && result.success === true) {
      dispatch(addMessage(t(`${translations.common}:Delete Success`)));
      goPreviousPage();
    } else {
      dispatch(addMessage(t(`${translations.common}:Delete Failure`)));
    }
  };

  const onRemove = () => {
    dispatch(
      addConfirm({
        callback: deleteOrganizationUnit,
        message: t(`${translations.userManagement}:Delete role confirmation`),
      })
    );
  };

  return (
    <PageContainer className="role-view">
      <p className="header-text">{t('admin:Roles')}</p>
      <BasicFormContainer>
        <TextInputField
          value={name}
          label={t(`${translations.common}:Name`)}
          required
          id="name"
          name="name"
          onChange={(e: any) => setName(e.target.value)}
        />
        <Permissions
          permissions={permissions}
          setPermissions={setPermissions}
        />
        <Row>
          <Col>
            <PrimaryButton onClick={goPreviousPage}>
              {t(`${translations.common}:Back`)}
            </PrimaryButton>
            {roleId !== undefined ? (
              <PrimaryButton className="add-button-margin" onClick={onRemove}>
                {t(`${translations.common}:Remove`)}
              </PrimaryButton>
            ) : (
              ''
            )}
            <PrimaryButton
              className="add-button-margin"
              onClick={addUpdateOrganizationUnit}
            >
              {t(`${translations.common}:Save`)}
            </PrimaryButton>
          </Col>
        </Row>
      </BasicFormContainer>
    </PageContainer>
  );
};

export default Role;
