import api from './api';
import IIdResult from './interfaces/results/IIdResult';

export default {
  post: async (file: any, type: any): Promise<IIdResult> => {
    const formData = new FormData();
    formData.append('data', file);
    if (type != null) {
      formData.append('type', type);
    }
    console.log('TYPE', type);
    return api.postFile('/api/v1/files', formData);
  },
};
