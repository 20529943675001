import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SelectInputTranslated from 'components/layout/forms/SelectInputTranslated';
import translations from 'services/translations/translations';
import { APPROVED } from 'services/enums/offers/Statuses';
import { fetchStatusTypes } from 'store/offers/actions';

import IOffer from '../IOffer';

const Status = ({
  formState,
  handleFormState,
  initialStatus,
}: {
  formState: IOffer;
  handleFormState: any;
  initialStatus: null | string;
}): JSX.Element => {
  const { t } = useTranslation([translations.offers]);

  const dispatch = useDispatch();
  const statusTypes = useSelector((state: any) => state.offers.statusTypes);

  useEffect(() => {
    dispatch(fetchStatusTypes());
  }, []);

  const offerIsApproved = initialStatus === APPROVED;

  return (
    <SelectInputTranslated
      disabled={offerIsApproved}
      key="status"
      label={t(`${translations.offers}:Status`)}
      name="status"
      onChange={handleFormState}
      options={statusTypes}
      required
      translationPath={`${translations.offers}:statuses`}
      value={formState.status}
    />
  );
};

export default Status;
