import numericStringToFloat from 'services/numbers/numericStringToFloat';
import IInstallation from './IInstallation';

const installationsUnitInstallationPrice = (
  installations: IInstallation[]
): number =>
  installations
    .map((installation) => {
      const unitInstallationPrice =
        typeof installation.installationPrice === 'number'
          ? installation.installationPrice
          : numericStringToFloat(installation.installationPrice);

      if ([null, ''].includes(installation.installationPriceUnit)) {
        return unitInstallationPrice;
      }

      const quantity =
        typeof installation.quantity === 'number'
          ? installation.quantity
          : numericStringToFloat(installation.quantity);

      return unitInstallationPrice * quantity;
    })
    .reduce((installationPrice, sum) => installationPrice + sum, 0);

export default installationsUnitInstallationPrice;
