import IMeasurement from 'interfaces/products/IMeasurement';
import replaceCommas from 'services/numbers/replaceCommas';

const formatMeasurementToNumber = (formValues: IMeasurement[]) =>
  formValues.map((x: IMeasurement) => {
    const newValue: IMeasurement = {
      value: replaceCommas(x.value.toString()),
      siUnit: x.siUnit,
      type: x.type,
    };
    return newValue;
  });

export default formatMeasurementToNumber;
