import React, { ChangeEvent, CSSProperties } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

export interface ISelectInputOption {
  displayValue: string;
  style?: CSSProperties;
  value: string;
}

const SelectInput = ({
  id,
  label,
  name,
  onChange,
  options,
  key,
  required,
  value,
}: {
  id?: string;
  key: string;
  label?: string;
  name?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  options: ISelectInputOption[];
  required?: boolean;
  value: string;
}): JSX.Element => (
  <FormGroup>
    {label === undefined ? null : (
      <Label className="label" for={id}>
        {label}
      </Label>
    )}
    <Input
      className="text-field"
      id={id}
      name={name}
      required={required}
      onChange={onChange}
      type="select"
      value={value}
    >
      {options.map((option) => (
        <option
          key={`${key}-${option.value}`}
          style={option.style}
          value={option.value}
        >
          {option.displayValue}
        </option>
      ))}
    </Input>
  </FormGroup>
);

export default SelectInput;
