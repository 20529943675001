import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentSizedTextarea from 'components/layout/forms/ContentSizedTextarea';
import { APPROVED } from 'services/enums/offers/Statuses';

import translations from 'services/translations/translations';

const OfferText = ({
  initialStatus,
  offerText,
  onOfferTextChanged,
}: {
  initialStatus: null | string;
  offerText: string;
  onOfferTextChanged: (value: string) => void;
}): JSX.Element => {
  const { t } = useTranslation([translations.offers]);

  const offerIsApproved = () => initialStatus === APPROVED;

  return (
    <ContentSizedTextarea
      disabled={offerIsApproved()}
      id="offerText"
      label={t(`${translations.calculationWindow}:Offer text`)}
      name="text"
      onChange={(e) => onOfferTextChanged(e.target.value)}
      value={offerText === null ? '' : offerText}
    />
  );
};

export default OfferText;
