import { AMOUNT } from 'services/enums/units';

import IInstallation from '../../IInstallation';

const getDefaultInstallation = (
  key: number,
  category: string
): IInstallation => ({
  category,
  customInstallation: '',
  installation: null,
  installationPrice: '',
  installationPriceUnit: AMOUNT,
  isCustomInstallation: false,
  key,
  quantity: '',
  quantityUnit: AMOUNT,
  sellingPrice: '',
  sellingPriceUnit: '',
  socialSecurityCostPercentage: '',
});

export default getDefaultInstallation;
