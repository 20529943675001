import BootstrapTable from 'react-bootstrap-table-next';
import React, { ChangeEvent, useEffect, useRef } from 'react';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import formatFloatForReadOnlyDisplay from 'services/numbers/formatFloatForReadOnlyDisplay';
import numericStringToFloat from 'services/numbers/numericStringToFloat';
import translations from 'services/translations/translations';

import IFormState from './IFormState';
import getPriceSummary from './getPriceSummary';

const VersionsSummaryTable = ({
  formState,
  setFormState,
}: {
  formState: IFormState;
  setFormState: (formState: IFormState) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common, translations.offers]);
  const formStateRef = useRef(formState);

  useEffect(() => {
    formStateRef.current = formState;
  }, [formState]);

  if (formState.unit === '') {
    return null;
  }

  const data = formState.versions.map((version) => {
    const priceSummary = getPriceSummary(
      version.installations,
      version.subproducts,
      numericStringToFloat(formState.quantity)
    );

    const getPrimaryVersionNotice = () =>
      version.isPrimary
        ? ` (${t(`${translations.calculationWindow}:primary`)})`
        : '';

    return {
      contributionMargin: formatFloatForReadOnlyDisplay(
        priceSummary.contributionMargin
      ),
      contributionMarginForUnit: formatFloatForReadOnlyDisplay(
        priceSummary.contributionMarginForUnit
      ),
      contributionMarginRatio: formatFloatForReadOnlyDisplay(
        priceSummary.contributionMarginRatio
      ),
      expenseSum: formatFloatForReadOnlyDisplay(priceSummary.expenseSum),
      expenseSumForUnit: formatFloatForReadOnlyDisplay(priceSummary.expenseSumForUnit),
      id: version.id,
      showInOfferPdf: version.showInOfferPdf,
      sellingPrice: formatFloatForReadOnlyDisplay(priceSummary.sellingPrice),
      sellingPriceForUnit: formatFloatForReadOnlyDisplay(
        priceSummary.sellingPriceForUnit
      ),
      name: version.name + getPrimaryVersionNotice(),
    };
  });

  const unit = t(`${translations.common}:units:${formState.unit}`);
  const percentFormatter = (cell: any) => `${cell}%`;
  const priceFormatter = (cell: any) => `${cell}€`;
  const priceForUnitFormatter = (cell: any) => `${cell}€/${unit}`;

  const onCheckboxClick = (versionId: number) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setFormState({
      ...formStateRef.current,
      versions: formStateRef.current.versions.map((version) =>
        version.id === versionId
          ? {
              ...version,
              showInOfferPdf: e.target.checked,
            }
          : version
      ),
    });
  };

  const pdfFormatter = (id: any): JSX.Element => {
    const version = formState.versions.find((v) => v.id === id);

    if (version === undefined) {
      return <></>;
    }

    return (
      <Input
        className="pdf-checkbox"
        checked={version.showInOfferPdf}
        onChange={onCheckboxClick(id)}
        type="checkbox"
      />
    );
  };

  return (
    <div className="version-summary">
      <hr />
      <h3>{t(`${translations.calculationWindow}:Version summary`)}</h3>
      <BootstrapTable
        bordered={false}
        columns={[
          {
            dataField: 'name',
            text: t(`${translations.common}:Name`),
          },
          {
            dataField: 'expenseSumForUnit',
            formatter: priceForUnitFormatter,
            text: `${t(`${translations.offers}:Expense`)} €/${unit}`,
          },
          {
            dataField: 'sellingPriceForUnit',
            formatter: priceForUnitFormatter,
            text: `${t(`${translations.offers}:Selling price`)} €/${unit}`,
          },
          {
            dataField: 'contributionMarginForUnit',
            formatter: priceForUnitFormatter,
            text: `${t(
              `${translations.offers}:Contribution margin`
            )} €/${unit}`,
          },
          {
            dataField: 'expenseSum',
            formatter: priceFormatter,
            text: `${t(`${translations.offers}:Expense`)} €`,
          },
          {
            dataField: 'sellingPrice',
            formatter: priceFormatter,
            text: `${t(`${translations.offers}:Selling price`)} €`,
          },
          {
            dataField: 'contributionMargin',
            formatter: priceFormatter,
            text: `${t(`${translations.offers}:Contribution margin`)} €`,
          },
          {
            dataField: 'contributionMarginRatio',
            formatter: percentFormatter,
            headerStyle: () => ({ width: '6rem' }),
            text: `${t(`${translations.offers}:Contribution margin`)} %`,
          },
          {
            dataField: 'id',
            formatter: pdfFormatter,
            headerStyle: () => ({ width: '3rem' }),
            text: 'PDF',
          },
        ]}
        data={data}
        keyField="id"
      />
    </div>
  );
};

export default VersionsSummaryTable;
