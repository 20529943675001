const getFileName = (headers: any) => {
  const contentDisposition = headers['content-disposition'];

  let name = contentDisposition.match(
    /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  )[1];

  if (name.length <= 2) {
    return name;
  }

  if (name[0] === '"') {
    name = name.slice(1, name.length);
  }

  if (name[name.length - 1] === '"') {
    name = name.slice(0, name.length - 1);
  }

  return name;
};

export default getFileName;
