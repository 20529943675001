import React, { useEffect } from 'react';
import {
  AuthenticatedTemplate,
  MsalProvider,
  MsalAuthenticationTemplate,
} from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { msalConfigCreator, loginRequest } from './services/users/authConfig';
import App from './App';
import { fetchSettings } from './store/settings/actions';

import { RootState } from './store/store';
import AuthInstanceWrapper from './AuthInstanceWrapper';
import NotAuthorized from './components/layout/NotAuthorized';

/**
 * This component creates the msal instance, of which there may only be one
 * in existence.
 */
const AuthWrapper = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const settings = useSelector((state: RootState) => state.settings.settings);

  useEffect(() => {
    if (!settings) {
      dispatch(fetchSettings());
    }
  }, [dispatch, settings]);

  if (!settings) {
    return null;
  }

  const msalConfig = msalConfigCreator(settings);
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <Router>
      <Switch>
        <Route exact path="/ad-login">
          <NotAuthorized />
        </Route>
      </Switch>
      <Route>
        <MsalProvider instance={msalInstance}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
          >
            <AuthInstanceWrapper>
              <AuthenticatedTemplate>
                <App />
              </AuthenticatedTemplate>
            </AuthInstanceWrapper>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </Route>
    </Router>
  );
};

export default AuthWrapper;
