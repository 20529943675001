import numericStringToFloat from 'services/numbers/numericStringToFloat';

import ISubproduct from './ISubproduct';
import IDetail from './IDetail';

export default (subproduct: ISubproduct | IDetail): number => {
  const quantity =
    typeof subproduct.quantity === 'number'
      ? subproduct.quantity
      : numericStringToFloat(subproduct.quantity);

  const sellingPrice =
    typeof subproduct.sellingPrice === 'number'
      ? subproduct.sellingPrice
      : numericStringToFloat(subproduct.sellingPrice);

  if (subproduct.sellingPriceUnit === null) {
    return sellingPrice;
  }

  if (subproduct.sellingPriceUnit === '') {
    return sellingPrice;
  }

  return sellingPrice * quantity;
};
