import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getFormStateHandler from 'services/forms/getFormStateHandler';
import InputLabel from 'components/layout/forms/InputLabel';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import translations from 'services/translations/translations';
import { APPROVED } from 'services/enums/offers/Statuses';
import { H } from 'services/enums/units';

import getVersionInstallationContract from './getVersionInstallationContract';
import IVersion from '../IVersion';

const InstallationContract = ({
  initialStatus,
  productUnit,
  quantity,
  setVersion,
  version,
}: {
  initialStatus: null | string;
  productUnit: string;
  quantity: number;
  setVersion: (version: IVersion) => void;
  version: IVersion;
}): JSX.Element => {
  const { t } = useTranslation([translations.offers]);

  const handleFormState = getFormStateHandler(version, setVersion);

  const offerIsApproved = () => initialStatus === APPROVED;

  const installationCategories = useSelector(
    (state: any) => state.products.installationCategories
  );

  const installationContract = getVersionInstallationContract(
    version,
    quantity,
    installationCategories
  );

  return (
    <div className="calculation-sum-row">
      <h2>{t(`${translations.calculationWindow}:Installation contract`)}</h2>
      <Row>
        <Col>
          <InputLabel>
            {t(`${translations.calculationWindow}:Contract price`)}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {t(`${translations.calculationWindow}:Installation contract`)} €
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {`${t(
              `${translations.calculationWindow}:Installation contract`
            )} €`}
            /{t(`${translations.common}:units:${productUnit}`)}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {t(
              `${translations.calculationWindow}:average-hourly-earnings-short`
            )}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {t(`${translations.calculationWindow}:Total hours`)}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {t(`${translations.common}:units:${productUnit}`)}/
            {t(`${translations.common}:units:${H}`)}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {`${t(`${translations.offers}:Contribution margin`)} €`}/
            {t(`${translations.common}:units:${H}`)}
          </InputLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="intallations-expense-sum"
            unit="€"
            value={formatFloatForDisplay(
              installationContract.installationsExpenseTotal
            )}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="installation-contract-eur"
            unit="€"
            value={formatFloatForDisplay(
              installationContract.installationsInstallationPriceSum
            )}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="installation-contract-eur-for-unit"
            unit={`€/${t(`${translations.common}:units:${productUnit}`)}`}
            value={formatFloatForDisplay(
              installationContract.installationsUnitInstallationPrice
            )}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled={offerIsApproved()}
            id="average-hourly-earnings"
            name="averageHourlyEarnings"
            onChange={handleFormState}
            unit="€"
            value={version.averageHourlyEarnings}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="total-hours"
            unit={H}
            value={formatFloatForDisplay(installationContract.totalHours)}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="unit-for-h"
            unit={t(`${translations.common}:units:${productUnit}`)}
            value={formatFloatForDisplay(installationContract.unitForH)}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="contribution-margin-eur-for-h"
            unit="€"
            value={formatFloatForDisplay(
              installationContract.contributionMarginEurForH
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default InstallationContract;
