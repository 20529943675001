import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import ITypeReduxAction from 'interfaces/store/ITypeReduxAction';
import '../types';
import {
  FETCH_ABSORPTION_CLASSES,
  FETCH_ATTRIBUTE_TYPES,
  FETCH_CATEGORIES,
  FETCH_CERTIFICATE_TYPES,
  FETCH_CLASSES_OF_FIRE,
  FETCH_DELIVERY_CATEGORY_TYPES,
  FETCH_EDGE_TYPES,
  FETCH_FASTENER_TYPES,
  FETCH_FILE_TYPES,
  FETCH_GROUPS,
  FETCH_INSTALLATION_CATEGORIES,
  FETCH_INSTALLATION_METHOD_TYPES,
  FETCH_INSTALLATION_PRICE_TYPES,
  FETCH_ISO_CLEANROOM_CLASSES,
  FETCH_MATERIAL_SURFACE_TYPES,
  FETCH_MATERIAL_TYPES,
  FETCH_MEASUREMENT_TYPES,
  FETCH_PERFORATION_TYPES,
  FETCH_PRICE_TYPES,
  FETCH_PRODUCT_ID_TYPES,
  FETCH_TAG_TYPES,
  FETCH_TIP_TYPES,
  FETCH_UNITS,
  SET_CONFIGURATION_ITEMS,
} from './types';

export const fetchAbsorptionClasses = (): ITypeReduxAction => ({
  type: FETCH_ABSORPTION_CLASSES.PENDING,
});

export const fetchAttributeTypes = (): ITypeReduxAction => ({
  type: FETCH_ATTRIBUTE_TYPES.PENDING,
});

export const fetchCategories = (): ITypeReduxAction => ({
  type: FETCH_CATEGORIES.PENDING,
});

export const fetchCertificateTypes = (): ITypeReduxAction => ({
  type: FETCH_CERTIFICATE_TYPES.PENDING,
});

export const fetchClassesOfFire = (): ITypeReduxAction => ({
  type: FETCH_CLASSES_OF_FIRE.PENDING,
});

export const fetchDeliveryCategoryTypes = (): ITypeReduxAction => ({
  type: FETCH_DELIVERY_CATEGORY_TYPES.PENDING,
});

export const fetchFastenerTypes = (): ITypeReduxAction => ({
  type: FETCH_FASTENER_TYPES.PENDING,
});

export const fetchEdgeTypes = (): ITypeReduxAction => ({
  type: FETCH_EDGE_TYPES.PENDING,
});

export const fetchFileTypes = (): ITypeReduxAction => ({
  type: FETCH_FILE_TYPES.PENDING,
});

export const fetchGroups = (): ITypeReduxAction => ({
  type: FETCH_GROUPS.PENDING,
});

export const fetchInstallationCategories = (): ITypeReduxAction => ({
  type: FETCH_INSTALLATION_CATEGORIES.PENDING,
});

export const fetchInstallationMethodTypes = (): ITypeReduxAction => ({
  type: FETCH_INSTALLATION_METHOD_TYPES.PENDING,
});

export const fetchInstallationPriceTypes = (): ITypeReduxAction => ({
  type: FETCH_INSTALLATION_PRICE_TYPES.PENDING,
});

export const fetchIsoCleanroomClasses = (): ITypeReduxAction => ({
  type: FETCH_ISO_CLEANROOM_CLASSES.PENDING,
});

export const fetchMaterialSurfaceTypes = (): ITypeReduxAction => ({
  type: FETCH_MATERIAL_SURFACE_TYPES.PENDING,
});

export const fetchMaterialTypes = (): ITypeReduxAction => ({
  type: FETCH_MATERIAL_TYPES.PENDING,
});

export const fetchMeasurementTypes = (): ITypeReduxAction => ({
  type: FETCH_MEASUREMENT_TYPES.PENDING,
});

export const fetchPerforationTypes = (): ITypeReduxAction => ({
  type: FETCH_PERFORATION_TYPES.PENDING,
});

export const fetchPriceTypes = (): ITypeReduxAction => ({
  type: FETCH_PRICE_TYPES.PENDING,
});

export const fetchProductIdTypes = (): ITypeReduxAction => ({
  type: FETCH_PRODUCT_ID_TYPES.PENDING,
});

export const fetchTagTypes = (): ITypeReduxAction => ({
  type: FETCH_TAG_TYPES.PENDING,
});

export const fetchTipTypes = (): ITypeReduxAction => ({
  type: FETCH_TIP_TYPES.PENDING,
});

export const fetchUnits = (): ITypeReduxAction => ({
  type: FETCH_UNITS.PENDING,
});

export const setConfigurationItems = (
  configurationItems: IProductConfigurationItem[]
): any => ({
  type: SET_CONFIGURATION_ITEMS,
  configurationItems,
});
