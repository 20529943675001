import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BasicTableNoPagination from 'components/layout/tables/BasicTableNoPagination';
import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getContributionMarginPerUnit from 'services/products/calculations/getContributionMarginPerUnit';
import getContributionMarginTotal from 'services/products/calculations/getContributionMarginTotal';
import getContributionMarginRatio from 'services/products/calculations/getContributionMarginRatio';
import getSellingPricePerUnit from 'services/products/calculations/getSellingPricePerUnit';
import getSellingPriceTotal from 'services/products/calculations/getSellingPriceTotal';
import IOfferProductsGet from 'api/interfaces/offers/IOfferProductsGet';
import translations from 'services/translations/translations';

const OfferProductsTable = ({
  products,
  type,
}: {
  products: IOfferProductsGet[];
  type: string;
}): JSX.Element | null => {
  const history = useHistory();

  const { t } = useTranslation([
    translations.common,
    translations.offers,
    translations.products,
  ]);

  const getContributionMarginPerUnitValue = (
    offerProduct: IOfferProductsGet
  ) => {
    const primaryVersion = offerProduct.versions.find(
      (version) => version.isPrimary
    );

    if (primaryVersion === undefined) {
      return 0;
    }

    const contributionMarginPerUnit = formatFloatForDisplay(
      getContributionMarginPerUnit(
        primaryVersion.installations,
        primaryVersion.subproducts
      )
    );

    const unit = t(`${translations.common}:units:${offerProduct.unit}`);

    return `${contributionMarginPerUnit}€/${unit}`;
  };

  const getContributionMarginRatioValue = (
    offerProduct: IOfferProductsGet
  ): string => {
    const primaryVersion = offerProduct.versions.find(
      (version) => version.isPrimary
    );

    if (primaryVersion === undefined) {
      return '';
    }

    const contributionMarginRatio = formatFloatForDisplay(
      getContributionMarginRatio(
        primaryVersion.installations,
        primaryVersion.subproducts
      )
    );

    return `${contributionMarginRatio}%`;
  };

  const getContributionMarginValue = (
    offerProduct: IOfferProductsGet
  ): string => {
    const primaryVersion = offerProduct.versions.find(
      (version) => version.isPrimary
    );

    if (primaryVersion === undefined) {
      return '';
    }

    const contributionMargin = formatFloatForDisplay(
      getContributionMarginTotal(
        primaryVersion.installations,
        primaryVersion.subproducts,
        offerProduct.quantity
      )
    );

    return `${contributionMargin}€`;
  };

  const getPriceValue = (offerProduct: IOfferProductsGet) => {
    const primaryVersion = offerProduct.versions.find(
      (version) => version.isPrimary
    );

    if (primaryVersion === undefined) {
      return '';
    }

    const sellingPriceSum = formatFloatForDisplay(
      getSellingPriceTotal(
        primaryVersion.installations,
        primaryVersion.subproducts,
        offerProduct.quantity
      )
    );

    return `${sellingPriceSum}€`;
  };

  const getQuantity = (offerProduct: IOfferProductsGet) => {
    const unit = t(`${translations.common}:units:${offerProduct.unit}`);
    return `${offerProduct.quantity}${unit}`;
  };

  const getPricePerUnitValue = (offerProduct: IOfferProductsGet) => {
    const primaryVersion = offerProduct.versions.find(
      (version) => version.isPrimary
    );

    if (primaryVersion === undefined) {
      return '';
    }

    const sellingPricePerUnit = formatFloatForDisplay(
      getSellingPricePerUnit(
        primaryVersion.installations,
        primaryVersion.subproducts
      )
    );

    const unit = t(`${translations.common}:units:${offerProduct.unit}`);

    return `${sellingPricePerUnit}€/${unit}`;
  };

  const displayedProducts = products.map((offerProduct) => ({
    contributionMargin: getContributionMarginValue(offerProduct),
    contributionMarginPerUnit: getContributionMarginPerUnitValue(offerProduct),
    contributionMarginRatio: getContributionMarginRatioValue(offerProduct),
    id: offerProduct.id,
    name: offerProduct.name,
    position: offerProduct.position,
    pricePerUnit: getPricePerUnitValue(offerProduct),
    priceTotal: getPriceValue(offerProduct),
    quantity: getQuantity(offerProduct),
  }));

  const themeColorTextClass = 'theme-text-color';
  const columns = [
    {
      dataField: 'position',
      text: t(`${translations.products}:Position`),
      headerStyle: () => {
        return { width: '12%' };
      },
    },
    {
      dataField: 'name',
      text: t(`${translations.products}:Product`),
      headerStyle: () => {
        return { width: '35%' };
      },
    },
    {
      dataField: 'quantity',
      text: t(`${translations.common}:Quantity`),
    },
    {
      classes: themeColorTextClass,
      dataField: 'pricePerUnit',
      headerClasses: themeColorTextClass,
      text: t(`${translations.offers}:Price per unit`),
    },
    {
      classes: themeColorTextClass,
      dataField: 'contributionMarginPerUnit',
      headerClasses: themeColorTextClass,
      text: t(`${translations.offers}:Contribution margin per unit €`),
    },
    {
      dataField: 'contributionMarginRatio',
      text: `${t(`${translations.offers}:Contribution margin`)} %`,
    },
    {
      dataField: 'contributionMargin',
      text: `${t(`${translations.offers}:Contribution margin`)} €`,
    },
    {
      dataField: 'priceTotal',
      text: t(`${translations.common}:Total`),
    },
  ];

  const setSelectedRow = (index: number) => {
    history.push(`/offers/products/${displayedProducts[index].id}/${type}`);
  };

  return (
    <BasicTableNoPagination
      columns={columns}
      data={displayedProducts}
      keyField="id"
      setSelectedRow={setSelectedRow}
    />
  );
};

export default OfferProductsTable;
