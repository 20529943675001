import React from 'react';

import maximize from 'assets/maximize.svg';
import minimize from 'assets/minimize.svg';

const CollapseIcon = ({
  className,
  collapsed,
  onClick,
  size,
}: {
  className?: string;
  collapsed: boolean;
  onClick: () => void;
  size?: string;
}): JSX.Element => {
  const getStyle = () => {
    if (size === 'sm') {
      return {
        width: '1.45rem',
      };
    }

    return {};
  };

  return (
    <img
      alt="toggle-offer"
      className={`collapse-icon ${className}`}
      onClick={() => onClick()}
      style={getStyle()}
      src={collapsed ? minimize : maximize}
    />
  );
};

export default CollapseIcon;
