import IProductConfigurationAttribute from 'api/interfaces/products/IProductConfigurationAttribute';
import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';

import {
  EXPENSE_UNIT,
  QUANTITY_UNIT,
  SELLING_PRICE_UNIT,
} from 'services/products/productConfigurationAttributeTypes';

import getAttributeValue from '../getAttributeValue';

const updateProductUnitsToValidValues = (
  item: IProductConfigurationItem,
  updateAttributeValues: (attribute: IProductConfigurationAttribute[]) => void
): void => {
  const currentExpenseUnit = getAttributeValue(item, EXPENSE_UNIT);
  const currentQuantityUnit = getAttributeValue(item, QUANTITY_UNIT);
  const currentSellingPriceUnit = getAttributeValue(item, SELLING_PRICE_UNIT);

  const newExpenseUnit =
    currentExpenseUnit === '' || currentExpenseUnit === currentQuantityUnit
      ? currentExpenseUnit
      : currentQuantityUnit;

  const newSellingPriceUnit =
    currentSellingPriceUnit === '' ||
    currentSellingPriceUnit === currentQuantityUnit
      ? currentSellingPriceUnit
      : currentQuantityUnit;

  if (currentExpenseUnit !== newExpenseUnit) {
    updateAttributeValues([
      {
        type: EXPENSE_UNIT,
        value: newExpenseUnit,
      },
    ]);
  }

  if (currentSellingPriceUnit !== newSellingPriceUnit) {
    updateAttributeValues([
      {
        type: SELLING_PRICE_UNIT,
        value: newSellingPriceUnit,
      },
    ]);
  }
};

export default updateProductUnitsToValidValues;
