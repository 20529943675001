import getSellingPricePerUnit from './getSellingPricePerUnit';
import IInstallation from './IInstallation';
import ISubproduct from './ISubproduct';
import IDetail from './IDetail';

const getSellingPriceTotal = (
  installations: IInstallation[],
  subproducts: ISubproduct[] | IDetail[],
  quantity: number
): number => getSellingPricePerUnit(installations, subproducts) * quantity;

export default getSellingPriceTotal;
