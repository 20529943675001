import IBuilding from 'interfaces/projects/IBuilding';

const getBuilding = (building: IBuilding): IBuilding => ({
  id: building.id,
  name: building.name,
  floors: parseInt(building.floors.toString(), 10),
});

const getBuildings = (buildings: IBuilding[]): IBuilding[] =>
  buildings.map((building) => getBuilding(building));

export default getBuildings;
