import React, { useState } from 'react';
import { Row, Col, Label, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import IBuilding from 'interfaces/projects/IBuilding';
import translations from 'services/translations/translations';
import TrashIcon from '../icons/TrashIcon';
import NumberInputFieldNoLabel from './NumberInputFieldNoLabel';
import TextInputFieldNoLabel from './TextInputFieldNoLabel';
import EditIcon from '../icons/EditIcon';

import 'style/layout/form/CreatableListWithFloor.css';

const CreatableList = ({
  elements,
  title,
  addElement,
  removeElement,
  updateElement,
}: {
  elements: IBuilding[];
  title: string;
  addElement: (buildingName: any, floors: any, e: any) => void;
  removeElement: (e: string) => void;
  updateElement: (e: string) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common]);
  const [floors, setFloors] = useState<number>(0);
  const [buildingName, setBuildingName] = useState<string>('');
  const showElements = () => {
    return elements.map((element) => {
      return (
        <Row key={element.name}>
          <Col xs={3} className="grey-text">
            {element.name}
          </Col>
          <Col xs={1}>{element.floors.toString()}</Col>
          <Col xs={1}>
            <EditIcon
              onClick={() => {
                updateElement(element.name);
              }}
            />
          </Col>
          <Col xs={1}>
            <TrashIcon
              onClick={() => {
                removeElement(element.name);
              }}
            />
          </Col>
        </Row>
      );
    });
  };

  const addFloor = (floorN: any) => {
    setFloors(floorN);
  };

  const submitBuilding = (e: any) => {
    setFloors(0);
    setBuildingName('');
    addElement(buildingName, floors, e);
  };

  return (
    <>
      <Row className="button-margin-top">
        <Col>
          <Label className="label">{title}</Label>
        </Col>
      </Row>
      {showElements()}
      <Row>
        <Col xs={10}>
          <Row className="button-margin-top">
            <Col className="no-padding" xs={12}>
              <form
                id="workGroup"
                className="flex-form"
                onSubmit={(e: any) => submitBuilding(e)}
              >
                <Col xs={3}>
                  <TextInputFieldNoLabel
                    value={buildingName}
                    required
                    id="buildingName"
                    name="buildingName"
                    onChange={(e: any) => setBuildingName(e.target.value)}
                  />
                </Col>
                <Col xs={3}>
                  <NumberInputFieldNoLabel
                    id="floors"
                    name="floors"
                    onChange={(e: any) => addFloor(e.target.value)}
                    value={floors.toString()}
                    required
                  />
                </Col>
                <Col xs={3}>
                  <Button>
                    {t(`${translations.common}:Add`).toUpperCase()}
                  </Button>
                </Col>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CreatableList;
