import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { fi } from 'date-fns/locale';
import { Provider } from 'react-redux';
import { registerLocale } from 'react-datepicker';

import getBaseUrl from 'services/getBaseUrl';

import AuthWrapper from './AuthWrapper';
import store from './store/store';

import './services/translations/translationsConfig';

const rootElement = document.getElementById('root');

registerLocale('fi', fi);

ReactDOM.render(
  <BrowserRouter basename={getBaseUrl()}>
    <Provider store={store}>
      <Suspense fallback={null}>
        <AuthWrapper />
      </Suspense>
    </Provider>
  </BrowserRouter>,
  rootElement
);
