import { AxiosError } from 'axios';

import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  takeEvery,
} from 'redux-saga/effects';

import apiOffers from 'api/offers';

import { finishLoading, startLoading } from '../loading/actions';

import { FETCH_OFFER_TYPES, FETCH_STATUS_TYPES } from './types';

function* fetchOfferTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_OFFER_TYPES.PENDING));
    const offerTypes = yield call(apiOffers.offerTypes.get);
    yield put({ type: FETCH_OFFER_TYPES.SUCCESS, offerTypes });
    yield put(finishLoading(FETCH_OFFER_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({ type: FETCH_OFFER_TYPES.FAILURE, message: axiosError.message });
  }
}

function* fetchStatusTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_STATUS_TYPES.PENDING));
    const statusTypes = yield call(apiOffers.status.get);
    yield put({ type: FETCH_STATUS_TYPES.SUCCESS, statusTypes });
    yield put(finishLoading(FETCH_STATUS_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({
      type: FETCH_STATUS_TYPES.FAILURE,
      message: axiosError.message,
    });
  }
}

function* saga(): IterableIterator<ForkEffect> {
  yield takeEvery(FETCH_OFFER_TYPES.PENDING, fetchOfferTypes);
  yield takeEvery(FETCH_STATUS_TYPES.PENDING, fetchStatusTypes);
}

export default saga;
