const getConfigurationColumns = (t: any) => {
  const colTitles = {
    name: t('Name'),
  };

  return [
    {
      dataField: 'name',
      text: colTitles.name,
    },
  ];
};

export default getConfigurationColumns;
