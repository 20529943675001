import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'style/layout/Table.css';

const BasicTable = ({
  columns,
  data,
  page,
  sizePerPage,
  totalSize,
  handleTableChange,
  setSelectedRow,
  keyField,
}: {
  columns: any;
  data: any;
  page: any;
  sizePerPage: any;
  totalSize: any;
  handleTableChange: any;
  setSelectedRow: any;
  keyField: any;
}): JSX.Element | null => {
  const rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      setSelectedRow(rowIndex);
    },
  };
  const sizePerPageList = [
    {
      text: '50',
      value: 50,
    },
    {
      text: '100',
      value: 100,
    },
    {
      text: '200',
      value: 200,
    },
    {
      text: '250',
      value: 250,
    },
  ];

  return (
    <div className="basic-table">
      <BootstrapTable
        classes="basic-table-width"
        keyField={keyField}
        headerWrapperClasses="table-header"
        bodyClasses="table-body"
        rowClasses="row-body"
        bordered={false}
        columns={columns}
        data={data || []}
        remote
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize,
          sizePerPageList,
        })}
        onTableChange={handleTableChange}
        rowEvents={rowEvents}
      />
    </div>
  );
};

export default BasicTable;
