import getContributionMarginPerUnit from './getContributionMarginPerUnit';
import IInstallation from './IInstallation';
import ISubproduct from './ISubproduct';
import IDetail from './IDetail';

const getContributionMarginTotal = (
  installations: IInstallation[],
  subproducts: ISubproduct[] | IDetail[],
  quantity: number
): number =>
  getContributionMarginPerUnit(installations, subproducts) * quantity;

export default getContributionMarginTotal;
