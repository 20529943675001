import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';

import 'style/layout/form/Datepicker.css';

const DatePicker = ({
  className,
  disabled,
  endDate,
  id,
  label,
  labelClassName,
  locale,
  onChange,
  required,
  selected,
  showWeekNumbers,
  startDate,
}: {
  className?: string;
  disabled?: boolean;
  endDate?: Date | null;
  id?: string;
  label?: string;
  labelClassName?: string;
  locale?: string | Locale;
  onChange(
    date: Date | [Date, Date] | /* for selectsRange */ null,
    event: React.SyntheticEvent<any> | undefined
  ): void;
  required?: boolean;
  selected?: Date | null;
  showWeekNumbers?: boolean;
  startDate?: Date | null;
}): JSX.Element => {
  const getClassName = () => {
    const classes = ['text-field'];

    if (className !== undefined) {
      classes.push(className);
    }

    return classes.join(' ');
  };

  return (
    <FormGroup>
      {label === undefined ? null : (
        <Label className={`${labelClassName} label`} for={id}>
          {label}
        </Label>
      )}
      <div className="date-picker">
        <ReactDatePicker
          className={getClassName()}
          dateFormat="d.M.yyyy"
          disabled={disabled}
          endDate={endDate}
          id={id}
          locale={locale}
          onChange={onChange}
          required={required}
          selected={selected}
          showWeekNumbers={showWeekNumbers}
          startDate={startDate}
        />
      </div>
    </FormGroup>
  );
};

export default DatePicker;
