import IInstallationPriceGet from 'api/interfaces/installationPrices/IInstallationPriceGet';
import IProduct from 'api/interfaces/products/IProductGet';
import IProductConfigurationAttribute from 'interfaces/products/IProductConfigurationAttribute';
import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';

import IApiProductConfigurationAttribute from 'api/interfaces/products/IProductConfigurationAttribute';
import IApiProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';

import formatAttribute from './formatAttribute';

const formatItem = (
  productConfigurationItems: IApiProductConfigurationItem[],
  products: IProduct[],
  installations: IInstallationPriceGet[]
): IProductConfigurationItem[] => {
  const recursiveFormatItems = (
    items: IApiProductConfigurationItem[]
  ): IProductConfigurationItem[] =>
    items.map((item) => {
      const formattedItem = {
        attributes: item.attributes
          .map((attribute: IApiProductConfigurationAttribute) =>
            formatAttribute(attribute, products, installations)
          )
          .filter(
            (
              attribute: IProductConfigurationAttribute | undefined
            ): attribute is Required<IProductConfigurationAttribute> =>
              attribute !== undefined
          ),
        children: recursiveFormatItems(item.children),
        id: item.id === undefined ? 0 : item.id,
        key: `item-${item.id}`,
        sortIndex: item.sortIndex,
        type: item.type,
      };

      return formattedItem;
    });

  return recursiveFormatItems(productConfigurationItems);
};

export default formatItem;
