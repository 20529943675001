import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import CollapseIcon from 'components/layout/icons/CollapseIcon';
import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getContributionMarginPerUnit from 'services/products/calculations/getContributionMarginPerUnit';
import getContributionMarginTotal from 'services/products/calculations/getContributionMarginTotal';
import getContributionMarginRatio from 'services/products/calculations/getContributionMarginRatio';
import getExpensePerUnit from 'services/products/calculations/getExpensePerUnit';
import getExpenseTotal from 'services/products/calculations/getExpenseTotal';
import getFormStateHandler from 'services/forms/getFormStateHandler';
import getSellingPriceTotal from 'services/products/calculations/getSellingPriceTotal';
import IProduct from 'api/interfaces/products/IProductGet';
import IValueWithLabel from 'interfaces/forms/IValueWithLabel';
import NumberWithDropdownInputField from 'components/layout/forms/NumberWithDropdownInputField';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import SearchDropdown from 'components/layout/forms/SearchDropdown';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import TextInputFieldNoLabel from 'components/layout/forms/TextInputFieldNoLabel';
import translations from 'services/translations/translations';
import TrashIcon from 'components/layout/icons/TrashIcon';
import { APPROVED } from 'services/enums/offers/Statuses';
import { fetchCategories, fetchUnits } from 'store/products/actions';

import getProductCategoryOptions from '../../../../services/getProductCategoryOptions';
import getSellingPriceUnitOptions from '../../../../services/getSellingPriceUnitOptions';
import getUnitOptions from '../../../../services/getUnitOptions';
import ISubproduct from '../../ISubproduct';
import SubproductDetails from './SubproductDetails';

const Subproduct = ({
  initialStatus,
  openedSubproduct,
  quantity,
  removeSubproduct,
  setOpenedSubproduct,
  setSubproduct,
  subproduct,
}: {
  initialStatus: null | string;
  openedSubproduct: null | number;
  quantity: number;
  removeSubproduct: (subproduct: ISubproduct) => void;
  setOpenedSubproduct: (key: null | number) => void;
  setSubproduct: (subproduct: ISubproduct) => void;
  subproduct: ISubproduct;
}): JSX.Element => {
  const { t } = useTranslation([translations.common, translations.products]);

  const dispatch = useDispatch();
  const categories = useSelector((state: any) => state.products.categories);
  const units = useSelector((state: any) => state.products.units);

  const collapsed = openedSubproduct === subproduct.key;

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchUnits());
  }, [dispatch]);

  useEffect(() => {
    const expenseUnit =
      subproduct.expenseUnit === '' ||
      subproduct.expenseUnit === subproduct.quantityUnit
        ? subproduct.expenseUnit
        : subproduct.quantityUnit;

    const sellingPriceUnit =
      subproduct.sellingPriceUnit === '' ||
      subproduct.sellingPriceUnit === subproduct.quantityUnit
        ? subproduct.sellingPriceUnit
        : subproduct.quantityUnit;

    const changed =
      subproduct.expenseUnit !== expenseUnit ||
      subproduct.sellingPriceUnit !== sellingPriceUnit;

    if (!changed) {
      return;
    }

    setSubproduct({
      ...subproduct,
      expenseUnit,
      sellingPriceUnit,
    });
  }, [setSubproduct, subproduct]);

  const handleFormState = getFormStateHandler(subproduct, setSubproduct);
  const handleProductChange = (product: IValueWithLabel) => {
    setSubproduct({ ...subproduct, product });
  };

  const contributionMarginForUnit = getContributionMarginPerUnit(
    [],
    [subproduct]
  );

  const contributionMarginTotal = getContributionMarginTotal(
    [],
    [subproduct],
    quantity
  );

  const contributionMarginRatio = getContributionMarginRatio([], [subproduct]);
  const expenseForUnit = getExpensePerUnit([], [subproduct]);
  const expenseTotal = getExpenseTotal([], [subproduct], quantity);
  const sellingPriceTotal = getSellingPriceTotal([], [subproduct], quantity);

  const toggleCollapse = () =>
    setOpenedSubproduct(
      openedSubproduct === subproduct.key ? null : subproduct.key
    );

  const loadProducts = async (inputValue: string) => {
    const list = (await apiProducts.get(100, 0, inputValue)) as IProduct[];
    return list.map((product: IProduct) => ({
      label: [null, ''].includes(product.displayName)
        ? product.name
        : product.displayName,
      value: product.id,
    }));
  };

  const offerIsApproved = () => initialStatus === APPROVED;

  const sellingPriceUnitOptions = getSellingPriceUnitOptions(
    subproduct.quantityUnit,
    subproduct.key.toString(),
    t
  );

  return (
    <>
      <Row className="subproduct-row">
        <Col>
          <SelectInputFieldControlled
            disabled={offerIsApproved()}
            id={`product-subproduct-${subproduct.key}-category`}
            name="category"
            onChange={handleFormState}
            options={getProductCategoryOptions(categories, t)}
            required
            value={subproduct.category}
          />
        </Col>
        <Col>
          {subproduct.isCustomSubproduct ? (
            <TextInputFieldNoLabel
              disabled={offerIsApproved()}
              id={`product-subproduct-${subproduct.key}-product`}
              name="customSubproduct"
              onChange={handleFormState}
              value={
                subproduct.customSubproduct === null
                  ? ''
                  : subproduct.customSubproduct
              }
            />
          ) : (
            <SearchDropdown
              className="text-field search-field"
              disabled={offerIsApproved()}
              id={`product-subproduct-${subproduct.key}-product`}
              loadOptions={loadProducts}
              onChange={handleProductChange}
              name="product"
              placeholder={t(`${translations.common}:Search`)}
              required
              value={subproduct.product}
            />
          )}
        </Col>
        <Col className="icon-column">
          <CollapseIcon
            collapsed={collapsed}
            onClick={() => toggleCollapse()}
            size="sm"
          />
        </Col>
        <Col>
          <NumberWithDropdownInputField
            disabled={offerIsApproved()}
            dropdownName="quantityUnit"
            dropdownOnChange={handleFormState}
            dropdownOptions={getUnitOptions(units, t)}
            dropdownValue={subproduct.quantityUnit}
            id={`product-subproduct-${subproduct.key}-quantity`}
            numberName="quantity"
            numberOnChange={handleFormState}
            numberValue={subproduct.quantity}
            required
          />
        </Col>
        <Col>
          <NumberWithDropdownInputField
            disabled={offerIsApproved()}
            dropdownDisabled
            dropdownName="expenseUnit"
            dropdownOnChange={handleFormState}
            dropdownOptions={sellingPriceUnitOptions}
            dropdownValue={subproduct.expenseUnit}
            id={`product-subproduct-${subproduct.key}-expense`}
            numberDisabled={subproduct.calculatePriceFromDetails}
            numberName="expense"
            numberOnChange={handleFormState}
            numberValue={subproduct.expense}
            required
          />
        </Col>
        <Col className="small-column">
          <NumberWithUnitInputField
            disabled={offerIsApproved()}
            id={`product-subproduct-${subproduct.key}-waste-percentage`}
            name="wastePercentage"
            onChange={handleFormState}
            required
            unit="%"
            value={subproduct.wastePercentage}
          />
        </Col>
        <Col className="small-column">
          <NumberWithUnitInputField
            disabled
            unit="€"
            value={formatFloatForDisplay(expenseForUnit)}
          />
        </Col>
        <Col>
          <NumberWithDropdownInputField
            disabled={offerIsApproved()}
            dropdownDisabled
            dropdownName="sellingPriceUnit"
            dropdownOnChange={handleFormState}
            dropdownOptions={sellingPriceUnitOptions}
            dropdownValue={subproduct.sellingPriceUnit}
            id={`product-subproduct-${subproduct.key}-selling-price`}
            numberName="sellingPrice"
            numberOnChange={handleFormState}
            numberValue={subproduct.sellingPrice}
            required
          />
        </Col>
        <Col className="small-column">
          <NumberWithUnitInputField
            disabled
            unit="€"
            value={formatFloatForDisplay(contributionMarginForUnit)}
          />
        </Col>
        <Col className="small-column">
          <NumberWithUnitInputField
            disabled
            unit="€"
            value={formatFloatForDisplay(expenseTotal)}
          />
        </Col>
        <Col className="small-column">
          <NumberWithUnitInputField
            disabled
            unit="€"
            value={formatFloatForDisplay(sellingPriceTotal)}
          />
        </Col>
        <Col className="small-column">
          <NumberWithUnitInputField
            disabled
            unit="€"
            value={formatFloatForDisplay(contributionMarginTotal)}
          />
        </Col>
        <Col className="small-column">
          <NumberWithUnitInputField
            disabled
            unit="%"
            value={formatFloatForDisplay(contributionMarginRatio)}
          />
        </Col>
        <Col className="icon-column">
          {offerIsApproved() ? null : (
            <TrashIcon onClick={() => removeSubproduct(subproduct)} />
          )}
        </Col>
      </Row>
      <SubproductDetails
        collapsed={collapsed}
        initialStatus={initialStatus}
        subproduct={subproduct}
        setSubproduct={setSubproduct}
        subproductQuantity={quantity}
      />
    </>
  );
};

export default Subproduct;
