import React from 'react';
import { Col, FormGroup, Label, Input } from 'reactstrap';
import IProductId from 'interfaces/products/IProductId';

const showIDFields = (
  idValues: IProductId[],
  addIdValue: any,
  t: any,
  translations: any
) => {
  return idValues.map((x, i) => {
    return (
      <Col md={4} key={x.type}>
        <FormGroup>
          <Label className="label">
            {t(`${translations.products}:${x.type}`)}
          </Label>
          <Input
            className="text-field"
            value={x.value}
            type="text"
            onChange={(e) => addIdValue(e, i)}
          />
        </FormGroup>
      </Col>
    );
  });
};

export default showIDFields;
