import api from './api';

export default {
  delete: async (id: string): Promise<string> =>
    api.delete(`/api/v1/contacts/${id}`),
  get: async (filters: {
    limit?: number;
    offset?: number;
    ownOrganizationUnit?: boolean;
    search?: string;
    type?: string;
  }): Promise<any> => api.get('/api/v1/contacts', filters),
  count: async (search: string, type: string): Promise<any> =>
    api.get(`/api/v1/contacts/count?search=${search}&type=${type}`),
  getContact: async (
    contactId: string,
    queryParams?: { ownOrganizationUnit: boolean }
  ): Promise<any> => api.get(`/api/v1/contacts/${contactId}`, queryParams),
  getPerson: async (personId: string): Promise<any> =>
    api.get(`/api/v1/contacts/persons/${personId}`),
  getPersons: async (filters: {
    limit?: number;
    offset?: number;
    ownOrganizationUnit?: boolean;
  }): Promise<string[]> => api.get('/api/v1/contacts/persons', filters),
  getTypes: async (): Promise<string[]> => api.get('/api/v1/contacts/types'),
  post: async (contact: any): Promise<any> =>
    api.post('/api/v1/contacts', contact),
  postPerson: async (contactId: string, person: any): Promise<any> =>
    api.post(`/api/v1/contacts/${contactId}/persons`, person),
  putContact: async (contactId: string, contact: any): Promise<string> =>
    api.put(`/api/v1/contacts/${contactId}`, contact),
  putPerson: async (personId: string, person: any): Promise<string> =>
    api.put(`/api/v1/contacts/persons/${personId}`, person),
  deleteContact: async (contactId: string): Promise<any> =>
    api.delete(`/api/v1/contacts/${contactId}`),
  deletePerson: async (personId: string): Promise<string> =>
    api.delete(`/api/v1/contacts/persons/${personId}`),
};
