import IOfferPost from 'api/interfaces/offers/IOfferPost';
import IAddress from 'interfaces/offers/IAddress';
import IInvoicingInformation from 'interfaces/offers/IInvoicingInformation';
import IOfficePerson from 'interfaces/offers/IOfficePerson';
import IOfferModify from 'api/interfaces/offers/IOfferGetSingle';
import getISODate from 'services/dates/getISODate';
import replaceCommas from 'services/numbers/replaceCommas';

import IOffer from './IOffer';

export const showPaymentTerms = (
  paymentTerms: any,
  translations: any,
  t: any
) => {
  const paymentTermsMapped: any = [];
  paymentTerms.map((x: any) =>
    paymentTermsMapped.push({
      value: x,
      label: `${t(`${translations.offers}:${x}`)}`,
    })
  );
  return paymentTermsMapped;
};

export const getOfferPost = (
  formState: IOffer,
  customerOffice: any,
  designOffice: any,
  signatureUserId: null | string,
  paymentTerm: any,
  startDate: any,
  endDate: any,
  offerType: any
): IOfferPost => {
  const address: IAddress = {
    city: formState.city,
    country: formState.country,
    postalNumber: formState.zipCode,
    streetAddress: formState.address,
  };

  const invoicingInformation: IInvoicingInformation = {
    electronicInvoicingAddress: formState.electronicInvoicingAddress,
    electronicInvoicingOperator: formState.electronicInvoicingOperator,
    electronicInvoicingOperatorId: formState.electronicInvoicingOperatorId,
    invoiceReference: formState.invoiceReference,
    note: formState.invoiceNote,
    paymentTerm:
      paymentTerm !== undefined ? replaceCommas(paymentTerm.value) : undefined,
    taxRate:
      formState.taxRate !== undefined
        ? replaceCommas(formState.taxRate)
        : undefined,
  };

  let customerContacts: IOfficePerson[] = [];
  if (customerOffice !== undefined && customerOffice.value !== '') {
    customerContacts = [
      {
        contactId: customerOffice
          ? parseInt(customerOffice.value, 10)
          : undefined,
        contactPersonId:
          formState.customer !== ''
            ? parseInt(formState.customer, 10)
            : undefined,
      },
    ];
  }

  let designContacts: IOfficePerson | undefined;
  if (designOffice !== undefined && designOffice.value !== '') {
    designContacts = {
      contactId: designOffice ? parseInt(designOffice.value, 10) : undefined,
      contactPersonId:
        formState.designer !== ''
          ? parseInt(formState.designer, 10)
          : undefined,
    };
  }

  return {
    address,
    attachmentsNote: formState.attachmentsNote,
    buildingType: formState.buildingType === '' ? null : formState.buildingType,
    cargo: formState.cargo,
    collateralAmount:
      formState.collateralAmount !== undefined
        ? replaceCommas(formState.collateralAmount)
        : undefined,
    collateralPercentage:
      formState.collateralPercentage !== undefined
        ? replaceCommas(formState.collateralPercentage)
        : undefined,
    collateralType: formState.collateral === '' ? null : formState.collateral,
    contractType: formState.contractType === '' ? null : formState.contractType,
    customerContacts,
    customerMark: formState.customerMark,
    deliveryTimeNote: formState.deliveryTimeNote,
    designOfficeContacts: designContacts !== undefined ? [designContacts] : [],
    employeeInformationType:
      formState.employeeInformationType === ''
        ? null
        : formState.employeeInformationType,
    invoicingInformation,
    offerText: formState.offerText,
    signatureUserId,
    status: formState.status,
    targetName: formState.target,
    type: offerType,
    validity: formState.validity,
    wayOfReceivingType:
      formState.wayOfReceivingType === '' ? null : formState.wayOfReceivingType,
    workEnds: getISODate(endDate),
    workStarts: getISODate(startDate),
    worksiteKey: formState.worksiteKey,
    workType: formState.jobType === '' ? null : formState.jobType,
  };
};

export const getFormState = (formState: any, result: IOfferModify): IOffer => {
  let designerId = '';
  if (
    result.designOfficeContacts.length &&
    result.designOfficeContacts[0].contactPerson !== undefined &&
    result.designOfficeContacts[0].contactPerson !== null
  ) {
    designerId = result?.designOfficeContacts[0].contactPerson.id.toString();
  }
  return {
    ...formState,
    address: result?.address.streetAddress ? result.address.streetAddress : '',
    attachmentsNote: result?.attachmentsNote ? result.attachmentsNote : '',
    buildingType: result.buildingType ? result.buildingType : '',
    cargo: result?.cargo ? result.cargo : '',
    city: result?.address.city ? result.address.city : '',
    collateral: result?.collateralType ? result.collateralType : '',
    collateralAmount: result?.collateralAmount
      ? result?.collateralAmount.toString()
      : '',
    collateralPercentage: result?.collateralPercentage
      ? result?.collateralPercentage.toString()
      : '',
    contractType: result?.contractType ? result.contractType : '',
    country: result?.address.country ? result.address.country : '',
    created: result?.created,
    createdUser: result?.createdUser,
    customer:
      result.customerContacts.length && result.customerContacts[0].contactPerson
        ? result?.customerContacts[0].contactPerson.id.toString()
        : '',
    customerMark: result?.customerMark ? result.customerMark : '',
    deliveryTimeNote: result?.deliveryTimeNote ? result.deliveryTimeNote : '',
    designer: designerId,
    electronicInvoicingAddress: result?.invoicingInformation
      .electronicInvoicingAddress
      ? result?.invoicingInformation.electronicInvoicingAddress
      : '',
    electronicInvoicingOperator: result?.invoicingInformation
      .electronicInvoicingOperator
      ? result?.invoicingInformation.electronicInvoicingOperator
      : '',
    electronicInvoicingOperatorId: result?.invoicingInformation
      .electronicInvoicingOperatorId
      ? result?.invoicingInformation.electronicInvoicingOperatorId
      : '',
    employeeInformationType: result?.employeeInformationType
      ? result.employeeInformationType
      : '',
    invoiceNote: result?.invoicingInformation.note,
    invoiceReference: result?.invoicingInformation.invoiceReference
      ? result?.invoicingInformation.invoiceReference
      : '',
    jobType: result?.workType ? result.workType : '',
    offerText: result?.offerText ?? '',
    offerType: result?.type ? result.type : '',
    organizationUnit: result?.organizationUnit,
    signatureUser: result?.signatureUser,
    status: result?.status ? result.status : '',
    target: result?.targetName ? result.targetName : '',
    taxRate:
      result?.invoicingInformation.taxRate !== undefined
        ? result?.invoicingInformation.taxRate.toString()
        : '',
    updated: result?.updated,
    updatedUser: result?.updatedUser,
    validity: result?.validity ? result.validity : '',
    wayOfReceivingType: result?.wayOfReceivingType
      ? result.wayOfReceivingType
      : '',
    worksiteKey: result?.worksiteKey ? result.worksiteKey : '',
    zipCode: result?.address.postalNumber ? result.address.postalNumber : '',
  };
};
