const getHeaderStyle = (column: any, colIndex: any) => {
  return { width: '20px' };
};

const getPlaceColumns = (
  colTitles: any,
  getRowHeader: any,
  getTotalSquares: any,
  getCheckbox: any,
  getStatusName: any
) => {
  const columns = [
    {
      dataField: '',
      text: '',
      formatter: getCheckbox,
      classes: 'checkbox-column',
      headerStyle: getHeaderStyle,
    },
    {
      dataField: 'id',
      text: colTitles.id,
      formatter: getRowHeader,
    },
    {
      dataField: 'status',
      text: colTitles.status,
      formatter: getStatusName,
    },
    {
      dataField: 'positionId',
      text: colTitles.position,
      classes: 'fixed-td',
    },
    {
      dataField: 'height',
      text: colTitles.height,
      classes: 'fixed-td',
    },
    {
      dataField: 'width',
      text: colTitles.width,
      classes: 'fixed-td',
    },
    {
      dataField: 'squares',
      text: colTitles.squares,
      formatter: getTotalSquares,
      classes: 'fixed-td',
    },
  ];

  return columns;
};

export default getPlaceColumns;
