import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import { M2 } from 'services/enums/units';

import {
  CATEGORY,
  CUSTOM_PRODUCT,
  EXPENSE,
  EXPENSE_UNIT,
  IS_CUSTOM_SUBPRODUCT,
  PRODUCT_ID,
  QUANTITY,
  QUANTITY_UNIT,
  SELLING_PRICE,
  SELLING_PRICE_UNIT,
  WASTE_PERCENTAGE,
} from 'services/products/productConfigurationAttributeTypes';

const getDefaultProductItem = (type: string): IProductConfigurationItem => ({
  attributes: [
    { type: CATEGORY, value: '' },
    { type: CUSTOM_PRODUCT, value: '' },
    { type: EXPENSE, value: '' },
    { type: EXPENSE_UNIT, value: M2 },
    { type: IS_CUSTOM_SUBPRODUCT, value: '0' },
    { type: PRODUCT_ID, value: '' },
    { type: QUANTITY, value: '' },
    { type: QUANTITY_UNIT, value: M2 },
    { type: SELLING_PRICE, value: '' },
    { type: SELLING_PRICE_UNIT, value: '' },
    { type: WASTE_PERCENTAGE, value: '' },
  ],
  children: [],
  sortIndex: 0,
  type,
});

export default getDefaultProductItem;
