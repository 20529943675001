import React from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/layout/forms/Checkbox';
import translations from 'services/translations/translations';
import { APPROVED } from 'services/enums/offers/Statuses';

import IInstallation from '../../IInstallation';

const InstallationDetails = ({
  collapsed,
  initialStatus,
  installation,
  setInstallation,
}: {
  collapsed: boolean;
  initialStatus: null | string;
  installation: IInstallation;
  setInstallation: (installation: IInstallation) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common, translations.offers]);

  if (!collapsed) {
    return null;
  }

  const handleIsCustomInstallationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInstallation({
      ...installation,
      installation: null,
      isCustomInstallation: event.target.checked,
      sellingPrice: '',
      sellingPriceUnit: '',
    });
  };

  const offerIsApproved = () => initialStatus === APPROVED;

  return (
    <div className="item-details-box">
      <Checkbox
        checked={installation.isCustomInstallation}
        className="item-details-checkbox"
        disabled={offerIsApproved()}
        label={t(`${translations.calculationWindow}:Custom installation`)}
        onChange={handleIsCustomInstallationChange}
      />
    </div>
  );
};

export default InstallationDetails;
