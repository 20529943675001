const getOfferType = (
  typeId: string | undefined,
  type: string | undefined
): string => {
  if (type !== undefined) {
    return type;
  }
  if (typeId === '0') {
    return 'offer';
  }
  if (typeId === '1') {
    return 'preliminary-offer';
  }
  if (typeId === '2') {
    return 'additional-offer';
  }
  if (typeId === '3') {
    return 'material-offer';
  }
  return '';
};

export default getOfferType;
