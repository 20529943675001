import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import sortByTranslation from 'services/sort/sortByTranslation';
import NumberInputField from 'components/layout/forms/NumberInputField';
import TextInputField from 'components/layout/forms/TextInputField';
import { showOptionsFirstBlank } from 'components/offers/OfferSelectOptions';
import IAttribute from 'interfaces/products/IAttribute';
import translations from 'services/translations/translations';
import {
  fetchAbsorptionClasses,
  fetchCertificateTypes,
  fetchClassesOfFire,
  fetchEdgeTypes,
  fetchFastenerTypes,
  fetchInstallationMethodTypes,
  fetchIsoCleanroomClasses,
  fetchMaterialSurfaceTypes,
  fetchMaterialTypes,
  fetchPerforationTypes,
  fetchTipTypes,
} from 'store/products/actions';
import DeleteIcon from 'components/layout/icons/DeleteIcon';
import {
  ABSORPTION_CLASS,
  CERTIFICATE,
  CLASS_OF_FIRE,
  COLOR,
  EDGE,
  FASTENER,
  INSTALLATION_METHOD,
  ISO_CLEANROOM_CLASS,
  LIGHT_REFLECTANCE_PERCENTAGE,
  MATERIAL,
  MATERIAL_CLARIFICATION,
  MATERIAL_SURFACE,
  PERFORATION,
  PERFORATION_PERCENTAGE,
  TIP,
} from './attributeTypes';
import ProductAttributeDropdown from './ProductAttributeDropdown';
import updateAttribute from './updateAttribute';

const ProductAttributeRows = ({
  attributeFormValues,
  attributeTypesList,
  setAttributeFormValues,
}: {
  attributeFormValues: IAttribute[];
  attributeTypesList: string[];
  setAttributeFormValues: (newValues: IAttribute[]) => void;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const { t } = useTranslation([translations.common, translations.products]);

  const absorptionClasses: string[] = useSelector(
    (state: any) => state.products.absorptionClasses
  );

  const certificateTypes: string[] = useSelector(
    (state: any) => state.products.certificateTypes
  );

  const classesOfFire: string[] = useSelector(
    (state: any) => state.products.classesOfFire
  );

  const edgeTypes: string[] = useSelector(
    (state: any) => state.products.edgeTypes
  );

  const fastenerTypes: string[] = useSelector(
    (state: any) => state.products.fastenerTypes
  );

  const installationMethodTypes: string[] = useSelector(
    (state: any) => state.products.installationMethodTypes
  );

  const isoCleanroomClasses: string[] = useSelector(
    (state: any) => state.products.isoCleanroomClasses
  );

  const materialSurfaceTypes: string[] = useSelector(
    (state: any) => state.products.materialSurfaceTypes
  );

  const materialTypes: string[] = useSelector(
    (state: any) => state.products.materialTypes
  );

  const perforationTypes: string[] = useSelector(
    (state: any) => state.products.perforationTypes
  );

  const tipTypes: string[] = useSelector(
    (state: any) => state.products.tipTypes
  );

  useEffect(() => {
    dispatch(fetchAbsorptionClasses());
    dispatch(fetchCertificateTypes());
    dispatch(fetchClassesOfFire());
    dispatch(fetchEdgeTypes());
    dispatch(fetchFastenerTypes());
    dispatch(fetchInstallationMethodTypes());
    dispatch(fetchIsoCleanroomClasses());
    dispatch(fetchMaterialSurfaceTypes());
    dispatch(fetchMaterialTypes());
    dispatch(fetchPerforationTypes());
    dispatch(fetchTipTypes());
  }, [dispatch]);

  const getAttributeOptions = (x: any) => {
    const attributeOptions = [x.type].concat(attributeTypesList);

    return attributeOptions
      .sort(sortByTranslation(t, `${translations.products}:attributeTypes`))
      .map((at) => (
        <option value={at}>
          {t(`${translations.products}:attributeTypes.${at}`)}
        </option>
      ));
  };

  const updateAttributeRow = (e: any, index: any) => {
    setAttributeFormValues(updateAttribute(attributeFormValues, e, index));
  };

  const deleteRow = (index: any, rows: any) => {
    const list = [...rows];
    list.splice(index, 1);
    setAttributeFormValues(list);
  };

  const getTypeValidator = (types: string[]) => (x: any) =>
    x.value === '' || types.find((type) => type === x.value) !== undefined;

  const isAbsorptionClass = getTypeValidator(absorptionClasses);
  const isCertificate = getTypeValidator(certificateTypes);
  const isClassOfFire = getTypeValidator(classesOfFire);
  const isEdge = getTypeValidator(edgeTypes);
  const isFastener = getTypeValidator(fastenerTypes);
  const isInstallationMethod = getTypeValidator(installationMethodTypes);
  const isIsoCleanroomClass = getTypeValidator(isoCleanroomClasses);
  const isMaterial = getTypeValidator(materialTypes);
  const isMaterialSurface = getTypeValidator(materialSurfaceTypes);
  const isPerforation = getTypeValidator(perforationTypes);
  const isTip = getTypeValidator(tipTypes);

  const getValueField = (x: any, i: any): JSX.Element | null => {
    const getDropdown = (
      options: string[],
      type: string,
      translationGroupId: null | string,
      validator: (item: any) => boolean
    ) => (
      <ProductAttributeDropdown
        index={i}
        item={x}
        options={options}
        showOptionsFirstBlank={showOptionsFirstBlank}
        translationGroupId={translationGroupId}
        type={type}
        updateAttributeRow={updateAttributeRow}
        validator={validator}
      />
    );

    const getTextField = (type: string) => (
      <TextInputField
        value={x.value}
        label={i === 0 ? t(`${translations.products}:Value`) : undefined}
        required
        id={type}
        name="value"
        onChange={(e: any) => updateAttributeRow(e, i)}
      />
    );

    const getNumberField = (type: string) => (
      <NumberInputField
        value={x.value.toString()}
        label={i === 0 ? t(`${translations.products}:Value`) : undefined}
        required
        id={type}
        name="value"
        onChange={(e: any) => updateAttributeRow(e, i)}
      />
    );

    if ([COLOR, MATERIAL_CLARIFICATION].includes(x.type)) {
      return getTextField(x.type);
    }

    const numberFields = [LIGHT_REFLECTANCE_PERCENTAGE, PERFORATION_PERCENTAGE];
    if (numberFields.includes(x.type)) {
      return getNumberField(x.type);
    }

    if (x.type === ABSORPTION_CLASS) {
      return getDropdown(
        absorptionClasses,
        ABSORPTION_CLASS,
        'absorptionClasses',
        isAbsorptionClass
      );
    }

    if (x.type === CERTIFICATE) {
      return getDropdown(
        certificateTypes,
        CERTIFICATE,
        'certificateTypes',
        isCertificate
      );
    }

    if (x.type === CLASS_OF_FIRE) {
      return getDropdown(
        classesOfFire,
        CLASS_OF_FIRE,
        'classesOfFire',
        isClassOfFire
      );
    }

    if (x.type === EDGE) {
      return getDropdown(edgeTypes, EDGE, 'edges', isEdge);
    }

    if (x.type === FASTENER) {
      return getDropdown(fastenerTypes, FASTENER, 'fastenerTypes', isFastener);
    }

    if (x.type === INSTALLATION_METHOD) {
      return getDropdown(
        installationMethodTypes,
        INSTALLATION_METHOD,
        'installationMethodTypes',
        isInstallationMethod
      );
    }

    if (x.type === ISO_CLEANROOM_CLASS) {
      return getDropdown(
        isoCleanroomClasses,
        ISO_CLEANROOM_CLASS,
        'isoCleanroomClasses',
        isIsoCleanroomClass
      );
    }

    if (x.type === MATERIAL) {
      return getDropdown(materialTypes, MATERIAL, 'materialTypes', isMaterial);
    }

    if (x.type === MATERIAL_SURFACE) {
      return getDropdown(
        materialSurfaceTypes,
        MATERIAL_SURFACE,
        'materialSurfaceTypes',
        isMaterialSurface
      );
    }

    if (x.type === PERFORATION) {
      return getDropdown(
        perforationTypes,
        PERFORATION,
        'perforationTypes',
        isPerforation
      );
    }

    if (x.type === TIP) {
      return getDropdown(tipTypes, TIP, null, isTip);
    }

    return null;
  };

  return (
    <div>
      {attributeFormValues.map((x, i) => (
        <Row className="product-attribute">
          <Col md={3}>
            <SelectInputFieldControlled
              value={x.type}
              label={
                i === 0 ? t(`${translations.products}:Attribute`) : undefined
              }
              required
              id="attributeType"
              name="attributeType"
              onChange={(e: any) => updateAttributeRow(e, i)}
              options={getAttributeOptions(x)}
            />
          </Col>
          <Col md={3}>{getValueField(x, i)}</Col>
          <Col md={3}>
            <DeleteIcon onClick={() => deleteRow(i, attributeFormValues)} />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default ProductAttributeRows;
