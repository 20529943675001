import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { closeMessage } from 'store/messages/actions';

import ModalAccept from './modals/ModalAccept';

const Message = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const messages = useSelector((state: any) => state.messages.messages);

  if (messages.length === 0) {
    return null;
  }

  const triggerCloseMessage = (showing: boolean) => {
    if (!showing) {
      dispatch(closeMessage());
    }
  };

  return (
    <ModalAccept
      header=""
      showing
      setShowing={triggerCloseMessage}
      text={messages[0]}
    />
  );
};

export default Message;
