const ABSORPTION_CLASS = 'absorption-class';
const CERTIFICATE = 'certificate';
const CLASS_OF_FIRE = 'class-of-fire';
const COLOR = 'color';
const EDGE = 'edge';
const FASTENER = 'fastener';
const INSTALLATION_METHOD = 'installation-method';
const ISO_CLEANROOM_CLASS = 'iso-cleanroom-class';
const LIGHT_REFLECTANCE_PERCENTAGE = 'light-reflectance-percentage';
const MATERIAL = 'material';
const MATERIAL_CLARIFICATION = 'material-clarification';
const MATERIAL_SURFACE = 'material-surface';
const PERFORATION = 'perforation';
const PERFORATION_PERCENTAGE = 'perforation-percentage';
const TIP = 'tip';

export {
  ABSORPTION_CLASS,
  CERTIFICATE,
  CLASS_OF_FIRE,
  COLOR,
  EDGE,
  FASTENER,
  INSTALLATION_METHOD,
  ISO_CLEANROOM_CLASS,
  LIGHT_REFLECTANCE_PERCENTAGE,
  MATERIAL,
  MATERIAL_CLARIFICATION,
  MATERIAL_SURFACE,
  PERFORATION,
  PERFORATION_PERCENTAGE,
  TIP,
};
