import React from 'react';

import 'style/layout/buttons/LinkButton.css';

const LinkButton = ({
  children,
  className,
  onClick,
}: {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  onClick: () => void;
}): JSX.Element => (
  <span
    className={`${className === undefined ? '' : className} link-button`}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    {children}
  </span>
);

export default LinkButton;
