import React from 'react';
import { useSelector } from 'react-redux';

import ICurrentUser from 'api/interfaces/users/ICurrentUser';

import NotAuthorized from './NotAuthorized';

const Permission = ({
  children,
  type,
}: {
  children: JSX.Element;
  type: string;
}): JSX.Element => {
  const currentUser: ICurrentUser = useSelector(
    (state: any) => state.users.current
  );

  const isAuthorized = (section: string): boolean =>
    currentUser !== null && currentUser.views.includes(section);

  if (isAuthorized(type)) {
    return children;
  }

  return <NotAuthorized />;
};

export default Permission;
