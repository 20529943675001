import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CustomersImage from 'assets/DashboardCustomers.svg';
import GlassConfiguratorImage from 'assets/Glass_icon_green.svg';
import ICurrentUser from 'api/interfaces/users/ICurrentUser';
import OffersImage from 'assets/DashboardOffers.svg';
import ProductsImage from 'assets/DashboardProducts.svg';
import ProjectsImage from 'assets/DashboardProjects.svg';
import translations from 'services/translations/translations';

import {
  CONTACTS,
  OFFERS,
  PRODUCTS,
  PRODUCT_CONFIGURATIONS,
  PROJECTS,
} from 'services/types/viewTypes';

import DashboardLink from './layout/buttons/DashboardButton';

import 'style/Dashboard.css';

const Home = (): JSX.Element => {
  const { t } = useTranslation([
    translations.common,
    translations.products,
    translations.dashboard,
  ]);

  const currentUser: ICurrentUser = useSelector(
    (state: any) => state.users.current
  );

  const userHasOneOfThePermissions = (...permissions: string[]) =>
    permissions
      .map((permission) => currentUser.views.includes(permission))
      .reduce((any, current) => any || current, false);

  return (
    <>
      <h2 className="dashboard-header">
        {t('dashboard:Greetings', { name: currentUser.firstName })}
      </h2>
      <div className="centered-container">
        {userHasOneOfThePermissions(PROJECTS) ? (
          <DashboardLink
            id="1"
            image={ProjectsImage}
            link="projects"
            value={t('Projects')}
          />
        ) : null}

        {userHasOneOfThePermissions(OFFERS) ? (
          <DashboardLink
            id="2"
            image={OffersImage}
            link="offers"
            value={t('Offers')}
          />
        ) : null}

        {userHasOneOfThePermissions(CONTACTS) ? (
          <DashboardLink
            id="3"
            image={CustomersImage}
            link="contacts"
            value={t('Contacts')}
          />
        ) : null}

        {userHasOneOfThePermissions(PRODUCT_CONFIGURATIONS, PRODUCTS) ? (
          <DashboardLink
            id="4"
            image={ProductsImage}
            link="products"
            value={t(`${translations.products}:Products`)}
          />
        ) : null}

        <DashboardLink
          external
          id="5"
          image={GlassConfiguratorImage}
          link={currentUser.glassConfigurator}
          value={t(`${translations.common}:Glass configurator`)}
        />
      </div>
    </>
  );
};

export default Home;
