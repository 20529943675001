import React from 'react';

import '../../../style/layout/BasicTabs.css';

export interface tabInfo {
  tabIcon: any;
  tabLabel: string;
  tabId: string;
}

const BasicTabBar = ({
  tabs,
  selectedTab,
  setSelectedTab,
  className,
}: {
  tabs: tabInfo[];
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
  className?: string;
}): JSX.Element | null => {
  return (
    <>
      <div className="tab-bar">
        {tabs.map((tab) => (
          <div
            className={
              selectedTab === tab.tabId
                ? `${className} tab-selector tab-selected`
                : `${className} tab-selector`
            }
            onClick={() => setSelectedTab(tab.tabId)}
            aria-hidden
            key={tab.tabId}
          >
            <img className="tab-icon" src={tab.tabIcon} alt="" />
            <p className="tab-text">{tab.tabLabel}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default BasicTabBar;
