import { M2 } from 'services/enums/units';

import ISubproduct from '../../ISubproduct';

const getDefaultSubproduct = (key: number): ISubproduct => ({
  calculatePriceFromDetails: false,
  category: 'material',
  customSubproduct: null,
  details: [],
  expense: '',
  expenseUnit: M2,
  hideInPfferPdf: false,
  isCustomSubproduct: false,
  key,
  product: null,
  quantity: '',
  quantityUnit: M2,
  sellingPrice: '',
  sellingPriceUnit: '',
  wastePercentage: '',
});

export default getDefaultSubproduct;
