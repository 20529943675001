import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import IUserGet from 'api/interfaces/users/IUserGetSingle';
import IBasicInfoGet from 'api/interfaces/projects/IBasicInfoGet';
import IBasicInfoPost from 'api/interfaces/projects/IBasicInfoPost';
import CreatableList from 'components/layout/forms/CreatableList';
import CreatableListWithFloor from 'components/layout/forms/CreatableListWithFloor';
import CreatableListForPositions from 'components/layout/forms/CreatableListForPositions';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import IPosition from 'interfaces/projects/IPosition';
import IWorkGroup from 'interfaces/projects/IWorkGroupPost';
import IBuilding from 'interfaces/projects/IBuilding';
import IBuildingStructure from 'interfaces/projects/IBuildingStructure';
import translations from 'services/translations/translations';
import { fetchUsers } from 'store/users/actions';
import { RIGHT } from 'services/enums/sides';

import getBuildings from './getBuildings';
import getPositions from './getPositions';
import getWorkGroups from './getWorkGroups';
import MessageModal from './MessageModal';
import ModalEditBuildingStructure from './ModalEditBuildingStructure';
import ModalEditPosition from './ModalEditPosition';
import ModalEditWorkGroup from './ModalEditWorkGroup';
import ModalEditBuilding from './ModalEditBuilding';
import WorkGroups from './WorkGroups';
import apiProjects from '../../../../api/projects';
import { PROJECT_MEASUREMENT_LIST } from '../sectionTypes';

const ProjectMeasurementBasicInfo = ({
  userList,
  fetchUserList,
  setSection,
}: {
  userList: IUserGet[];
  fetchUserList: () => void;
  setSection: (sectionName: string) => void;
}): JSX.Element => {
  const { projectId } = useParams();
  const { t } = useTranslation([translations.projects]);
  const [buildings, setBuildings] = useState<IBuilding[]>([]);
  const [buildingSections, setBuildingSections] = useState<
    IBuildingStructure[]
  >([]);
  const [buildingSpaces, setBuildingSpaces] = useState<IBuildingStructure[]>(
    []
  );
  const [positions, setPositions] = useState<IPosition[]>([]);
  const [wGroups, setWGroups] = useState<IWorkGroup[]>([]);
  const [modalSaved, setModalSaved] = useState(false);
  const [savedInfo, setSavedInfo] = useState(0);
  const [saveError, setSaveError] = useState<null | string>(null);
  const [buildingTypeToEdit, setBuildingTypeToEdit] = useState('');
  const [buildingTypeName, setBuildingTypeName] = useState('');
  const [modalBuildingStructure, setModalBuildingStructure] = useState(false);
  const [modalPosition, setModalPosition] = useState(false);
  const [positionName, setPositionName] = useState('');
  const [modalBuilding, setModalBuilding] = useState(false);
  const [buildingName, setBuildingName] = useState('');
  const [modalWorkGroup, setModalWorkGroup] = useState(false);
  const [workGroupName, setWorkGroupName] = useState('');
  const [removedItems, setRemovedItems] = useState<{
    removedBuildings: number[];
    removedBuildingSpaces: number[];
    removedBuildingSections: number[];
    removedPositions: number[];
    removedWorkgroups: number[];
  }>({
    removedBuildings: [],
    removedBuildingSpaces: [],
    removedBuildingSections: [],
    removedPositions: [],
    removedWorkgroups: [],
  });

  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);

  const initializeBasicInfo = async () => {
    const result: IBasicInfoGet = await apiProjects.measurementCard.get(
      projectId
    );
    setBuildings(result.buildings);
    setBuildingSections(result.buildingSections);
    setBuildingSpaces(result.buildingSpaces);
    const posTemp = getPositions(result.positions);
    setPositions(posTemp);
    setWGroups(getWorkGroups(result.workgroups, posTemp));
    setRemovedItems({
      removedBuildings: [],
      removedBuildingSpaces: [],
      removedBuildingSections: [],
      removedPositions: [],
      removedWorkgroups: [],
    });
  };

  useEffect(() => {
    initializeBasicInfo().catch((e) => console.log(e));
  }, [projectId, savedInfo]);

  const addWorkGroup = (
    workGName: string,
    positionRefs: any,
    userIds: any,
    e: any
  ) => {
    e.preventDefault();
    e.target.reset();
    setWGroups([
      ...wGroups,
      {
        id: null,
        name: workGName,
        positions: positionRefs.map((position: any) => {
          return position.value;
        }),
        users: userIds.map((userId: any) => {
          return userId.value;
        }),
      },
    ]);
  };

  const removeWorkGroup = (workGName: string) => {
    const elementIndex = wGroups.findIndex(
      (workGroup) => workGroup.name === workGName
    );
    const tempWorkGroups = [...wGroups];
    tempWorkGroups.splice(elementIndex, 1);
    setWGroups(tempWorkGroups);
    const wgId = wGroups[elementIndex].id;
    if (wgId !== null) {
      const removed = removedItems;
      removed.removedWorkgroups.push(wgId);
      setRemovedItems(removed);
    }
  };

  const addBuilding = (buildName: any, floorsN: any, e: any) => {
    e.preventDefault();
    setBuildings([
      ...buildings,
      {
        name: buildName,
        floors: floorsN,
        id: null,
      },
    ]);
    e.target.reset();
  };

  const removeBuilding = (element: string) => {
    const elementIndex = buildings.findIndex(
      (building) => building.name === element
    );
    const tempBuildings = [...buildings];
    tempBuildings.splice(elementIndex, 1);
    setBuildings(tempBuildings);
    const bId = buildings[elementIndex].id;
    if (bId !== null) {
      const removed = removedItems;
      removed.removedBuildings.push(bId);
      setRemovedItems(removed);
    }
  };

  const addBuildingSection = (form: any, e: any) => {
    setBuildingSections([
      ...buildingSections,
      { name: form.buildingSections, id: null },
    ]);
    e.target.reset();
  };

  const removeBuildingSection = (element: string) => {
    const elementIndex = buildingSections.findIndex(
      (building) => building.name === element
    );
    const tempBuildings = [...buildingSections];
    tempBuildings.splice(elementIndex, 1);
    setBuildingSections(tempBuildings);
    const bId = buildingSections[elementIndex].id;
    if (bId !== null) {
      const removed = removedItems;
      removed.removedBuildingSections.push(bId);
      setRemovedItems(removed);
    }
  };

  const addBuildingSpace = (form: any, e: any) => {
    setBuildingSpaces([
      ...buildingSpaces,
      { name: form.buildingSpaces, id: null },
    ]);
    e.target.reset();
  };

  const removeBuildingSpace = (element: string) => {
    const elementIndex = buildingSpaces.findIndex(
      (building) => building.name === element
    );
    const tempBuildings = [...buildingSpaces];
    tempBuildings.splice(elementIndex, 1);
    setBuildingSpaces(tempBuildings);
    const bId = buildingSpaces[elementIndex].id;
    if (bId !== null) {
      const removed = removedItems;
      removed.removedBuildingSpaces.push(bId);
      setRemovedItems(removed);
    }
  };

  const addPosition = (form: any, e: any) => {
    setPositions([
      ...positions,
      {
        id: null,
        name: form.positions,
        reference: form.positions,
      },
    ]);
    e.target.reset();
  };

  const removePosition = (element: string) => {
    const elementIndex = positions.findIndex(
      (position) => position.name === element
    );
    const tempBuildings = [...positions];
    tempBuildings.splice(elementIndex, 1);
    setPositions(tempBuildings);
    const posId = positions[elementIndex].id;
    if (posId !== null) {
      const removed = removedItems;
      removed.removedPositions.push(posId);
      setRemovedItems(removed);
    }
  };

  const saveBasicInfo = () => {
    const basicInfo: IBasicInfoPost = {
      buildings: getBuildings(buildings) || null,
      buildingSections: buildingSections || null,
      buildingSpaces: buildingSpaces || null,
      positions: positions || null,
      workgroups: wGroups || null,
      removedBuildings: removedItems.removedBuildings,
      removedBuildingSections: removedItems.removedBuildingSections,
      removedBuildingSpaces: removedItems.removedBuildingSpaces,
      removedPositions: removedItems.removedPositions,
      removedWorkgroups: removedItems.removedWorkgroups,
    };

    const submit = async () => {
      try {
        await apiProjects.measurementCard.put(projectId, basicInfo);
        setSaveError(null);
        setModalSaved(true);
        setSavedInfo(savedInfo + 1);
      } catch (error) {
        const { message } = error.response.data;
        setSaveError(message === undefined ? 'failure' : message);
        setModalSaved(true);
      }
    };

    submit();
  };

  const editBuildingSection = (sectionName: string) => {
    setBuildingTypeToEdit('section');
    setBuildingTypeName(sectionName);
    setModalBuildingStructure(true);
  };

  const editBuildingSpace = (spaceName: string) => {
    setBuildingTypeToEdit('space');
    setBuildingTypeName(spaceName);
    setModalBuildingStructure(true);
  };

  const editPosition = (posName: string) => {
    setPositionName(posName);
    setModalPosition(true);
  };

  const editBuilding = (buildName: string) => {
    setBuildingName(buildName);
    setModalBuilding(true);
  };

  const editWorkGroup = (workGName: string) => {
    setWorkGroupName(workGName);
    setModalWorkGroup(true);
  };

  const updateStructure = (structureName: string) => {
    if (buildingTypeToEdit === 'space') {
      const tempSpaces = [...buildingSpaces];
      const index = tempSpaces.findIndex(
        (space) => space.name === buildingTypeName
      );
      tempSpaces[index].name = structureName;
      setBuildingSpaces(tempSpaces);
    } else {
      const tempSections = [...buildingSections];
      const index = tempSections.findIndex(
        (section) => section.name === buildingTypeName
      );
      tempSections[index].name = structureName;
      setBuildingSections(tempSections);
    }
    setModalBuildingStructure(false);
  };

  const updatePosition = (posName: string) => {
    const tempPositions = [...positions];
    const index = tempPositions.findIndex((pos) => pos.name === positionName);
    tempPositions[index].name = posName;
    setPositions(tempPositions);
    setModalPosition(false);
  };

  const updateBuilding = (buildName: string, floorsN: any) => {
    const tempBuildings = [...buildings];
    const index = tempBuildings.findIndex(
      (build) => build.name === buildingName
    );
    tempBuildings[index].name = buildName;
    tempBuildings[index].floors = floorsN;
    setBuildings(tempBuildings);
    setModalBuilding(false);
  };

  const updateWorkGroup = (workGName: string, pos: any, userIds: any) => {
    const tempWG = [...wGroups];
    const index = tempWG.findIndex((wg) => wg.name === workGroupName);
    tempWG[index].name = workGName;
    tempWG[index].positions = pos ? pos.map((p: any) => p.value) : [];
    tempWG[index].users = userIds ? userIds.map((user: any) => user.value) : [];
    setWGroups(tempWG);
    setModalWorkGroup(false);
  };

  const goPreviousPage = () => {
    setSection(PROJECT_MEASUREMENT_LIST);
  };

  return (
    <div>
      <Row>
        <Col md={9}>
          <div className="header-text title-spacing">
            {t(`${translations.projects}:Edit Measurement Card`).toUpperCase()}
          </div>
        </Col>
      </Row>
      <CreatableListWithFloor
        elements={buildings}
        title={t(`${translations.projects}:Buildings`)}
        addElement={addBuilding}
        removeElement={removeBuilding}
        updateElement={editBuilding}
      />
      <CreatableList
        elements={buildingSections}
        title={t(`${translations.projects}:Building Sections`)}
        addElement={addBuildingSection}
        name="buildingSections"
        removeElement={removeBuildingSection}
        updateElement={editBuildingSection}
      />
      <CreatableList
        elements={buildingSpaces}
        title={t(`${translations.projects}:Building Spaces`)}
        addElement={addBuildingSpace}
        name="buildingSpaces"
        removeElement={removeBuildingSpace}
        updateElement={editBuildingSpace}
      />
      <CreatableListForPositions
        elements={positions}
        title={t(`${translations.projects}:Positions`)}
        addElement={addPosition}
        name="positions"
        removeElement={removePosition}
        updateElement={editPosition}
      />
      <WorkGroups
        workGroups={wGroups}
        addWorkGroup={addWorkGroup}
        removeElement={removeWorkGroup}
        positions={positions}
        users={userList}
        updateElement={editWorkGroup}
      />
      <Row>
        <Col>
          <PrimaryButton onClick={goPreviousPage}>
            {t(`${translations.common}:Back`).toLocaleUpperCase()}
          </PrimaryButton>
          <PrimaryButton float={RIGHT} onClick={() => saveBasicInfo()}>
            {t(`${translations.common}:Save`).toLocaleUpperCase()}
          </PrimaryButton>
        </Col>
      </Row>
      <MessageModal
        initializeBasicInfo={initializeBasicInfo}
        modalSaved={modalSaved}
        saveError={saveError}
        setModalSaved={setModalSaved}
        setSaveError={setSaveError}
      />
      <ModalEditBuildingStructure
        label={buildingTypeToEdit}
        building={
          buildingTypeToEdit === 'space'
            ? buildingSpaces.find(
                (building) => building.name === buildingTypeName
              )
            : buildingSections.find(
                (building) => building.name === buildingTypeName
              )
        }
        showing={modalBuildingStructure}
        setShowing={setModalBuildingStructure}
        updateElement={updateStructure}
      />
      <ModalEditPosition
        position={positions.find((position) => position.name === positionName)}
        showing={modalPosition}
        setShowing={setModalPosition}
        updateElement={updatePosition}
      />
      <ModalEditBuilding
        building={buildings.find((building) => building.name === buildingName)}
        showing={modalBuilding}
        setShowing={setModalBuilding}
        updateElement={updateBuilding}
      />
      <ModalEditWorkGroup
        workGroup={wGroups.find(
          (workgroup) => workgroup.name === workGroupName
        )}
        showing={modalWorkGroup}
        setShowing={setModalWorkGroup}
        updateElement={updateWorkGroup}
        users={userList}
        positions={positions}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userList: state.users.users,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserList: () => dispatch(fetchUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectMeasurementBasicInfo);
