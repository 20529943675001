import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import { NAME } from 'services/products/productConfigurationAttributeTypes';

const getDefaultItem = (type: string): IProductConfigurationItem => ({
  attributes: [{ type: NAME, value: '' }],
  children: [],
  sortIndex: 0,
  type,
});

export default getDefaultItem;
