import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import apiInstallationPrices from 'api/installationPrices';
import CollapseIcon from 'components/layout/icons/CollapseIcon';
import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getContributionMarginPerUnit from 'services/products/calculations/getContributionMarginPerUnit';
import getContributionMarginRatio from 'services/products/calculations/getContributionMarginRatio';
import getContributionMarginTotal from 'services/products/calculations/getContributionMarginTotal';
import getExpensePerUnit from 'services/products/calculations/getExpensePerUnit';
import getExpenseTotal from 'services/products/calculations/getExpenseTotal';
import getFormStateHandler from 'services/forms/getFormStateHandler';
import getSellingPriceTotal from 'services/products/calculations/getSellingPriceTotal';
import NumberWithDropdownInputField from 'components/layout/forms/NumberWithDropdownInputField';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import SearchDropdown from 'components/layout/forms/SearchDropdown';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import TextInputFieldNoLabel from 'components/layout/forms/TextInputFieldNoLabel';
import translations from 'services/translations/translations';
import TrashIcon from 'components/layout/icons/TrashIcon';
import { fetchUnits } from 'store/products/actions';

import { APPROVED } from 'services/enums/offers/Statuses';
import getInstallationCategoryOptions from '../../../../services/getInstallationCategoryOptions';
import getSellingPriceUnitOptions from '../../../../services/getSellingPriceUnitOptions';
import getUnitOptions from '../../../../services/getUnitOptions';
import IInstallation from '../../IInstallation';
import InstallationDetails from './InstallationDetails';

interface ILabelInstallationPrice {
  category: string;
  installationPrice: number;
  installationPriceUnit: string;
  label: string;
  value: string;
}

const Installation = ({
  initialStatus,
  installation,
  openedInstallation,
  quantity,
  removeInstallation,
  setInstallation,
  setOpenedInstallation,
  type,
}: {
  initialStatus: null | string;
  installation: IInstallation;
  openedInstallation: null | number;
  quantity: number;
  removeInstallation: (installation: IInstallation) => void;
  setInstallation: (installation: IInstallation) => void;
  setOpenedInstallation: (key: null | number) => void;
  type: string;
}): JSX.Element => {
  const { t } = useTranslation([translations.products]);

  const offerIsApproved = () => initialStatus === APPROVED;

  const dispatch = useDispatch();
  const units = useSelector((state: any) => state.products.units);
  const [
    firstCollapseCustomInstallationIsSet,
    setFirstCollapseCustomInstallationIsSet,
  ] = useState(false);

  const collapsed = openedInstallation === installation.key;

  const toggleCollapse = () => {
    if (
      !firstCollapseCustomInstallationIsSet &&
      installation.installation == null
    ) {
      setFirstCollapseCustomInstallationIsSet(true);
      setInstallation({
        ...installation,
        installation: null,
        isCustomInstallation: true,
        sellingPrice: '',
        sellingPriceUnit: '',
      });
    }
    setOpenedInstallation(
      openedInstallation === installation.key ? null : installation.key
    );
  };

  const handleInstallationPriceChange = (
    installationPrice: ILabelInstallationPrice | null
  ) => {
    if (installationPrice === null) {
      setInstallation({
        ...installation,
        installation: null,
        sellingPrice: '',
        sellingPriceUnit: '',
      });

      return;
    }

    setInstallation({
      ...installation,
      category: installationPrice.category,
      installation: {
        label: installationPrice.label,
        value: installationPrice.value,
      },
      installationPrice: formatFloatForDisplay(
        installationPrice.installationPrice
      ),
      installationPriceUnit: installationPrice.installationPriceUnit,
      quantityUnit: installationPrice.installationPriceUnit,
    });
  };

  const installationCategories = useSelector(
    (state: any) => state.products.installationCategories
  );

  useEffect(() => {
    dispatch(fetchUnits());
  }, []);

  useEffect(() => {
    const installationPriceUnit =
      installation.installationPriceUnit === '' ||
      installation.installationPriceUnit === installation.quantityUnit
        ? installation.installationPriceUnit
        : installation.quantityUnit;

    const sellingPriceUnit =
      installation.sellingPriceUnit === '' ||
      installation.sellingPriceUnit === installation.quantityUnit
        ? installation.sellingPriceUnit
        : installation.quantityUnit;

    const changed =
      installation.installationPriceUnit !== installationPriceUnit ||
      installation.sellingPriceUnit !== sellingPriceUnit;

    if (!changed) {
      return;
    }

    setInstallation({
      ...installation,
      installationPriceUnit,
      sellingPriceUnit,
    });
  }, [installation]);

  const loadInstallationPrices = async (
    inputValue: string
  ): Promise<ILabelInstallationPrice[]> => {
    const list = await apiInstallationPrices.get({
      limit: 100,
      offset: 0,
      search: inputValue,
      type,
    });

    return list.map((installationPrice) => ({
      category: installationPrice.category,
      installationPrice: installationPrice.sellingPrice,
      installationPriceUnit: installationPrice.sellingPriceUnit,
      label: installationPrice.name,
      value: installationPrice.id.toString(),
    }));
  };

  const handleFormState = getFormStateHandler(installation, setInstallation);

  const sellingPriceUnitOptions = getSellingPriceUnitOptions(
    installation.quantityUnit,
    installation.key.toString(),
    t
  );

  const contributionMarginForUnit = getContributionMarginPerUnit(
    [installation],
    []
  );

  const contributionMarginTotal = getContributionMarginTotal(
    [installation],
    [],
    quantity
  );

  const contributionMarginRatio = getContributionMarginRatio(
    [installation],
    []
  );

  const expenseForUnit = getExpensePerUnit([installation], []);
  const expenseTotal = getExpenseTotal([installation], [], quantity);
  const sellingPriceTotal = getSellingPriceTotal([installation], [], quantity);

  const SMALL_COLUMN_CLASS = 'small-column';

  return (
    <>
      <Row className="installation-row">
        <Col>
          <SelectInputFieldControlled
            disabled={offerIsApproved() || !installation.isCustomInstallation}
            id={`product-installation-${installation.key}-category`}
            name="category"
            onChange={handleFormState}
            options={getInstallationCategoryOptions(
              installationCategories,
              t,
              type
            )}
            required
            value={installation.category}
          />
        </Col>
        <Col>
          {installation.isCustomInstallation ? (
            <TextInputFieldNoLabel
              id={`product-installation-${installation.key}-installation`}
              name="customInstallation"
              onChange={handleFormState}
              value={
                installation.customInstallation === null
                  ? ''
                  : installation.customInstallation
              }
              disabled={offerIsApproved()}
            />
          ) : (
            <SearchDropdown
              className="text-field search-field"
              disabled={offerIsApproved()}
              id={`product-installation-${installation.key}-installation`}
              loadOptions={loadInstallationPrices}
              onChange={handleInstallationPriceChange}
              name="installation"
              placeholder={t(`${translations.common}:Search`)}
              required
              value={installation.installation}
            />
          )}
        </Col>
        <Col className="icon-column">
          <CollapseIcon
            collapsed={collapsed}
            onClick={() => toggleCollapse()}
            size="sm"
          />
        </Col>
        <Col>
          <NumberWithDropdownInputField
            dropdownDisabled={!installation.isCustomInstallation}
            dropdownName="quantityUnit"
            dropdownOnChange={handleFormState}
            dropdownOptions={getUnitOptions(units, t)}
            dropdownValue={installation.quantityUnit}
            id={`product-installation-${installation.key}-quantity`}
            numberName="quantity"
            numberOnChange={handleFormState}
            numberValue={installation.quantity}
            required
            disabled={offerIsApproved()}
          />
        </Col>
        <Col>
          <NumberWithDropdownInputField
            dropdownDisabled
            dropdownName="installationPriceUnit"
            dropdownOnChange={handleFormState}
            dropdownOptions={sellingPriceUnitOptions}
            dropdownValue={installation.installationPriceUnit}
            id={`product-installation-${installation.key}-installation-price`}
            numberName="installationPrice"
            numberOnChange={handleFormState}
            numberValue={installation.installationPrice}
            required
            disabled={offerIsApproved()}
          />
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <NumberWithUnitInputField
            id={`product-installation-${installation.key}-social-security-cost-percentage`}
            onChange={handleFormState}
            name="socialSecurityCostPercentage"
            required
            unit="%"
            value={installation.socialSecurityCostPercentage}
            disabled={offerIsApproved()}
          />
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <NumberWithUnitInputField
            disabled
            unit="€"
            value={formatFloatForDisplay(expenseForUnit)}
          />
        </Col>
        <Col>
          <NumberWithDropdownInputField
            dropdownDisabled
            dropdownName="sellingPriceUnit"
            dropdownOnChange={handleFormState}
            dropdownOptions={sellingPriceUnitOptions}
            dropdownValue={installation.sellingPriceUnit}
            id={`product-installation-${installation.key}-selling-price`}
            numberName="sellingPrice"
            numberOnChange={handleFormState}
            numberValue={installation.sellingPrice}
            required
            disabled={offerIsApproved()}
          />
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <NumberWithUnitInputField
            disabled
            unit="€"
            value={formatFloatForDisplay(contributionMarginForUnit)}
          />
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <NumberWithUnitInputField
            disabled
            unit="€"
            value={formatFloatForDisplay(expenseTotal)}
          />
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <NumberWithUnitInputField
            disabled
            unit="€"
            value={formatFloatForDisplay(sellingPriceTotal)}
          />
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <NumberWithUnitInputField
            disabled
            unit="€"
            value={formatFloatForDisplay(contributionMarginTotal)}
          />
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <NumberWithUnitInputField
            disabled
            unit="%"
            value={formatFloatForDisplay(contributionMarginRatio)}
          />
        </Col>
        <Col className="icon-column">
          {offerIsApproved() ? null : (
            <TrashIcon onClick={() => removeInstallation(installation)} />
          )}
        </Col>
      </Row>
      <InstallationDetails
        collapsed={collapsed}
        initialStatus={initialStatus}
        installation={installation}
        setInstallation={setInstallation}
      />
    </>
  );
};

export default Installation;
