const BASIC_INFORMATION = 'basic-information';
const CONTACTS = 'contacts';
const FILES = 'files';
const INVOICING = 'invoicing';
const MEASUREMENT_CARD = 'measurement-card';
const NOTES = 'notes';
const OFFERS = 'offers';

export {
  BASIC_INFORMATION,
  CONTACTS,
  FILES,
  INVOICING,
  MEASUREMENT_CARD,
  NOTES,
  OFFERS,
};
