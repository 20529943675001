import React from 'react';
import { useSelector } from 'react-redux';

import ICurrentUser from 'api/interfaces/users/ICurrentUser';

import NotAuthorized from './NotAuthorized';

const AnyPermission = ({
  children,
  types,
}: {
  children: JSX.Element;
  types: string[];
}): JSX.Element => {
  const currentUser: ICurrentUser = useSelector(
    (state: any) => state.users.current
  );

  const isAuthorized = (section: string): boolean =>
    currentUser !== null && currentUser.views.includes(section);

  const anyAuthorized = types.find((t) => isAuthorized(t));

  if (anyAuthorized !== undefined) {
    return children;
  }

  return <NotAuthorized />;
};

export default AnyPermission;
