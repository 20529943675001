import React from 'react';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import PrimaryButtonDropdown from 'components/layout/buttons/PrimaryButtonDropdown';
import translations from 'services/translations/translations';

import 'style/products/configurations/src/items/AddItem.css';

const Actions = ({
  item,
  onDelete,
}: {
  item: IProductConfigurationItem;
  onDelete: (itemId: number) => void;
}): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation([translations.common]);

  const onEditClick = () => () => {
    history.push(`/products/configurations/items/${item.id}`);
  };

  const onDeleteClick = () => async () => {
    onDelete(item.id);
  };

  return (
    <div className="item-actions">
      <PrimaryButtonDropdown
        size="sm"
        text={t(`${translations.common}:Actions`)}
      >
        <DropdownMenu right className="primary-dropdown-menu">
          <DropdownItem onClick={onEditClick()}>
            {t(`${translations.common}:Edit`)}
          </DropdownItem>
          <DropdownItem onClick={onDeleteClick()}>
            {t(`${translations.common}:Remove`)}
          </DropdownItem>
        </DropdownMenu>
      </PrimaryButtonDropdown>
    </div>
  );
};

export default Actions;
