import React from 'react';
import { useTranslation } from 'react-i18next';
import translations from 'services/translations/translations';

const NotAuthorized = (): JSX.Element => {
  const { t } = useTranslation([translations.common]);
  return (
    <>
      <div className="centered-container">
        <h2>{t(`${translations.common}:Not Authorized`)}</h2>
      </div>
    </>
  );
};

export default NotAuthorized;
