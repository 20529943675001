import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import Checkbox from 'components/layout/forms/Checkbox';
import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getContributionMarginPerUnit from 'services/products/calculations/getContributionMarginPerUnit';
import getContributionMarginRatio from 'services/products/calculations/getContributionMarginRatio';
import getDefaultDetail from 'components/offers/src/offerProduct/calculationWindow/subproducts/getDefaultDetail';
import getDefaultSubproduct from 'components/offers/src/offerProduct/calculationWindow/subproducts/getDefaultSubproduct';
import getExpensePerUnit from 'services/products/calculations/getExpensePerUnit';
import getProductCategoryOptions from 'components/offers/services/getProductCategoryOptions';
import getSellingPriceUnitOptions from 'components/offers/services/getSellingPriceUnitOptions';
import getSubproductDetailsTotalExpense from 'services/products/calculations/getSubproductDetailsTotalExpense';
import getSubproductDetailsTotalSellingPrice from 'services/products/calculations/getSubproductDetailsTotalSellingPrice';
import getUnitOptions from 'components/offers/services/getUnitOptions';
import IDetail from 'components/offers/src/offerProduct/IDetail';
import InputLabel from 'components/layout/forms/InputLabel';
import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import IProductConfigurationProduct from 'interfaces/products/IProductConfigurationProduct';
import ISubproduct from 'components/offers/src/offerProduct/ISubproduct';
import LinkButton from 'components/layout/buttons/LinkButton';
import NumberWithDropdownInputField from 'components/layout/forms/NumberWithDropdownInputField';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';
import { fetchCategories, fetchUnits } from 'store/products/actions';
import { PRODUCT_DETAIL } from 'services/products/productConfigurationItemTypes';

import {
  CALCULATE_PRICE_FROM_DETAILS,
  CATEGORY,
  CUSTOM_PRODUCT,
  EXPENSE,
  EXPENSE_UNIT,
  IS_CUSTOM_SUBPRODUCT,
  PRODUCT_ID,
  QUANTITY,
  QUANTITY_UNIT,
  SELLING_PRICE,
  SELLING_PRICE_UNIT,
  WASTE_PERCENTAGE,
} from 'services/products/productConfigurationAttributeTypes';

import attributeValueUpdater from './attributeValueUpdater';
import getAttributeValue from '../getAttributeValue';
import getDefaultProductDetailItem from './getDefaultProductDetailItem';
import ProductConfigurationProduct from '../ProductConfigurationProduct';
import ProductItemDetail from './ProductItemDetail';
import updateProductUnitsToValidValues from './updateProductUnitsToValidValues';

const ProductItem = ({
  item,
  setItem,
}: {
  item: IProductConfigurationItem;
  setItem: (item: IProductConfigurationItem) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([
    translations.common,
    translations.offers,
    translations.products,
  ]);

  const categories = useSelector((state: any) => state.products.categories);
  const units = useSelector((state: any) => state.products.units);
  const dispatch = useDispatch();

  const [product, setProduct] = useState<IProductConfigurationProduct>({
    id: null,
    key: '',
    name: '',
  });

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchUnits());
  }, []);

  const updateAttributeValues = attributeValueUpdater(item, setItem);

  useEffect(() => {
    updateProductUnitsToValidValues(item, updateAttributeValues);
  }, [item.attributes]);

  const initializeProduct = async (productId: number) => {
    const apiProduct = await apiProducts.getProduct(productId);
    setProduct({
      ...product,
      id: productId,
      name: ['', null].includes(apiProduct.displayName)
        ? apiProduct.name
        : apiProduct.displayName,
    });
  };

  useEffect(() => {
    if (product.id !== null) {
      return;
    }

    const productId = getAttributeValue(item, PRODUCT_ID);
    if (productId === '') {
      return;
    }

    initializeProduct(parseInt(productId, 10));
  }, [item]);

  const calculatePriceFromDetails =
    getAttributeValue(item, CALCULATE_PRICE_FROM_DETAILS) === '1';

  const recalculateExpenseAndSellingPriceBasedOnDetails = () => {
    const subproduct: ISubproduct = getDefaultSubproduct(0);
    subproduct.details = item.children.map((child, index) => {
      const detail: IDetail = getDefaultDetail(index);
      detail.expense = getAttributeValue(child, EXPENSE);
      detail.expenseUnit = getAttributeValue(child, EXPENSE_UNIT);
      detail.quantity = getAttributeValue(child, QUANTITY);
      detail.sellingPrice = getAttributeValue(child, SELLING_PRICE);
      detail.sellingPriceUnit = getAttributeValue(child, SELLING_PRICE_UNIT);
      detail.wastePercentage = getAttributeValue(child, WASTE_PERCENTAGE);
      return detail;
    });

    return [
      {
        type: EXPENSE,
        value: formatFloatForDisplay(
          getSubproductDetailsTotalExpense(subproduct.details)
        ),
      },
      {
        type: SELLING_PRICE,
        value: formatFloatForDisplay(
          getSubproductDetailsTotalSellingPrice(subproduct.details)
        ),
      },
    ];
  };

  useEffect(() => {
    if (calculatePriceFromDetails) {
      updateAttributeValues(recalculateExpenseAndSellingPriceBasedOnDetails());
    }
  }, [item.children]);

  const isCustomSubproduct =
    getAttributeValue(item, IS_CUSTOM_SUBPRODUCT) === '1';

  const sellingPriceUnitOptions = getSellingPriceUnitOptions(
    getAttributeValue(item, QUANTITY_UNIT),
    'product',
    t
  );

  const onProductChange = (newProduct: IProductConfigurationProduct) => {
    updateAttributeValues([
      {
        type: PRODUCT_ID,
        value: newProduct.id === null ? '' : newProduct.id.toString(),
      },
    ]);

    setProduct(newProduct);
  };

  const onCalculatePriceFromDetailsChange = (e: any) => {
    const updatedAttributes = [
      {
        type: CALCULATE_PRICE_FROM_DETAILS,
        value: e.target.checked ? '1' : '0',
      },
    ];

    if (!isCustomSubproduct) {
      updatedAttributes.push({
        type: IS_CUSTOM_SUBPRODUCT,
        value: '1',
      });
    }

    const calculatedDetails = recalculateExpenseAndSellingPriceBasedOnDetails();

    updateAttributeValues([...updatedAttributes, ...calculatedDetails]);
  };

  const onIsCustomProductChange = (e: any) => {
    updateAttributeValues([
      { type: IS_CUSTOM_SUBPRODUCT, value: e.target.checked ? '1' : '0' },
    ]);
  };

  const onTextValueChange = (type: string) => (event: any) => {
    updateAttributeValues([{ type, value: event.target.value }]);
  };

  const addDetail = () => {
    setItem({
      ...item,
      children: [...item.children, getDefaultProductDetailItem(PRODUCT_DETAIL)],
    });
  };

  const getDetailRemover = (index: number) => () => {
    setItem({
      ...item,
      children: item.children.filter(
        (child, childIndex) => childIndex !== index
      ),
    });
  };

  const getDetailSetter = (index: number) => (
    updatedDetail: IProductConfigurationItem
  ) => {
    setItem({
      ...item,
      children: item.children.map((child, childIndex) =>
        childIndex === index ? updatedDetail : child
      ),
    });
  };

  const subproductForCalculations = {
    expense: getAttributeValue(item, EXPENSE),
    expenseUnit: getAttributeValue(item, EXPENSE_UNIT),
    quantity: getAttributeValue(item, QUANTITY),
    quantityUnit: getAttributeValue(item, QUANTITY_UNIT),
    sellingPrice: getAttributeValue(item, SELLING_PRICE),
    sellingPriceUnit: getAttributeValue(item, SELLING_PRICE_UNIT),
    wastePercentage: getAttributeValue(item, WASTE_PERCENTAGE),
  };

  const contributionMarginForUnit = getContributionMarginPerUnit(
    [],
    [subproductForCalculations]
  );

  const contributionMarginRatio = getContributionMarginRatio(
    [],
    [subproductForCalculations]
  );

  const expenseForUnit = getExpensePerUnit([], [subproductForCalculations]);

  return (
    <>
      <Row>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            <SelectInputFieldControlled
              id="product-category"
              label={t(`${translations.common}:Category`)}
              onChange={onTextValueChange(CATEGORY)}
              options={getProductCategoryOptions(categories, t)}
              required
              value={getAttributeValue(item, CATEGORY)}
            />
          </div>
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            {isCustomSubproduct ? (
              <TextInputField
                id="product-custom-name"
                label={t(`${translations.products}:Product`)}
                onChange={onTextValueChange(CUSTOM_PRODUCT)}
                value={getAttributeValue(item, CUSTOM_PRODUCT)}
              />
            ) : (
              <ProductConfigurationProduct
                configurationProduct={product}
                setConfigurationProduct={onProductChange}
                showLabel
              />
            )}
          </div>
        </Col>
        <Col xs="3">
          <div className="field-wrapper">
            <Checkbox
              checked={isCustomSubproduct}
              className="product-configuration-item-checkbox"
              disabled={calculatePriceFromDetails}
              label={t(`${translations.calculationWindow}:Custom product`)}
              onChange={onIsCustomProductChange}
            />
          </div>
        </Col>
        <Col xs="3">
          <div className="field-wrapper">
            <Checkbox
              checked={calculatePriceFromDetails}
              className="product-configuration-item-checkbox"
              label={t(
                `${translations.calculationWindow}:Calculate price from details`
              )}
              onChange={onCalculatePriceFromDetailsChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            <NumberWithDropdownInputField
              dropdownOnChange={onTextValueChange(QUANTITY_UNIT)}
              dropdownOptions={getUnitOptions(units, t)}
              dropdownValue={getAttributeValue(item, QUANTITY_UNIT)}
              id="product-quantity"
              label={t(`${translations.common}:Quantity`)}
              numberOnChange={onTextValueChange(QUANTITY)}
              numberValue={getAttributeValue(item, QUANTITY)}
              required
            />
          </div>
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            <NumberWithDropdownInputField
              dropdownDisabled
              dropdownOnChange={onTextValueChange(EXPENSE_UNIT)}
              dropdownOptions={sellingPriceUnitOptions}
              dropdownValue={getAttributeValue(item, EXPENSE_UNIT)}
              id="product-expense"
              label={t(`${translations.offers}:Purchase`)}
              numberDisabled={calculatePriceFromDetails}
              numberOnChange={onTextValueChange(EXPENSE)}
              numberValue={getAttributeValue(item, EXPENSE)}
              required
            />
          </div>
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            <NumberWithUnitInputField
              id="product-waste-percentage"
              label={t(`${translations.offers}:Waste %`)}
              onChange={onTextValueChange(WASTE_PERCENTAGE)}
              required
              unit="%"
              value={getAttributeValue(item, WASTE_PERCENTAGE)}
            />
          </div>
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            <NumberWithDropdownInputField
              dropdownDisabled
              dropdownOnChange={onTextValueChange(SELLING_PRICE_UNIT)}
              dropdownOptions={sellingPriceUnitOptions}
              dropdownValue={getAttributeValue(item, SELLING_PRICE_UNIT)}
              id="product-selling-price"
              label={t(`${translations.offers}:Selling price`)}
              numberOnChange={onTextValueChange(SELLING_PRICE)}
              numberValue={getAttributeValue(item, SELLING_PRICE)}
              required
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <NumberWithUnitInputField
            disabled
            label={t(`${translations.offers}:Expense`)}
            unit="€"
            value={formatFloatForDisplay(expenseForUnit)}
          />
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <NumberWithUnitInputField
            disabled
            label={`${t(`${translations.offers}:Contribution margin`)} €`}
            unit="€"
            value={formatFloatForDisplay(contributionMarginForUnit)}
          />
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <NumberWithUnitInputField
            disabled
            label={`${t(`${translations.offers}:Contribution margin`)} %`}
            unit="%"
            value={formatFloatForDisplay(contributionMarginRatio)}
          />
        </Col>
      </Row>
      <div>
        <h3 className="structure-header">
          {t(`${translations.productConfigurations}:Structure`)}
        </h3>
        <Row>
          <Col xs="2">
            <InputLabel>
              {t(`${translations.calculationWindow}:Detail`)}
            </InputLabel>
          </Col>
          <Col>
            <InputLabel>{t(`${translations.common}:Quantity`)}</InputLabel>
          </Col>
          <Col>
            <InputLabel>{t(`${translations.offers}:Purchase`)}</InputLabel>
          </Col>
          <Col>
            <InputLabel>{t(`${translations.offers}:Waste %`)}</InputLabel>
          </Col>
          <Col xs="1">
            <InputLabel>{t(`${translations.offers}:Expense`)}</InputLabel>
          </Col>
          <Col>
            <InputLabel>{t(`${translations.offers}:Selling price`)}</InputLabel>
          </Col>
          <Col xs="1">
            <InputLabel>
              {t(`${translations.offers}:Contribution margin`)} €
            </InputLabel>
          </Col>
          <Col xs="1">
            <InputLabel>
              {t(`${translations.offers}:Contribution margin`)} %
            </InputLabel>
          </Col>
          <Col className="icon-column" xs="1" />
        </Row>
        {item.children.map((child, index) => (
          <ProductItemDetail
            detail={child}
            removeDetail={getDetailRemover(index)}
            setDetail={getDetailSetter(index)}
          />
        ))}
        <LinkButton onClick={addDetail}>
          {`+ ${t(`${translations.common}:Add row`)}`}
        </LinkButton>
      </div>
    </>
  );
};

export default ProductItem;
