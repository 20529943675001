import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import IProductConfigurationAttribute from 'interfaces/products/IProductConfigurationAttribute';

import { PRODUCT_ID } from 'services/products/productConfigurationAttributeTypes';

import {
  PRODUCT,
  PRODUCT_DETAIL,
} from 'services/products/productConfigurationItemTypes';

const nonNumericFilter = (value: number) => !Number.isNaN(value);

const flattenItems = (
  items: IProductConfigurationItem[]
): IProductConfigurationItem[] =>
  items.reduce(
    (
      flattenedItems: IProductConfigurationItem[],
      item: IProductConfigurationItem
    ) => [...flattenedItems, item, ...flattenItems(item.children)],
    []
  );

const nullStringsFilter = (value: string | null): value is Required<string> =>
  value !== null;

const productItemsFilter = (item: IProductConfigurationItem) =>
  item.type === PRODUCT || PRODUCT_DETAIL;

const removeDuplicateIdsReducer = (uniqueIds: number[], id: number): number[] =>
  uniqueIds.includes(id) ? uniqueIds : [...uniqueIds, id];

const undefinedAttributesFilter = (
  attribute: IProductConfigurationAttribute | undefined
): attribute is Required<IProductConfigurationAttribute> =>
  attribute !== undefined;

const getProductIdsFromProductConfigurationItems = (
  items: IProductConfigurationItem[]
): number[] =>
  flattenItems(items)
    .filter(productItemsFilter)
    .map((item: IProductConfigurationItem) =>
      item.attributes.find(
        (attribute: IProductConfigurationAttribute) =>
          attribute.type === PRODUCT_ID
      )
    )
    .filter(undefinedAttributesFilter)
    .map(
      (attribute: IProductConfigurationAttribute): string | null =>
        attribute.value
    )
    .filter(nullStringsFilter)
    .map((value: string): number => parseInt(value, 10))
    .filter(nonNumericFilter)
    .reduce(removeDuplicateIdsReducer, []);

export default getProductIdsFromProductConfigurationItems;
