import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import IAttributeType from 'api/interfaces/products/IAttributeType';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import IAttribute from 'interfaces/products/IAttribute';
import translations from 'services/translations/translations';
import { fetchAttributeTypes } from 'store/products/actions';
import getEmptyAttribute from './getEmptyAttribute';
import ProductAttributeRows from './ProductAttributeRows ';

const ProductAttribute = ({
  attributeTypesList,
  fetchAttributeTypesList,
  attributeFormValues,
  setAttributeFormValues,
}: {
  attributeTypesList: IAttributeType[];
  fetchAttributeTypesList: () => void;
  attributeFormValues: IAttribute[];
  setAttributeFormValues: (newValue: IAttribute[]) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common, translations.products]);

  const getSelectedAttributes = (): string[] =>
    attributeFormValues.map((formValue) => formValue.type);

  const availableOptions: string[] = attributeTypesList
    .filter((x) => !getSelectedAttributes().includes(x.attributeType))
    .map((attributeObject) => attributeObject.attributeType);

  useEffect(() => {
    fetchAttributeTypesList();
  }, [fetchAttributeTypesList]);

  const addAttributeRow = () => {
    setAttributeFormValues(
      getEmptyAttribute(attributeFormValues, availableOptions[0])
    );
  };

  return (
    <>
      <ProductAttributeRows
        attributeFormValues={attributeFormValues}
        attributeTypesList={availableOptions}
        setAttributeFormValues={setAttributeFormValues}
      />
      {availableOptions.length === 0 ? null : (
        <Row>
          <Col>
            <PrimaryButton onClick={addAttributeRow}>
              {t(`${translations.products}:Add attribute`)}
            </PrimaryButton>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  attributeTypesList: state.products.attributeTypes,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAttributeTypesList: () => dispatch(fetchAttributeTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttribute);
