import React from 'react';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PrimaryButtonDropdown from 'components/layout/buttons/PrimaryButtonDropdown';
import translations from 'services/translations/translations';

import {
  INSTALLATION,
  PRODUCT,
  PRODUCT_SELECT,
  SELECT,
} from 'services/products/productConfigurationItemTypes';

import { OTHER_EXPENSE } from '../installationTypes';

import 'style/products/configurations/src/items/AddItem.css';

const AddItem = ({ type }: { type: string }): JSX.Element => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation([translations.common, translations.products]);

  const configurationId = parseInt(id, 10);

  const onAddItemClick = (addItemType: string) => () => {
    history.push(
      `/products/configurations/${configurationId}/items/${addItemType}/create`
    );
  };

  const getDropdownItems = () => {
    if (type === INSTALLATION) {
      return [
        <DropdownItem onClick={onAddItemClick(INSTALLATION)}>
          {t(`${translations.productConfigurations}:Add installation`)}
        </DropdownItem>,
      ];
    }

    if (type === OTHER_EXPENSE) {
      return [
        <DropdownItem onClick={onAddItemClick(OTHER_EXPENSE)}>
          {t(`${translations.productConfigurations}:Add other expense`)}
        </DropdownItem>,
      ];
    }

    if (type === PRODUCT) {
      return [
        <DropdownItem onClick={onAddItemClick(PRODUCT)}>
          {t(`${translations.products}:Add Product`)}
        </DropdownItem>,
        <DropdownItem onClick={onAddItemClick(PRODUCT_SELECT)}>
          {t(`${translations.productConfigurations}:Add product select`)}
        </DropdownItem>,
        <DropdownItem onClick={onAddItemClick(SELECT)}>
          {t(`${translations.productConfigurations}:Add select`)}
        </DropdownItem>,
      ];
    }

    return [];
  };

  return (
    <div className="product-configurator-add-item">
      <PrimaryButtonDropdown
        className="new-item-type-select"
        text={t(`${translations.common}:Add`)}
      >
        <DropdownMenu right className="primary-dropdown-menu">
          {getDropdownItems()}
        </DropdownMenu>
      </PrimaryButtonDropdown>
    </div>
  );
};

export default AddItem;
