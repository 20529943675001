import { FETCH_OFFER_TYPES, FETCH_STATUS_TYPES } from './types';

export function fetchOfferTypes() {
  return {
    type: FETCH_OFFER_TYPES.PENDING,
  };
}

export function fetchStatusTypes() {
  return {
    type: FETCH_STATUS_TYPES.PENDING,
  };
}
