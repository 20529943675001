import React from 'react';
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import isNumber from 'services/numbers/isNumber';
import { useTranslation } from 'react-i18next';
import translations from 'services/translations/translations';
import isZero from 'services/numbers/isZero';

import 'style/layout/form/NumberInputField.css';

export default ({
  className,
  disabled,
  id,
  label,
  labelClassName,
  name,
  onChange,
  required,
  value,
}: {
  className?: string;
  disabled?: boolean;
  id: string;
  label?: string;
  labelClassName?: string;
  name?: string;
  onChange: any;
  required: boolean;
  value: string;
}): JSX.Element => {
  const { t } = useTranslation([translations.common]);
  return (
    <FormGroup>
      {label === undefined ? null : (
        <Label className={`${labelClassName} label`} for={id}>
          {label}
        </Label>
      )}
      <Input
        className={`${className} text-field`}
        disabled={disabled}
        id={id}
        invalid={!isNumber(value) || isZero(value)}
        name={name}
        onChange={onChange}
        required={required}
        type="text"
        value={value}
      />
      <FormFeedback>{t(`${translations.common}:Input number`)}</FormFeedback>
    </FormGroup>
  );
};
