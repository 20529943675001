import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import 'style/layout/form/SelectInputFieldControlled.css';

export default ({
  bsSize,
  className,
  disabled,
  formGroupClassName,
  id,
  label,
  labelClassName,
  name,
  onChange,
  options,
  required,
  value,
}: {
  bsSize?: 'lg' | 'sm';
  className?: string;
  disabled?: boolean;
  formGroupClassName?: string;
  id: string;
  label?: string;
  labelClassName?: string;
  name?: string;
  onChange: any;
  options: any;
  required?: boolean;
  value: any;
}): JSX.Element => {
  return (
    <FormGroup
      className={`select-input-field-controlled ${formGroupClassName}`}
    >
      {label === undefined ? null : (
        <Label className={`${labelClassName} label`} for={id}>
          {label}
        </Label>
      )}
      <Input
        bsSize={bsSize}
        className={`${className} text-field`}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        required={required}
        type="select"
        value={value}
      >
        {options}
      </Input>
    </FormGroup>
  );
};
