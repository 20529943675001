import React from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/layout/forms/Checkbox';
import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getNextObjectKey from 'services/numbers/getNextObjectKey';
import getSubproductDetailsTotalExpense from 'services/products/calculations/getSubproductDetailsTotalExpense';
import getSubproductDetailsTotalSellingPrice from 'services/products/calculations/getSubproductDetailsTotalSellingPrice';
import InputLabel from 'components/layout/forms/InputLabel';
import LinkButton from 'components/layout/buttons/LinkButton';
import translations from 'services/translations/translations';
import { APPROVED } from 'services/enums/offers/Statuses';

import {
  CALCULATE_PRICE_FROM_DETAILS,
  EXPENSE,
  EXPENSE_UNIT,
  QUANTITY,
  SELLING_PRICE,
  SELLING_PRICE_UNIT,
  WASTE_PERCENTAGE,
} from 'services/products/productConfigurationAttributeTypes';

import getDefaultDetail from './getDefaultDetail';
import IDetail from '../../IDetail';
import ISubproduct from '../../ISubproduct';
import SubproductDetail from './SubproductDetail';

const SubproductDetails = ({
  collapsed,
  initialStatus,
  setSubproduct,
  subproduct,
  subproductQuantity,
}: {
  collapsed: boolean;
  initialStatus: null | string;
  setSubproduct: (subproduct: ISubproduct) => void;
  subproduct: ISubproduct;
  subproductQuantity: number;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common, translations.offers]);

  if (!collapsed) {
    return null;
  }

  const setSubproductAndCalculatePrices = (
    updatedSubproduct: ISubproduct,
    updatedField: string
  ) => {
    const updateExpenseTypes = [
      CALCULATE_PRICE_FROM_DETAILS,
      EXPENSE,
      EXPENSE_UNIT,
      QUANTITY,
      WASTE_PERCENTAGE,
    ];

    const updateSellingPriceTypes = [
      CALCULATE_PRICE_FROM_DETAILS,
      QUANTITY,
      SELLING_PRICE,
      SELLING_PRICE_UNIT,
    ];

    const expense = updateExpenseTypes.includes(updatedField)
      ? formatFloatForDisplay(
          getSubproductDetailsTotalExpense(updatedSubproduct.details)
        )
      : updatedSubproduct.expense;

    const sellingPrice = updateSellingPriceTypes.includes(updatedField)
      ? formatFloatForDisplay(
          getSubproductDetailsTotalSellingPrice(updatedSubproduct.details)
        )
      : updatedSubproduct.sellingPrice;

    setSubproduct({
      ...updatedSubproduct,
      expense,
      sellingPrice,
    });
  };

  const handleCalculatePriceFromDetailsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubproductAndCalculatePrices(
      {
        ...subproduct,
        calculatePriceFromDetails: event.target.checked,
        isCustomSubproduct: event.target.checked
          ? true
          : subproduct.isCustomSubproduct,
      },
      CALCULATE_PRICE_FROM_DETAILS
    );
  };

  const handleIsCustomSubproductChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubproduct({
      ...subproduct,
      isCustomSubproduct: event.target.checked,
    });
  };

  const addDetail = () => {
    const key = getNextObjectKey(subproduct.details, 'key');
    setSubproduct({
      ...subproduct,
      details: [...subproduct.details, getDefaultDetail(key)],
    });
  };

  const setDetail = (detailKey: number) => (updatedField: string) => (
    newDetail: IDetail
  ) => {
    const details = subproduct.details.map((detail) =>
      detail.key === detailKey ? newDetail : detail
    );

    if (subproduct.calculatePriceFromDetails) {
      setSubproductAndCalculatePrices(
        {
          ...subproduct,
          details,
        },
        updatedField
      );

      return;
    }

    setSubproduct({
      ...subproduct,
      details,
    });
  };

  const removeDetail = (detailKey: number) => () => {
    setSubproductAndCalculatePrices(
      {
        ...subproduct,
        details: subproduct.details.filter(
          (detail) => detail.key !== detailKey
        ),
      },
      CALCULATE_PRICE_FROM_DETAILS
    );
  };

  const offerIsApproved = () => initialStatus === APPROVED;

  const SMALL_COLUMN_CLASS = 'small-column';

  return (
    <div className="item-details-box">
      <Checkbox
        checked={subproduct.isCustomSubproduct}
        className="item-details-checkbox"
        disabled={subproduct.calculatePriceFromDetails || offerIsApproved()}
        label={t(`${translations.calculationWindow}:Custom product`)}
        onChange={handleIsCustomSubproductChange}
      />
      <Checkbox
        checked={subproduct.calculatePriceFromDetails}
        className="item-details-checkbox"
        disabled={offerIsApproved()}
        label={t(
          `${translations.calculationWindow}:Calculate price from details`
        )}
        onChange={handleCalculatePriceFromDetailsChange}
      />
      {subproduct.isCustomSubproduct ? (
        <>
          <Row>
            <Col xs="1">
              <InputLabel>
                {t(`${translations.calculationWindow}:Custom product (short)`)}
              </InputLabel>
            </Col>
            <Col>
              <InputLabel>
                {t(`${translations.calculationWindow}:Detail`)}
              </InputLabel>
            </Col>
            <Col>
              <InputLabel>{t(`${translations.common}:Quantity`)}</InputLabel>
            </Col>
            <Col>
              <InputLabel>{t(`${translations.offers}:Purchase`)}</InputLabel>
            </Col>
            <Col xs="1" className={SMALL_COLUMN_CLASS}>
              <InputLabel>{t(`${translations.offers}:Waste %`)}</InputLabel>
            </Col>
            <Col xs="1" className={SMALL_COLUMN_CLASS}>
              <InputLabel>{t(`${translations.offers}:Expense`)}</InputLabel>
            </Col>
            <Col>
              <InputLabel>
                {t(`${translations.offers}:Selling price`)}
              </InputLabel>
            </Col>
            <Col xs="1" className={SMALL_COLUMN_CLASS}>
              <InputLabel>
                {t(`${translations.offers}:Contribution margin`)} €
              </InputLabel>
            </Col>
            <Col xs="1" className={SMALL_COLUMN_CLASS}>
              <InputLabel>
                {t(`${translations.offers}:Expense`)}{' '}
                {t(`${translations.common}:total-short`)}
              </InputLabel>
            </Col>
            <Col xs="1" className={SMALL_COLUMN_CLASS}>
              <InputLabel>
                {t(`${translations.offers}:Selling price`)}{' '}
                {t(`${translations.common}:total-short`)}
              </InputLabel>
            </Col>
            <Col xs="1" className={SMALL_COLUMN_CLASS}>
              <InputLabel>
                {t(`${translations.offers}:Contribution margin`)}{' '}
                {t(`${translations.common}:total-short`)} €
              </InputLabel>
            </Col>
            <Col className={SMALL_COLUMN_CLASS}>
              <InputLabel>
                {t(`${translations.offers}:Contribution margin`)} %
              </InputLabel>
            </Col>
            <Col className="icon-column" />
          </Row>
          {subproduct.details.map((detail) => (
            <SubproductDetail
              detail={detail}
              initialStatus={initialStatus}
              key={`subproduct-${subproduct.key}-detail-row-${detail.key}`}
              removeDetail={removeDetail(detail.key)}
              setDetail={setDetail(detail.key)}
              subproductQuantity={subproductQuantity}
            />
          ))}
          {offerIsApproved() ? null : (
            <LinkButton onClick={addDetail}>
              {`+ ${t(`${translations.common}:Add row`)}`}
            </LinkButton>
          )}
        </>
      ) : null}
    </div>
  );
};

export default SubproductDetails;
