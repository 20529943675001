import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import BasicTable from 'components/layout/tables/BasicTable';
import IProduct from 'api/interfaces/products/IProductGet';
import SearchInput from 'components/layout/forms/SearchInput';

import productColumns from './productColumns';
import translations from '../../../../services/translations/translations';

const ProductsList = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation([translations.products, translations.common]);

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState<IProduct[]>();
  const [selectedRow, setSelectedRow] = useState<number>();
  const [sizePerPage, setSizePerPage] = useState(50);
  const [totalSize, setTotalSize] = useState<number>();

  const selectedProduct =
    products !== undefined && selectedRow !== undefined
      ? products[selectedRow]
      : undefined;
  const [searchTerms, setSearchTerms] = useState<string>('');

  useEffect(() => {
    if (selectedRow !== undefined) {
      history.push(`/products/${selectedProduct?.id}`);
    }
  }, [history, selectedProduct, selectedRow]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const list = (await apiProducts.get(
          sizePerPage,
          offset,
          searchTerms
        )) as IProduct[];

        const count = await apiProducts.count(searchTerms);
        setProducts(list);
        setTotalSize(count.count);
      } catch (error) {
        // define action
      }
    };
    getProducts().catch((e) => console.log(e));
  }, [offset, searchTerms, sizePerPage]);

  const columns = productColumns(t);

  const handleTableChange = (type: any, { page, sizePerPage }: any) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    setOffset((page - 1) * sizePerPage);
  };

  return (
    <>
      <Row>
        <Col xs={3}>
          <SearchInput setSearchTerms={setSearchTerms} />
        </Col>
      </Row>
      <Row>
        <Col>
          <BasicTable
            columns={columns}
            data={products}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalSize}
            handleTableChange={handleTableChange}
            setSelectedRow={setSelectedRow}
            keyField="id"
          />
        </Col>
      </Row>
    </>
  );
};

export default ProductsList;
