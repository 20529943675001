import IOfferState from 'interfaces/store/IOfferState';
import { FETCH_OFFER_TYPES, FETCH_STATUS_TYPES } from './types';

const initialState: IOfferState = {
  offerTypes: [],
  statusTypes: [],
};

export default (
  state: IOfferState = initialState,
  action: {
    offerTypes: string[];
    statusTypes: string[];
    type: string;
  }
): IOfferState => {
  if (action.type === FETCH_OFFER_TYPES.SUCCESS) {
    return {
      ...state,
      offerTypes: action.offerTypes,
    };
  }

  if (action.type === FETCH_STATUS_TYPES.SUCCESS) {
    return {
      ...state,
      statusTypes: action.statusTypes,
    };
  }

  return state;
};
