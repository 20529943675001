import getFileName from './getFileName';

const openPdfInNewTab = (data: any, headers: any) => {
  const blob = new Blob([data], {
    type: headers['content-type'],
  });

  const objectUrl = URL.createObjectURL(blob);
  window.open(objectUrl, getFileName(headers));
};

export default openPdfInNewTab;
