import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import isNumber from 'services/numbers/isNumber';

import 'style/layout/form/NumberWithUnitInputField.css';

const isValid = (value: string, required?: boolean) =>
  (!required && value === '') || isNumber(value);

export default ({
  bsSize,
  id,
  label,
  name,
  onChange,
  disabled,
  required,
  unit,
  value,
}: {
  bsSize?: 'lg' | 'sm';
  id?: string;
  label?: string;
  name?: string;
  onChange?: any;
  disabled?: boolean;
  required?: boolean;
  unit: string;
  value: string;
}): JSX.Element => {
  const baseInputUnitPadding = 15;

  const [inputUnitPadding, setInputUnitPadding] = useState<string>(
    `${baseInputUnitPadding}px`
  );

  const [valid, setValid] = useState<boolean>(false);

  const unitElementRef = useRef<HTMLParagraphElement>(null);

  const updateInputUnitPadding = () => {
    if (!valid) {
      return;
    }

    const width = unitElementRef.current?.clientWidth;

    if (width === undefined) {
      setInputUnitPadding(`${baseInputUnitPadding}px`);
      return;
    }

    setInputUnitPadding(`${baseInputUnitPadding + width}px`);
  };

  useEffect(() => {
    const validStatus = isValid(value, required);
    setValid(validStatus);
    if (!validStatus) {
      setInputUnitPadding(`${baseInputUnitPadding + 20}px`);
    }
  }, [required, value]);

  useLayoutEffect(() => {
    updateInputUnitPadding();
  }, [unit, valid, value]);

  return (
    <FormGroup className="number-with-unit-input-field">
      {label === undefined ? null : (
        <Label className="label" for={id}>
          {label}
        </Label>
      )}
      <Input
        bsSize={bsSize}
        className="text-field align-right"
        id={id}
        invalid={!valid}
        name={name}
        onChange={onChange}
        disabled={disabled}
        required={required}
        style={{ paddingRight: inputUnitPadding }}
        type="text"
        value={value}
      />
      <p className="text-field" ref={unitElementRef}>
        {valid ? unit : null}
      </p>
    </FormGroup>
  );
};
