import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import numericStringToFloat from 'services/numbers/numericStringToFloat';
import translations from 'services/translations/translations';
import { addMessage } from 'store/messages/actions';
import { fetchInstallationCategories } from 'store/products/actions';

import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import ActionButtons from '../ActionButtons';
import allFieldsAreFilled from './allFieldsAreFilled';
import AttachConfiguration from './attachConfiguration/AttachConfiguration';
import getDefaultVersion from './getDefaultVersion';
import Header from './Header';
import IFormState from '../IFormState';
import IInstallation from '../IInstallation';
import InstallationContract from './InstallationContract';
import Installations from './installations/Installations';
import ISubproduct from '../ISubproduct';
import IVersion from '../IVersion';
import Memo from './Memo';
import OtherExpenses from './installations/OtherExpenses';
import Subproducts from './subproducts/Subproducts';
import Summary from './Summary';
import Version from './Version';
import views from '../views';

import 'style/offers/CalculationWindow.css';
import BatchCalculationReviews from './batchCalculationReview/BatchCalculationReviews';
import ICalculationReview from '../ICalculationReview';

const CalculationWindow = ({
  close,
  formState,
  initialStatus,
  setFormState,
  setView,
  view,
  save,
}: {
  close: () => void;
  formState: IFormState;
  initialStatus: null | string;
  setFormState: (formState: IFormState) => void;
  setView: (view: string) => void;
  view: string;
  save: (preventRedirect: boolean) => void;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const { t } = useTranslation([translations.common]);
  const [memo, setMemo] = useState<string>('');
  const [versions, setVersions] = useState<IVersion[]>([getDefaultVersion()]);
  const [activeVersionIndex, setActiveVersionIndex] = useState<number>(0);
  const [saveVersion, setSaveVersion] = useState(false);

  useEffect(() => {
    dispatch(fetchInstallationCategories());
  }, []);

  useEffect(() => {
    setMemo(formState.memo);

    setVersions(
      formState.versions.length > 0
        ? [...formState.versions]
        : [getDefaultVersion()]
    );

    const getPrimaryVersionIndex = () => {
      const index = formState.versions.findIndex(
        (version) => version.isPrimary
      );

      return index === -1 ? 0 : index;
    };

    setActiveVersionIndex(getPrimaryVersionIndex());

    if (saveVersion) {
      setSaveVersion(false);
      save(true);
    }
  }, [formState]);

  const onOfferTextChanged = (newText: string) => {
    const newActiveVersion = {
      ...versions[activeVersionIndex],
      offerText: newText,
    };

    const newVersions = [
      ...versions.slice(0, activeVersionIndex),
      newActiveVersion,
      ...versions.slice(activeVersionIndex + 1),
    ];

    setVersions(newVersions);
  };

  const setStateOfForm = () => {
    if (!allFieldsAreFilled(versions)) {
      dispatch(addMessage(t(`${translations.common}:Fill all fields`)));
      return;
    }
    setFormState({
      ...formState,
      memo,
      versions,
    });
  };

  const ready = () => {
    setStateOfForm();

    if (allFieldsAreFilled(versions)) {
      close();
    }
  };

  const saveData = () => {
    setSaveVersion(true);
    setStateOfForm();
  };

  const setVersion = (updatedVersion: IVersion) =>
    setVersions(
      versions.map((version, index) =>
        index === activeVersionIndex ? updatedVersion : version
      )
    );

  const setInstallations = (installations: IInstallation[]) =>
    setVersion({
      ...versions[activeVersionIndex],
      installations,
    });

  const setCalculationReviews = (calculationReviews: ICalculationReview[]) =>
    setVersion({
      ...versions[activeVersionIndex],
      calculationReviews,
    });

  const setSubproducts = (subproducts: ISubproduct[]) =>
    setVersion({
      ...versions[activeVersionIndex],
      subproducts,
    });

  const setSubproductsAndInstallations = (
    subproducts: ISubproduct[],
    installations: IInstallation[],
    offerText: string
  ) => {
    let newOfferText = versions[activeVersionIndex].offerText;

    if (newOfferText != null && newOfferText.length > 0) {
      newOfferText += `\n${offerText}`;
    } else {
      newOfferText += offerText;
    }

    subproducts.forEach((sp) => {
        sp.details.forEach((detail) => {
            detail.expenseUnit =  detail.expenseUnit === '' || detail.expenseUnit === detail.quantityUnit
                ? detail.expenseUnit
                : detail.quantityUnit;
        });
    });

    setVersion({
      ...versions[activeVersionIndex],
      installations,
      subproducts,
      offerText: newOfferText,
    });
  };

  if (view === views.attachConfiguration) {
    return (
      <div className="calculation-window">
        <AttachConfiguration
          cancel={() => setView(views.calculationWindow)}
          formState={formState}
          initialStatus={initialStatus}
          installations={versions[activeVersionIndex].installations}
          setSubproductsAndInstallations={setSubproductsAndInstallations}
          subproducts={versions[activeVersionIndex].subproducts}
        />
      </div>
    );
  }

  return (
    <div className="calculation-window">
      <Header
        activeVersionIndex={activeVersionIndex}
        formState={formState}
        initialStatus={initialStatus}
        setActiveVersionIndex={setActiveVersionIndex}
        setVersions={setVersions}
        setView={setView}
        versions={versions}
      />
      <Subproducts
        initialStatus={initialStatus}
        quantity={numericStringToFloat(formState.quantity)}
        setSubproducts={setSubproducts}
        subproducts={versions[activeVersionIndex].subproducts}
      />
      <Installations
        initialStatus={initialStatus}
        installations={versions[activeVersionIndex].installations}
        quantity={numericStringToFloat(formState.quantity)}
        setInstallations={setInstallations}
      />
      <OtherExpenses
        initialStatus={initialStatus}
        installations={versions[activeVersionIndex].installations}
        quantity={numericStringToFloat(formState.quantity)}
        setInstallations={setInstallations}
      />
      <hr />
      <Summary
        installations={versions[activeVersionIndex].installations}
        productUnit={formState.unit}
        quantity={numericStringToFloat(formState.quantity)}
        subproducts={versions[activeVersionIndex].subproducts}
      />
      <hr />
      <InstallationContract
        initialStatus={initialStatus}
        productUnit={formState.unit}
        quantity={parseFloat(formState.quantity)}
        setVersion={setVersion}
        version={versions[activeVersionIndex]}
      />
      <hr />
      <BatchCalculationReviews
        initialStatus={initialStatus}
        productUnit={formState.unit}
        quantity={parseFloat(formState.quantity)}
        setCalculationReviews={setCalculationReviews}
        version={versions[activeVersionIndex]}
        calculationReviews={
          versions[activeVersionIndex].calculationReviews ?? []
        }
      />
      <hr />
      <Version
        activeVersionIndex={activeVersionIndex}
        initialStatus={initialStatus}
        offerText={versions[activeVersionIndex].offerText}
        onOfferTextChanged={onOfferTextChanged}
        setActiveVersionIndex={setActiveVersionIndex}
        setVersions={setVersions}
        versions={versions}
      />
      <hr />
      <div className="calculation-window-action-buttons-wrapper">
        <Memo
          memo={memo}
          onTextInputChanged={setMemo}
          initialStatus={initialStatus}
        />
        <div className="calculation-window-action-buttons">
          <PrimaryButton className="button-one-line" onClick={saveData}>
            {t(`${translations.common}:Save`).toLocaleUpperCase()}
          </PrimaryButton>
          <ActionButtons
            cancel={close}
            initialStatus={initialStatus}
            save={ready}
            saveButtonText={t(`${translations.common}:Ready`)}
          />
        </div>
      </div>
    </div>
  );
};

export default CalculationWindow;
