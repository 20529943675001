import React from 'react';
import { FormGroup, Input, InputGroup, Label } from 'reactstrap';

import isNumber from 'services/numbers/isNumber';

import 'style/layout/form/NumberWithDropdownInputField.css';

export default ({
  bsSize,
  className,
  disabled,
  dropdownDisabled,
  dropdownName,
  dropdownOnChange,
  dropdownOptions,
  dropdownValue,
  id,
  label,
  labelClassName,
  numberDisabled,
  numberName,
  numberOnChange,
  numberValue,
  required,
}: {
  bsSize?: 'lg' | 'sm';
  className?: string;
  disabled?: boolean;
  dropdownDisabled?: boolean;
  dropdownName?: string;
  dropdownOnChange: any;
  dropdownOptions: JSX.Element[];
  dropdownValue: string;
  id?: string;
  label?: string;
  labelClassName?: string;
  numberDisabled?: boolean;
  numberName?: string;
  numberOnChange: any;
  numberValue: string;
  required: boolean;
}): JSX.Element => (
  <FormGroup className={`${labelClassName} number-with-dropdown-input-field`}>
    {label === undefined ? null : (
      <Label className="label" for={id}>
        {label}
      </Label>
    )}
    <InputGroup>
      <Input
        bsSize={bsSize}
        className={`${className} text-field`}
        disabled={disabled || numberDisabled}
        id={id}
        invalid={!isNumber(numberValue)}
        name={numberName}
        onChange={numberOnChange}
        required={required}
        type="text"
        value={numberValue}
      />
      <Input
        bsSize={bsSize}
        className={`${className} text-field`}
        disabled={disabled || dropdownDisabled}
        name={dropdownName}
        onChange={dropdownOnChange}
        required={required}
        type="select"
        value={dropdownValue}
      >
        {dropdownOptions}
      </Input>
    </InputGroup>
  </FormGroup>
);
