import React from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getContributionMarginPerUnit from 'services/products/calculations/getContributionMarginPerUnit';
import getContributionMarginRatio from 'services/products/calculations/getContributionMarginRatio';
import getContributionMarginTotal from 'services/products/calculations/getContributionMarginTotal';
import getExpensePerUnit from 'services/products/calculations/getExpensePerUnit';
import getExpenseTotal from 'services/products/calculations/getExpenseTotal';
import getSellingPricePerUnit from 'services/products/calculations/getSellingPricePerUnit';
import getSellingPriceTotal from 'services/products/calculations/getSellingPriceTotal';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import translations from 'services/translations/translations';

import ISubproduct from '../../ISubproduct';

const SubproductsSum = ({
  quantity,
  subproducts,
}: {
  quantity: number;
  subproducts: ISubproduct[];
}): JSX.Element => {
  const { t } = useTranslation([translations.offers]);

  const sellingPriceForUnit = getSellingPricePerUnit([], subproducts);
  const sellingPriceTotal = getSellingPriceTotal([], subproducts, quantity);
  const expensePerUnit = getExpensePerUnit([], subproducts);
  const expenseTotal = getExpenseTotal([], subproducts, quantity);
  const contributionMarginRatio = getContributionMarginRatio([], subproducts);

  const contributionMarginPerUnit = getContributionMarginPerUnit(
    [],
    subproducts
  );

  const contributionMarginTotal = getContributionMarginTotal(
    [],
    subproducts,
    quantity
  );

  return (
    <Row className="calculation-sum-row">
      <Col xs="4" />
      <Col>
        <NumberWithUnitInputField
          disabled
          label={t(`${translations.offers}:Selling price`)}
          unit="€"
          value={formatFloatForDisplay(sellingPriceForUnit)}
        />
      </Col>
      <Col>
        <NumberWithUnitInputField
          disabled
          label={t(`${translations.offers}:Expense`)}
          unit="€"
          value={formatFloatForDisplay(expensePerUnit)}
        />
      </Col>
      <Col>
        <NumberWithUnitInputField
          disabled
          label={`${t(`${translations.offers}:Contribution margin`)} €`}
          unit="€"
          value={formatFloatForDisplay(contributionMarginPerUnit)}
        />
      </Col>
      <Col>
        <NumberWithUnitInputField
          disabled
          label={`${t(`${translations.offers}:Selling price`)} ${t(
            `${translations.common}:total-short`
          )}`}
          unit="€"
          value={formatFloatForDisplay(sellingPriceTotal)}
        />
      </Col>
      <Col>
        <NumberWithUnitInputField
          disabled
          label={`${t(`${translations.offers}:Expense`)} ${t(
            `${translations.common}:total-short`
          )}`}
          unit="€"
          value={formatFloatForDisplay(expenseTotal)}
        />
      </Col>
      <Col>
        <NumberWithUnitInputField
          disabled
          label={`${t(`${translations.offers}:Contribution margin`)} ${t(
            `${translations.common}:total-short`
          )}`}
          unit="€"
          value={formatFloatForDisplay(contributionMarginTotal)}
        />
      </Col>
      <Col>
        <NumberWithUnitInputField
          disabled
          label={`${t(`${translations.offers}:Contribution margin`)} %`}
          unit="%"
          value={formatFloatForDisplay(contributionMarginRatio)}
        />
      </Col>
    </Row>
  );
};

export default SubproductsSum;
