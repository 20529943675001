import React from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';
import UpdatedField from 'components/layout/forms/UpdatedField';

import IConfigurationBasicInformation from '../IConfigurationBasicInformation';

const BasicInformation = ({
  configurationBasicInformation,
}: {
  configurationBasicInformation: IConfigurationBasicInformation;
}): JSX.Element => {
  const { t } = useTranslation([
    translations.common,
    translations.userManagement,
  ]);

  return (
    <Row>
      <Col xs={4}>
        <TextInputField
          disabled
          label={t(`${translations.userManagement}:Organization unit`)}
          required
          value={
            configurationBasicInformation.organizationUnit === null
              ? ''
              : configurationBasicInformation.organizationUnit.name
          }
        />
      </Col>
      <Col xs={4}>
        <UpdatedField
          label={t(`${translations.common}:Created`)}
          updated={configurationBasicInformation.created}
          updatedUser={configurationBasicInformation.createdUser}
        />
      </Col>
      <Col xs={4}>
        <UpdatedField
          label={t(`${translations.common}:Updated`)}
          updated={configurationBasicInformation.updated}
          updatedUser={configurationBasicInformation.updatedUser}
        />
      </Col>
    </Row>
  );
};

export default BasicInformation;
