import { tabInfo } from 'components/layout/tabs/BasicTabBar';
import translations from 'services/translations/translations';
import ProjectInfoIcon from '../../assets/information.svg';
import ContactIcon from '../../assets/contact_details.svg';
import MittakorttiIcon from '../../assets/mittakortti.svg';
import Offers from '../../assets/offers.svg';
import FilesIcon from '../../assets/files.svg';
import InvoicingIcon from '../../assets/invoicing.svg';
import NotesIcon from '../../assets/notes.svg';
import {
  BASIC_INFORMATION,
  CONTACTS,
  FILES,
  INVOICING,
  MEASUREMENT_CARD,
  NOTES,
  OFFERS,
} from './tabTypes';

const getTab = (tabName: string, t: any) => {
  let tab: tabInfo;
  switch (tabName) {
    case BASIC_INFORMATION:
      tab = {
        tabIcon: ProjectInfoIcon,
        tabLabel: t(`${translations.projects}:tabs.basic-information`),
        tabId: BASIC_INFORMATION,
      };
      break;
    case CONTACTS:
      tab = {
        tabIcon: ContactIcon,
        tabLabel: t(`${translations.projects}:tabs.contacts`),
        tabId: CONTACTS,
      };
      break;

    case FILES:
      tab = {
        tabIcon: FilesIcon,
        tabLabel: t(`${translations.projects}:tabs.files`),
        tabId: FILES,
      };
      break;

    case INVOICING:
      tab = {
        tabIcon: InvoicingIcon,
        tabLabel: t(`${translations.projects}:tabs.invoicing`),
        tabId: INVOICING,
      };
      break;
    case MEASUREMENT_CARD:
      tab = {
        tabIcon: MittakorttiIcon,
        tabLabel: t(`${translations.projects}:tabs.measurement-card`),
        tabId: MEASUREMENT_CARD,
      };
      break;
    case NOTES:
      tab = {
        tabIcon: NotesIcon,
        tabLabel: t(`${translations.projects}:tabs.notes`),
        tabId: NOTES,
      };
      break;
    case OFFERS:
      tab = {
        tabIcon: Offers,
        tabLabel: t(`${translations.projects}:tabs.offers`),
        tabId: OFFERS,
      };
      break;
    default:
      tab = {
        tabIcon: ContactIcon,
        tabLabel: t('default'),
        tabId: 'default',
      };
      break;
  }
  return tab;
};

const formatProjecTabs = (tabNames: string[], t: any): tabInfo[] =>
  tabNames.map((tabName) => getTab(tabName, t));

export default formatProjecTabs;
