const getInstallationPriceColumns = (t: any, translations: any) => {
  const colTitles = {
    name: t('Name'),
    category: t('Category'),
    price: t('Price value'),
  };

  const categoryFormatter = (cell: any) =>
    t(`${translations.products}:installationCategories.${cell}`);

  const priceFormatter = (cell: any, row: any) =>
    `${row.sellingPrice} €/${t(
      `${translations.common}:units.${row.sellingPriceUnit}`
    )}`;

  return [
    {
      dataField: 'name',
      text: colTitles.name,
    },
    {
      dataField: 'category',
      text: colTitles.category,
      formatter: categoryFormatter,
    },
    {
      dataField: 'price',
      text: colTitles.price,
      formatter: priceFormatter,
    },
  ];
};

export default getInstallationPriceColumns;
