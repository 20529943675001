/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Form, FormGroup, Input, Row } from 'reactstrap';

import SearchInput from 'components/layout/forms/SearchInput';
import BasicTable from 'components/layout/tables/BasicTable';
import PageContainer from 'components/layout/PageContainer';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import customerApi from '../../api/contacts';
import translations from '../../services/translations/translations';
import '../../style/contacts/Contacts.css';
import '../../style/layout/Table.css';

const Contacts = (): JSX.Element | null => {
  const { t } = useTranslation([translations.contacts, translations.common]);
  const [contactData, setContactData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [totalSize, setTotalSize] = useState(100);
  const [offset, setOffset] = useState(0);
  const [contactTypes, setContactTypes] = useState<string[]>([]);
  const [prevSelectedType, setPrevSelectedType] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const [prevSearchTerm, setPrevSearchTerm] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const history = useHistory();

  const getTypes = () => {
    customerApi.getTypes().then((contactTypeData) => {
      setContactTypes(contactTypeData);
    });
  };

  const colTitles = {
    name: t('Name'),
    id: t('Id'),
  };

  const columns = [
    {
      dataField: 'companyName',
      text: colTitles.name,
    },
    {
      dataField: 'id',
      text: colTitles.id,
    },
  ];

  const handleTableChange = (type: any, { page, sizePerPage }: any) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    setOffset((page - 1) * sizePerPage);
  };

  const handleChange = (e: any) => {
    setSelectedType(e.target.value);
  };

  const createContact = () => {
    history.push('/contacts/create');
  };

  const capitalizeFirstLetter = (word: string) => {
    let capitalized = word;
    if (capitalized != null) {
      capitalized = word[0].toUpperCase() + word.slice(1);
    }
    return capitalized;
  };

  useEffect(() => getTypes(), []);

  useEffect(() => {
    const getContacts = async () => {
      try {
        if (
          (searchTerm !== '' && searchTerm !== prevSearchTerm) ||
          (selectedType !== '' && selectedType !== prevSelectedType)
        ) {
          setPage(1);
          setOffset(0);
        }
        setPrevSearchTerm(searchTerm);
        setPrevSelectedType(selectedType);
        const list = await customerApi.get({
          limit: sizePerPage,
          offset,
          type: selectedType,
          search: searchTerm,
        });
        const count = await customerApi.count(searchTerm, selectedType);
        setContactData(list);
        setTotalSize(count.count);
      } catch (error) {
        // define action
      }
    };
    getContacts();
  }, [offset, searchTerm, sizePerPage, selectedType]);

  useEffect(() => {
    if (selectedRow !== undefined && contactData.length > 0) {
      history.push(`/contacts/${contactData[selectedRow || 0].id}`);
    }
  }, [history, selectedRow, contactData]);

  return (
    <PageContainer>
      <Row>
        <Col>
          <p className="header-text">{t('common:Contacts')}</p>
        </Col>

        <Col className="header-add-btn button-margin-top">
          <PrimaryButton onClick={() => createContact()}>
            {t('common:Add').toLocaleUpperCase()}
          </PrimaryButton>
        </Col>
      </Row>
      <div className="basic-table table-background-offers">
        <div className="search-type-row">
          <Form className="text-right-margin">
            <FormGroup>
              <Input
                className="text-field select-option"
                type="select"
                onChange={handleChange}
              >
                <option className="select-option" value="" key="all">
                  {t('common:All')}
                </option>
                {contactTypes.map((cType) => (
                  <option className="select-option" value={cType} key={cType}>
                    {t(capitalizeFirstLetter(cType))}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Form>
          <SearchInput setSearchTerms={setSearchTerm} />
        </div>
        <BasicTable
          columns={columns}
          data={contactData || []}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize}
          handleTableChange={handleTableChange}
          setSelectedRow={setSelectedRow}
          keyField="id"
        />
      </div>
    </PageContainer>
  );
};

export default Contacts;
