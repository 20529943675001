import IPrice from 'interfaces/products/IPrice';
import getTargetValue from 'services/forms/getTargetValue';

const updatePrice = (priceFormValues: IPrice[], e: any, index: number) => {
  const newValues = [...priceFormValues];
  const type = e.target.name;
  if (type === 'priceValue') {
    newValues[index].value = getTargetValue(e);
  } else if (type === 'priceType') {
    newValues[index].type = getTargetValue(e);
  } else if (type === 'priceUnit') {
    newValues[index].siUnit = getTargetValue(e);
  }
  return newValues;
};

export default updatePrice;
