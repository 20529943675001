import getInstallationTotalExpense from './getInstallationTotalExpense';
import getSubproductTotalExpense from './getSubproductTotalExpense';
import IInstallation from './IInstallation';
import ISubproduct from './ISubproduct';
import IDetail from './IDetail';

const getExpensePerUnit = (
  installations: IInstallation[],
  subproducts: ISubproduct[] | IDetail[]
): number => {
  const subproductsTotalExpenses = subproducts
    .map((subproduct): number => getSubproductTotalExpense(subproduct))
    .reduce((sum, currentValue) => sum + currentValue, 0);

  const installationsExpenses = installations
    .map((installation): number => getInstallationTotalExpense(installation))
    .reduce((sum, currentValue) => sum + currentValue, 0);

  return subproductsTotalExpenses + installationsExpenses;
};

export default getExpensePerUnit;
