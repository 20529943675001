import React from 'react';
import { connect } from 'react-redux';

import loadingIcon from 'assets/loading_light_green.svg';

import 'style/layout/Spinner.css';

const Spinner = ({ loading }: { loading: string[] }): JSX.Element | null => {
  if (loading.length === 0) {
    return null;
  }

  return (
    <div className="layout-spinner-layover">
      <div className="layout-spinner">
        <img alt="" src={loadingIcon} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.loading,
});

export default connect(mapStateToProps)(Spinner);
