import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import PageContainer from 'components/layout/PageContainer';
import PrimaryButtonDropdown from 'components/layout/buttons/PrimaryButtonDropdown';

import { fetchOfferTypes, fetchStatusTypes } from 'store/offers/actions';
import customerApi from 'api/contacts';
import BasicMaterialTable from '../layout/tables/BasicMaterialTable';

import apiOffers from '../../api/offers';
import pdfIcon from '../../assets/pdf_icon.svg';
import OfferStatusOptions from './src/offers/OfferStatusOptions';
import orderOfferStatuses from './src/offers/orderOfferStatuses';
import OfferTypeOptions from './src/offers/OfferTypeOptions';
import orderOfferTypes from './src/offers/orderOfferTypes';
import translations from '../../services/translations/translations';

import 'style/offers/Offers.css';
import openOfferPdf from './services/openOfferPdf';
import { fetchUsers } from '../../store/users/actions';
import IUserGet from '../../api/interfaces/users/IUserGet';

const Offers = ({
  offerStatusesList,
  fetchOfferStatusesList,
  fetchUsersList,
  offerTypesList,
  usersList,
  fetchOfferTypesList,
}: {
  offerStatusesList: string[];
  fetchOfferStatusesList: () => void;
  fetchUsersList: () => void;
  offerTypesList: string[];
  usersList: IUserGet[];
  fetchOfferTypesList: () => void;
}): JSX.Element | null => {
  const history = useHistory();
  const { t } = useTranslation([
    translations.offers,
    translations.common,
    translations.projects,
  ]);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [contactsFilter, setContactsFilter] = useState({});
  const OFFERS_FILTER_SESSION = 'offers-filter-local-storage';
  
  const getContactsList = async () => {
    try {
      return await customerApi.get({
        type: 'customer',
      });
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  useEffect(() => {
    fetchOfferStatusesList();
    fetchOfferTypesList();
    fetchUsersList();

    getContactsList().then((response: any) => {
      const aContactFilter: any = {};
      response.forEach((u: any) => {
        const key = u.id ? u.id : '';
        if (u.id == null) {
          return false;
        }
        aContactFilter[key] = `${u.companyName}`;
        setContactsFilter(aContactFilter);
        return true;
      });
    });
  }, []);

  useEffect(() => {
    if (selectedRow !== undefined) {
      history.push(`/offers/${selectedRow?.id}/${selectedRow?.type}`);
    }
  }, [history, selectedRow, selectedRow]);

  const getColumnFieldFromFilter = (query: any, fieldName: string): any => {
    return query.filters?.find((f: any) => f.column.field === fieldName)?.value;
  };

  const getOffers = async (query: any) => {
    try {
      const created = getColumnFieldFromFilter(query, 'created');
      const updated = getColumnFieldFromFilter(query, 'updated');
      const createdUserId = getColumnFieldFromFilter(query, 'createdUserId');
      const updatedUserId = getColumnFieldFromFilter(query, 'updatedUserId');
      const search = getColumnFieldFromFilter(query, 'targetName');
      const contactId = getColumnFieldFromFilter(query, 'contactId');
      const type = getColumnFieldFromFilter(query, 'type');
      const status = getColumnFieldFromFilter(query, 'status');

      const list = await apiOffers.get({
        created: created || null,
        createdUserId: createdUserId?.join(','),
        limit: query.pageSize,
        offset: query.page * query.pageSize,
        search,
        orderBy: query.orderBy?.field,
        orderByDirection: query.orderDirection || null,
        updated,
        updatedUserId: updatedUserId?.join(','),
        contactId: contactId?.join(','),
        type: type?.join(','),
        status: status?.join(','),
      });

      const count = await apiOffers.count({
        created: created || null,
        createdUserId: createdUserId?.join(','),
        search,
        orderBy: query.orderBy?.field,
        orderByDirection: query.orderDirection || null,
        updated,
        updatedUserId: updatedUserId?.join(','),
        contactId: contactId?.join(','),
        type: type?.join(','),
        status: status?.join(','),
      });

      return {
        data: list,
        page: query.page,
        totalCount: count.count,
      };
    } catch (error) {
      return {};
    }
  };

  const colTitles = {
    type: t(`${translations.offers}:Offer Type`),
    customer: t(`${translations.offers}:Customer`),
    targetName: t(`${translations.offers}:Project`),
    status: t(`${translations.projects}:status.Status`),
    created: t(`${translations.common}:Created`),
    createdBy: t(`${translations.common}:CreatedBy`),
    updated: t(`${translations.common}:Updated`),
    updatedBy: t(`${translations.common}:UpdatedBy`),
    pdf: '',
  };

  const handlePdfOpenEvent = async (e: any, row: any) => {
    e.preventDefault();
    e.stopPropagation();
    return openOfferPdf(row.id, row.type);
  };

  const imageFormatter = (cell: any, row: any) => (
    <img
      id={row.targetName}
      alt="pdf icon"
      src={pdfIcon}
      onClick={(e) => handlePdfOpenEvent(e, cell)}
    />
  );

  const typeFormatter = (cell: any, row: any) =>
    t(`${translations.offers}:types:${cell}`);

  const statusFormatter = (cell: any, row: any) =>
    t(`${translations.offers}:statuses:${cell}`);


  usersList.sort((u1, u2) => {
    const n1 = (u1.firstName+u1.lastName).toLowerCase();
    const n2 = (u2.firstName+u2.lastName).toLowerCase();
    return n1.localeCompare(n2);
  });

  const usersFilter: any = {};

  usersList.forEach((u) => {
    const key = u.id ? u.id : '';
    if (u.id == null) {
      return false;
    }
    usersFilter[key] = `${u.firstName} ${u.lastName}`;
    return true;
  });

  const getDefaultFilter = (field: string) => {
    const storedFilters = window.localStorage.getItem(OFFERS_FILTER_SESSION);
    if (storedFilters == null) {
      return null;
    }

    const lastUsedFilters = JSON.parse(storedFilters);
    if (lastUsedFilters == null || lastUsedFilters.length <= 0) {
      return null;
    }

    const result = lastUsedFilters.find(
      (lastUsedfilter: any) => lastUsedfilter.column.field === field
    );

    return result != null ? result.value : null;
  };

  const columns = [
    {
      field: 'targetName',
      title: colTitles.targetName,
      defaultFilter: getDefaultFilter('targetName'),
      cellStyle: {
        width: 310,
        minWidth: 310,
      },
      headerStyle: {
        width: 310,
        minWidth: 310,
      },
    },
    {
      field: 'contactId',
      title: colTitles.customer,
      defaultFilter: getDefaultFilter('contactId'),
      lookup: contactsFilter,
    },
    {
      field: 'type',
      title: colTitles.type,
      defaultFilter: getDefaultFilter('type'),
      lookup: t(`${translations.offers}:types`, { returnObjects: true }),
      render: (cell: any) => typeFormatter(cell.type, 'type'),
    },
    {
      field: 'status',
      title: colTitles.status,
      defaultFilter: getDefaultFilter('status'),
      lookup: t(`${translations.offers}:statuses`, { returnObjects: true }),
      render: (cell: any) => statusFormatter(cell.status, 'status'),
    },
    {
      field: 'created',
      title: colTitles.created,
      defaultFilter: getDefaultFilter('created'),
      type: 'date',
      dateSetting: { locale: 'fi-FI' },
    },
    {
      field: 'createdUserId',
      title: colTitles.createdBy,
      defaultFilter: getDefaultFilter('createdUserId'),
      lookup: usersFilter,
    },
    {
      field: 'updated',
      title: colTitles.updated,
      defaultFilter: getDefaultFilter('updated'),
      type: 'date',
      dateSetting: { locale: 'fi-FI' },
    },
    {
      field: 'updatedUserId',
      title: colTitles.updatedBy,
      defaultFilter: getDefaultFilter('updatedUserId'),
      lookup: usersFilter,
    },
    {
      field: 'pdf',
      title: colTitles.pdf,
      filtering: false,
      render: (cell: any) => imageFormatter(cell, 'pdf'),
    },
  ];

  const getOfferTypes = () => {
    if (offerTypesList.length !== 0) {
      return (
        <DropdownMenu right className="primary-dropdown-menu">
          {orderOfferTypes(offerTypesList).map((type, i) => {
            return (
              <DropdownItem
                onClick={() => history.push(`/offers/types/${i}/create`)}
                key={Math.random()}
              >
                {t(`${translations.offers}:types:${type}`).toUpperCase()}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      );
    }
    return <div />;
  };

  return (
    <PageContainer>
      <Row className="row-content">
        <Col xs={9}>
          <p className="header-text">{t(`${translations.common}:Offers`)}</p>
        </Col>
        <Col xs={3} className="header-add-btn">
          <PrimaryButtonDropdown
            className="add-button"
            text={t(`${translations.common}:Add`)}
          >
            {getOfferTypes()}
          </PrimaryButtonDropdown>
        </Col>
      </Row>
      <div className="basic-table table-background-offers">
        <Row>
          <Col>
            <BasicMaterialTable
              columns={columns}
              fetchData={getOffers}
              sizePerPage={sizePerPage}
              setSelectedRow={setSelectedRow}
              localStorageTag={OFFERS_FILTER_SESSION}
            />
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state: any) => ({
  offerTypesList: state.offers.offerTypes,
  offerStatusesList: state.offers.statusTypes,
  usersList: state.users.users,
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchOfferTypesList: () => dispatch(fetchOfferTypes()),
    fetchOfferStatusesList: () => dispatch(fetchStatusTypes()),
    fetchUsersList: () => dispatch(fetchUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
