import React, { useState, useEffect } from 'react';
import translations from 'services/translations/translations';
import { Form, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import getTargetValue from 'services/forms/getTargetValue';
import IUserGet from 'api/interfaces/users/IUserGetSingle';
import IUserPost from 'api/interfaces/users/IUserPost';
import IUserPut from 'api/interfaces/users/IUserPut';
import PageContainer from 'components/layout/PageContainer';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import TextInputField from 'components/layout/forms/TextInputField';
import { addConfirm, addMessage } from 'store/messages/actions';
import { fetchOrganizationUnits } from 'store/users/actions';

import IUserFormState from './src/user/IUserFormState';
import userApi from '../../api/userManagement';
import UserRoles from './src/user/UserRoles';
import { showOrganizationUnitsDropdown } from './showOrganizationUnitsDropdown';

import 'style/userManagement/User.css';

const User = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation([
    translations.common,
    translations.admin,
    translations.userManagement,
  ]);
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const [formState, setFormState] = useState<IUserFormState>({
    email: '',
    firstName: '',
    lastName: '',
    organizationUnit: 0,
    phone: '',
    roles: [],
  });
  const organizationUnits = useSelector(
    (state: any) => state.users.organizationUnits
  );

  useEffect(() => {
    dispatch(fetchOrganizationUnits());
  }, []);

  useEffect(() => {
    // If not undefined there is a user to modify
    if (userId !== undefined) {
      const submit = async () => {
        try {
          const result: IUserGet = await userApi.getUser(userId);
          if (result !== undefined) {
            setFormState({
              ...formState,
              email: result.email,
              firstName: result.firstName,
              lastName: result.lastName,
              organizationUnit:
                result.organizationUnit !== null
                  ? result.organizationUnit.id
                  : 0,
              phone: result.phone,
              roles: result.roles,
            });
          }
        } catch (error) {
          // define action
        }
      };
      submit().catch((e) => console.log(e));
    }
  }, [userId]);

  const goPreviousPage = () => {
    history.push('/users');
  };

  const handleFormState = (e: any) => {
    setFormState({
      ...formState,
      [e.target.name]: getTargetValue(e),
    });
  };

  const addUpdateUser = () => {
    if (
      formState.email === '' ||
      formState.firstName === '' ||
      formState.lastName === '' ||
      formState.phone === '' ||
      formState.organizationUnit === 0
    ) {
      dispatch(addMessage(t(`${translations.common}:Fill all fields`)));
    } else {
      const submit = async () => {
        let result;
        if (userId !== undefined) {
          try {
            const formattedUser: IUserPut = {
              email: formState.email,
              firstName: formState.firstName,
              lastName: formState.lastName,
              phone: formState.phone,
              roles: formState.roles,
              organizationUnitId: formState.organizationUnit,
            };
            result = await userApi.put(formattedUser, userId);
            dispatch(addMessage(t(`${translations.common}:Saving succeeded`)));
          } catch (error) {
            dispatch(addMessage(t(`${translations.common}:Saving failed`)));
          }
        } else {
          try {
            const formattedUser: IUserPost = {
              email: formState.email,
              firstName: formState.firstName,
              lastName: formState.lastName,
              roles: formState.roles,
              phone: formState.phone,
              organizationUnitId: formState.organizationUnit,
            };
            result = await userApi.post(formattedUser);
            dispatch(addMessage(t(`${translations.common}:Saving succeeded`)));
            history.push(`/users/${result.id}`);
          } catch (error) {
            dispatch(addMessage(t(`${translations.common}:Saving failed`)));
          }
        }
        if (result !== undefined && result.succeeded === true) {
          goPreviousPage();
        }
      };
      submit().catch((e) => console.log(e));
    }
  };

  const selectOrganizationUnit = (e: any) => {
    setFormState({
      ...formState,
      organizationUnit: parseInt(e.target.value, 10),
    });
  };

  const deleteUser = async () => {
    let deleteSuccess = false;
    try {
      const result = await userApi.delete(userId);
      deleteSuccess = result !== undefined && result.success === true;
    } catch (error) {
      console.log(error);
    }

    dispatch(
      addMessage(
        deleteSuccess
          ? t(`${translations.common}:Delete Success`)
          : t(`${translations.common}:Delete Failure`)
      )
    );
  };

  const triggerRemove = () => {
    dispatch(
      addConfirm({
        callback: deleteUser,
        header: t('common:Confirm Delete'),
        message: t(`${translations.userManagement}:Delete user confirmation`),
      })
    );
  };

  return (
    <PageContainer>
      <p className="header-text">{t('admin:Users')}</p>
      <div className="basic-form-container">
        <Form className="form-spacing">
          <Row>
            <Col>
              <TextInputField
                id="firstName"
                label={t(`${translations.common}:Firstname`)}
                name="firstName"
                onChange={handleFormState}
                required
                value={formState.firstName}
              />
            </Col>
            <Col>
              <TextInputField
                id="lastName"
                label={t(`${translations.common}:Lastname`)}
                name="lastName"
                onChange={handleFormState}
                required
                value={formState.lastName}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInputField
                id="email"
                label={t(`${translations.common}:Email`)}
                name="email"
                onChange={handleFormState}
                required
                value={formState.email}
              />
            </Col>
            <Col>
              <TextInputField
                id="phone"
                label={t(`${translations.common}:Phone`)}
                name="phone"
                onChange={handleFormState}
                required
                value={formState.phone}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <SelectInputFieldControlled
                id="orgUnits"
                label={t(`${translations.admin}:Organization Units`)}
                name="orgUnits"
                onChange={selectOrganizationUnit}
                options={showOrganizationUnitsDropdown(organizationUnits, t)}
                required
                value={formState.organizationUnit}
              />
            </Col>
          </Row>
          <UserRoles formState={formState} setFormState={setFormState} />
        </Form>
        <Row>
          <Col>
            <PrimaryButton onClick={goPreviousPage}>
              {t(`${translations.common}:Back`)}
            </PrimaryButton>

            {userId !== undefined ? (
              <PrimaryButton
                className="add-button-margin"
                onClick={triggerRemove}
              >
                {t(`${translations.common}:Remove`)}
              </PrimaryButton>
            ) : null}

            <PrimaryButton
              className="add-button-margin"
              onClick={addUpdateUser}
            >
              {t(`${translations.common}:Save`)}
            </PrimaryButton>
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
};

export default User;
