import IPrice from 'interfaces/products/IPrice';

import { EUR } from 'services/enums/currencies';

const getEmptyPrice = (
  priceFormValues: IPrice[],
  unitsList: string[],
  priceTypesList: string[]
) => {
  const firstUnused = priceTypesList.findIndex(
    (priceType) =>
      !priceFormValues.find(
        (priceFormValue) => priceFormValue.type === priceType
      )
  );
  const emptyValues: IPrice = {
    value: '0',
    siUnit: unitsList[0],
    type: priceTypesList[firstUnused],
    currency: EUR,
  };
  return [...priceFormValues, emptyValues];
};

export default getEmptyPrice;
