import React from 'react';
import { Route } from 'react-router-dom';

import { PROJECTS } from 'services/types/viewTypes';
import Project from 'components/projects/Project';
import Projects from 'components/projects/Projects';
import Permission from 'components/layout/Permission';

const projects = (): JSX.Element[] => [
  <Route exact key="projects-route" path="/projects">
    <Permission type={PROJECTS}>
      <Projects />
    </Permission>
  </Route>,
  <Route exact key="project-route" path="/projects/:projectId/:tab">
    <Permission type={PROJECTS}>
      <Project />
    </Permission>
  </Route>,
];

export default projects;
