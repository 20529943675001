import React from 'react';
import { Link } from 'react-router-dom';

import '../../style/layout/form/SidebarLink.css';

export default ({
  label,
  link,
  sidebarClose,
}: {
  label: string;
  link: string;
  sidebarClose: (open: boolean) => void;
}): JSX.Element => {
  return (
    <Link
      className="sidebar-link"
      to={link}
      onClick={() => sidebarClose(false)}
    >
      <p className="sidebar-link-text">{label}</p>
    </Link>
  );
};
