import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import MultiSelectDropdown from 'components/layout/forms/MultiSelectDropdown';
import NumberWithDropdownInputField from 'components/layout/forms/NumberWithDropdownInputField';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import TextInputField from 'components/layout/forms/TextInputField';
import IContact from 'interfaces/contacts/IContact';
import IGlassConfiguratorProduct from 'interfaces/products/IGlassConfiguratorProduct';
import capitalizeFirstLetter from 'services/forms/capitalizeFirstLetter';
import translations from 'services/translations/translations';
import { fetchCategories, fetchUnits } from 'store/products/actions';
import sortByTranslation from 'services/sort/sortByTranslation';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import { KG, M, MM, MM2 } from 'services/enums/units';
import getSupplierOptions from '../product/getSupplierOptions';
import { showCategoryOptions } from '../../ProductSelectOptions';

import 'style/products/GlassConfiguratorProductRows.css';
import apiFiles from '../../../../api/files';
import IFile from '../../../../interfaces/forms/IFile';
import PlaceImage from '../../configurations/PlaceImage';

const GlassConfiguratorProductRows = ({
  productsFormValues,
  updateRow,
  nameKey,
  handleCategories,
  suppliers,
  handleIds,
}: {
  productsFormValues: IGlassConfiguratorProduct[];
  updateRow: any;
  nameKey: string;
  handleCategories: any;
  suppliers: IContact[];
  handleIds: any;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common, translations.products]);
  const categories = useSelector((state: any) => state.products.categories);
  const units = useSelector((state: any) => state.products.units);
  const [file, setFile] = useState<IFile>();
  const unitsTranslationLocation = `${translations.common}:units`;
  const dispatch = useDispatch();
  const COL_SMALL_PADDING = 'col-small-padding';
  const LABEL_FOR_ROW = 10;
  const LARGE_COLUMN = 'large-column';
  const MEDIUM_COLUMN = 'medium-column';
  const SMALL_COLUMN = 'small-column';
  const EXTRA_SMALL_COLUMN = 'extra-small-column';

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchUnits());
  }, []);

  const onDrop = (acceptedFiles: any) => {
    const handleFileSave = async (loadingFile: any, index: number) => {
      await apiFiles.post(
        loadingFile,
        'glass-configurator-product'
      );
    };

    let acceptedFileIndex = 0;
    for (const acceptedFile of acceptedFiles) {
      handleFileSave(acceptedFile, acceptedFileIndex);
      acceptedFileIndex++;
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getUnitOptions = () =>
    [...units]
      .sort(sortByTranslation(t, unitsTranslationLocation))
      .filter((unit: string) => !unit.includes('/'))
      .map((unit: string) => (
        <option key={`unit-option-${unit}`} value={unit}>
          €/{t(`${unitsTranslationLocation}:${unit}`)}
        </option>
      ));

  const getLabelForRow = (text: string, index: number) =>
    index % LABEL_FOR_ROW === 0 ? text : undefined;

  const showIDFields = (index: number) =>
    [...productsFormValues[index].productIds]
      .sort((a, b) => {
        if (a.type === b.type) {
          return 0;
        }

        return a.type < b.type ? -1 : 1;
      })
      .map((x) => (
        <Col className={`${COL_SMALL_PADDING} ${EXTRA_SMALL_COLUMN}`}>
          <TextInputField
            bsSize="sm"
            value={x.value}
            label={getLabelForRow(
              t(`${translations.products}:${x.type}`),
              index
            )}
            required
            id={x.type + index + x.type}
            onChange={(e: any) => handleIds(e, index, x.type)}
          />
        </Col>
      ));

  const onSetImageId = (newImageId: string, index: number) => {
    const customEvent = {
      target: {
        value: newImageId,
        name: `${nameKey}ImageId`,
      },
    };
    updateRow(customEvent, index);
  };

  return (
    <>
      {productsFormValues.map((x, i) => (
        <>
          {i === 0 ? console.log(x) : null}
          <Row className="flex-nowrap">
            {showIDFields(i)}
            <Col className={`${COL_SMALL_PADDING} ${LARGE_COLUMN}`}>
              <TextInputField
                bsSize="sm"
                id={`${nameKey}Name`}
                label={getLabelForRow(t(`${translations.products}:Name`), i)}
                name={`${nameKey}Name`}
                onChange={(e: any) => updateRow(e, i)}
                required
                title={x.name}
                value={x.name}
              />
            </Col>
            <Col className={`${COL_SMALL_PADDING} ${MEDIUM_COLUMN}`}>
              <FormGroup>
                {i % LABEL_FOR_ROW === 0 ? (
                  <Label className="label">
                    {t(`${translations.products}:Category`)}
                  </Label>
                ) : null}
                <MultiSelectDropdown
                  className="category-field no-padding"
                  placeholder={t(`${translations.products}:Select`)}
                  required
                  name={`${nameKey}Category`}
                  value={x.categories.map((category) => ({
                    label: t(`${translations.products}:categories:${category}`),
                    value: category,
                  }))}
                  onChange={(e: any) => handleCategories(e, i)}
                  options={
                    categories
                      ? showCategoryOptions(categories, translations, t)
                      : ''
                  }
                />
              </FormGroup>
            </Col>
            <Col className={`${COL_SMALL_PADDING} ${EXTRA_SMALL_COLUMN}`}>
              <NumberWithUnitInputField
                bsSize="sm"
                value={x.kgM === null ? '' : x.kgM}
                label={getLabelForRow(
                  t(`${translations.products}:measurementTypes.kg-in-m`),
                  i
                )}
                required
                id={`${nameKey}kg-in-m`}
                name={`${nameKey}kg-in-m`}
                onChange={(e: any) => updateRow(e, i)}
                unit={t(`${translations.common}:units:${KG}`)}
              />
            </Col>
            <Col className={`${COL_SMALL_PADDING} unit-${MEDIUM_COLUMN}`}>
              <NumberWithDropdownInputField
                bsSize="sm"
                dropdownName={`${nameKey}PurchasePriceUnit`}
                dropdownOnChange={(e: any) => updateRow(e, i)}
                dropdownOptions={getUnitOptions()}
                dropdownValue={x.purchaseUnit}
                id={`${nameKey}PurchasePrice`}
                numberName={`${nameKey}PurchasePrice`}
                numberOnChange={(e: any) => updateRow(e, i)}
                numberValue={x.purchasePrice.toString()}
                required
                label={getLabelForRow(
                  t(`${translations.products}:priceTypes.purchase-price`),
                  i
                )}
              />
            </Col>
            <Col className={`${COL_SMALL_PADDING} ${MEDIUM_COLUMN}`}>
              <SelectInputFieldControlled
                bsSize="sm"
                label={getLabelForRow(
                  t(`${translations.products}:Supplier`),
                  i
                )}
                required
                id={`${nameKey}SupplierId`}
                name={`${nameKey}SupplierId`}
                value={x.supplierId}
                onChange={(e: any) => updateRow(e, i)}
                options={getSupplierOptions(suppliers)}
              />
            </Col>
            <Col className={`${COL_SMALL_PADDING} ${SMALL_COLUMN}`}>
              <NumberWithUnitInputField
                bsSize="sm"
                value={x.painting1 === null ? '' : x.painting1}
                label={getLabelForRow(
                  t(`${translations.products}:priceTypes.painting-1`),
                  i
                )}
                required
                id={`${nameKey}Painting-1`}
                name={`${nameKey}Painting-1`}
                onChange={(e: any) => updateRow(e, i)}
                unit={`€/${t(`${translations.common}:units:${M}`)}`}
              />
            </Col>
            <Col className={`${COL_SMALL_PADDING} ${SMALL_COLUMN}`}>
              <NumberWithUnitInputField
                bsSize="sm"
                value={x.painting2 === null ? '' : x.painting2}
                label={getLabelForRow(
                  t(`${translations.products}:priceTypes.painting-2`),
                  i
                )}
                required
                id={`${nameKey}Painting-2`}
                name={`${nameKey}Painting-2`}
                onChange={(e: any) => updateRow(e, i)}
                unit={`€/${t(`${translations.common}:units:${M}`)}`}
              />
            </Col>
            <Col className={`${COL_SMALL_PADDING} ${SMALL_COLUMN}`}>
              <NumberWithUnitInputField
                bsSize="sm"
                value={x.painting3 === null ? '' : x.painting3}
                label={getLabelForRow(
                  t(`${translations.products}:priceTypes.painting-3`),
                  i
                )}
                required
                id={`${nameKey}Painting-3`}
                name={`${nameKey}Painting-3`}
                onChange={(e: any) => updateRow(e, i)}
                unit={`€/${t(`${translations.common}:units:${M}`)}`}
              />
            </Col>
            <Col className={`${COL_SMALL_PADDING} ${SMALL_COLUMN}`}>
              <NumberWithUnitInputField
                bsSize="sm"
                value={x.painting4 === null ? '' : x.painting4}
                label={getLabelForRow(
                  t(`${translations.products}:priceTypes.painting-4`),
                  i
                )}
                required
                id={`${nameKey}Painting-4`}
                name={`${nameKey}Painting-4`}
                onChange={(e: any) => updateRow(e, i)}
                unit={`€/${t(`${translations.common}:units:${M}`)}`}
              />
            </Col>
            <Col className={`${COL_SMALL_PADDING} ${EXTRA_SMALL_COLUMN}`}>
              <NumberWithUnitInputField
                bsSize="sm"
                value={x.length === null ? '' : x.length}
                label={getLabelForRow(
                  capitalizeFirstLetter(
                    t(`${translations.products}:measurementTypes.length`)
                  ),
                  i
                )}
                required
                id={`${nameKey}Length`}
                name={`${nameKey}Length`}
                onChange={(e: any) => updateRow(e, i)}
                unit={t(`${translations.common}:units:${M}`)}
              />
            </Col>
            <Col className={`${COL_SMALL_PADDING} ${EXTRA_SMALL_COLUMN}`}>
              <NumberWithUnitInputField
                bsSize="sm"
                value={x.paintingArea === null ? '' : x.paintingArea}
                label={getLabelForRow(
                  t(`${translations.products}:Painting Area`),
                  i
                )}
                required
                id={`${nameKey}PaintingArea`}
                name={`${nameKey}PaintingArea`}
                onChange={(e: any) => updateRow(e, i)}
                unit={t(`${translations.common}:units:${MM}`)}
              />
            </Col>
            <Col className={`${COL_SMALL_PADDING} ${EXTRA_SMALL_COLUMN}`}>
              <NumberWithUnitInputField
                bsSize="sm"
                value={x.waste === null ? '' : x.waste}
                label={getLabelForRow(t(`${translations.products}:Waste`), i)}
                required
                id={`${nameKey}Waste`}
                name={`${nameKey}Waste`}
                onChange={(e: any) => updateRow(e, i)}
                unit={t(`${translations.common}:units:${MM}`)}
              />
            </Col>
            <Col
              className={`${COL_SMALL_PADDING} ${EXTRA_SMALL_COLUMN} place-image-container`}
            >
              {i % LABEL_FOR_ROW === 0 ? (
                <Label className="label">
                  {t(`${translations.common}:Image`)}
                </Label>
              ) : null}
              <PlaceImage
                name={`${nameKey}ImageId`}
                imageId={x.imageId}
                setImageId={(newImageId: string) => onSetImageId(newImageId, i)}
              />
            </Col>
            <div className="clearfix" />
          </Row>
        </>
      ))}
    </>
  );
};

export default GlassConfiguratorProductRows;
