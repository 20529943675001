import emptyStringToNull from 'services/emptyStringsToNull';
import IOfferProductInstallation from 'api/interfaces/offers/IOfferProductInstallation';
import IOfferProductPost from 'api/interfaces/offers/IOfferProductPost';
import IOfferSubproductPost from 'api/interfaces/offers/IOfferSubproductPost';
import IOfferProductVersionPost from 'api/interfaces/offers/IOfferProductVersionPost';
import numericStringToFloat from 'services/numbers/numericStringToFloat';

import IFormState from './IFormState';
import ICalculationReview from './ICalculationReview';
import IOfferProductCalculationReview from '../../../../api/interfaces/offers/IOfferProductCalculationReview';

export default (formState: IFormState): IOfferProductPost => ({
  group: formState.productGroup,
  memo: formState.memo,
  name: formState.name,
  position: formState.position,
  quantity: numericStringToFloat(formState.quantity),
  unit: formState.unit,
  versions: formState.versions.map(
    (version): IOfferProductVersionPost => ({
      averageHourlyEarnings: numericStringToFloat(
        version.averageHourlyEarnings
      ),
      id: version.id,
      calculationReviews:
        version.calculationReviews != null
          ? version.calculationReviews.map(
              (review): IOfferProductCalculationReview => ({
                quantity: numericStringToFloat(review.quantity),
                quantityUnit: emptyStringToNull(review.quantityUnit),
              })
            )
          : [],
      installations: version.installations.map(
        (installation): IOfferProductInstallation => ({
          category: installation.category,
          customInstallation: installation.customInstallation,
          installationId:
            installation.installation === null
              ? null
              : parseInt(installation.installation.value, 10),
          installationPrice: numericStringToFloat(
            installation.installationPrice
          ),
          installationPriceUnit: emptyStringToNull(
            installation.installationPriceUnit
          ),
          isCustomInstallation: installation.isCustomInstallation,
          quantity: numericStringToFloat(installation.quantity),
          quantityUnit: emptyStringToNull(installation.quantityUnit),
          sellingPrice: numericStringToFloat(installation.sellingPrice),
          sellingPriceUnit: emptyStringToNull(installation.sellingPriceUnit),
          socialSecurityCostPercentage: numericStringToFloat(
            installation.socialSecurityCostPercentage
          ),
        })
      ),
      name: version.name,
      isPrimary: version.isPrimary,
      offerText: version.offerText,
      showInOfferPdf: version.showInOfferPdf,
      subproducts: version.subproducts.map(
        (subproduct): IOfferSubproductPost => ({
          calculatePriceFromDetails: subproduct.calculatePriceFromDetails,
          category: subproduct.category,
          customSubproduct: subproduct.customSubproduct,
          details: subproduct.details.map((detail) => ({
            customProductName: detail.customProductName,
            expense: numericStringToFloat(detail.expense),
            expenseUnit: detail.expenseUnit === '' ? null : detail.expenseUnit,
            hasCustomProductName: detail.hasCustomProductName,
            productId:
              detail.product === null
                ? null
                : parseInt(detail.product.value, 10),
            quantity: numericStringToFloat(detail.quantity),
            quantityUnit: detail.quantityUnit,
            sellingPrice: numericStringToFloat(detail.sellingPrice),
            sellingPriceUnit:
              detail.sellingPriceUnit === '' ? null : detail.sellingPriceUnit,
            wastePercentage: numericStringToFloat(detail.wastePercentage),
          })),
          expense: numericStringToFloat(subproduct.expense),
          expenseUnit:
            subproduct.expenseUnit === '' ? null : subproduct.expenseUnit,
          hideInOfferPdf: subproduct.hideInPfferPdf,
          isCustomSubproduct: subproduct.isCustomSubproduct,
          productId:
            subproduct.product === null
              ? null
              : parseInt(subproduct.product.value, 10),
          quantity: numericStringToFloat(subproduct.quantity),
          quantityUnit: subproduct.quantityUnit,
          sellingPrice: numericStringToFloat(subproduct.sellingPrice),
          sellingPriceUnit:
            subproduct.sellingPriceUnit === ''
              ? null
              : subproduct.sellingPriceUnit,
          wastePercentage: numericStringToFloat(subproduct.wastePercentage),
        })
      ),
    })
  ),
});
