import ISettingsGet from 'api/interfaces/common/ISettingsGet';
import { AccountInfo } from '@azure/msal-browser';
import store from '../../store/store';

export const msalConfigCreator = (
  settings: ISettingsGet
): {
  auth: {
    clientId: string;
    authority: string;
    redirectUri: string;
  };
  cache: {
    cacheLocation: string;
    storeAuthStateInCookie: boolean;
  };
} => {
  const baseUrl = settings === null ? '' : settings.baseUrl;
  const azureAd = settings === null ? null : settings.azureAd;
  const applicationId = azureAd === null ? '' : azureAd.applicationId;
  const tenantId = azureAd === null ? '' : azureAd.tenantId;

  return {
    auth: {
      clientId: applicationId,
      authority: `https://login.microsoftonline.com/${tenantId}`,
      redirectUri: baseUrl,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  };
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const silentLoginRequest = (
  account: AccountInfo
): {
  scopes: string[];
  redirectUri: string;
  forceRefresh: boolean;
  account: AccountInfo;
} => {
  const { settings } = store.getState();
  return {
    forceRefresh: true,
    scopes: ['User.Read'],
    redirectUri: settings.settings === null ? '' : settings.settings.adLoginUrl,
    account,
  };
};
