import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import IProductInstallationCategory from 'api/interfaces/products/IProductInstallationCategory';
import { OTHER_EXPENSE } from 'components/offers/services/installationTypes';
import { CATEGORY } from 'services/products/productConfigurationAttributeTypes';
import { INSTALLATION } from 'services/products/productConfigurationItemTypes';

const isOtherExpenseItem = (
  item: IProductConfigurationItem,
  installationCategories: IProductInstallationCategory[]
): boolean => {
  if (item.type !== INSTALLATION) {
    return false;
  }

  const categoryAttribute = item.attributes.find(
    (attribute) => attribute.type === CATEGORY
  );

  if (categoryAttribute === undefined) {
    return false;
  }

  if (categoryAttribute.value === null) {
    return false;
  }

  return (
    installationCategories.find(
      (installationCategory) =>
        installationCategory.type === OTHER_EXPENSE &&
        installationCategory.category === categoryAttribute.value
    ) !== undefined
  );
};

export default isOtherExpenseItem;
