import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import translations from 'services/translations/translations';

import { NAME } from 'services/products/productConfigurationAttributeTypes';

import {
  PRODUCT,
  PRODUCT_SELECT,
  SELECT,
  SELECT_OPTION,
} from 'services/products/productConfigurationItemTypes';

const getEditItemTitle = (
  item: IProductConfigurationItem | null,
  t: any
): string => {
  if (item === null) {
    return '';
  }

  const nameAttribute =
    item === null
      ? undefined
      : item.attributes.find((attribute) => attribute.type === NAME);

  const parentName =
    nameAttribute === undefined ? '' : `${nameAttribute.value}: `;

  if (item.type === PRODUCT) {
    return `${parentName}${t(
      `${translations.productConfigurations}:Edit product`
    )}`;
  }

  if (item.type === PRODUCT_SELECT) {
    return t(`${translations.productConfigurations}:Edit product select`);
  }

  if (item.type === SELECT) {
    return t(`${translations.productConfigurations}:Edit select`);
  }

  if (item.type === SELECT_OPTION) {
    return `${parentName}${t(
      `${translations.productConfigurations}:Edit option`
    )}`;
  }

  return '';
};

export default getEditItemTitle;
