import React, { useEffect } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import IRole from 'interfaces/users/IRole';
import translations from 'services/translations/translations';
import { fetchRoles } from 'store/users/actions';

import IUserFormState from './IUserFormState';

const UserRoles = ({
  formState,
  setFormState,
}: {
  formState: IUserFormState;
  setFormState: (formState: IUserFormState) => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const roles = useSelector((state: any) => state.users.roles);
  const { t } = useTranslation([translations.admin]);

  useEffect(() => {
    dispatch(fetchRoles());
  }, []);

  const handleMultiSelectChange = (e: any) => {
    const { checked: value, name: selected } = e.target;
    const { roles: cTypes } = formState;

    if (value === true) {
      cTypes.push(selected);
    } else {
      const typeIndex = cTypes.findIndex((curr) => curr === selected);
      if (typeIndex >= 0) {
        cTypes.splice(typeIndex, 1);
      }
    }

    setFormState({
      ...formState,
      roles: cTypes,
    });
  };

  const typeIncluded = (type: IRole) => {
    return formState.roles.findIndex((id) => id === type.name) !== -1;
  };

  return (
    <Row>
      <Col>
        <Label className="label">{t(`${translations.admin}:Roles`)}</Label>
        {roles.map((type: any) => (
          <FormGroup check key={type.name}>
            <Label className="contact-type-label" check>
              <Input
                className="type-checkmark"
                name={type.name}
                type="checkbox"
                checked={typeIncluded(type)}
                onChange={handleMultiSelectChange}
              />
              {t(type.name)}
            </Label>
          </FormGroup>
        ))}
      </Col>
    </Row>
  );
};

export default UserRoles;
