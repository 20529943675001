import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import IOffer from 'api/interfaces/offers/IOfferGet';
import BasicTable from 'components/layout/tables/BasicTable';
import translations from 'services/translations/translations';
import apiOffers from '../../api/offers';

const ProjectOffersTab = (): JSX.Element | null => {
  const history = useHistory();
  const { projectId } = useParams();
  const { t } = useTranslation([translations.offers, translations.projects]);
  const [offers, setOffers] = useState<IOffer[]>();
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [offset, setOffset] = useState(0);
  const [totalSize, setTotalSize] = useState<number>(100);
  const [selectedRow, setSelectedRow] = useState<number>();
  const selectedOffer =
    offers !== undefined && selectedRow !== undefined
      ? offers[selectedRow]
      : undefined;

  useEffect(() => {
    if (selectedRow !== undefined) {
      history.push(`/offers/${selectedOffer?.id}/${selectedOffer?.type}`);
    }
  }, [history, selectedOffer, selectedRow]);

  useEffect(() => {
    const getOffers = async () => {
      try {
        const list = await apiOffers.get({
          limit: sizePerPage,
          offset,
          projectId,
        });
        const count = await apiOffers.count({});
        setOffers(list);
        setTotalSize(count.count);
      } catch (error) {
        // define action
      }
    };
    getOffers().catch((e) => console.log(e));
  }, [offset, sizePerPage, projectId]);

  const colTitles = {
    type: t(`${translations.offers}:Offer Type`),
    targetName: t(`${translations.offers}:Project`),
    status: t(`${translations.projects}:status.Status`),
  };

  const typeFormatter = (cell: any, row: any) =>
    t(`${translations.offers}:types:${cell}`);

  const statusFormatter = (cell: any, row: any) =>
    t(`${translations.offers}:${cell}`);

  const columns = [
    {
      dataField: 'type',
      text: colTitles.type,
      formatter: typeFormatter,
    },
    {
      dataField: 'targetName',
      text: colTitles.targetName,
    },
    {
      dataField: 'status',
      text: colTitles.status,
      formatter: statusFormatter,
    },
  ];

  const handleTableChange = (
    type: any,
    { pageTemp, sizePerPage: sizePerPageTemp }: any
  ) => {
    setPage(page);
    setSizePerPage(sizePerPageTemp);
    setOffset((page - 1) * sizePerPageTemp);
  };

  return (
    <div>
      <Row>
        <Col>
          <div className="header-text title-spacing">
            {t(`${translations.common}:Offers`).toUpperCase()}
          </div>
        </Col>
      </Row>
      <div className="basic-table table-background-offers">
        <Row>
          <Col>
            <BasicTable
              columns={columns}
              data={offers}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              handleTableChange={handleTableChange}
              setSelectedRow={setSelectedRow}
              keyField="targetName"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProjectOffersTab;
