import isNumber from 'services/numbers/isNumber';
import IDetail from '../IDetail';

import IInstallation from '../IInstallation';
import ISubproduct from '../ISubproduct';
import IVersion from '../IVersion';

const allInstallationFieldsAreFilled = (installation: IInstallation) =>
  installation.category !== '' &&
  installation.socialSecurityCostPercentage !== '' &&
  isNumber(installation.socialSecurityCostPercentage) &&
  installation.installationPrice !== '' &&
  isNumber(installation.installationPrice) &&
  installation.sellingPrice !== '' &&
  isNumber(installation.sellingPrice);

const validExpense = (subproduct: IDetail | ISubproduct) =>
  subproduct.expense !== '' && isNumber(subproduct.expense);

const validExpenseUnit = (subproduct: IDetail | ISubproduct) =>
  subproduct.expenseUnit === '' ||
  subproduct.expenseUnit === subproduct.quantityUnit;

const validQuantity = (quantity: string) =>
  quantity !== '' && isNumber(quantity);

const validSellingPrice = (subproduct: IDetail | ISubproduct) =>
  subproduct.sellingPrice !== '' && isNumber(subproduct.sellingPrice);

const validSellingPriceUnit = (subproduct: IDetail | ISubproduct) =>
  subproduct.sellingPriceUnit === '' ||
  subproduct.sellingPriceUnit === subproduct.quantityUnit;

const validSubproductProduct = (subproduct: ISubproduct) =>
  (subproduct.isCustomSubproduct && subproduct.customSubproduct !== '') ||
  (!subproduct.isCustomSubproduct && subproduct.product !== null);

const validWastePercentage = (subproduct: IDetail | ISubproduct) =>
  subproduct.wastePercentage !== '' && isNumber(subproduct.wastePercentage);

const allSubproductDetailFieldsAreFilled = (detail: IDetail) =>
  validExpense(detail) &&
  validExpenseUnit(detail) &&
  (detail.product !== null ||
    (detail.hasCustomProductName === true &&
      detail.customProductName !== '' &&
      detail.customProductName !== null)) &&
  detail.quantityUnit !== '' &&
  validQuantity(detail.quantity) &&
  validSellingPrice(detail) &&
  validSellingPriceUnit(detail) &&
  validWastePercentage(detail);

const allSubproductFieldsAreFilled = (subproduct: ISubproduct) => {
  for (const detail of subproduct.details) {
    if (!allSubproductDetailFieldsAreFilled(detail)) {
      return false;
    }
  }

  return (
    subproduct.category !== '' &&
    subproduct.quantityUnit !== '' &&
    validExpense(subproduct) &&
    validExpenseUnit(subproduct) &&
    validQuantity(subproduct.quantity) &&
    validSellingPrice(subproduct) &&
    validSellingPriceUnit(subproduct) &&
    validSubproductProduct(subproduct) &&
    validWastePercentage(subproduct)
  );
};

export default (versions: IVersion[]): boolean => {
  for (const version of versions) {
    for (const installation of version.installations) {
      if (!allInstallationFieldsAreFilled(installation)) {
        return false;
      }
    }

    for (const subproduct of version.subproducts) {
      if (!allSubproductFieldsAreFilled(subproduct)) {
        return false;
      }
    }
  }

  return true;
};
