import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import InlookLogo from '../../assets/InlookLogoWhite.png';
import Sidebar from './Sidebar';
import UserLogo from '../../assets/GenericUser.svg';
import '../../style/layout/NavMenu.css';

const NavMenu = ({ currentUser }: { currentUser: any }): JSX.Element | null => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const headerText = useSelector((state: any) => state.navMenu.text);

  return (
    <header>
      {sidebarOpen ? (
        <Sidebar isOpen={sidebarOpen} closeFunction={setSidebarOpen} />
      ) : null}
      <div className="space-reserver" />
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 navbar-color navbar-size fixed-top"
        light
        style={{ padding: 0 }}
      >
        <Container className="full-container">
          <Nav
            className="navmenu-hamburger-button"
            onClick={() => {
              setSidebarOpen(true);
            }}
          >
            <p className="navmenu-hamburger-text">☰</p>
          </Nav>
          <NavbarBrand tag={Link} to="/" style={{ padding: 0 }}>
            <img
              alt="Inlook"
              height="41px"
              src={InlookLogo}
              style={{ float: 'left' }}
              width="200px"
            />
          </NavbarBrand>
          <Collapse className="collapse-section" isOpen navbar>
            <Nav>
              <NavItem>
                <div className="nav-title">
                  <h2>{headerText}</h2>
                </div>
              </NavItem>
            </Nav>
          </Collapse>
          <Collapse isOpen navbar>
            <Nav className="mr-auto" navbar />
            <Nav pullright="true">
              <NavItem>
                <Link className="account-name-container" to="NotYetImplemented">
                  <img src={UserLogo} alt="" className="account-logo" />
                  {currentUser != null ? (
                    <div className="account-name">
                      {currentUser.firstName} {currentUser.lastName}
                    </div>
                  ) : null}
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.users.current,
});

export default connect(mapStateToProps)(NavMenu);
