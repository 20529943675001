import IPosition from 'interfaces/projects/IPosition';

const getPosition = (position: IPosition): IPosition => ({
  id: position.id,
  name: position.name,
  reference: position.name,
});

const getPositions = (positions: IPosition[]): IPosition[] =>
  positions.map((position) => getPosition(position));

export default getPositions;
