import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
  Button,
} from 'reactstrap';

import IUserGet from 'api/interfaces/users/IUserGetSingle';
import apiProjects from 'api/projects';
import TextInputFieldNoLabel from 'components/layout/forms/TextInputFieldNoLabel';
import MultiSelectDropdown from 'components/layout/forms/MultiSelectDropdown';
import IWorkGroupPost from 'interfaces/projects/IWorkGroupPost';
import translations from 'services/translations/translations';
import { fetchUsers } from 'store/users/actions';
import showUserOptions from '../basicInfo/showUserOptions';

import 'style/projects/projectMeasurementTab/addMeasurement/ModalCreateWorkGroup.css';

const ModalCreateWorkGroup = ({
  showing,
  setShowing,
  positionId,
  projectId,
  userList,
  fetchUserList,
  updateWorkGroupList,
}: {
  showing: boolean;
  setShowing: (showing: boolean) => void;
  positionId: number;
  projectId: number;
  userList: IUserGet[];
  fetchUserList: () => void;
  updateWorkGroupList: () => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common, translations.projects]);
  const toggle = () => setShowing(!showing);
  const [selectedUsers, setSelectedUsers] = useState<
    { label: string; value: string }[]
  >([]);
  const [workGroupName, setWorkGroupName] = useState<string>('');

  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);

  const handleUserIds = (e: any) => {
    setSelectedUsers(e);
  };

  const submitWorkGroup = () => {
    const wg: IWorkGroupPost = {
      id: null,
      name: workGroupName,
      positions: [positionId],
      users: selectedUsers.map((u) => u.value),
    };
    const saveWG = async () => {
      const result = await apiProjects.workGroups.post(projectId, wg);
      updateWorkGroupList();
      setShowing(false);
    };
    saveWG().catch((e) => console.log(e));
  };

  return (
    <>
      <Modal size="lg" isOpen={showing} toggle={toggle} centered>
        <ModalHeader className="modal-header" toggle={toggle} />
        <ModalBody className="basic-table">
          <p className="label">{t(`${translations.projects}:Workgroup`)}</p>
          <Row>
            <Col>
              <TextInputFieldNoLabel
                value={workGroupName}
                required
                id="workGroupName"
                name="workGroupName"
                onChange={(e: any) => setWorkGroupName(e.target.value)}
              />
            </Col>
            <Col>
              <MultiSelectDropdown
                placeholder={t(`${translations.common}:Select`)}
                required
                name="users"
                value={selectedUsers}
                onChange={handleUserIds}
                options={showUserOptions(userList)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => submitWorkGroup()} className="modal-button">
            {t(`${translations.common}:Save`)}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userList: state.users.users,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserList: () => dispatch(fetchUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCreateWorkGroup);
