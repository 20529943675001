import React from 'react';

import getTargetValue from 'services/forms/getTargetValue';
import IProductConfigurationAttribute from 'interfaces/products/IProductConfigurationAttribute';
import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';

import {
  NAME,
  PRODUCT_ID,
} from 'services/products/productConfigurationAttributeTypes';

import { PRODUCT } from 'services/products/productConfigurationItemTypes';

import ISelection from './ISelection';

const ProductSelect = ({
  item,
  selections,
  setSelection,
}: {
  item: IProductConfigurationItem;
  selections: ISelection[];
  setSelection: (
    selection: null | number,
    item: IProductConfigurationItem
  ) => void;
}): JSX.Element => {
  const nameAttribute = item.attributes.find(
    (attribute: IProductConfigurationAttribute) => attribute.type === NAME
  );

  const name = nameAttribute === undefined ? '' : nameAttribute.value;

  const currentSelection = selections.find(
    (selection: ISelection) => selection.id === item.id
  );

  const currentValue =
    currentSelection === undefined ? null : currentSelection.selection;

  const getOptions = () => [
    <option key={`item-${item.id}-option-empty`} value="">
      -
    </option>,
    ...item.children
      .filter((child: IProductConfigurationItem) => child.type === PRODUCT)
      .map((product: IProductConfigurationItem) => {
        const productNameAttribute = product.attributes.find(
          (attribute: IProductConfigurationAttribute) =>
            attribute.type === PRODUCT_ID
        );

        const productName =
          productNameAttribute === undefined
            ? ''
            : productNameAttribute.displayValue;

        return (
          <option
            key={`item-${item.id}-option-${product.id}`}
            value={product.id}
          >
            {productName}
          </option>
        );
      }),
  ];

  return (
    <SelectInputFieldControlled
      id={`configuration-product-select-${item.id}`}
      label={name === null ? '' : name}
      onChange={(e: any) => {
        const value = getTargetValue(e);
        setSelection(value === '' ? null : parseInt(value, 10), item);
      }}
      options={getOptions()}
      required
      value={currentValue === null ? '' : currentValue}
    />
  );
};

export default ProductSelect;
