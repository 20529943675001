import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import 'style/layout/Table.css';

const Subtable = ({
  columns,
  data,
  keyField,
}: {
  columns: any;
  data: any;
  keyField: any;
}): JSX.Element | null => {
  return (
    <BootstrapTable
      keyField={keyField}
      headerWrapperClasses="table-header subtable-no-header"
      bodyClasses="table-body"
      rowClasses="row-body subtable-row"
      bordered={false}
      columns={columns}
      data={data || []}
      remote
    />
  );
};

export default Subtable;
