import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DropdownMenu, DropdownItem } from 'reactstrap';

import ICurrentUser from 'api/interfaces/users/ICurrentUser';
import BasicTableNoPagination from 'components/layout/tables/BasicTableNoPagination';
import BasicTabBar, { tabInfo } from 'components/layout/tabs/BasicTabBar';
import PageContainer from 'components/layout/PageContainer';
import PrimaryButtonDropdown from 'components/layout/buttons/PrimaryButtonDropdown';
import { ROLES, USERS } from 'services/types/viewTypes';

import UserApi from '../../api/userManagement';
import translations from '../../services/translations/translations';
import UsersIcon from '../../assets/users_icon.svg';
import UserGroupsIcon from '../../assets/userGroups_icon.svg';
import RolesIcon from '../../assets/roles_icon.svg';
import { fetchUser as fetchUserAction } from '../../store/users/actions';

import '../../style/userManagement/UserManagement.css';

const Admin = ({
  currentUser,
  fetchUser,
}: {
  currentUser: ICurrentUser;
  fetchUser: () => void;
}): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation([translations.admin, translations.common]);
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const [listData, setListData] = useState<any[]>([]);
  const userTitles = {
    firstname: t(`${translations.common}:Firstname`),
    lastname: t(`${translations.common}:Lastname`),
    email: t(`${translations.common}:Email`),
  };
  const orgUnitTitles = {
    name: t(`${translations.common}:Name`),
  };
  const roleTitles = {
    name: t(`${translations.common}:Name`),
  };
  const userColumns = [
    {
      dataField: 'firstName',
      text: userTitles.firstname,
    },
    {
      dataField: 'lastName',
      text: userTitles.lastname,
    },
    {
      dataField: 'email',
      text: userTitles.email,
    },
  ];
  const orgUnitColumns = [
    {
      dataField: 'name',
      text: orgUnitTitles.name,
    },
  ];
  const roleColumns = [
    {
      dataField: 'name',
      text: roleTitles.name,
    },
  ];

  const tabIds = ['users', 'organizationunits', 'roles'];

  const userTabs: tabInfo[] = [
    {
      tabIcon: UsersIcon,
      tabLabel: t('Users'),
      tabId: tabIds[0],
    },
    {
      tabIcon: UserGroupsIcon,
      tabLabel: t('Organization Units'),
      tabId: tabIds[1],
    },
  ];

  const roleTab: tabInfo[] = [
    {
      tabIcon: RolesIcon,
      tabLabel: t('Roles'),
      tabId: tabIds[2],
    },
  ];

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const getTabs = (): tabInfo[] => {
    let tabs: tabInfo[] = [];
    if (currentUser.views.includes(USERS)) {
      tabs = tabs.concat(userTabs);
    }
    if (currentUser.views.includes(ROLES)) {
      tabs = tabs.concat(roleTab);
    }
    return tabs;
  };

  const [columns, setColumns] = useState<any[]>(userColumns);
  const [selectedTab, setSelectedTab] = useState(getTabs()[0].tabId);

  useEffect(() => {
    if (selectedRow !== undefined) {
      switch (selectedTab) {
        case tabIds[0]:
          // User edit page
          history.push(`/users/${listData[selectedRow].id}`);
          break;
        case tabIds[1]:
          // Organization Units edit page
          history.push(`/organizationunits/${listData[selectedRow].id}`);
          break;
        case tabIds[2]:
          // Roles edit page
          history.push(`/roles/${listData[selectedRow].id}`);
          break;
        default:
          break;
      }
    }
  }, [selectedRow]);

  useEffect(() => {
    const getListData = async () => {
      try {
        if (selectedTab === tabIds[0]) {
          const list = await UserApi.get();
          setColumns(userColumns);
          setListData(list);
        } else if (selectedTab === tabIds[1]) {
          const list = await UserApi.organizationUnits.get();
          setColumns(orgUnitColumns);
          setListData(list);
        } else if (selectedTab === tabIds[2]) {
          const list = await UserApi.getRoles();
          setColumns(roleColumns);
          setListData(list);
        }
      } catch (error) {
        // define action
      }
    };
    getListData();
  }, [selectedTab]);

  const getTabTypes = () => (
    <DropdownMenu right className="primary-dropdown-menu">
      <DropdownItem key="adduser" onClick={() => history.push('/users/create')}>
        {t(`${translations.common}:User`).toUpperCase()}
      </DropdownItem>
      <DropdownItem
        key="addgroup"
        onClick={() => history.push('/organizationunits/create')}
      >
        {t(`${translations.common}:Workgroup`).toUpperCase()}
      </DropdownItem>
      <DropdownItem key="addrole" onClick={() => history.push('/roles/create')}>
        {t(`${translations.common}:Role`).toUpperCase()}
      </DropdownItem>
    </DropdownMenu>
  );

  return (
    <>
      <PageContainer className="row-content">
        <p className="header-text">{t('common:User Management')}</p>
        <PrimaryButtonDropdown
          className="add-button"
          text={t(`${translations.common}:Add`)}
        >
          {getTabTypes()}
        </PrimaryButtonDropdown>
      </PageContainer>
      <BasicTabBar
        tabs={getTabs()}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <div className="table-background-offers">
        <BasicTableNoPagination
          columns={columns}
          data={listData}
          setSelectedRow={setSelectedRow}
          keyField="id"
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.users.current,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUser: () => dispatch(fetchUserAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
