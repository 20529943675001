import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
  Button,
  Modal,
} from 'reactstrap';
import translations from 'services/translations/translations';
import TextInputField from 'components/layout/forms/TextInputField';
import IBuildingStructure from 'interfaces/projects/IBuildingStructure';

const ModalEditBuildingStructure = ({
  label,
  showing,
  setShowing,
  updateElement,
  building,
}: {
  label: string;
  showing: boolean;
  setShowing: (showing: boolean) => void;
  updateElement: (element: string) => void;
  building: IBuildingStructure | undefined;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common]);
  const toggle = () => setShowing(!showing);
  const [buildingName, setBuildingName] = useState<string>('');

  useEffect(() => {
    if (building !== undefined) {
      setBuildingName(building.name);
    }
  }, [building]);

  const handleOnChange = (e: any) => {
    setBuildingName(e);
  };

  return (
    <>
      <Modal isOpen={showing} toggle={toggle} centered>
        <ModalHeader className="modal-header" toggle={toggle} />
        <ModalBody className="basic-table">
          <Row>
            <Col>
              <TextInputField
                label={
                  label === 'space'
                    ? t(`${translations.projects}:Building Spaces`)
                    : t(`${translations.projects}:Building Sections`)
                }
                id={building ? building.name : ''}
                onChange={(e: any) => handleOnChange(e.target.value)}
                required
                value={buildingName}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => updateElement(buildingName)}
            className="modal-button"
          >
            {t(`${translations.common}:Save`)}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalEditBuildingStructure;
