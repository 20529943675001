import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import apiFiles from 'api/files';
import DocumentGreyLarge from 'assets/Document_Grey_Large.svg';
import getNextObjectKey from 'services/numbers/getNextObjectKey';
import IFile from 'interfaces/forms/IFile';
import translations from 'services/translations/translations';

import 'style/layout/form/FileInput.css';

const FileInput = ({
  addFile,
  files,
  id,
}: {
  addFile: (files: IFile) => void;
  files: IFile[];
  id: string;
}): JSX.Element => {
  const { t } = useTranslation([translations.common]);
  const [loadingFiles, setLoadingFiles] = useState<any>([]);

  const onDrop = (acceptedFiles: any) => {
    setLoadingFiles(acceptedFiles);

    const handleFileSave = async (loadingFile: any, index: number) => {
      const result = await apiFiles.post(loadingFile, null);

      setLoadingFiles(
        loadingFiles.filter(
          (existingLoadingFile: any) => existingLoadingFile !== loadingFile
        )
      );

      addFile({
        file: loadingFile,
        id: result.id.toString(),
        name: loadingFile.name,
        key: getNextObjectKey(files, 'key') + index,
      });
    };

    let acceptedFileIndex = 0;
    for (const acceptedFile of acceptedFiles) {
      handleFileSave(acceptedFile, acceptedFileIndex);
      acceptedFileIndex++;
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (loadingFiles.length > 0) {
    return (
      <div className="file-input-dropzone">
        <img src={DocumentGreyLarge} alt="" className="files-icon" />
        <h5>{t('Uploading files...')}</h5>
      </div>
    );
  }

  return (
    <div {...getRootProps()} className="file-input-dropzone">
      <input type="file" id={`file-input-${id}`} {...getInputProps()} />
      <img src={DocumentGreyLarge} alt="" className="files-icon" />
      {isDragActive ? (
        <h5>{t('Drop the files here')}</h5>
      ) : (
        <h5>{t('Drop files here, or click to select files')}</h5>
      )}
    </div>
  );
};

export default FileInput;
