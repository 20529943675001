import { combineReducers } from 'redux';

import loading from './loading/reducer';
import messages from './messages/reducer';
import modal from './reducers/modal';
import offers from './offers/reducer';
import users from './users/reducer';
import products from './products/reducer';
import projects from './projects/reducer';
import settings from './settings/reducer';
import navMenu from './navMenu/reducer';

export default combineReducers({
  loading,
  messages,
  modal,
  offers,
  users,
  products,
  projects,
  navMenu,
  settings,
});
