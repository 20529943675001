import React from 'react';
import IMeasurementGroupType from 'interfaces/products/IMeasurementGroupType';
import IMeasurement from 'interfaces/products/IMeasurement';
import { Row, Col } from 'reactstrap';
import NumberInputField from 'components/layout/forms/NumberInputField';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import { useTranslation } from 'react-i18next';
import translations from 'services/translations/translations';
import DeleteIcon from 'components/layout/icons/DeleteIcon';
import {
  getMeasurementTypeOptions,
  getMeasurementOptions,
} from './ProductSelectOptions';

const ProductMeasurementRows = ({
  measurementTypesList,
  unitsList,
  measurFormValues,
  updateMeasurementRow,
  deleteRow,
  nameKey,
  group,
}: {
  measurementTypesList: IMeasurementGroupType[];
  unitsList: string[];
  measurFormValues: IMeasurement[];
  updateMeasurementRow: any;
  deleteRow: any;
  nameKey: string;
  group: string | null;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common, translations.products]);
  const selectedMeasurFormValues = measurFormValues.map((x) => x.type);
  const getTypeOptions = (selected: string) =>
    getMeasurementTypeOptions(
      `${nameKey}T`,
      measurementTypesList
        .filter((x) => x.group === group)
        .filter(
          (x) =>
            x.type === selected || !selectedMeasurFormValues.includes(x.type)
        ),
      translations,
      t
    );

  const measurU = getMeasurementOptions(
    `${nameKey}U`,
    unitsList,
    translations,
    t
  );

  return (
    <div>
      {measurFormValues.map((x, i) => {
        return (
          <Row className="product-measurement">
            <Col md={3}>
              <NumberInputField
                value={x.value.toString()}
                label={
                  i === 0
                    ? t(`${translations.products}:Measurement value`)
                    : undefined
                }
                required
                id={`${nameKey}Value`}
                name={`${nameKey}Value`}
                onChange={(e: any) => updateMeasurementRow(e, i)}
              />
            </Col>
            <Col md={3}>
              <SelectInputFieldControlled
                value={x.type}
                label={
                  i === 0
                    ? t(`${translations.products}:Measurement type`)
                    : undefined
                }
                required
                id={`${nameKey}Type`}
                name={`${nameKey}Type`}
                onChange={(e: any) => updateMeasurementRow(e, i)}
                options={getTypeOptions(x.type)}
              />
            </Col>
            <Col md={3}>
              <SelectInputFieldControlled
                value={x.siUnit}
                label={
                  i === 0
                    ? t(`${translations.products}:Measurement unit`)
                    : undefined
                }
                required
                id={`${nameKey}Unit`}
                name={`${nameKey}Unit`}
                onChange={(e: any) => updateMeasurementRow(e, i)}
                options={measurU}
              />
            </Col>
            <Col md={3}>
              <DeleteIcon onClick={() => deleteRow(i, measurFormValues)} />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default ProductMeasurementRows;
