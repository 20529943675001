import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import getItemConfiguration from 'services/products/configurations/getItemConfiguration';
import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import translations from 'services/translations/translations';
import { addMessage } from 'store/messages/actions';
import { finishLoading, startLoading } from 'store/loading/actions';

import getEditItemTitle from './src/item/getEditItemTitle';
import Item from './src/item/Item';
import validItem from './src/item/validItem';

const EditItem = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { itemId } = useParams<{
    itemId: string | undefined;
  }>();

  const { t } = useTranslation([translations.common, translations.products]);

  const numericItemId = itemId === undefined ? null : parseInt(itemId, 10);

  const [configurationName, setConfigurationName] = useState('');
  const [item, setItem] = useState<IProductConfigurationItem | null>(null);

  const getNumericConfigurationId = (): null | number => {
    if (item === null) {
      return null;
    }

    const parentItemConfiguration = getItemConfiguration(item);
    if (parentItemConfiguration === null) {
      return null;
    }

    return parentItemConfiguration.id;
  };

  const initializeItem = async () => {
    if (numericItemId === null) {
      return;
    }

    dispatch(startLoading('product-configuration-item'));
    const itemResult: IProductConfigurationItem = await apiProducts.configurations.items.get(
      numericItemId
    );
    dispatch(finishLoading('product-configuration-item'));

    const parentItemConfiguration = getItemConfiguration(itemResult);
    if (parentItemConfiguration !== null) {
      setConfigurationName(parentItemConfiguration.name);
    }

    setItem(itemResult);
  };

  useEffect(() => {
    initializeItem();
  }, []);

  const save = async () => {
    if (!validItem(item, item === null ? null : item.type)) {
      dispatch(addMessage(t(`${translations.common}:Please fill all fields`)));
      return;
    }

    try {
      if (item !== null && numericItemId !== null) {
        await apiProducts.configurations.items.put(numericItemId, item);

        const parentItemConfiguration = getItemConfiguration(item);
        if (parentItemConfiguration !== null) {
          history.push(
            `/products/configurations/${parentItemConfiguration.id}`
          );
        }
      }
    } catch (e) {
      dispatch(addMessage(t(`${translations.common}:Saving failed`)));
    }
  };

  if (item === null) {
    return null;
  }

  return (
    <Item
      configurationId={getNumericConfigurationId()}
      configurationName={configurationName}
      item={item}
      save={save}
      setItem={setItem}
      title={getEditItemTitle(item, t)}
    />
  );
};

export default EditItem;
