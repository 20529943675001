import React from 'react';

import IContact from 'interfaces/contacts/IContact';

export default (suppliers: IContact[]): JSX.Element[] => {
  if (suppliers.length === 0) {
    return [];
  }

  const getBusinessId = (supplier: IContact) =>
    !supplier.businessId ? null : `(${supplier.businessId})`;

  return [
    <option key="supplier-00" value="" />,
    ...suppliers.map((supplier) => (
      <option key={`supplier-${supplier.id}`} value={supplier.id}>
        {supplier.companyName} {getBusinessId(supplier)}
      </option>
    )),
  ];
};
