import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import getNextObjectKey from 'services/numbers/getNextObjectKey';
import InputLabel from 'components/layout/forms/InputLabel';
import LinkButton from 'components/layout/buttons/LinkButton';
import translations from 'services/translations/translations';
import { APPROVED } from 'services/enums/offers/Statuses';

import ISubproduct from '../../ISubproduct';
import Subproduct from './Subproduct';
import getDefaultSubproduct from './getDefaultSubproduct';
import SubproductsSum from './SubproductsSum';

const Subproducts = ({
  initialStatus,
  quantity,
  subproducts,
  setSubproducts,
}: {
  initialStatus: null | string;
  quantity: number;
  subproducts: ISubproduct[];
  setSubproducts: (subproducts: ISubproduct[]) => void;
}): JSX.Element => {
  const { t } = useTranslation([
    translations.common,
    translations.offers,
    translations.products,
  ]);

  const [openedSubproduct, setOpenedSubproduct] = useState<null | number>(null);

  const addSubproduct = () => {
    const key = getNextObjectKey(subproducts, 'key');
    setSubproducts([...subproducts, getDefaultSubproduct(key)]);
  };

  const removeSubproduct = (subproduct: ISubproduct) => {
    setSubproducts(
      subproducts.filter(
        (existingSubproduct) => existingSubproduct.key !== subproduct.key
      )
    );
  };

  const setSubproduct = (subproduct: ISubproduct) =>
    setSubproducts(
      subproducts.map((existingSubproduct) =>
        existingSubproduct.key === subproduct.key
          ? subproduct
          : existingSubproduct
      )
    );

  const offerIsApproved = () => initialStatus === APPROVED;

  return (
    <>
      <Row className="subproduct-row">
        <Col>
          <InputLabel>{t(`${translations.common}:Category`)}</InputLabel>
        </Col>
        <Col>
          <InputLabel>{t(`${translations.products}:Product`)}</InputLabel>
        </Col>
        <Col className="icon-column" />
        <Col>
          <InputLabel>{t(`${translations.common}:Quantity`)}</InputLabel>
        </Col>
        <Col>
          <InputLabel>{t(`${translations.offers}:Purchase`)}</InputLabel>
        </Col>
        <Col className="small-column">
          <InputLabel>{t(`${translations.offers}:Waste %`)}</InputLabel>
        </Col>
        <Col className="small-column">
          <InputLabel>{t(`${translations.offers}:Expense`)}</InputLabel>
        </Col>
        <Col>
          <InputLabel>{t(`${translations.offers}:Selling price`)}</InputLabel>
        </Col>
        <Col className="small-column">
          <InputLabel>
            {`${t(`${translations.offers}:Contribution margin`)} €`}
          </InputLabel>
        </Col>
        <Col className="small-column">
          <InputLabel>
            {t(`${translations.offers}:Expense`)}{' '}
            {t(`${translations.common}:total-short`)}
          </InputLabel>
        </Col>
        <Col className="small-column">
          <InputLabel>
            {t(`${translations.offers}:Selling price`)}{' '}
            {t(`${translations.common}:total-short`)}
          </InputLabel>
        </Col>
        <Col className="small-column">
          <InputLabel>
            {t(`${translations.offers}:Contribution margin`)}{' '}
            {t(`${translations.common}:total-short`)} €
          </InputLabel>
        </Col>
        <Col className="small-column">
          <InputLabel>
            {`${t(`${translations.offers}:Contribution margin`)} %`}
          </InputLabel>
        </Col>
        <Col className="icon-column" />
      </Row>
      {subproducts.map((subproduct) => (
        <Subproduct
          initialStatus={initialStatus}
          key={`subproduct-${subproduct.key}`}
          openedSubproduct={openedSubproduct}
          quantity={quantity}
          removeSubproduct={removeSubproduct}
          setOpenedSubproduct={setOpenedSubproduct}
          setSubproduct={setSubproduct}
          subproduct={subproduct}
        />
      ))}
      {offerIsApproved() ? null : (
        <LinkButton onClick={addSubproduct}>
          {`+ ${t(`${translations.common}:Add row`)}`}
        </LinkButton>
      )}
      <SubproductsSum quantity={quantity} subproducts={subproducts} />
    </>
  );
};

export default Subproducts;
