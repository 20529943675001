import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';

import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';

import NotAuthorized from 'components/layout/NotAuthorized';
import ISettingsGet from './api/interfaces/common/ISettingsGet';

import { RootState } from './store/store';
import {
  fetchUser,
  setAccessToken,
  setMsalAccount,
  setMsalInstance,
} from './store/users/actions';
import { loginRequest, silentLoginRequest } from './services/users/authConfig';

/**
 * This component manages fetching of the idToken from Azure, after the MsalInstance
 * has been set up once in the outer wrapper (AuthWrapper).
 */
const AuthInstanceWrapper = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const idToken = useSelector((state: RootState) => state.users.accessToken);
  const currentUser = useSelector((state: RootState) => state.users.current);
  const maxUserFetches = 2;
  const { instance, inProgress, accounts } = useMsal();
  const userFetchedCount = useRef(0);

  const canFetchToken = useCallback(() => {
    return (
      !idToken && inProgress === InteractionStatus.None && accounts.length !== 0
    );
  }, [accounts.length, idToken, inProgress]);

  useEffect(() => {
    if (!canFetchToken()) {
      return;
    }

    const silentTokenRequest = silentLoginRequest(accounts[0]);

    async function acquireToken() {
      try {
        const response = await instance.acquireTokenSilent(silentTokenRequest);
        dispatch(setAccessToken(response.idToken));
        dispatch(setMsalInstance(instance));
        dispatch(setMsalAccount(accounts[0]));
      } catch (err) {
        if (!(err instanceof InteractionRequiredAuthError)) {
          console.error('Error fetching token', err);
          await instance.acquireTokenRedirect(loginRequest);
          return;
        }
        // Test if popups are blocked
        const openPopup = window.open('');
        if (openPopup == null) {
          await instance.acquireTokenRedirect(loginRequest);
          return;
        }
        openPopup.close();
        const result = await instance.acquireTokenPopup(silentTokenRequest);
        dispatch(setAccessToken(result.idToken));
      }
    }

    acquireToken();
  }, [instance, accounts, inProgress, idToken, dispatch, canFetchToken]);

  useEffect(() => {
    if (currentUser !== null) {
      userFetchedCount.current = 0;
    }

    if (userFetchedCount.current >= maxUserFetches) {
      return;
    }

    if (idToken !== null && currentUser === null) {
      userFetchedCount.current++;
      dispatch(fetchUser());
    }
  }, [idToken, currentUser, dispatch, userFetchedCount]);

  if (userFetchedCount.current >= maxUserFetches) {
    return <NotAuthorized />;
  }

  if (!idToken || currentUser === null) {
    return null;
  }

  return children;
};

export default AuthInstanceWrapper;
