import React from 'react';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import '../../../style/layout/PrimaryButton.css';

const getClassName = (className?: string): string =>
  className === undefined ? 'primary-button' : `primary-button ${className}`;

const getDisabled = (disabled?: boolean): boolean =>
  disabled === undefined ? false : disabled;

export default ({
  children,
  className,
  disabled,
  size,
  text,
}: {
  children: JSX.Element | JSX.Element[];
  className?: string;
  disabled?: boolean;
  size?: string;
  text: string;
}): JSX.Element => (
  <UncontrolledDropdown disabled={getDisabled(disabled)} size={size}>
    <DropdownToggle className={getClassName(className)} caret>
      {text}
    </DropdownToggle>
    {children}
  </UncontrolledDropdown>
);
