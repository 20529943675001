import React from 'react';
import { Input } from 'reactstrap';

export default ({
  id,
  onChange,
  name,
  required,
  options,
  classname,
}: {
  id: string;
  onChange: any;
  name: string;
  required: boolean;
  options: any;
  classname?: string;
}): JSX.Element => (
  <Input
    className={`text-field ${classname}`}
    id={id}
    name={name}
    required={required}
    onChange={onChange}
    type="select"
  >
    {options}
  </Input>
);
