import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import FileInput from 'components/layout/forms/FileInput';
import IFile from 'interfaces/forms/IFile';
import ReadOnlyTextInput from 'components/layout/forms/ReadOnlyTextInput';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import sortByTranslation from 'services/sort/sortByTranslation';
import translations from 'services/translations/translations';

import 'style/products/src/product/ProductFiles.css';
import TrashIcon from 'components/layout/icons/TrashIcon';
import downloadFile from 'services/files/downloadFile';

const ProductFiles = ({
  files,
  fileTypesList,
  id,
  removeFile,
  setFiles,
}: {
  files: IFile[];
  fileTypesList: string[];
  id: string;
  removeFile: (file: IFile) => void;
  setFiles: any;
}): JSX.Element => {
  const { t } = useTranslation([translations.common, translations.products]);

  const addFile = (newFile: IFile) => {
    const newFileWithType = { ...newFile, type: fileTypesList[0] || null };
    setFiles((previousFiles: IFile[]) => [...previousFiles, newFileWithType]);
  };

  const getOptions = () =>
    [...fileTypesList]
      .sort(sortByTranslation(t, translations.productFileTypes))
      .map((fileType: string) => (
        <option key={`product-file-type-${fileType}`} value={fileType}>
          {t(`${translations.productFileTypes}:${fileType}`)}
        </option>
      ));

  const setFileType = (file: IFile, type: string) => {
    setFiles(
      files.map((existingFile: IFile) =>
        existingFile.key === file.key
          ? { ...existingFile, type: type === '' ? null : type }
          : existingFile
      )
    );
  };

  const openFile = async (file: IFile) => {
    if (file.id === null) {
      return;
    }

    const response = await apiProducts.files.get(file.id);
    downloadFile(response.data, response.headers);
  };

  const getFile = (file: IFile) => (
    <Row key={`product-file-${file.key}`} className="product-file">
      <Col>
        <SelectInputFieldControlled
          id={`product-file-${file.key}`}
          label={t('Type')}
          name="product-file-select"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFileType(file, event.target.value)
          }
          options={getOptions()}
          value={file.type === null ? '' : file.type}
        />
      </Col>
      <Col>
        <ReadOnlyTextInput label={t('Name')} verticalAlignMatchInput>
          <p
            className="product-file-link"
            onClick={() => openFile(file)}
            tabIndex={-1}
          >
            {file.name}
          </p>
          <TrashIcon onClick={() => removeFile(file)} />
        </ReadOnlyTextInput>
      </Col>
    </Row>
  );

  return (
    <div className="product-files">
      <h3>{t('Files')}</h3>
      <div className="product-files-list">
        {files.map((file: IFile) => getFile(file))}
      </div>
      <FileInput addFile={addFile} files={files} id={id} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  fileTypesList: state.products.fileTypes,
});

export default connect(mapStateToProps)(ProductFiles);
