import IGlassConfiguratorProduct from 'interfaces/products/IGlassConfiguratorProduct';
import IProduct from 'api/interfaces/products/IProductGet';
import IProductConfigurationSummary from 'interfaces/products/IProductConfigurationSummary';
import IProductGet from 'api/interfaces/products/IProductGetSingle';
import IProductPost from 'api/interfaces/products/IProductPost';
import IMeasurementGroupType from 'interfaces/products/IMeasurementGroupType';

import api from './api';
import getProductConfiguration from './src/products/configurations/getProductConfiguration';
import IAttributeType from './interfaces/products/IAttributeType';
import ICountResult from './interfaces/results/ICountResult';
import IIdResult from './interfaces/results/IIdResult';
import IProductConfigurationItem from './interfaces/products/IProductConfigurationItem';
import IProductConfigurationItemPost from './interfaces/products/IProductConfigurationItemPost';
import IProductConfigurationPost from './interfaces/products/IProductConfigurationPost';
import IProductInstallationCategory from './interfaces/products/IProductInstallationCategory';
import ISuccessResult from './interfaces/results/ISuccessResult';

export const PRODUCTS_API = '/api/v1/products';

export default {
  attributes: {
    absorptionClasses: {
      get: async (): Promise<string[]> =>
        api.get('/api/v1/products/types/absorption-class'),
    },
    certificateTypes: {
      get: async (): Promise<string[]> =>
        api.get('/api/v1/products/types/certificate'),
    },
    classesOfFire: {
      get: async (): Promise<string[]> =>
        api.get('/api/v1/products/types/class-of-fire'),
    },
    fastenerTypes: {
      get: async (): Promise<string[]> =>
        api.get('/api/v1/products/types/fastener'),
    },
    installationMethodTypes: {
      get: async (): Promise<string[]> =>
        api.get('/api/v1/products/types/installation-method'),
    },
    isoCleanroomClasses: {
      get: async (): Promise<string[]> =>
        api.get('/api/v1/products/types/iso-cleanroom-class'),
    },
    materials: {
      get: async (): Promise<string[]> =>
        api.get('/api/v1/products/types/material'),
    },
    materialSurfaceTypes: {
      get: async (): Promise<string[]> =>
        api.get('/api/v1/products/types/material-surface'),
    },
    perforations: {
      get: async (): Promise<string[]> =>
        api.get('/api/v1/products/types/perforation'),
    },
    tips: {
      get: async (): Promise<string[]> => api.get('/api/v1/products/types/tip'),
    },
  },
  attributeTypes: {
    get: async (): Promise<IAttributeType[]> =>
      api.get('/api/v1/products/types/attribute'),
  },
  categories: {
    get: async (): Promise<IProductInstallationCategory[]> =>
      api.get('/api/v1/products/types/category'),
  },
  configurations: {
    count: async (search?: string): Promise<ICountResult> =>
      api.get('/api/v1/products/configurations/count', {
        search: search !== '' ? search : undefined,
      }),
    delete: (id: number): Promise<ISuccessResult> =>
      api.delete(`/api/v1/products/configurations/${id}`),
    get: getProductConfiguration,
    getAll: async ({
      limit,
      offset,
      productId,
      search,
    }: {
      limit?: number;
      offset?: number;
      productId?: number;
      search?: string;
    }): Promise<any> =>
      api.get('/api/v1/products/configurations', {
        limit,
        offset,
        productId,
        search: search !== '' ? search : undefined,
      }),
    copy: (id: number): Promise<IIdResult> =>
      api.post(`/api/v1/products/configurations/${id}/copy`),
    items: {
      delete: async (id: number): Promise<IIdResult> =>
        api.delete(`/api/v1/products/configurations/items/${id}`),
      get: async (id: number): Promise<IProductConfigurationItem> =>
        api.get(`/api/v1/products/configurations/items/${id}`),
      post: async (
        configurationId: number,
        item: IProductConfigurationItemPost
      ): Promise<IIdResult> =>
        api.post(
          `/api/v1/products/configurations/${configurationId}/items`,
          item
        ),
      postForParent: async (
        itemId: number,
        item: IProductConfigurationItemPost
      ): Promise<IIdResult> =>
        api.post(
          `/api/v1/products/configurations/items/${itemId}/children`,
          item
        ),
      put: async (
        itemId: number,
        item: IProductConfigurationItemPost
      ): Promise<IIdResult> =>
        api.put(`/api/v1/products/configurations/items/${itemId}`, item),
    },
    links: {
      delete: async (
        configurationId: number,
        productId: number
      ): Promise<IIdResult> =>
        api.delete(
          `/api/v1/products/configurations/${configurationId}/products/${productId}`
        ),
      post: async (
        configurationId: number,
        link: { productId: number }
      ): Promise<IIdResult> =>
        api.post(
          `/api/v1/products/configurations/${configurationId}/links`,
          link
        ),
    },
    offerText: {
      patch: async (
        configurationId: number,
        offerText: string
      ): Promise<IIdResult> =>
        api.patch(
          `/api/v1/products/configurations/${configurationId}/offer-text`,
          {
            offerText,
          }
        ),
    },
    post: async (
      configuration: IProductConfigurationPost
    ): Promise<IIdResult> =>
      api.post('/api/v1/products/configurations', configuration),
    patch: async (configurationId: number, name: string): Promise<IIdResult> =>
      api.patch(`/api/v1/products/configurations/${configurationId}/name`, {
        name,
      }),
    put: async (
      id: number,
      configuration: IProductConfigurationPost
    ): Promise<IIdResult> =>
      api.put(`/api/v1/products/configurations/${id}`, configuration),
    summary: {
      get: async (id: number): Promise<IProductConfigurationSummary> =>
        api.get(`/api/v1/products/configurations/${id}/summary`),
    },
  },
  count: async (search?: string): Promise<any> =>
    api.get('/api/v1/products/count', {
      search: search !== '' ? search : undefined,
    }),
  delete: async (id?: number): Promise<any> =>
    api.delete(`/api/v1/products/${id}`),
  deliveryCategoryTypes: {
    get: async (): Promise<string[]> =>
      api.get('/api/v1/products/types/delivery-category'),
  },
  edgeTypes: {
    get: async () => api.get('/api/v1/products/types/edge'),
  },
  files: {
    get: async (id: string): Promise<any> =>
      api.getFile(`/api/v1/products/files/${id}`),
  },
  fileTypes: {
    get: async (): Promise<string[]> => api.get('/api/v1/products/types/file'),
  },
  get: async (
    limit?: number,
    offset?: number,
    search?: string,
    full?: boolean,
    glassconfigurator?: boolean
  ): Promise<IProduct[] | IGlassConfiguratorProduct[]> =>
    api.get(PRODUCTS_API, {
      limit,
      offset,
      search: search !== '' ? search : undefined,
      full,
      glassconfigurator,
    }),
  getProduct: async (id?: number): Promise<IProductGet> =>
    api.get(`/api/v1/products/${id}`),
  groups: {
    get: async () => api.get('/api/v1/products/types/group'),
  },
  idTypes: {
    get: async (): Promise<string[]> => api.get('/api/v1/products/types/id'),
  },
  installationCategories: {
    get: async (): Promise<string[]> =>
      api.get('/api/v1/products/types/installation-category'),
  },
  installationPriceTypes: {
    get: async (): Promise<string[]> =>
      api.get('/api/v1/products/types/installation-price'),
  },
  measurementTypes: {
    get: async (): Promise<IMeasurementGroupType[]> =>
      api.get('/api/v1/products/types/measurement'),
  },
  patch: async (body: any): Promise<IProduct[]> =>
    api.patch(PRODUCTS_API, body),
  post: async (product?: IProductPost): Promise<any> =>
    api.post(PRODUCTS_API, product),
  priceTypes: {
    get: async (): Promise<string[]> => api.get('/api/v1/products/types/price'),
  },
  put: async (id?: number, product?: any): Promise<any> =>
    api.put(`/api/v1/products/${id}`, product),
  tags: {
    get: async (): Promise<string[]> => api.get('/api/v1/products/types/tag'),
  },
  units: { get: async (): Promise<string[]> => api.get('/api/v1/units/si') },
};
