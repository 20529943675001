import IInstallationPrice from 'interfaces/products/IInstallationPrice';
import api from './api';
import IInstallationPricePost from './interfaces/installationPrices/IInstallationPricePost';
import IInstallationPriceGet from './interfaces/installationPrices/IInstallationPriceGet';

export const INSTALLATION_API = '/api/v1/installations/prices';

export default {
  count: async (search?: string): Promise<any> =>
    api.get(`${INSTALLATION_API}/count`, {
      search: search !== '' ? search : undefined,
    }),
  delete: async (id?: number): Promise<any> =>
    api.delete(`/api/v1/installations/prices/${id}`),
  get: async ({
    limit,
    offset,
    search,
    type,
  }: {
    limit?: number;
    offset?: number;
    search?: string;
    type?: string;
  }): Promise<IInstallationPrice[]> =>
    api.get(INSTALLATION_API, {
      limit,
      offset,
      search: search !== '' ? search : undefined,
      type,
    }),
  getInstallation: async (id?: number): Promise<IInstallationPriceGet> =>
    api.get(`/api/v1/installations/prices/${id}`),
  post: async (installation?: IInstallationPricePost): Promise<any> =>
    api.post(INSTALLATION_API, installation),
  put: async (id: number, installation: IInstallationPricePost): Promise<any> =>
    api.put(`/api/v1/installations/prices/${id}`, installation),
};
