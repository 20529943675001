import React from 'react';
import { useTranslation } from 'react-i18next';

import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import translations from 'services/translations/translations';
import { NAME } from 'services/products/productConfigurationAttributeTypes';

import Actions from './Actions';
import getAttribute from '../../getAttribute';
import Item from '../Item';

const SelectItem = ({
  item,
  onDelete,
}: {
  item: IProductConfigurationItem;
  onDelete: (itemId: number) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.products]);

  const getName = () => {
    const attribute = getAttribute(item, NAME);
    if (attribute === undefined) {
      return '';
    }

    return attribute.value;
  };

  return (
    <div className="select-item">
      <div>
        <h4 className="item-title">
          {t(`${translations.productConfigurations}:Select`)}: {getName()}
        </h4>
        <Actions item={item} onDelete={onDelete} />
        <div className="clearfix" />
      </div>
      <div className="children-container">
        {item.children.map((child) => (
          <Item
            item={child}
            onDelete={onDelete}
            key={`product-configurator-item-${child.key}`}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectItem;
