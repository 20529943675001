import IGlassConfiguratorProduct from 'interfaces/products/IGlassConfiguratorProduct';

const isEmptyStringOrUndefined = (value: string) =>
  value === undefined || value === '';
const containsEmptyStringOrUndefinedValue = (values: any[]) =>
  !!values.find((value: string) => isEmptyStringOrUndefined(value));

const glassConfiguratorEmptyFields = (
  productsFormValues: IGlassConfiguratorProduct[]
): boolean =>
  productsFormValues.some((product: IGlassConfiguratorProduct) =>
    containsEmptyStringOrUndefinedValue([
      product.name,
      product.kgM,
      product.length,
      product.painting1,
      product.painting2,
      product.painting3,
      product.painting4,
      product.paintingArea,
      product.purchasePrice,
    ])
  );

export default glassConfiguratorEmptyFields;
