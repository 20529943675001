import { SET_MESSAGE, REMOVE_MESSAGE } from './types';

export const setNavMenuText = (
  text: string
): {
  type: string;
  text: string;
} => ({
  type: SET_MESSAGE,
  text,
});

export const removeNavMenuText = (): { type: string; text: string } => ({
  type: REMOVE_MESSAGE,
  text: '',
});
