import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiInstallation from 'api/installationPrices';
import BasicTable from 'components/layout/tables/BasicTable';
import SearchInput from 'components/layout/forms/SearchInput';
import IInstallationPrice from 'interfaces/products/IInstallationPrice';

import getInstallationPriceColumns from './installationPriceColumns';
import translations from '../../../../services/translations/translations';

const InstallationPricesList = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation([translations.products, translations.common]);

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState<number>();
  const [sizePerPage, setSizePerPage] = useState(50);
  const [totalSize, setTotalSize] = useState<number>();
  const [installationPrices, setInstallationPrices] = useState<
    IInstallationPrice[]
  >([]);

  const [searchTerms, setSearchTerms] = useState<string>('');
  const selectedInstallationPrice =
    installationPrices !== undefined && selectedRow !== undefined
      ? installationPrices[selectedRow]
      : undefined;

  useEffect(() => {
    if (selectedRow !== undefined) {
      history.push(
        `/products/installation-prices/${selectedInstallationPrice?.id}`
      );
    }
  }, [history, selectedInstallationPrice, selectedRow]);

  useEffect(() => {
    const getInstallationPrices = async () => {
      try {
        const list = (await apiInstallation.get({
          limit: sizePerPage,
          offset,
          search: searchTerms,
        })) as IInstallationPrice[];

        const count = await apiInstallation.count(searchTerms);
        setInstallationPrices(list);
        setTotalSize(count.count);
      } catch (error) {
        // define action
      }
    };
    getInstallationPrices().catch((e) => console.log(e));
  }, [offset, searchTerms, sizePerPage]);

  const columns = getInstallationPriceColumns(t, translations);

  const handleTableChange = (type: any, { page, sizePerPage }: any) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    setOffset((page - 1) * sizePerPage);
  };

  return (
    <>
      <Row>
        <Col xs={3}>
          <SearchInput setSearchTerms={setSearchTerms} />
        </Col>
      </Row>
      <Row>
        <Col>
          <BasicTable
            columns={columns}
            data={installationPrices}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalSize}
            handleTableChange={handleTableChange}
            setSelectedRow={setSelectedRow}
            keyField="id"
          />
        </Col>
      </Row>
    </>
  );
};

export default InstallationPricesList;
