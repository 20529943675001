import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import '../../../style/layout/form/DashboardButton.css';

export default ({
  external,
  id,
  image,
  link,
  value,
}: {
  external?: boolean;
  id: string;
  image: string;
  link: string;
  value: string;
}): JSX.Element => {
  if (external) {
    return (
      <a href={link} className="dashboard-link">
        <div className="dashboard-image-container">
          <img className="dashboard-image" src={image} alt="" id={id} />
          <p className="dashboard-text">{value}</p>
        </div>
      </a>
    );
  }

  return (
    <Link to={link} className="dashboard-link">
      <div className="dashboard-image-container">
        <img className="dashboard-image" src={image} alt="" id={id} />
        <p className="dashboard-text">{value}</p>
      </div>
    </Link>
  );
};
