import React from 'react';

import translations from 'services/translations/translations';
import sortByTranslation from 'services/sort/sortByTranslation';

const getProductUnitOptions = (
  units: string[],
  t: any,
  unitPrice = false
): JSX.Element[] => {
  const unitsTranslationLocation = `${translations.common}:units`;

  const getOption = (unit: null | string) => {
    if (unit === null) {
      return (
        <option key="product-unit-empty" value="">
          -
        </option>
      );
    }

    const valueToDisplay = unitPrice
      ? `€/${t(`${unitsTranslationLocation}:${unit}`)}`
      : t(`${unitsTranslationLocation}:${unit}`);

    return (
      <option key={`product-unit-${unit}`} value={unit}>
        {valueToDisplay}
      </option>
    );
  };

  const unitsSortedByTranslation = units
    .filter((unit) => !unitPrice || !unit.includes('/'))
    .sort(sortByTranslation(t, unitsTranslationLocation));

  const unitsWithEmptyOption = [null, ...unitsSortedByTranslation];

  return unitsWithEmptyOption.map((unit: null | string) => getOption(unit));
};

export default getProductUnitOptions;
