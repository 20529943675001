import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';

import apiContacts from 'api/contacts';
import apiProducts from 'api/products';
import BasicFormContainer from 'components/layout/BasicFormContainer';
import getTargetValue from 'services/forms/getTargetValue';
import IContact from 'interfaces/contacts/IContact';
import IGlassConfiguratorProduct from 'interfaces/products/IGlassConfiguratorProduct';
import IProduct from 'api/interfaces/products/IProductGet';
import IProductPatch from 'api/interfaces/products/IProductPatch';
import ModalAccept from 'components/layout/modals/ModalAccept';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import translations from 'services/translations/translations';
import { fetchProductIdTypes } from 'store/products/actions';

import fieldsToUpdate from './src/glassConfiguratorProducts/fieldsToUpdate';
import glassConfiguratorEmptyFields from './src/glassConfiguratorProducts/glassConfiguratorEmptyFields';
import getEmptyGlassConfiguratorProduct from './src/glassConfiguratorProducts/getEmptyGlassConfiguratorProduct';
import getProductPatch from './src/glassConfiguratorProducts/getProductPatch';
import getTempGlassConfiguratorProduct from './src/glassConfiguratorProducts/getTempGlassConfiguratorProduct';
import GlassConfiguratorProductRows from './src/glassConfiguratorProducts/GlassConfiguratorProductRows';
import updateGlassConfiguratorProduct from './src/glassConfiguratorProducts/updateGlassConfiguratorProduct';

import 'style/products/GlassConfiguratorProducts.css';

const GlassConfiguratorProducts = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation([translations.products, translations.common]);
  const [productsFormValues, setProductsFormValues] = useState<
    IGlassConfiguratorProduct[]
  >([]);
  const [suppliers, setSuppliers] = useState<IContact[]>();
  const [errorMessage, setErrorMessage] = useState('');
  const [formError, setFormError] = useState(false);

  const goPreviousPage = () => {
    history.push('/products');
  };

  const initialize = async () => {
    try {
      const list = (await apiProducts.get(
        undefined,
        undefined,
        '',
        true,
        true
      )) as IProduct[];

      setProductsFormValues(
        list.map((product) => getTempGlassConfiguratorProduct(product))
      );
    } catch (error) {
      // define action
    }
  };

  useEffect(() => {
    initialize();
    dispatch(fetchProductIdTypes());

    const getSupplierIds = async () => {
      try {
        const result: IContact[] = await apiContacts.get({
          limit: 100,
          offset: 0,
          type: 'supplier',
        });
        if (result !== undefined) {
          setSuppliers(result);
        }
      } catch (error) {
        // define action
      }
    };

    getSupplierIds();
  }, []);

  const addProductRow = () => {
    setProductsFormValues(getEmptyGlassConfiguratorProduct(productsFormValues));
  };

  const setProductFormValue = (
    productToUpdate: IGlassConfiguratorProduct,
    productToUpdateIndex: number
  ) => {
    setProductsFormValues(
      productsFormValues.map((product, index) =>
        index === productToUpdateIndex ? productToUpdate : product
      )
    );
  };

  const handleIds = (e: any, productToUpdateIndex: number, type: string) => {
    const productToUpdate = productsFormValues.find(
      (product, index) => index === productToUpdateIndex
    );

    if (productToUpdate === undefined) {
      return;
    }

    setProductFormValue(
      {
        ...productToUpdate,
        productIds: productToUpdate.productIds.map((productId) =>
          productId.type === type
            ? { ...productId, value: getTargetValue(e) }
            : productId
        ),
      },
      productToUpdateIndex
    );
  };

  const updateRow = (e: any, index: any) => {
    let newValues = [];
    newValues = updateGlassConfiguratorProduct(productsFormValues, e, index);
    setProductsFormValues(newValues);
  };

  const handleCategories = (
    categories: null | { label: string; value: string }[],
    productToUpdateIndex: number
  ) => {
    const productToUpdate = productsFormValues.find(
      (product, index) => index === productToUpdateIndex
    );

    if (productToUpdate === undefined) {
      return;
    }

    setProductFormValue(
      {
        ...productToUpdate,
        categories:
          categories === null
            ? []
            : categories.map((category) => category.value),
      },
      productToUpdateIndex
    );
  };

  const submitProduct = () => {
    if (!glassConfiguratorEmptyFields(productsFormValues)) {
      const patchProducts: IProductPatch[] = [];
      productsFormValues.forEach((product, index) => {
        const tempProduct: IProductPatch = getProductPatch(product);
        patchProducts.push(tempProduct);
      });

      const submit = async () => {
        try {
          await apiProducts.patch({ fieldsToUpdate, products: patchProducts });
          setErrorMessage(t(`${translations.common}:Saving succeeded`));
          setFormError(true);
          initialize();
        } catch (error) {
          setErrorMessage(t(`${translations.common}:Saving failed`));
          setFormError(true);
        }
      };

      submit();
    } else {
      setErrorMessage(t(`${translations.common}:Fill all fields`));
      setFormError(true);
    }
  };

  return (
    <div className="glass-configurator-products">
      <h3>{t(`${translations.products}:Glass Configurator Products`)}</h3>
      <BasicFormContainer>
        <GlassConfiguratorProductRows
          productsFormValues={productsFormValues}
          updateRow={updateRow}
          nameKey="glassConfProducts"
          handleCategories={handleCategories}
          suppliers={suppliers || []}
          handleIds={handleIds}
        />
        <hr />
        <Row style={{ position: 'fixed', bottom: '10px' }}>
          <Col>
            <PrimaryButton onClick={addProductRow}>
              {t(`${translations.products}:Add Product`)}
            </PrimaryButton>
            <PrimaryButton
              className="add-button-margin"
              onClick={goPreviousPage}
            >
              {t(`${translations.common}:Back`)}
            </PrimaryButton>
            <PrimaryButton
              className="add-button-margin"
              onClick={submitProduct}
            >
              {t(`${translations.common}:Save`)}
            </PrimaryButton>
          </Col>
        </Row>
      </BasicFormContainer>
      <ModalAccept
        showing={formError}
        setShowing={setFormError}
        header=""
        text={errorMessage}
      />
    </div>
  );
};

export default GlassConfiguratorProducts;
