import React from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import formatFloatForReadOnlyDisplay from 'services/numbers/formatFloatForReadOnlyDisplay';
import getContributionMarginTotal from 'services/products/calculations/getContributionMarginTotal';
import getContributionMarginRatio from 'services/products/calculations/getContributionMarginRatio';
import getSellingPriceTotal from 'services/products/calculations/getSellingPriceTotal';
import IInstallation from 'services/products/calculations/IInstallation';
import IOfferProductsGet from 'api/interfaces/offers/IOfferProductsGet';
import ISubproduct from 'services/products/calculations/ISubproduct';
import ReadOnlyTextInput from 'components/layout/forms/ReadOnlyTextInput';
import translations from 'services/translations/translations';

interface IProductPrimaryVersion {
  installations: IInstallation[];
  quantity: number;
  subproducts: ISubproduct[];
}

const OfferProductPriceSummary = ({
  products,
  title,
}: {
  products: IOfferProductsGet[];
  title?: string;
}): JSX.Element => {
  const { t } = useTranslation([translations.common, translations.offers]);

  const productPrimaryVersions = products.map(
    (product): IProductPrimaryVersion => {
      const primaryVersion = product.versions.find(
        (version) => version.isPrimary
      );

      if (primaryVersion === undefined) {
        return {
          installations: [],
          quantity: product.quantity,
          subproducts: [],
        };
      }

      return {
        installations: primaryVersion.installations,
        quantity: product.quantity,
        subproducts: primaryVersion.subproducts,
      };
    }
  );

  const contributionMargin = productPrimaryVersions.reduce(
    (sum, version) =>
      getContributionMarginTotal(
        version.installations,
        version.subproducts,
        version.quantity
      ) + sum,
    0
  );

  const sellingPriceSum = productPrimaryVersions.reduce(
    (sum, version) =>
      getSellingPriceTotal(
        version.installations,
        version.subproducts,
        version.quantity
      ) + sum,
    0
  );

  const contributionMarginRatio = (contributionMargin / sellingPriceSum) * 100;

  const contributionMarginFormatted = `${formatFloatForReadOnlyDisplay(
    contributionMargin
  )}€`;

  const contributionMarginRatioFormatted = `${formatFloatForReadOnlyDisplay(
    contributionMarginRatio
  )}%`;

  const priceFormatted = `${formatFloatForReadOnlyDisplay(sellingPriceSum)}€`;

  return (
    <>
      <Row>
        <Col>
          <h3 className="compact-header">
            {title == null
              ? t(`${translations.common}:Price`).toUpperCase()
              : title}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md="3" lg="2">
          <ReadOnlyTextInput
            label={`${t(`${translations.offers}:Contribution margin`)} €`}
          >
            {contributionMarginFormatted}
          </ReadOnlyTextInput>
        </Col>
        <Col md="3" lg="2">
          <ReadOnlyTextInput
            label={`${t(`${translations.offers}:Contribution margin`)} %`}
          >
            {contributionMarginRatioFormatted}
          </ReadOnlyTextInput>
        </Col>
        <Col md="3" lg="2">
          <ReadOnlyTextInput label={t(`${translations.common}:Total`)}>
            {priceFormatted}
          </ReadOnlyTextInput>
        </Col>
      </Row>
    </>
  );
};

export default OfferProductPriceSummary;
