import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import IHindranceGet from 'api/interfaces/projects/IHindranceGet';
import NumberInputField from 'components/layout/forms/NumberInputField';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import IHindrancePost from 'interfaces/projects/IHindrancePost';
import translations from 'services/translations/translations';
import DeleteIcon from 'components/layout/icons/DeleteIcon';

const HindranceRows = ({
  hindranceTypesList,
  hindranceFormValues,
  updateHindrance,
  deleteRow,
}: {
  hindranceTypesList: IHindranceGet[];
  hindranceFormValues: IHindrancePost[];
  updateHindrance: any;
  deleteRow: any;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.products]);

  const getOptions = (options: IHindranceGet[]) => [
    <option key="opt00" value={undefined} />,
    ...options.map((option) => (
      <option key={`opt${option.type}`} value={option.type}>
        {t(`${translations.projects}:hindrances.${option.type}`)}
      </option>
    )),
  ];

  return (
    <>
      {hindranceFormValues.map((x, i) => (
        <Row key={x.type}>
          <Col>
            <SelectInputFieldControlled
              value={x.type}
              label={t(`${translations.projects}:Type`)}
              required
              id={`type${i}`}
              name="type"
              onChange={(e: any) => updateHindrance(e, i)}
              options={getOptions(hindranceTypesList)}
            />
          </Col>
          <Col>
            <NumberInputField
              value={x.value.toString()}
              label={t(`${translations.projects}:Amount`)}
              required
              id={`value${i}`}
              name="value"
              onChange={(e: any) => updateHindrance(e, i)}
            />
          </Col>
          <Col>
            <DeleteIcon onClick={() => deleteRow(i, hindranceFormValues)} />
          </Col>
        </Row>
      ))}
    </>
  );
};

export default HindranceRows;
