import React from 'react';

import getTargetValue from 'services/forms/getTargetValue';
import IProductConfigurationAttribute from 'interfaces/products/IProductConfigurationAttribute';
import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';

import { NAME } from 'services/products/productConfigurationAttributeTypes';
import { SELECT_OPTION } from 'services/products/productConfigurationItemTypes';

import ISelection from './ISelection';
import getSelectSelectedProducts from './getSelectSelectedProducts';
import getProductName from './getProductName';

const Select = ({
  item,
  selections,
  setSelection,
}: {
  item: IProductConfigurationItem;
  selections: ISelection[];
  setSelection: (
    selection: null | number,
    item: IProductConfigurationItem
  ) => void;
}): JSX.Element => {
  const nameAttribute = item.attributes.find(
    (attribute: IProductConfigurationAttribute) => attribute.type === NAME
  );

  const name = nameAttribute === undefined ? '' : nameAttribute.value;

  const currentSelection = selections.find(
    (selection: ISelection) => selection.id === item.id
  );

  const currentValue =
    currentSelection === undefined ? null : currentSelection.selection;

  const getOptions = () => [
    <option key={`item-${item.id}-option-empty`} value="">
      -
    </option>,
    ...item.children
      .filter(
        (child: IProductConfigurationItem) => child.type === SELECT_OPTION
      )
      .map((product: IProductConfigurationItem) => {
        const productNameAttribute = product.attributes.find(
          (attribute: IProductConfigurationAttribute) => attribute.type === NAME
        );

        const productName =
          productNameAttribute === undefined ? '' : productNameAttribute.value;

        return (
          <option
            key={`item-${item.id}-option-${product.id}`}
            value={product.id}
          >
            {productName}
          </option>
        );
      }),
  ];

  const selectedProducts = getSelectSelectedProducts(item, selections);

  return (
    <>
      <SelectInputFieldControlled
        id={`configuration-select-${item.id}`}
        label={name === null ? '' : name}
        onChange={(e: any) => {
          const value = getTargetValue(e);
          setSelection(value === '' ? null : parseInt(value, 10), item);
        }}
        options={getOptions()}
        required
        value={currentValue === null ? '' : currentValue}
      />
      {selectedProducts.map((selectedProduct) => (
        <p>{getProductName(selectedProduct)}</p>
      ))}
    </>
  );
};

export default Select;
