import IUserGet from 'api/interfaces/users/IUserGet';

import IOrganizationUnit from 'interfaces/users/IOrganizationUnit';
import IOrganizationUnitPost from 'interfaces/users/IOrganizationUnitPost';
import IRole from 'interfaces/users/IRole';
import IUserGetSingle from 'api/interfaces/users/IUserGetSingle';
import IUserPost from 'api/interfaces/users/IUserPost';
import IUserPut from 'api/interfaces/users/IUserPut';

import api from './api';

export default {
  delete: async (userId: string): Promise<any> =>
    api.delete(`/api/v1/users/${userId}`),
  deleteRole: async (roleId: string): Promise<any> =>
    api.delete(`/api/v1/roles/${roleId}`),
  get: async (queryParams?: {
    search?: string;
    ownOrganizationUnit?: boolean;
  }): Promise<IUserGet[]> => api.get('/api/v1/users', queryParams),
  getCurrentUser: async (): Promise<IUserGet[]> => api.get('/api/v1/user'),
  getRoles: async (): Promise<IRole[]> => api.get('/api/v1/roles'),
  getRole: async (roleId: string): Promise<IRole> =>
    api.get(`/api/v1/roles/${roleId}`),
  getUser: async (userId: string): Promise<IUserGetSingle> =>
    api.get(`/api/v1/users/${userId}`),
  organizationUnits: {
    delete: async (orgId: string): Promise<any> =>
      api.delete(`/api/v1/organizationunits/${orgId}`),
    get: async (): Promise<IOrganizationUnit[]> =>
      api.get('/api/v1/organizationunits'),
    getSingle: async (orgId: string): Promise<IOrganizationUnit> =>
      api.get(`/api/v1/organizationunits/${orgId}`),
    post: async (org: IOrganizationUnitPost): Promise<any> =>
      api.post('/api/v1/organizationunits', { ...org }),
    put: async (org: IOrganizationUnitPost, orgId: string): Promise<any> =>
      api.put(`/api/v1/organizationunits/${orgId}`, { ...org }),
  },
  post: async (user: IUserPost): Promise<any> =>
    api.post('/api/v1/users', { ...user }),
  postRole: async (role: IRole): Promise<any> =>
    api.post('/api/v1/roles', { ...role }),
  put: async (user: IUserPut, userId: string): Promise<any> =>
    api.put(`/api/v1/users/${userId}`, { ...user }),
  putRole: async (role: IRole, roleId: string): Promise<any> =>
    api.put(`/api/v1/roles/${roleId}`, { ...role }),
};
