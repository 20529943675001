import axios from 'axios';
import store from '../store/store';
import { silentLoginRequest } from '../services/users/authConfig';
import { setAccessToken as setAccessTokenAction } from '../store/users/actions';

const getConfig = async (queryParams) => {
  const { users } = store.getState();

  const msalSilentLoginRequest = silentLoginRequest(users.msalAccount);

  const config = { headers: {} };
  if (typeof queryParams !== 'undefined') {
    config.params = queryParams;
  }

  if (
    msalSilentLoginRequest.redirectUri == null ||
    msalSilentLoginRequest.redirectUri === ''
  ) {
    return config;
  }

  try {
    const msalResponse = await users.msalInstance?.acquireTokenSilent(
      msalSilentLoginRequest
    );
    config.headers.Authorization = `Bearer ${msalResponse.idToken}`;
  } catch (e) {
    console.log('Token fetch failed');
    return config;
  }

  return config;
};

export default {
  delete: async (url) => {
    const result = await axios.delete(url, await getConfig());
    return result.data;
  },
  get: async (url, queryParams) => {
    const result = await axios.get(url, await getConfig(queryParams));
    return result.data;
  },
  getFile: async (url, queryParams) => {
    const config = await getConfig(queryParams);
    config.responseType = 'arraybuffer';
    return axios.get(url, config);
  },
  getPdf: async (url, queryParams) => {
    const config = await getConfig(queryParams);
    config.responseType = 'blob';
    return axios.get(url, config);
  },
  patch: async (url, data) => {
    const result = await axios.patch(url, data, await getConfig());
    return result.data;
  },
  post: async (url, data) => {
    const result = await axios.post(url, data, await getConfig());
    return result.data;
  },
  postFile: async (url, data) => {
    const config = await getConfig();
    config.headers['Content-Type'] = 'multipart/form-data';
    const result = await axios.post(url, data, config);
    return result.data;
  },
  put: async (url, data) => {
    const result = await axios.put(url, data, await getConfig());
    return result.data;
  },
};
