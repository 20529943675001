import React from 'react';
import { Route } from 'react-router-dom';

import { ROLES, USERS } from 'services/types/viewTypes';
import OrganizationUnit from 'components/userManagement/OrganizationUnit';
import Role from 'components/userManagement/Role';
import User from 'components/userManagement/User';
import UserManagement from 'components/userManagement/UserManagement';
import Permission from 'components/layout/Permission';

const users = (): JSX.Element[] => [
  <Route
    exact
    key="create-organization-unit-route"
    path="/organizationunits/create"
  >
    <Permission type={USERS}>
      <OrganizationUnit />
    </Permission>
  </Route>,
  <Route exact key="organization-unit-route" path="/organizationunits/:orgId">
    <Permission type={USERS}>
      <OrganizationUnit />
    </Permission>
  </Route>,
  <Route exact key="create-role-route" path="/roles/create">
    <Permission type={ROLES}>
      <Role />
    </Permission>
  </Route>,
  <Route exact key="role-route" path="/roles/:roleId">
    <Permission type={ROLES}>
      <Role />
    </Permission>
  </Route>,
  <Route exact key="users-route" path="/users">
    <Permission type={USERS || ROLES}>
      <UserManagement />
    </Permission>
  </Route>,
  <Route exact key="create-user-route" path="/users/create">
    <Permission type={USERS}>
      <User />
    </Permission>
  </Route>,
  <Route exact key="user-route" path="/users/:userId">
    <Permission type={USERS}>
      <User />
    </Permission>
  </Route>,
];

export default users;
