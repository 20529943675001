import {
  ADD_CONFIRM,
  ADD_MESSAGE,
  CLOSE_CONFIRM,
  CLOSE_MESSAGE,
} from './types';

interface IConfirm {
  callback: () => void;
  message: string;
}

interface IState {
  confirms: IConfirm[];
  messages: string[];
}

const initialState = {
  confirms: [],
  messages: [],
};

export default (
  state: IState = initialState,
  action: {
    confirm: IConfirm;
    message: string;
    type: string;
  }
): IState => {
  if (action.type === ADD_CONFIRM) {
    return {
      ...state,
      confirms: [...state.confirms, action.confirm],
    };
  }

  if (action.type === ADD_MESSAGE) {
    return {
      ...state,
      messages: [...state.messages, action.message],
    };
  }

  if (action.type === CLOSE_CONFIRM) {
    const currentConfirms = [...state.confirms];
    currentConfirms.shift();
    return {
      ...state,
      confirms: currentConfirms,
    };
  }

  if (action.type === CLOSE_MESSAGE) {
    const currentMessages = [...state.messages];
    currentMessages.shift();
    return {
      ...state,
      messages: currentMessages,
    };
  }

  return state;
};
