import React from 'react';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import apiInstallationPrices from 'api/installationPrices';
import IProductConfigurationInstallation from 'interfaces/products/IProductConfigurationInstallation';
import SearchDropdown from 'components/layout/forms/SearchDropdown';
import translations from 'services/translations/translations';
import { AMOUNT } from 'services/enums/units';

import 'style/products/configurations/src/ProductConfigurationProduct.css';

interface ILabelInstallationPrice {
  category: string;
  label: string;
  sellingPrice: number;
  sellingPriceUnit: string;
  value: string;
}

const ProductConfigurationInstallation = ({
  configurationInstallation,
  setConfigurationInstallation,
  showLabel,
  type,
}: {
  configurationInstallation: IProductConfigurationInstallation;
  setConfigurationInstallation: (
    configurationInstallation: IProductConfigurationInstallation
  ) => void;
  showLabel: boolean;
  type: string;
}): JSX.Element => {
  const { t } = useTranslation([translations.common, translations.products]);

  const loadInstallationsPrices = async (
    inputValue: string
  ): Promise<ILabelInstallationPrice[]> => {
    const list = await apiInstallationPrices.get({
      limit: 100,
      offset: 0,
      search: inputValue,
      type,
    });

    return list.map((installationPrice) => ({
      category: installationPrice.category,
      label: installationPrice.name,
      sellingPrice: installationPrice.sellingPrice,
      sellingPriceUnit: installationPrice.sellingPriceUnit,
      value: installationPrice.id.toString(),
    }));
  };

  const onChange = (newInstallation: ILabelInstallationPrice) => {
    setConfigurationInstallation({
      ...configurationInstallation,
      category:
        newInstallation === null
          ? configurationInstallation.category
          : newInstallation.category,
      id: newInstallation === null ? null : parseInt(newInstallation.value, 10),
      name: newInstallation === null ? '' : newInstallation.label,
      sellingPrice:
        newInstallation === null ? '' : newInstallation.sellingPrice.toString(),
      sellingPriceUnit:
        newInstallation === null ? AMOUNT : newInstallation.sellingPriceUnit,
    });
  };

  const getValue = () => ({
    category: configurationInstallation.category,
    label: configurationInstallation.name,
    value: configurationInstallation.id,
  });

  return (
    <div className="product-configuration-product">
      {showLabel ? (
        <Label className="label">
          {t(`${translations.products}:Installation price`)}
        </Label>
      ) : null}
      <SearchDropdown
        className="text-field search-field"
        loadOptions={loadInstallationsPrices}
        onChange={onChange}
        placeholder={t(`${translations.common}:Search`)}
        required
        value={getValue()}
      />
    </div>
  );
};

export default ProductConfigurationInstallation;
