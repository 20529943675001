import numericStringToFloat from 'services/numbers/numericStringToFloat';
import ISubproduct from './ISubproduct';
import IDetail from './IDetail';

export default (subproduct: ISubproduct | IDetail): number => {
  const expense =
    typeof subproduct.expense === 'number'
      ? subproduct.expense
      : numericStringToFloat(subproduct.expense);

  const wastePercentage =
    typeof subproduct.wastePercentage === 'number'
      ? subproduct.wastePercentage
      : numericStringToFloat(subproduct.wastePercentage);

  return expense * (1 + wastePercentage / 100);
};
