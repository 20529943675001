import ICalculationReview from '../../ICalculationReview';

const getDefaultCalculationReview = (
  key: number,
  unit: string
): ICalculationReview => ({
  key,
  quantity: '1',
  quantityUnit: unit,
});

export default getDefaultCalculationReview;
