import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import getNextObjectKey from 'services/numbers/getNextObjectKey';
import InputLabel from 'components/layout/forms/InputLabel';
import IProductInstallationCategory from 'api/interfaces/products/IProductInstallationCategory';
import LinkButton from 'components/layout/buttons/LinkButton';
import translations from 'services/translations/translations';

import { ACCOMMODATION } from 'services/enums/offers/installationTypes';

import { APPROVED } from 'services/enums/offers/Statuses';
import getDefaultInstallation from './getDefaultInstallation';
import IInstallation from '../../IInstallation';
import Installation from './Installation';
import InstallationsSum from './InstallationsSum';
import { OTHER_EXPENSE } from '../../../../services/installationTypes';

const OtherExpenses = ({
  initialStatus,
  installations,
  quantity,
  setInstallations,
}: {
  initialStatus: null | string;
  installations: IInstallation[];
  quantity: number;
  setInstallations: (installations: IInstallation[]) => void;
}): JSX.Element => {
  const { t } = useTranslation([translations.common, translations.offers]);

  const [openedInstallation, setOpenedInstallation] = useState<null | number>(
    null
  );

  const installationCategories = useSelector(
    (state: any) => state.products.installationCategories
  );

  const addOtherExpense = () => {
    const key = getNextObjectKey(installations, 'key');
    setInstallations([
      ...installations,
      getDefaultInstallation(key, ACCOMMODATION),
    ]);
  };

  const removeInstallation = (installation: IInstallation) => {
    setInstallations(
      installations.filter(
        (existingInstallation) => existingInstallation.key !== installation.key
      )
    );
  };

  const setInstallation = (installation: IInstallation) => {
    setInstallations(
      installations.map((existingInstallation) =>
        existingInstallation.key === installation.key
          ? installation
          : existingInstallation
      )
    );
  };

  const otherExpenses = installations.filter((installation) =>
    installationCategories.find(
      (installationCategory: IProductInstallationCategory) =>
        installationCategory.type === OTHER_EXPENSE &&
        installationCategory.category === installation.category
    )
  );

  const offerIsApproved = () => initialStatus === APPROVED;

  const SMALL_COLUMN_CLASS = 'small-column';

  return (
    <>
      <h2>{t(`${translations.calculationWindow}:Other expenses`)}</h2>
      <Row className="installation-row">
        <Col>
          <InputLabel>{t(`${translations.common}:Category`)}</InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {t(`${translations.calculationWindow}:Expense`)}
          </InputLabel>
        </Col>
        <Col className="icon-column" />
        <Col>
          <InputLabel>{t(`${translations.common}:Quantity`)}</InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {t(`${translations.calculationWindow}:Expense`)}
          </InputLabel>
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <InputLabel>
            {`${t(`${translations.calculationWindow}:Percentage`)} %`}
          </InputLabel>
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <InputLabel>{t(`${translations.offers}:Cost price`)}</InputLabel>
        </Col>
        <Col>
          <InputLabel>{t(`${translations.offers}:Selling price`)}</InputLabel>
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <InputLabel>
            {`${t(`${translations.offers}:Contribution margin`)} €`}
          </InputLabel>
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <InputLabel>
            {t(`${translations.offers}:Expense`)}{' '}
            {t(`${translations.common}:total-short`)}
          </InputLabel>
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <InputLabel>
            {t(`${translations.offers}:Selling price`)}{' '}
            {t(`${translations.common}:total-short`)}
          </InputLabel>
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <InputLabel>
            {t(`${translations.offers}:Contribution margin`)}{' '}
            {t(`${translations.common}:total-short`)} €
          </InputLabel>
        </Col>
        <Col className={SMALL_COLUMN_CLASS}>
          <InputLabel>
            {`${t(`${translations.offers}:Contribution margin`)} %`}
          </InputLabel>
        </Col>
        <Col className="icon-column" />
      </Row>
      {otherExpenses.map((installation) => (
        <Installation
          initialStatus={initialStatus}
          installation={installation}
          key={`installation-${installation.key}`}
          openedInstallation={openedInstallation}
          quantity={quantity}
          removeInstallation={removeInstallation}
          setInstallation={setInstallation}
          setOpenedInstallation={setOpenedInstallation}
          type={OTHER_EXPENSE}
        />
      ))}
      {offerIsApproved() ? null : (
        <LinkButton onClick={addOtherExpense}>
          {`+ ${t(`${translations.common}:Add row`)}`}
        </LinkButton>
      )}
      <InstallationsSum quantity={quantity} installations={otherExpenses} />
    </>
  );
};

export default OtherExpenses;
