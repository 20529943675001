import React from 'react';
import { FormGroup, Label } from 'reactstrap';

import 'style/layout/form/ReadOnlyTextInput.css';

export default ({
  children,
  label,
  verticalAlignMatchInput,
}: {
  children: string | JSX.Element | JSX.Element[];
  label: string;
  verticalAlignMatchInput?: boolean;
}): JSX.Element => (
  <FormGroup className="read-only-text-input">
    <Label className="label">{label}</Label>
    <div className={verticalAlignMatchInput ? 'match-input-valign' : ''}>
      {children}
    </div>
  </FormGroup>
);
