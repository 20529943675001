import IProductConfigurationAttribute from 'interfaces/products/IProductConfigurationAttribute';
import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';

import {
  CUSTOM_PRODUCT,
  IS_CUSTOM_SUBPRODUCT,
  PRODUCT_ID,
} from 'services/products/productConfigurationAttributeTypes';

const isCustomSubproduct = (item: IProductConfigurationItem) => {
  const isCustomSubproductAttribute = item.attributes.find(
    (attribute: IProductConfigurationAttribute) =>
      attribute.type === IS_CUSTOM_SUBPRODUCT
  );

  if (isCustomSubproductAttribute === undefined) {
    return false;
  }

  return isCustomSubproductAttribute.value === '1';
};

const getCustomProductName = (item: IProductConfigurationItem): string => {
  const customProductAttribute = item.attributes.find(
    (attribute: IProductConfigurationAttribute) =>
      attribute.type === CUSTOM_PRODUCT
  );

  if (customProductAttribute === undefined) {
    return '';
  }

  if (customProductAttribute.value === null) {
    return '';
  }

  return customProductAttribute.value;
};

export default (item: IProductConfigurationItem): string => {
  if (isCustomSubproduct(item)) {
    return getCustomProductName(item);
  }

  const productIdAttribute = item.attributes.find(
    (attribute: IProductConfigurationAttribute) => attribute.type === PRODUCT_ID
  );

  if (productIdAttribute === undefined) {
    return '';
  }

  if (productIdAttribute.displayValue === undefined) {
    return '';
  }

  if (productIdAttribute.displayValue === null) {
    return '';
  }

  return productIdAttribute.displayValue;
};
