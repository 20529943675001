import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import { AMOUNT } from 'services/enums/units';

import {
  CATEGORY,
  CUSTOM_INSTALLATION,
  INSTALLATION_PRICE,
  INSTALLATION_PRICE_UNIT,
  QUANTITY,
  QUANTITY_UNIT,
  SELLING_PRICE,
  SELLING_PRICE_UNIT,
  SOCIAL_SECURITY_COST_PERCENTAGE,
} from 'services/products/productConfigurationAttributeTypes';

const getDefaultInstallationItem = (
  type: string,
  category: string
): IProductConfigurationItem => ({
  attributes: [
    { type: CATEGORY, value: category },
    { type: CUSTOM_INSTALLATION, value: '' },
    { type: INSTALLATION_PRICE, value: '' },
    { type: INSTALLATION_PRICE_UNIT, value: AMOUNT },
    { type: QUANTITY, value: '' },
    { type: QUANTITY_UNIT, value: AMOUNT },
    { type: SELLING_PRICE, value: '' },
    { type: SELLING_PRICE_UNIT, value: '' },
    { type: SOCIAL_SECURITY_COST_PERCENTAGE, value: '' },
  ],
  children: [],
  sortIndex: 0,
  type,
});

export default getDefaultInstallationItem;
