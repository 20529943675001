import React, { useState, useRef, useEffect } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import translations from 'services/translations/translations';

import 'style/layout/form/SearchInput.css';

const SearchInput = (props: any) => {
  const { setSearchTerms } = props;
  const { t } = useTranslation([translations.common]);
  const [searchTemp, setSearchTemp] = useState<string | undefined>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      if (searchTemp !== undefined) {
        setSearchTerms(searchTemp);
      }
    }, 1500); // Timeout for the search input set to 1.5 seconds
  }, [searchTemp, setSearchTerms]);

  return (
    <>
      <FormGroup>
        <Input
          className="text-field"
          type="text"
          name="search"
          id="searchInput"
          placeholder={t(`${translations.common}:Search`)}
          onChange={(e) => setSearchTemp(e.target.value)}
        />
      </FormGroup>
    </>
  );
};

export default SearchInput;
