import getExpensePerUnit from './getExpensePerUnit';
import IInstallation from './IInstallation';
import ISubproduct from './ISubproduct';
import IDetail from './IDetail';

const getExpenseTotal = (
  installations: IInstallation[],
  subproducts: ISubproduct[] | IDetail[],
  quantity: number
): number => getExpensePerUnit(installations, subproducts) * quantity;

export default getExpenseTotal;
