import IPosition from 'interfaces/projects/IPosition';

const showPositionOptions = (positions: IPosition[]) =>
  positions.map((position: IPosition, index) => {
    return {
      value: position.reference,
      label: `${position.name}`,
    };
  });

export default showPositionOptions;
