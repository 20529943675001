import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import translations from 'services/translations/translations';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import 'style/layout/form/TextInputWithSubmit.css';

export default ({
  name,
  required,
  submit,
}: {
  name: string;
  required: boolean;
  submit: (form: any, e: any) => void;
}): JSX.Element => {
  const { t } = useTranslation([translations.common]);
  const { register, handleSubmit } = useForm();
  return (
    <Row>
      <Col xs={6}>
        <Row className="button-margin-top">
          <Col className="no-padding" xs={12}>
            <form
              id={name}
              className="flex-form"
              onSubmit={handleSubmit(submit)}
            >
              <Col xs={9}>
                <input
                  className="text-field input-size"
                  name={name}
                  required={required}
                  ref={register}
                  type="text"
                />
              </Col>
              <Col xs={3}>
                <Button>{t(`${translations.common}:Add`).toUpperCase()}</Button>
              </Col>
            </form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
