const getColumns = (
  colTitles: any,
  getSquares: any,
  getPositionName: any,
  getMillimiterUnit: any
) => {
  return [
    {
      dataField: 'id',
      text: colTitles.position,
      formatter: getPositionName,
    },
    {
      dataField: 'height',
      text: colTitles.height,
      formatter: getMillimiterUnit,
    },
    {
      dataField: 'width',
      text: colTitles.width,
      formatter: getMillimiterUnit,
    },
    {
      dataField: 'squares',
      text: colTitles.squares,
      formatter: getSquares,
    },
  ];
};

export default getColumns;
