import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InputLabel from 'components/layout/forms/InputLabel';
import IProductConfiguration from 'interfaces/products/IProductConfiguration';
import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import isOtherExpenseItem from 'services/products/configurations/isOtherExpenseItem';
import translations from 'services/translations/translations';

import {
  INSTALLATION,
  PRODUCT,
  PRODUCT_SELECT,
  SELECT,
} from 'services/products/productConfigurationItemTypes';

import getProductName from './getProductName';
import ISelection from './ISelection';
import ProductSelect from './ProductSelect';
import Select from './Select';
import getInstallationName from './getInstallationName';

const Configuration = ({
  configuration,
  selections,
  setSelections,
}: {
  configuration: IProductConfiguration | null;
  selections: ISelection[];
  setSelections: (selections: ISelection[]) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([
    translations.common,
    translations.offers,
    translations.calculationWindow,
  ]);

  const installationCategories = useSelector(
    (state: any) => state.products.installationCategories
  );

  if (configuration === null) {
    return null;
  }

  const setSelection = (
    selection: null | number,
    item: IProductConfigurationItem
  ) => {
    setSelections(
      selections.map((existingSelection) => ({
        ...existingSelection,
        selection:
          existingSelection.id === item.id
            ? selection
            : existingSelection.selection,
      }))
    );
  };

  const getItem = (item: IProductConfigurationItem) => {
    if (item.type === INSTALLATION) {
      return (
        <>
          <InputLabel>
            {isOtherExpenseItem(item, installationCategories)
              ? t(`${translations.productConfigurations}:Other expense`)
              : t(`${translations.productConfigurations}:Installation`)}
          </InputLabel>
          <p>{getInstallationName(item)}</p>
        </>
      );
    }

    if (item.type === PRODUCT) {
      return (
        <>
          <InputLabel>{t(`${translations.products}:Product`)}</InputLabel>
          <p>{getProductName(item)}</p>
        </>
      );
    }

    if (item.type === PRODUCT_SELECT) {
      return (
        <ProductSelect
          item={item}
          selections={selections}
          setSelection={setSelection}
        />
      );
    }

    if (item.type === SELECT) {
      return (
        <Select
          item={item}
          selections={selections}
          setSelection={setSelection}
        />
      );
    }

    return null;
  };

  return (
    <>
      {configuration.items.map((item) => getItem(item))}{' '}
      {configuration.offerText ? (
        <>
          <InputLabel>
            {t(`${translations.calculationWindow}:Offer text`)}
          </InputLabel>
          <p>{configuration.offerText}</p>
        </>
      ) : null}
    </>
  );
};

export default Configuration;
