const KGM_KEY = 'glassConfProductskg-in-m';
const LENGTH_KEY = 'glassConfProductsLength';
const NAME_KEY = 'glassConfProductsName';
const PAINTING_AREA_KEY = 'glassConfProductsPaintingArea';
const PAINTING_1_KEY = 'glassConfProductsPainting-1';
const PAINTING_2_KEY = 'glassConfProductsPainting-2';
const PAINTING_3_KEY = 'glassConfProductsPainting-3';
const PAINTING_4_KEY = 'glassConfProductsPainting-4';
const PURCHASE_PRICE_KEY = 'glassConfProductsPurchasePrice';
const PURCHASE_UNIT_KEY = 'glassConfProductsPurchasePriceUnit';
const SUPPLIER_ID_KEY = 'glassConfProductsSupplierId';
const IMAGE_ID_KEY = 'glassConfProductsImageId';
const WASTE_KEY = 'glassConfProductsWaste';

export {
  KGM_KEY,
  LENGTH_KEY,
  NAME_KEY,
  PAINTING_1_KEY,
  PAINTING_2_KEY,
  PAINTING_3_KEY,
  PAINTING_4_KEY,
  PAINTING_AREA_KEY,
  PURCHASE_PRICE_KEY,
  PURCHASE_UNIT_KEY,
  SUPPLIER_ID_KEY,
  IMAGE_ID_KEY,
  WASTE_KEY,
};
