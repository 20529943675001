import React from 'react';
import { useTranslation } from 'react-i18next';

import apiProjects from 'api/projects';
import downloadFile from 'services/files/downloadFile';
import IFile from 'interfaces/forms/IFile';
import ImagesWithPreviewInput from 'components/layout/forms/ImagesWithPreviewInput';
import translations from 'services/translations/translations';

import 'style/projects/projectMeasurementTab/addMeasurement/PlaceImages.css';

const PlaceImages = ({
  files,
  setFiles,
}: {
  files: IFile[];
  setFiles: any;
}): JSX.Element => {
  const { t } = useTranslation([translations.common]);

  const addFile = (file: IFile) => {
    setFiles([...files, file]);
  };

  const removeFile = (file: IFile) => {
    setFiles(
      files.filter((existingFile: IFile) => existingFile.id !== file.id)
    );
  };

  const onDownloadFile = async (file: IFile) => {
    if (file.id === null) {
      return;
    }

    const response = await apiProjects.files.get(file.id);
    downloadFile(response.data, response.headers);
  };

  return (
    <div className="place-images">
      <h3>{t('Images')}</h3>
      <ImagesWithPreviewInput
        addFile={addFile}
        downloadFile={onDownloadFile}
        files={files}
        id="place-images"
        removeFile={removeFile}
      />
      <div className="clearfix" />
    </div>
  );
};

export default PlaceImages;
