const isNumber = (answer: string): boolean => {
  // Pattern looking for integer or decimal with comma or dot
  if (/[A-Za-z]/.test(answer)) {
    return false;
  }

  const dotCount = (answer.match(/\./g) || []).length;
  const commaCount = (answer.match(/,/g) || []).length;
  if (dotCount + commaCount > 1) {
    return false;
  }

  if (
    /[+-]?([1-9]\d*(,\d*[1-9])?|0,\d*[1-9]+)|\d+(,\d*[1-9])?/.test(answer) ||
    /[+-]?([1-9]\d*(\.\d*[1-9])?|0\.\d*[1-9]+)|\d+(\.\d*[1-9])?/.test(answer)
  ) {
    return true;
  }

  return false;
};

export default isNumber;
