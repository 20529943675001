import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'style/layout/modal/ModalConfirm.css';

const ModalConfirm = ({
  showing,
  setShowing,
  header,
  text,
  yesButton,
  noButton,
  confirm,
}: {
  showing: boolean;
  setShowing: (showing: boolean) => void;
  header: string;
  text: string;
  yesButton: string;
  noButton: string;
  confirm: () => void;
}): JSX.Element | null => {
  const toggle = () => setShowing(!showing);

  return (
    <>
      <Modal isOpen={showing} toggle={toggle} centered>
        <ModalHeader className="modal-header" toggle={toggle}>
          {header}
        </ModalHeader>
        <ModalBody>{text}</ModalBody>
        <ModalFooter>
          <Button onClick={confirm} className="modal-button">
            {yesButton}
          </Button>
          <Button onClick={toggle} className="modal-button">
            {noButton}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalConfirm;
