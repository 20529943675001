import IAttribute from 'interfaces/products/IAttribute';
import getTargetValue from 'services/forms/getTargetValue';

const updateAttribute = (
  attributeFormValues: IAttribute[],
  e: any,
  index: number
): IAttribute[] => {
  const newValues = [...attributeFormValues];
  const type = e.target.name;
  if (type === 'attributeType') {
    newValues[index].type = getTargetValue(e);
    newValues[index].value = '';
  } else if (type === 'value') {
    newValues[index].value = getTargetValue(e);
  }
  return newValues;
};

export default updateAttribute;
