import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';

const getAttributeValue = (
  item: IProductConfigurationItem,
  type: string
): string => {
  const existingAttribute = item.attributes.find(
    (attribute) => attribute.type === type
  );

  if (existingAttribute === undefined) {
    return '';
  }

  return existingAttribute.value === null ? '' : existingAttribute.value;
};

export default getAttributeValue;
