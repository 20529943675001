import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';
import isOtherExpenseItem from 'services/products/configurations/isOtherExpenseItem';
import translations from 'services/translations/translations';

import {
  CUSTOM_INSTALLATION,
  INSTALLATION_ID,
  IS_CUSTOM_INSTALLATION,
} from 'services/products/productConfigurationAttributeTypes';

import Actions from './Actions';
import getAttribute from '../../getAttribute';

const ProductItem = ({
  item,
  onDelete,
}: {
  item: IProductConfigurationItem;
  onDelete: (itemId: number) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.products]);

  const getInstallationName = () => {
    const isCustomInstallation = getAttribute(item, IS_CUSTOM_INSTALLATION);

    if (isCustomInstallation?.value !== '0') {
      const customInstallation = getAttribute(item, CUSTOM_INSTALLATION);
      return customInstallation === undefined ? '' : customInstallation.value;
    }

    const installation = getAttribute(item, INSTALLATION_ID);
    return installation === undefined ? '' : installation.displayValue;
  };

  const installationCategories = useSelector(
    (state: any) => state.products.installationCategories
  );

  const getTitle = () => {
    if (isOtherExpenseItem(item, installationCategories)) {
      return t(`${translations.productConfigurations}:Other expense`);
    }

    return t(`${translations.productConfigurations}:Installation`);
  };

  return (
    <div className="product-item">
      <div>
        <h4 className="item-title">
          {getTitle()}: {getInstallationName()}
        </h4>
        <Actions item={item} onDelete={onDelete} />
        <div className="clearfix" />
      </div>
    </div>
  );
};

export default ProductItem;
