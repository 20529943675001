import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getFormStateHandler from 'services/forms/getFormStateHandler';
import getSellingPricePerUnit from 'services/products/calculations/getSellingPricePerUnit';
import LinkButton from 'components/layout/buttons/LinkButton';
import NumberWithDropdownInputField from 'components/layout/forms/NumberWithDropdownInputField';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';
import { fetchGroups, fetchUnits } from 'store/products/actions';

import { APPROVED } from 'services/enums/offers/Statuses';
import ActionButtons from './ActionButtons';
import getProductGroupOptions from './getProductGroupOptions';
import getProductUnitOptions from './getProductUnitOptions';
import IFormState from './IFormState';
import VersionsSummaryTable from './VersionsSummaryTable';

const ProductFields = ({
  cancel,
  formState,
  initialStatus,
  openCalculationWindow,
  remove,
  save,
  setFormState,
}: {
  cancel: () => void;
  formState: IFormState;
  initialStatus: null | string;
  openCalculationWindow: () => void;
  remove?: () => void;
  save: (preventRedirect: boolean) => void;
  setFormState: (formState: IFormState) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([
    translations.common,
    translations.offers,
    translations.productConfigurations,
    translations.products,
  ]);

  const dispatch = useDispatch();
  const groups = useSelector((state: any) => state.products.groups);
  const units = useSelector((state: any) => state.products.units);

  const handleFormState = getFormStateHandler(formState, setFormState);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchUnits());
  }, []);

  const primaryVersion = formState.versions.find(
    (version) => version.isPrimary
  );

  if (primaryVersion === undefined) {
    return null;
  }

  const sellingPricePerUnit = getSellingPricePerUnit(
    primaryVersion.installations,
    primaryVersion.subproducts
  );

  const offerIsApproved = () => initialStatus === APPROVED;

  const priceUnit =
    formState.unit === ''
      ? '€'
      : `€/${t(`${translations.common}:units:${formState.unit}`)}`;

  return (
    <>
      <Row>
        <Col>
          <SelectInputFieldControlled
            id="product-group"
            label={t(`${translations.products}:Product Group`)}
            name="productGroup"
            onChange={handleFormState}
            options={getProductGroupOptions(groups, t)}
            required
            value={formState.productGroup}
            disabled={offerIsApproved()}
          />
        </Col>
        <Col>
          <TextInputField
            id="name"
            label={t(`${translations.products}:Product`)}
            name="name"
            onChange={handleFormState}
            required
            value={formState.name}
            disabled={offerIsApproved()}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <NumberWithDropdownInputField
            disabled={offerIsApproved()}
            dropdownName="unit"
            dropdownOnChange={handleFormState}
            dropdownOptions={getProductUnitOptions(units, t)}
            dropdownValue={formState.unit}
            id="quantity-and-unit"
            label={t(`${translations.common}:Quantity`)}
            numberName="quantity"
            numberOnChange={handleFormState}
            numberValue={formState.quantity}
            required
          />
        </Col>
        <Col sm="3">
          <TextInputField
            id="position"
            label={t(`${translations.products}:Position`)}
            name="position"
            onChange={handleFormState}
            required
            value={formState.position}
            disabled={offerIsApproved()}
          />
        </Col>
        <Col className="price-cell" sm="3">
          <NumberWithUnitInputField
            disabled
            id="price"
            label={t(`${translations.common}:Price`)}
            name="price"
            unit={priceUnit}
            value={formatFloatForDisplay(sellingPricePerUnit)}
          />
          <LinkButton onClick={openCalculationWindow}>
            {t(`${translations.offers}:Calculation window`)}
          </LinkButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <VersionsSummaryTable
            formState={formState}
            setFormState={setFormState}
          />
        </Col>
      </Row>
      <ActionButtons
        initialStatus={initialStatus}
        cancel={cancel}
        remove={remove}
        save={() => save(false)}
        saveButtonText={t(`${translations.common}:Save`)}
      />
    </>
  );
};

export default ProductFields;
