import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import apiInstallationPrices from 'api/installationPrices';
import Checkbox from 'components/layout/forms/Checkbox';
import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getContributionMarginPerUnit from 'services/products/calculations/getContributionMarginPerUnit';
import getContributionMarginRatio from 'services/products/calculations/getContributionMarginRatio';
import getExpensePerUnit from 'services/products/calculations/getExpensePerUnit';
import getInstallationCategoryOptions from 'components/offers/services/getInstallationCategoryOptions';
import getSellingPriceUnitOptions from 'components/offers/services/getSellingPriceUnitOptions';
import getUnitOptions from 'components/offers/services/getUnitOptions';
import IProductConfigurationInstallation from 'interfaces/products/IProductConfigurationInstallation';
import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import isOtherExpenseItem from 'services/products/configurations/isOtherExpenseItem';
import NumberWithDropdownInputField from 'components/layout/forms/NumberWithDropdownInputField';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';

import {
  fetchInstallationCategories,
  fetchUnits,
} from 'store/products/actions';

import {
  CATEGORY,
  CUSTOM_INSTALLATION,
  INSTALLATION_ID,
  INSTALLATION_PRICE,
  INSTALLATION_PRICE_UNIT,
  IS_CUSTOM_INSTALLATION,
  QUANTITY,
  QUANTITY_UNIT,
  SELLING_PRICE,
  SELLING_PRICE_UNIT,
  SOCIAL_SECURITY_COST_PERCENTAGE,
} from 'services/products/productConfigurationAttributeTypes';

import {
  INSTALLATION,
  OTHER_EXPENSE,
} from 'components/offers/services/installationTypes';

import attributeValueUpdater from './attributeValueUpdater';
import getAttributeValue from '../getAttributeValue';
import ProductConfigurationInstallation from '../ProductConfigurationInstallation';
import updateInstallationUnitsToValidValues from './updateInstallationUnitsToValidValues';

const InstallationItem = ({
  item,
  setItem,
}: {
  item: IProductConfigurationItem;
  setItem: (item: IProductConfigurationItem) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.offers, translations.products]);
  const dispatch = useDispatch();
  const units = useSelector((state: any) => state.products.units);

  const installationCategories = useSelector(
    (state: any) => state.products.installationCategories
  );

  const [installation, setInstallation] = useState<
    IProductConfigurationInstallation
  >({
    category: '',
    id: null,
    key: '',
    name: '',
    sellingPrice: '',
    sellingPriceUnit: '',
  });

  useEffect(() => {
    dispatch(fetchInstallationCategories());
    dispatch(fetchUnits());
  }, []);

  const updateAttributeValues = attributeValueUpdater(item, setItem);

  const initializeInstallation = async (installationId: number) => {
    const apiInstallation = await apiInstallationPrices.getInstallation(
      installationId
    );

    setInstallation({
      ...installation,
      id: installationId,
      name: apiInstallation.name,
    });
  };

  useEffect(() => {
    updateInstallationUnitsToValidValues(item, updateAttributeValues);

    if (installation.id !== null) {
      return;
    }

    const installationPriceId = getAttributeValue(item, INSTALLATION_ID);
    if (installationPriceId === '') {
      return;
    }

    initializeInstallation(parseInt(installationPriceId, 10));
  }, [item]);

  const installationType = isOtherExpenseItem(item, installationCategories)
    ? OTHER_EXPENSE
    : INSTALLATION;

  const isCustomInstallation =
    getAttributeValue(item, IS_CUSTOM_INSTALLATION) !== '0';

  const onIsCustomInstallationChange = (e: any) => {
    updateAttributeValues([
      { type: IS_CUSTOM_INSTALLATION, value: e.target.checked ? '1' : '0' },
    ]);
  };

  const onInstallationChange = (
    newInstallation: IProductConfigurationInstallation
  ) => {
    updateAttributeValues([
      {
        type: CATEGORY,
        value: newInstallation.category,
      },
      {
        type: INSTALLATION_ID,
        value: newInstallation.id === null ? '' : newInstallation.id.toString(),
      },
      {
        type: INSTALLATION_PRICE,
        value: newInstallation.sellingPrice,
      },
      {
        type: QUANTITY_UNIT,
        value: newInstallation.sellingPriceUnit,
      },
    ]);

    setInstallation(newInstallation);
  };

  const getValue = (type: string) => {
    const existingAttribute = item.attributes.find(
      (attribute) => attribute.type === type
    );

    if (existingAttribute === undefined) {
      return '';
    }

    return existingAttribute.value === null ? '' : existingAttribute.value;
  };

  const sellingPriceUnitOptions = getSellingPriceUnitOptions(
    getValue(QUANTITY_UNIT),
    'product',
    t
  );

  const onTextValueChange = (type: string) => (event: any) => {
    updateAttributeValues([{ type, value: event.target.value }]);
  };

  const getCustomNameTitle = () => {
    if (isOtherExpenseItem(item, installationCategories)) {
      return t(`${translations.calculationWindow}:Expense`);
    }

    return t(`${translations.calculationWindow}:Installation`);
  };

  const getInstallationPriceTitle = () => {
    if (isOtherExpenseItem(item, installationCategories)) {
      return t(`${translations.calculationWindow}:Expense`);
    }

    return t(`${translations.calculationWindow}:Installation price`);
  };

  const getPercentageTitle = () => {
    if (isOtherExpenseItem(item, installationCategories)) {
      return `${t(`${translations.calculationWindow}:Percentage`)} %`;
    }

    return `${t(`${translations.calculationWindow}:Social security cost`)} %`;
  };

  const getCategoryOptions = () => {
    if (isOtherExpenseItem(item, installationCategories)) {
      return getInstallationCategoryOptions(
        installationCategories,
        t,
        OTHER_EXPENSE
      );
    }

    return getInstallationCategoryOptions(
      installationCategories,
      t,
      INSTALLATION
    );
  };

  const installationForCalculations = {
    installationPrice: getValue(INSTALLATION_PRICE),
    installationPriceUnit: getValue(INSTALLATION_PRICE_UNIT),
    quantity: getValue(QUANTITY),
    quantityUnit: getValue(QUANTITY_UNIT),
    sellingPrice: getValue(SELLING_PRICE),
    sellingPriceUnit: getValue(SELLING_PRICE_UNIT),
    socialSecurityCostPercentage: getValue(SOCIAL_SECURITY_COST_PERCENTAGE),
  };

  const expenseForUnit = getExpensePerUnit([installationForCalculations], []);

  const contributionMarginForUnit = getContributionMarginPerUnit(
    [installationForCalculations],
    []
  );

  const contributionMarginRatio = getContributionMarginRatio(
    [installationForCalculations],
    []
  );

  return (
    <>
      <Row>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            <SelectInputFieldControlled
              disabled={!isCustomInstallation}
              id="product-category"
              label={t(`${translations.common}:Category`)}
              onChange={onTextValueChange(CATEGORY)}
              options={getCategoryOptions()}
              required
              value={getValue(CATEGORY)}
            />
          </div>
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            {isCustomInstallation ? (
              <TextInputField
                id="installation-custom-name"
                label={getCustomNameTitle()}
                onChange={onTextValueChange(CUSTOM_INSTALLATION)}
                value={getValue(CUSTOM_INSTALLATION)}
              />
            ) : (
              <ProductConfigurationInstallation
                configurationInstallation={installation}
                setConfigurationInstallation={onInstallationChange}
                showLabel
                type={installationType}
              />
            )}
          </div>
        </Col>
        <Col xs="3">
          <div className="field-wrapper">
            <Checkbox
              checked={isCustomInstallation}
              className="product-configuration-item-checkbox"
              label={t(`${translations.calculationWindow}:Custom installation`)}
              onChange={onIsCustomInstallationChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            <NumberWithDropdownInputField
              dropdownDisabled={!isCustomInstallation}
              dropdownOnChange={onTextValueChange(QUANTITY_UNIT)}
              dropdownOptions={getUnitOptions(units, t)}
              dropdownValue={getValue(QUANTITY_UNIT)}
              id="product-quantity"
              label={t(`${translations.common}:Quantity`)}
              numberOnChange={onTextValueChange(QUANTITY)}
              numberValue={getValue(QUANTITY)}
              required
            />
          </div>
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            <NumberWithDropdownInputField
              dropdownDisabled
              dropdownOnChange={onTextValueChange(INSTALLATION_PRICE_UNIT)}
              dropdownOptions={sellingPriceUnitOptions}
              dropdownValue={getValue(INSTALLATION_PRICE_UNIT)}
              id="installation-price-unit"
              label={getInstallationPriceTitle()}
              numberOnChange={onTextValueChange(INSTALLATION_PRICE)}
              numberValue={getValue(INSTALLATION_PRICE)}
              required
            />
          </div>
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            <NumberWithUnitInputField
              id="social-security-cost-percentage"
              label={getPercentageTitle()}
              onChange={onTextValueChange(SOCIAL_SECURITY_COST_PERCENTAGE)}
              required
              unit="%"
              value={getValue(SOCIAL_SECURITY_COST_PERCENTAGE)}
            />
          </div>
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <div className="field-wrapper">
            <NumberWithDropdownInputField
              dropdownDisabled
              dropdownOnChange={onTextValueChange(SELLING_PRICE_UNIT)}
              dropdownOptions={sellingPriceUnitOptions}
              dropdownValue={getValue(SELLING_PRICE_UNIT)}
              id="product-selling-price"
              label={t(`${translations.offers}:Selling price`)}
              numberOnChange={onTextValueChange(SELLING_PRICE)}
              numberValue={getValue(SELLING_PRICE)}
              required
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <NumberWithUnitInputField
            disabled
            label={t(`${translations.offers}:Cost price`)}
            unit="€"
            value={formatFloatForDisplay(expenseForUnit)}
          />
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <NumberWithUnitInputField
            disabled
            label={`${t(`${translations.offers}:Contribution margin`)} €`}
            unit="€"
            value={formatFloatForDisplay(contributionMarginForUnit)}
          />
        </Col>
        <Col xs="3" sm="3" md="3" lg="3" xl="2">
          <NumberWithUnitInputField
            disabled
            label={`${t(`${translations.offers}:Contribution margin`)} %`}
            unit="%"
            value={formatFloatForDisplay(contributionMarginRatio)}
          />
        </Col>
      </Row>
    </>
  );
};

export default InstallationItem;
