import IUserGet from 'api/interfaces/users/IUserGet';

const showUserOptions = (users: IUserGet[]) =>
  users.map((user: IUserGet, index) => {
    return {
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    };
  });

export default showUserOptions;
