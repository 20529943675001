const getPlaceSubColumns = (
  getPositionName: any,
  getMillimiterUnit: any,
  getSquares: any
) => {
  const subcolumns = [
    {
      dataField: 'positionId',
      text: '',
      formatter: getPositionName,
      classes: 'fixed-td',
    },
    {
      dataField: 'height',
      text: '',
      formatter: getMillimiterUnit,
      classes: 'fixed-td',
    },
    {
      dataField: 'width',
      text: '',
      formatter: getMillimiterUnit,
      classes: 'fixed-td',
    },
    {
      dataField: 'squares',
      text: '',
      formatter: getSquares,
      classes: 'fixed-td',
    },
  ];
  return subcolumns;
};

export default getPlaceSubColumns;
