const alphabeticSortByObjectProperty = (propertyName: string) => (
  object1: any,
  object2: any
): number => {
  if (object1[propertyName] === null && object2[propertyName] === null) {
    return 0;
  }

  if (object1[propertyName] === null && object2[propertyName] !== null) {
    return -1;
  }

  if (object1[propertyName] !== null && object2[propertyName] === null) {
    return 1;
  }

  if (object1[propertyName] === object2[propertyName]) {
    return 0;
  }

  return object1[propertyName] < object2[propertyName] ? -1 : 1;
};

export default alphabeticSortByObjectProperty;
