import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import apiProducts from 'api/products';
import IProductConfigurationsGet from 'api/interfaces/products/IProductConfigurationsGet';
import translations from 'services/translations/translations';

import { finishLoading, startLoading } from 'store/loading/actions';
import getCssVariable, { THEME_SECONDARY } from 'services/getCssVariable';

import getConfigurationOptions from './getConfigurationOptions';
import IFormState from '../../IFormState';

const ConfigurationDropdown = ({
  configurationId,
  formState,
  setConfigurationId,
}: {
  configurationId: string;
  formState: IFormState;
  setConfigurationId: (configurationId: string) => void;
}): JSX.Element => {
  const dispatch = useDispatch();

  const { t } = useTranslation([
    translations.offers,
    translations.productConfigurations,
  ]);

  const [configurations, setConfigurations] = useState<
    IProductConfigurationsGet[]
  >([]);

  const loadProductConfigurations = async () => {
    dispatch(startLoading('get-product-configurations'));

    setConfigurations(await apiProducts.configurations.getAll({}));

    dispatch(finishLoading('get-product-configurations'));
  };

  useEffect(() => {
    loadProductConfigurations();
  }, []);

  if (configurations.length === 0) {
    return (
      <>
        {t(
          `${translations.offers}:Product does not have available configurations`
        )}
      </>
    );
  }

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      minHeight: '50vh',
    }),
    menuList: (provided: any) => ({
      ...provided,
      minHeight: '50vh',
    }),
  };

  return (
    <div>
      <h3>
        {t(`${translations.productConfigurations}:Product configuration`)}
      </h3>
      <Select
        id="configuration-select"
        className="react-select-dropdown"
        onChange={(e: any) => {
          setConfigurationId(e.value);
        }}
        options={getConfigurationOptions(configurations)}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: getCssVariable(THEME_SECONDARY),
          },
        })}
      />
    </div>
  );
};

export default ConfigurationDropdown;
