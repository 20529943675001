import getContributionMarginPerUnit from 'services/products/calculations/getContributionMarginPerUnit';
import getContributionMarginRatio from 'services/products/calculations/getContributionMarginRatio';
import getContributionMarginTotal from 'services/products/calculations/getContributionMarginTotal';
import getExpensePerUnit from 'services/products/calculations/getExpensePerUnit';
import getExpenseTotal from 'services/products/calculations/getExpenseTotal';
import getSellingPricePerUnit from 'services/products/calculations/getSellingPricePerUnit';
import getSellingPriceTotal from 'services/products/calculations/getSellingPriceTotal';

import IInstallation from './IInstallation';
import ISubproduct from './ISubproduct';

const getPriceSummary = (
  installations: IInstallation[],
  subproducts: ISubproduct[],
  quantity: number
): {
  contributionMargin: number;
  contributionMarginForUnit: number;
  contributionMarginRatio: number;
  expenseSum: number;
  expenseSumForUnit: number;
  sellingPrice: number;
  sellingPriceForUnit: number;
} => {
  const sellingPriceForUnit = getSellingPricePerUnit(
    installations,
    subproducts
  );

  const expenseSumForUnit = getExpensePerUnit(installations, subproducts);

  const contributionMarginForUnit = getContributionMarginPerUnit(
    installations,
    subproducts
  );

  const contributionMarginRatio = getContributionMarginRatio(
    installations,
    subproducts
  );

  const contributionMargin = getContributionMarginTotal(
    installations,
    subproducts,
    quantity
  );

  const expenseSum = getExpenseTotal(installations, subproducts, quantity);

  const sellingPrice = getSellingPriceTotal(
    installations,
    subproducts,
    quantity
  );

  return {
    contributionMargin,
    contributionMarginForUnit,
    contributionMarginRatio,
    expenseSum,
    expenseSumForUnit,
    sellingPrice,
    sellingPriceForUnit,
  };
};

export default getPriceSummary;
