import IOfferProductGet from 'api/interfaces/offers/IOfferProductGet';
import nullToEmptyString from 'services/nullToEmptyString';
import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';

import IFormState from './IFormState';
import IOfferProductCalculationReview from '../../../../api/interfaces/offers/IOfferProductCalculationReview';
import numericStringToFloat from '../../../../services/numbers/numericStringToFloat';
import emptyStringToNull from '../../../../services/emptyStringsToNull';
import ICalculationReview from './ICalculationReview';

const formatFloat = (value: null | number): string =>
  value === null ? '0' : formatFloatForDisplay(value, 10);

const formatProduct = (
  product: null | {
    displayName: null | string;
    id: number;
    name: string;
  }
): {
  label: string;
  value: string;
} => {
  if (product === null) {
    return {
      label: '',
      value: '',
    };
  }

  const displayName = product.displayName === null ? '' : product.displayName;

  return {
    label: displayName === '' ? product.name : displayName,
    value: product.id.toString(),
  };
};

export default (product: IOfferProductGet): IFormState => ({
  memo: product.memo,
  name: product.name,
  position: product.position,
  productGroup: product.group,
  quantity: formatFloat(product.quantity),
  unit: product.unit,
  versions: product.versions.map((version) => ({
    averageHourlyEarnings: formatFloat(version.averageHourlyEarnings),
    id: version.id,
    calculationReviews:
      version.calculationReviews != null
        ? version.calculationReviews.map(
            (review, index): ICalculationReview => ({
              key: index,
              quantity: formatFloat(review.quantity),
              quantityUnit: nullToEmptyString(review.quantityUnit),
            })
          )
        : [],
    installations: version.installations.map((installation, index) => ({
      category: installation.category,
      customInstallation: nullToEmptyString(installation.customInstallation),
      installation: {
        label:
          installation.installation === null ||
          installation.installation === undefined
            ? ''
            : installation.installation.name,
        value:
          installation.installation === null ||
          installation.installation === undefined
            ? ''
            : installation.installation.id.toString(),
      },
      installationPrice: formatFloat(installation.installationPrice),
      installationPriceUnit: nullToEmptyString(
        installation.installationPriceUnit
      ),
      isCustomInstallation: installation.isCustomInstallation,
      key: index,
      quantity:
        installation.quantity === undefined
          ? '0'
          : formatFloat(installation.quantity),
      quantityUnit: nullToEmptyString(installation.quantityUnit),
      sellingPrice: formatFloat(installation.sellingPrice),
      sellingPriceUnit: nullToEmptyString(installation.sellingPriceUnit),
      socialSecurityCostPercentage: formatFloat(
        installation.socialSecurityCostPercentage
      ),
    })),
    name: version.name,
    isPrimary: version.isPrimary,
    offerText: version.offerText,
    showInOfferPdf: version.showInOfferPdf,
    subproducts: version.subproducts.map((subproduct, index) => ({
      calculatePriceFromDetails: subproduct.calculatePriceFromDetails,
      category: subproduct.category,
      customSubproduct: subproduct.customSubproduct,
      details: subproduct.details.map((detail, detailIndex) => ({
        customProductName: detail.customProductName,
        expense: formatFloat(detail.expense),
        expenseUnit: detail.expenseUnit === null ? '' : detail.expenseUnit,
        hasCustomProductName: detail.hasCustomProductName,
        key: detailIndex,
        quantity: formatFloat(detail.quantity),
        quantityUnit: detail.quantityUnit,
        product: formatProduct(detail.product),
        sellingPrice: formatFloat(detail.sellingPrice),
        sellingPriceUnit:
          detail.sellingPriceUnit === null ? '' : detail.sellingPriceUnit,
        wastePercentage: formatFloat(detail.wastePercentage),
      })),
      expense: formatFloat(subproduct.expense),
      expenseUnit:
        subproduct.expenseUnit === null ? '' : subproduct.expenseUnit,
      hideInPfferPdf: subproduct.hideInOfferPdf,
      isCustomSubproduct: subproduct.isCustomSubproduct,
      key: index,
      product: formatProduct(subproduct.product),
      quantity: formatFloat(subproduct.quantity),
      quantityUnit: subproduct.quantityUnit,
      sellingPrice: formatFloat(subproduct.sellingPrice),
      sellingPriceUnit:
        subproduct.sellingPriceUnit === null ? '' : subproduct.sellingPriceUnit,
      wastePercentage: formatFloat(subproduct.wastePercentage),
    })),
  })),
});
