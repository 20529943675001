import getExpensePerUnit from './getExpensePerUnit';
import getSellingPricePerUnit from './getSellingPricePerUnit';
import IInstallation from './IInstallation';
import ISubproduct from './ISubproduct';
import IDetail from './IDetail';

const getContributionMarginPerUnit = (
  installations: IInstallation[],
  subproducts: ISubproduct[] | IDetail[]
): number => {
  const expenseSum = getExpensePerUnit(installations, subproducts);
  const sellingPrice = getSellingPricePerUnit(installations, subproducts);
  return sellingPrice - expenseSum;
};

export default getContributionMarginPerUnit;
