import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

import IPermission from 'interfaces/users/IPermission';
import SelectInputTranslated from 'components/layout/forms/SelectInputTranslated';
import translations from 'services/translations/translations';
import TrashIcon from 'components/layout/icons/TrashIcon';

import {
  WRITE_CONFIGURATIONS,
  WRITE_OFFERS,
  WRITE_PRODUCT_CONFIGURATIONS,
  DELETE_CONFIGURATIONS,
  DELETE_PRODUCT_CONFIGURATIONS,
} from 'services/enums/users/permissions';

import Empty from './targets/Empty';
import OrganizationUnit from './targets/OrganizationUnit';
import OwnUser from './targets/OwnUser';
import OwnOrganizationUnit from './targets/OwnOrganizationUnit';

const Permission = ({
  permission,
  removePermission,
  setPermission,
}: {
  permission: IPermission;
  removePermission: () => void;
  setPermission: (permission: IPermission) => void;
}): JSX.Element => {
  const permissionTypesList = useSelector(
    (state: any) => state.users.permissionTypes
  );

  const getTargetDropdown = () => {
    const organizationUnitTargetPermissions = [
      WRITE_OFFERS,
      WRITE_PRODUCT_CONFIGURATIONS,
      DELETE_PRODUCT_CONFIGURATIONS,
    ];

    const ownUserTargetPermissions = [DELETE_CONFIGURATIONS];
    const ownOrgTargetPermissions = [WRITE_CONFIGURATIONS];

    if (organizationUnitTargetPermissions.includes(permission.type)) {
      return (
        <OrganizationUnit
          permission={permission}
          setPermission={setPermission}
          withOwnOrganization
        />
      );
    }

    if (ownUserTargetPermissions.includes(permission.type)) {
      return <OwnUser permission={permission} setPermission={setPermission} />;
    }

    if (ownOrgTargetPermissions.includes(permission.type)) {
      return (
        <OwnOrganizationUnit
          permission={permission}
          setPermission={setPermission}
        />
      );
    }

    return <Empty />;
  };

  const onPermissionTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPermission({
      ...permission,
      target: e.target.value === permission.type ? permission.target : '',
      type: e.target.value,
    });
  };

  const onRemove = () => {
    removePermission();
  };

  return (
    <Row>
      <Col xs="6" md="6" lg="6" xl="4">
        <SelectInputTranslated
          key="permission-type"
          onChange={onPermissionTypeChange}
          options={['', ...permissionTypesList]}
          required
          translationPath={`${translations.userManagement}:permissionTypes`}
          value={permission.type === null ? '' : permission.type}
        />
      </Col>
      <Col xs="5" md="5" lg="5" xl="4">
        {getTargetDropdown()}
      </Col>
      <Col className="icon-column" xs="1">
        <TrashIcon onClick={onRemove} />
      </Col>
    </Row>
  );
};

export default Permission;
