import IFile from 'interfaces/forms/IFile';
import IFilePost from 'interfaces/products/IFile';
import IAttribute from 'interfaces/products/IAttribute';
import IMeasurement from 'interfaces/products/IMeasurement';
import IPrice from 'interfaces/products/IPrice';
import IProductId from 'interfaces/products/IProductId';
import formatMeasurementToNumber from './src/glassConfiguratorProducts/formatMeasurementToNumber';
import formatPriceToNumber from './src/glassConfiguratorProducts/formatPriceToNumber';
import getValuesFromMultiSelect from './src/glassConfiguratorProducts/getValuesFromMultiSelect';

const getFiles = (files: IFile[]) =>
  files
    .map((file: IFile): IFilePost | undefined => {
      if (file.id === null) {
        return undefined;
      }

      if (file.type === undefined) {
        return undefined;
      }

      return { id: file.id, type: file.type };
    })
    .filter(
      (file: IFilePost | undefined): file is Required<IFilePost> =>
        file !== undefined
    );

const getProductPost = (
  idValues: IProductId[],
  attributeFormValues: IAttribute[],
  measurFormValues: IMeasurement[],
  priceFormValues: IPrice[],
  supplierId: null | number,
  categories: any,
  formState: any,
  files: IFile[],
  deliveryCategory: string,
  tags: any[]
) => ({
  attributes: attributeFormValues,
  color: formState.color,
  displayName: formState.displayName,
  measurements: formatMeasurementToNumber(measurFormValues),
  name: formState.name,
  prices: formatPriceToNumber(priceFormValues),
  productIds: idValues,
  supplierId,
  supplierProductName: formState.sPName,
  categories: getValuesFromMultiSelect(categories),
  edgeType: formState.edge,
  files: getFiles(files),
  deliveryCategory,
  tags: getValuesFromMultiSelect(tags),
});

export default getProductPost;
