import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getFormStateHandler from 'services/forms/getFormStateHandler';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import translations from 'services/translations/translations';
import { APPROVED } from 'services/enums/offers/Statuses';
import IVersion from '../../IVersion';
import getVersionInstallationContract from '../getVersionInstallationContract';
import NumberWithDropdownInputField from '../../../../../layout/forms/NumberWithDropdownInputField';
import getUnitOptions from '../../../../services/getUnitOptions';
import ICalculationReview from '../../ICalculationReview';
import InputLabel from '../../../../../layout/forms/InputLabel';
import { H } from '../../../../../../services/enums/units';
import numericStringToFloat from '../../../../../../services/numbers/numericStringToFloat';
import TrashIcon from '../../../../../layout/icons/TrashIcon';

const BatchCalculationReview = ({
  initialStatus,
  productUnit,
  quantity,
  setCalculationReview,
  removeCalculationReview,
  version,
  calculationReview,
  priceSummary,
}: {
  initialStatus: null | string;
  productUnit: string;
  quantity: number;
  setCalculationReview: (version: ICalculationReview) => void;
  removeCalculationReview: (calculationReview: ICalculationReview) => void;
  version: IVersion;
  calculationReview: ICalculationReview;
  priceSummary: any;
}): JSX.Element => {
  const { t } = useTranslation([translations.offers]);
  const units = useSelector((state: any) => state.products.units);

  const handleFormState = getFormStateHandler(
    calculationReview,
    setCalculationReview
  );

  const installationCategories = useSelector(
    (state: any) => state.products.installationCategories
  );

  const installationContract = getVersionInstallationContract(
    version,
    quantity,
    installationCategories
  );

  const calculationQuantityNumeric = numericStringToFloat(
    calculationReview.quantity
  );

  return (
    <div className="calculation-sum-row">
      <Row>
        <Col>
          <InputLabel>
            {`${t(`${translations.offers}:Expense`)} €`}/
            {t(
              `${translations.common}:units:${calculationReview.quantityUnit}`
            )}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {`${t(`${translations.offers}:Selling price`)} €`}/
            {t(
              `${translations.common}:units:${calculationReview.quantityUnit}`
            )}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {`${t(`${translations.offers}:Contribution margin`)} €`}/
            {t(
              `${translations.common}:units:${calculationReview.quantityUnit}`
            )}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {`${t(
              `${translations.calculationWindow}:Installation contract`
            )} €`}
            /
            {t(
              `${translations.common}:units:${calculationReview.quantityUnit}`
            )}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>{t(`${translations.offers}:Määrä`)}</InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {t(
              `${translations.common}:units:${calculationReview.quantityUnit}`
            )}{' '}
            /{t(`${translations.common}:units:${H}`)}
          </InputLabel>
        </Col>
        <Col />
      </Row>
      <Row>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="review-expense-sum-for-unit"
            unit="€"
            value={formatFloatForDisplay(
              priceSummary.expenseSumForUnit / calculationQuantityNumeric
            )}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="review-priceForUnit"
            unit="€"
            value={formatFloatForDisplay(
              priceSummary.sellingPriceForUnit / calculationQuantityNumeric
            )}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="review-contribution-margin-for-unit"
            unit="€"
            value={formatFloatForDisplay(
              priceSummary.contributionMarginForUnit /
                calculationQuantityNumeric
            )}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="review-contribution-margin-for-unit"
            name="averageHourlyEarnings"
            onChange={handleFormState}
            unit="€"
            value={formatFloatForDisplay(
              installationContract.installationsUnitInstallationPrice /
                calculationQuantityNumeric
            )}
          />
        </Col>
        <Col>
          <NumberWithDropdownInputField
            dropdownName="quantityUnit"
            dropdownOnChange={handleFormState}
            dropdownOptions={getUnitOptions(units, t)}
            dropdownValue={calculationReview.quantityUnit}
            id={`calculation-review-${calculationReview.key}-quantity`}
            numberName="quantity"
            numberOnChange={handleFormState}
            numberValue={calculationReview.quantity}
            required
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="review-total-hours"
            unit=""
            value={formatFloatForDisplay(
              installationContract.totalHours / calculationQuantityNumeric
            )}
          />
        </Col>
        <Col>
          <TrashIcon
            style={{ marginTop: '0.5rem' }}
            onClick={() => removeCalculationReview(calculationReview)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BatchCalculationReview;
