import IPlaceMeasurement from 'interfaces/projects/IPlaceMeasurement';

const getMeasurementsWithId = (measurements: IPlaceMeasurement[]) => {
  const placeM: IPlaceMeasurement[] = [];
  for (let index = 0; index < measurements.length; index++) {
    placeM.push({
      id: index + 1,
      positionId: measurements[index].positionId,
      workgroupId: measurements[index].workgroupId,
      height: measurements[index].height,
      width: measurements[index].width,
      hindrances: measurements[index].hindrances,
    });
  }
  return placeM;
};

export default getMeasurementsWithId;
