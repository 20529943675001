import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import isNumber from 'services/numbers/isNumber';
import ModalConfirm from 'components/layout/modals/ModalConfirm';
import PageContainer from 'components/layout/PageContainer';
import translations from 'services/translations/translations';
import { addMessage } from 'store/messages/actions';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import CalculationWindow from './calculationWindow/CalculationWindow';
import Header from './Header';
import IFormState from './IFormState';
import ProductFields from './ProductFields';
import views from './views';

import 'style/offers/OfferProduct.css';

const OfferProduct = ({
  action,
  cancel,
  formState,
  initialStatus,
  remove,
  save,
  setFormState,
}: {
  action: string;
  cancel: (calculationWindowOpen?: boolean) => void;
  formState: IFormState;
  initialStatus: null | string;
  remove?: () => void;
  save: (preventRedirect: boolean) => void;
  setFormState: (formState: IFormState) => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [modalDeleteConfirmation, setModalDeleteConfirmation] = useState(false);
  const [view, setView] = useState(views.offerProduct);
  const [closeWindow, setCloseWindow] = useState(false);
  const { search } = useLocation();

  const { t } = useTranslation([translations.common, translations.offers]);

  const openCalculationWindow = () => {
    if (formState.name === '') {
      dispatch(
        addMessage(t(`${translations.offers}:Define product name first`))
      );

      return;
    }

    if (!isNumber(formState.quantity)) {
      dispatch(addMessage(t(`${translations.offers}:Define quantity first`)));
      return;
    }

    if (formState.unit === '') {
      dispatch(addMessage(t(`${translations.offers}:Select unit first`)));
      return;
    }

    setView(views.calculationWindow);
  };

  useEffect(() => {
    const values = queryString.parse(search);
    if (
      !closeWindow &&
      values &&
      values.calculationWindowOpen &&
      formState.name !== ''
    ) {
      openCalculationWindow();
    }
  }, [formState]);

  const calculationWindowIsOpen = [
    views.attachConfiguration,
    views.calculationWindow,
  ].includes(view);

  const closeCalculationWindow = () => {
    setCloseWindow(true);
    setView(views.offerProduct);
  };

  const getDisplayedForm = () => {
    if (calculationWindowIsOpen) {
      return (
        <CalculationWindow
          close={closeCalculationWindow}
          formState={formState}
          initialStatus={initialStatus}
          setFormState={setFormState}
          setView={setView}
          view={view}
          save={save}
        />
      );
    }

    if (view === views.offerProduct) {
      return (
        <ProductFields
          cancel={cancel}
          formState={formState}
          initialStatus={initialStatus}
          openCalculationWindow={openCalculationWindow}
          remove={
            remove === undefined
              ? undefined
              : () => setModalDeleteConfirmation(true)
          }
          save={save}
          setFormState={setFormState}
        />
      );
    }

    return null;
  };

  return (
    <PageContainer fullWidth={view === views.calculationWindow}>
      <div className="offer-product">
        <Header action={action} view={view} />
        <div className="basic-table">{getDisplayedForm()}</div>
        {remove === undefined ? null : (
          <ModalConfirm
            showing={modalDeleteConfirmation}
            setShowing={() =>
              setModalDeleteConfirmation(!modalDeleteConfirmation)
            }
            header={t(`${translations.common}:Confirm Delete`)}
            text={t(`${translations.common}:Confirm Delete`)}
            yesButton={t(`${translations.common}:Confirm`)}
            noButton={t(`${translations.common}:Cancel`)}
            confirm={remove}
          />
        )}
      </div>
    </PageContainer>
  );
};

export default OfferProduct;
