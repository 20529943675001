import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
  Button,
} from 'reactstrap';

import TextInputField from 'components/layout/forms/TextInputField';
import IPosition from 'interfaces/projects/IPosition';
import translations from 'services/translations/translations';

const ModalEditPosition = ({
  showing,
  setShowing,
  updateElement,
  position,
}: {
  showing: boolean;
  setShowing: (showing: boolean) => void;
  updateElement: (element: string) => void;
  position: IPosition | undefined;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common]);
  const toggle = () => setShowing(!showing);
  const [positionName, setPositionName] = useState<string>('');

  useEffect(() => {
    if (position !== undefined) {
      setPositionName(position.name);
    }
  }, [position]);

  const handleOnChange = (e: any) => {
    setPositionName(e);
  };

  return (
    <>
      <Modal isOpen={showing} toggle={toggle} centered>
        <ModalHeader className="modal-header" toggle={toggle} />
        <ModalBody className="basic-table">
          <Row>
            <Col>
              <TextInputField
                label={t(`${translations.projects}:Positions`)}
                id={position ? position.name : ''}
                onChange={(e: any) => handleOnChange(e.target.value)}
                required
                value={positionName}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => updateElement(positionName)}
            className="modal-button"
          >
            {t(`${translations.common}:Save`)}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalEditPosition;
