import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
  Button,
} from 'reactstrap';

import NumberInputFieldNoLabel from 'components/layout/forms/NumberInputFieldNoLabel';
import TextInputFieldNoLabel from 'components/layout/forms/TextInputFieldNoLabel';
import IBuilding from 'interfaces/projects/IBuilding';
import translations from 'services/translations/translations';

const ModalEditBuilding = ({
  showing,
  setShowing,
  updateElement,
  building,
}: {
  showing: boolean;
  setShowing: (showing: boolean) => void;
  updateElement: (name: string, floors: any) => void;
  building: IBuilding | undefined;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common]);
  const toggle = () => setShowing(!showing);
  const [buildingName, setBuildingName] = useState<string>('');
  const [floors, setFloors] = useState<number>(0);

  useEffect(() => {
    if (building !== undefined) {
      setBuildingName(building.name);
      setFloors(building.floors);
    }
  }, [building]);

  return (
    <>
      <Modal isOpen={showing} toggle={toggle} centered>
        <ModalHeader className="modal-header" toggle={toggle} />
        <ModalBody className="basic-table">
          <p className="label">{t(`${translations.projects}:Buildings`)}</p>
          <Row>
            <Col>
              <TextInputFieldNoLabel
                id={building ? building.name : ''}
                onChange={(e: any) => setBuildingName(e.target.value)}
                required
                value={buildingName}
              />
            </Col>
            <Col>
              <NumberInputFieldNoLabel
                id="floors"
                name="floors"
                onChange={(e: any) => setFloors(e.target.value)}
                value={floors.toString()}
                required
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => updateElement(buildingName, floors)}
            className="modal-button"
          >
            {t(`${translations.common}:Save`)}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalEditBuilding;
