import IProductConfigurationAttribute from 'interfaces/products/IProductConfigurationAttribute';
import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';

import {
  CUSTOM_INSTALLATION,
  INSTALLATION_ID,
  IS_CUSTOM_INSTALLATION,
} from 'services/products/productConfigurationAttributeTypes';

const isCustomInstallation = (item: IProductConfigurationItem) => {
  const isCustomInstallationAttribute = item.attributes.find(
    (attribute: IProductConfigurationAttribute) =>
      attribute.type === IS_CUSTOM_INSTALLATION
  );

  if (isCustomInstallationAttribute === undefined) {
    return false;
  }

  return isCustomInstallationAttribute.value !== '0';
};

const getCustomInstallationName = (item: IProductConfigurationItem): string => {
  const customInstallationAttribute = item.attributes.find(
    (attribute: IProductConfigurationAttribute) =>
      attribute.type === CUSTOM_INSTALLATION
  );

  if (customInstallationAttribute === undefined) {
    return '';
  }

  if (customInstallationAttribute.value === null) {
    return '';
  }

  return customInstallationAttribute.value;
};

export default (item: IProductConfigurationItem): string => {
  if (isCustomInstallation(item)) {
    return getCustomInstallationName(item);
  }

  const installationIdAttribute = item.attributes.find(
    (attribute: IProductConfigurationAttribute) =>
      attribute.type === INSTALLATION_ID
  );

  if (installationIdAttribute === undefined) {
    return '';
  }

  if (installationIdAttribute.displayValue === undefined) {
    return '';
  }

  if (installationIdAttribute.displayValue === null) {
    return '';
  }

  return installationIdAttribute.displayValue;
};
