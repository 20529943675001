const moveNotesToFirstPosition = (tabs: string[]): string[] => {
  const fromIndex = tabs.indexOf('notes');
  const toIndex = 0;
  const element = tabs[fromIndex];
  tabs.splice(fromIndex, 1);
  tabs.splice(toIndex, 0, element);
  return tabs;
};

export default moveNotesToFirstPosition;
