import React from 'react';
import { Link } from 'react-router-dom';

import '../../../style/layout/buttons/ButtonLink.css';

const getClassName = (className?: string): string =>
  className === undefined
    ? 'btn btn-primary button-link'
    : `btn btn-primary button-link ${className}`;

const ButtonLink = ({
  children,
  className,
  to,
}: {
  children: string | JSX.Element;
  className?: string;
  to: string;
}): JSX.Element => (
  <Link className={getClassName(className)} to={to}>
    {children}
  </Link>
);

export default ButtonLink;
