import { FINISH_LOADING, START_LOADING } from './types';

const initialState: string[] = [];

export default (
  state: string[] = initialState,
  action: {
    item: string;
    type: string;
  }
): string[] => {
  if (action.type === FINISH_LOADING) {
    return state.filter((item) => item !== action.item);
  }

  if (action.type === START_LOADING) {
    return [...state, action.item];
  }

  return state;
};
