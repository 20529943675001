import React from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import InputLabel from 'components/layout/forms/InputLabel';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import translations from 'services/translations/translations';

import getPriceSummary from '../getPriceSummary';
import IInstallation from '../IInstallation';
import ISubproduct from '../ISubproduct';

const Summary = ({
  installations,
  productUnit,
  quantity,
  subproducts,
}: {
  installations: IInstallation[];
  productUnit: string;
  quantity: number;
  subproducts: ISubproduct[];
}): JSX.Element => {
  const { t } = useTranslation([translations.offers]);

  const priceSummary = getPriceSummary(installations, subproducts, quantity);

  return (
    <div className="calculation-sum-row">
      <h2>{t(`${translations.calculationWindow}:Calculation`)}</h2>
      <Row>
        <Col>
          <InputLabel>
            {t(`${translations.offers}:Expense`)} €/
            {t(`${translations.common}:units:${productUnit}`)}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {t(`${translations.offers}:Selling price`)} €/
            {t(`${translations.common}:units:${productUnit}`)}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {`${t(`${translations.offers}:Contribution margin`)} €`}/
            {t(`${translations.common}:units:${productUnit}`)}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>{t(`${translations.offers}:Expense`)}</InputLabel>
        </Col>
        <Col>
          <InputLabel>{t(`${translations.offers}:Selling price`)}</InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {`${t(`${translations.offers}:Contribution margin`)} €`}
          </InputLabel>
        </Col>
        <Col>
          <InputLabel>
            {`${t(`${translations.offers}:Contribution margin`)} %`}
          </InputLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="expense-sum-for-unit"
            unit={`€/${t(`${translations.common}:units:${productUnit}`)}`}
            value={formatFloatForDisplay(priceSummary.expenseSumForUnit)}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="priceForUnit"
            unit={`€/${t(`${translations.common}:units:${productUnit}`)}`}
            value={formatFloatForDisplay(priceSummary.sellingPriceForUnit)}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="contribution-margin-for-unit"
            unit={`€/${t(`${translations.common}:units:${productUnit}`)}`}
            value={formatFloatForDisplay(
              priceSummary.contributionMarginForUnit
            )}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="summary-expense-sum"
            unit="€"
            value={formatFloatForDisplay(priceSummary.expenseSum)}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="summary-price-sum"
            unit="€"
            value={formatFloatForDisplay(priceSummary.sellingPrice)}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="summary-contribution-margin-sum"
            unit="€"
            value={formatFloatForDisplay(priceSummary.contributionMargin)}
          />
        </Col>
        <Col>
          <NumberWithUnitInputField
            disabled
            id="summary-contribution-margin-ratio-sum"
            unit="%"
            value={formatFloatForDisplay(priceSummary.contributionMarginRatio)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Summary;
