import IHindranceGet from 'api/interfaces/projects/IHindranceGet';
import IProjectState from 'interfaces/store/IProjectState';
import {
  FETCH_CONTRACT_TYPES,
  FETCH_WORK_TYPES,
  FETCH_BUILDING_TYPES,
  FETCH_COLLATERAL_TYPES,
  FETCH_EMPLOYEE_INFORMATION_TYPES,
  FETCH_WAY_OF_RECEIVING_TYPES,
  FETCH_TAX_RATE_TYPES,
  FETCH_TAB_TYPES,
  FETCH_HINDRANCE_TYPES,
  FETCH_PLACE_STATUS_TYPES,
} from './types';

const initialState: IProjectState = {
  contractTypes: [],
  buildingTypes: [],
  workTypes: [],
  collateralTypes: [],
  employeeInformationTypes: [],
  wayOfReceivingTypes: [],
  taxRateTypes: [],
  tabTypes: [],
  hindranceTypes: [],
  placeStatusTypes: [],
};

export default (
  state: IProjectState = initialState,
  action: {
    contractTypes: string[];
    buildingTypes: string[];
    workTypes: string[];
    collateralTypes: string[];
    employeeInformationTypes: string[];
    wayOfReceivingTypes: string[];
    taxRateTypes: string[];
    tabTypes: string[];
    hindranceTypes: IHindranceGet[];
    placeStatusTypes: string[];
    type: string;
  }
): IProjectState => {
  if (action.type === FETCH_CONTRACT_TYPES.SUCCESS) {
    return {
      ...state,
      contractTypes: action.contractTypes,
    };
  }

  if (action.type === FETCH_WORK_TYPES.SUCCESS) {
    return {
      ...state,
      workTypes: action.workTypes,
    };
  }

  if (action.type === FETCH_BUILDING_TYPES.SUCCESS) {
    return {
      ...state,
      buildingTypes: action.buildingTypes,
    };
  }

  if (action.type === FETCH_COLLATERAL_TYPES.SUCCESS) {
    return {
      ...state,
      collateralTypes: action.collateralTypes,
    };
  }

  if (action.type === FETCH_EMPLOYEE_INFORMATION_TYPES.SUCCESS) {
    return {
      ...state,
      employeeInformationTypes: action.employeeInformationTypes,
    };
  }

  if (action.type === FETCH_WAY_OF_RECEIVING_TYPES.SUCCESS) {
    return {
      ...state,
      wayOfReceivingTypes: action.wayOfReceivingTypes,
    };
  }

  if (action.type === FETCH_TAX_RATE_TYPES.SUCCESS) {
    return {
      ...state,
      taxRateTypes: action.taxRateTypes,
    };
  }

  if (action.type === FETCH_HINDRANCE_TYPES.SUCCESS) {
    return {
      ...state,
      hindranceTypes: action.hindranceTypes,
    };
  }

  if (action.type === FETCH_TAB_TYPES.SUCCESS) {
    return {
      ...state,
      tabTypes: action.tabTypes,
    };
  }

  if (action.type === FETCH_PLACE_STATUS_TYPES.SUCCESS) {
    return {
      ...state,
      placeStatusTypes: action.placeStatusTypes,
    };
  }

  return state;
};
