import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
  Button,
} from 'reactstrap';

import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';
import IConfigurationBasicInformation from './src/IConfigurationBasicInformation';

const ModalEditConfiguration = ({
  showing,
  setShowing,
  updateElement,
  configuration,
}: {
  showing: boolean;
  setShowing: (showing: boolean) => void;
  updateElement: (newName: string) => void;
  configuration: IConfigurationBasicInformation | undefined;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common]);
  const toggle = () => setShowing(!showing);
  const [configurationName, setConfigurationName] = useState<string>('');

  useEffect(() => {
    if (configuration !== undefined) {
      setConfigurationName(configuration.name);
    }
  }, [configuration]);

  const handleOnChange = (e: any) => {
    setConfigurationName(e);
  };

  return (
    <>
      <Modal isOpen={showing} toggle={toggle} centered>
        <ModalHeader className="modal-header edit-modal-bg" toggle={toggle} />
        <ModalBody className="basic-table">
          <Row>
            <Col>
              <TextInputField
                label={t(`${translations.common}:Name`)}
                id={configuration ? configuration.name : ''}
                onChange={(e: any) => handleOnChange(e.target.value)}
                required
                value={configurationName}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="edit-modal-bg">
          <Button
            onClick={() => updateElement(configurationName)}
            className="modal-button"
          >
            {t(`${translations.common}:Save`)}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalEditConfiguration;
