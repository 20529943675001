import api from 'api/api';
import apiInstallationPrices from 'api/installationPrices';
import apiProducts from 'api/products';

import IProductConfiguration from 'interfaces/products/IProductConfiguration';

import formatItems from './formatItems';
import getInstallationIdsFromItems from './getInstallationIdsFromItems';
import getProductIdsFromItems from './getProductIdsFromItems';

interface IApiProductConfigurationProduct {
  id: number;
  name: string;
}

const getProductConfiguration = async (
  id: number
): Promise<IProductConfiguration> => {
  const apiConfiguration = await api.get(
    `/api/v1/products/configurations/${id}`
  );

  const installationsToFetch = getInstallationIdsFromItems(
    apiConfiguration.items
  );

  const installations: any = await Promise.all(
    installationsToFetch.map((installationToFetch: number) =>
      apiInstallationPrices.getInstallation(installationToFetch)
    )
  );

  const productsToFetch = getProductIdsFromItems(apiConfiguration.items);

  const products: any = await Promise.all(
    productsToFetch.map((productToFetch: number) =>
      apiProducts.getProduct(productToFetch)
    )
  );

  const configuration: IProductConfiguration = {
    created: apiConfiguration.created,
    createdUser: apiConfiguration.createdUser,
    items: formatItems(apiConfiguration.items, products, installations),
    name: apiConfiguration.name,
    offerText: apiConfiguration.offerText,
    organizationUnit: apiConfiguration.organizationUnit,
    products: apiConfiguration.products.map(
      (product: IApiProductConfigurationProduct) => ({
        id: product.id,
        name: product.name,
        key: product.id.toString(),
      })
    ),
    updated: apiConfiguration.updated,
    updatedUser: apiConfiguration.updatedUser,
  };

  return configuration;
};

export default getProductConfiguration;
