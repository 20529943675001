import { AxiosError } from 'axios';

import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  takeEvery,
} from 'redux-saga/effects';

import apiPermissionTypes from 'api/permissionTypes';
import apiUserManagement from 'api/userManagement';

import { finishLoading, startLoading } from '../loading/actions';

import {
  FETCH_USER,
  FETCH_USERS,
  FETCH_PERMISSION_TYPES,
  FETCH_ROLES,
  FETCH_ORGANIZATION_UNITS,
} from './types';

function* fetchPermissionTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_PERMISSION_TYPES.PENDING));
    const permissionTypes = yield call(apiPermissionTypes.get);
    yield put({ type: FETCH_PERMISSION_TYPES.SUCCESS, permissionTypes });
    yield put(finishLoading(FETCH_PERMISSION_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;

    yield put({
      type: FETCH_PERMISSION_TYPES.FAILURE,
      message: axiosError.message,
    });
  }
}

function* fetchRoles(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_ROLES.PENDING));
    const roles = yield call(apiUserManagement.getRoles);
    yield put({ type: FETCH_ROLES.SUCCESS, roles });
    yield put(finishLoading(FETCH_ROLES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({ type: FETCH_ROLES.FAILURE, message: axiosError.message });
  }
}

function* fetchUser(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_USER.PENDING));
    const user = yield call(apiUserManagement.getCurrentUser);
    yield put({
      type: FETCH_USER.SUCCESS,
      user,
    });
    yield put(finishLoading(FETCH_USER.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 401) {
      yield put({ type: FETCH_USER.SUCCESS, user: null });
      yield put(finishLoading(FETCH_USER.PENDING));
    } else {
      yield put({ type: FETCH_USER.FAILURE, message: axiosError.message });
    }
  }
}

function* fetchUsers(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_USERS.PENDING));
    const users = yield call(apiUserManagement.get);
    yield put({ type: FETCH_USERS.SUCCESS, users });
    yield put(finishLoading(FETCH_USERS.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({ type: FETCH_USERS.FAILURE, message: axiosError.message });
  }
}

function* fetchOrganizationUnits(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_ORGANIZATION_UNITS.PENDING));
    const organizationUnits = yield call(
      apiUserManagement.organizationUnits.get
    );
    yield put({ type: FETCH_ORGANIZATION_UNITS.SUCCESS, organizationUnits });
    yield put(finishLoading(FETCH_ORGANIZATION_UNITS.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({
      type: FETCH_ORGANIZATION_UNITS.FAILURE,
      message: axiosError.message,
    });
  }
}

function* saga(): IterableIterator<ForkEffect> {
  yield takeEvery(FETCH_PERMISSION_TYPES.PENDING, fetchPermissionTypes);
  yield takeEvery(FETCH_ROLES.PENDING, fetchRoles);
  yield takeEvery(FETCH_USER.PENDING, fetchUser);
  yield takeEvery(FETCH_USERS.PENDING, fetchUsers);
  yield takeEvery(FETCH_ORGANIZATION_UNITS.PENDING, fetchOrganizationUnits);
}

export default saga;
