import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import translations from 'services/translations/translations';
import isNumber from 'services/numbers/isNumber';

import 'style/layout/form/SquareDisabledField.css';

export default ({ value }: { value: string }): JSX.Element => {
  const { t } = useTranslation([translations.projects]);
  return (
    <FormGroup disabled>
      <Label className="label">{t(`${translations.projects}:Squares`)}</Label>
      <Input
        className="text-field align-right"
        id="squares"
        name="squares"
        disabled
        value={`${isNumber(value) ? value : '0'} ${t(
          `${translations.common}:units.m2`
        )}`}
        type="text"
      />
    </FormGroup>
  );
};
