import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import translations from 'services/translations/translations';
import usersApi from 'api/userManagement';

import SearchDropdown from './SearchDropdown';

const UserSelect = ({
  disabled,
  label,
  onChange,
  ownOrganizationUnit,
  value,
}: {
  disabled?: boolean;
  label?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  ownOrganizationUnit?: boolean;
  value: {
    label: string;
    value: string;
  };
}): JSX.Element => {
  const { t } = useTranslation([translations.common]);

  const loadOptions = async (search: string) => {
    const users = await usersApi.get({
      ownOrganizationUnit:
        ownOrganizationUnit === null ? undefined : ownOrganizationUnit,
      search,
    });

    return users.map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));
  };

  return (
    <SearchDropdown
      disabled={disabled}
      label={label}
      loadOptions={loadOptions}
      onChange={onChange}
      placeholder={t(`${translations.common}:Search`)}
      value={value}
    />
  );
};

export default UserSelect;
