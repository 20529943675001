import React from 'react';
import { Button } from 'reactstrap';
import { LEFT, RIGHT } from 'services/enums/sides';

import 'style/layout/PrimaryButton.css';

const getClassName = (className?: string, float?: string): string => {
  const classes = ['primary-button'];
  if (className !== undefined) {
    classes.push(className);
  }

  if (float === LEFT) {
    classes.push('button-left');
  }

  if (float === RIGHT) {
    classes.push('button-right');
  }

  return classes.join(' ');
};

const getDisabled = (disabled?: boolean): boolean =>
  disabled === undefined ? false : disabled;

const PrimaryButton = ({
  children,
  className,
  disabled,
  float,
  onClick,
  size,
}: {
  children: string | JSX.Element;
  className?: string;
  disabled?: boolean;
  float?: string;
  onClick: () => void;
  size?: string;
}): JSX.Element => (
  <Button
    disabled={getDisabled(disabled)}
    className={getClassName(className, float)}
    onClick={onClick}
    size={size}
  >
    {children}
  </Button>
);

export default PrimaryButton;
