import React, { useEffect, useState } from 'react';
import { finishLoading, startLoading } from 'store/loading/actions';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiOffers from 'api/offers';
import IOfferProductGet from 'api/interfaces/offers/IOfferProductGet';
import translations from 'services/translations/translations';
import { addMessage } from 'store/messages/actions';

import formatToApiFormat from './src/offerProduct/formatToApiFormat';
import formatToFormStateFormat from './src/offerProduct/formatToFormStateFormat';
import getDefaultFormState from './src/offerProduct/getDefaultFormState';
import IFormState from './src/offerProduct/IFormState';
import OfferProduct from './src/offerProduct/OfferProduct';
import IOfferGetSingle from '../../api/interfaces/offers/IOfferGetSingle';
import { removeNavMenuText, setNavMenuText } from '../../store/navMenu/actions';

const EditOfferProduct = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation([translations.common]);

  const [additionalOfferId, setAdditionalOfferId] = useState<null | number>(
    null
  );
  const [initialStatus, setInitialStatus] = useState<null | string>(null);
  const [formState, setFormState] = useState<IFormState>(getDefaultFormState());
  const [offerId, setOfferId] = useState<null | number>(null);
  const [offer, setOffer] = useState<IOfferGetSingle>();
  const { id, type }: { id: string | undefined; type: string } = useParams();

  useEffect(() => {
    if (offer != null) {
      dispatch(setNavMenuText(offer.targetName));
    }
  }, [offer]);

  useEffect(() => {
    // This is triggered when component is closed
    return () => {
      dispatch(removeNavMenuText());
    };
  }, []);

  useEffect(() => {
    if (id === undefined) {
      return;
    }

    const initializeProduct = async () => {
      dispatch(startLoading('offer-product-get'));

      const existingProduct: IOfferProductGet = await apiOffers.products.get(
        parseInt(id, 10)
      );

      if (existingProduct.additionalOffer !== null) {
        setAdditionalOfferId(existingProduct.additionalOffer.id);
        setInitialStatus(existingProduct.additionalOffer.status);
      }

      if (existingProduct.offer !== null) {
        setOfferId(existingProduct.offer.id);
        setInitialStatus(existingProduct.offer.status);
      }

      setFormState(formatToFormStateFormat(existingProduct));

      if (existingProduct?.offer.id != null) {
        setOffer(await apiOffers.getOffer(existingProduct.offer.id));
      }

      dispatch(finishLoading('offer-product-get'));
    };

    initializeProduct();
  }, []);

  const cancel = () => {
    if (additionalOfferId !== null) {
      history.push(`/offers/${additionalOfferId}/${type}`);
      return;
    }

    if (offerId !== null) {
      history.push(`/offers/${offerId}/${type}`);
    }
  };

  const remove = () => {
    if (id === undefined) {
      return;
    }

    const deleteProduct = async () => {
      dispatch(startLoading('offer-product-delete'));
      await apiOffers.products.delete(parseInt(id, 10));
      dispatch(finishLoading('offer-product-delete'));
      cancel();
    };

    deleteProduct();
  };

  const save = (preventRedirect: boolean) => {
    if (id === undefined) {
      return;
    }

    const postProduct = async () => {
      dispatch(startLoading('offer-product-put'));

      if (type === 'additional-offer') {
        try {
          await apiOffers.products.putAdditionalOffer(
            parseInt(id, 10),
            formatToApiFormat(formState)
          );
        } catch (error) {
          if (error.response.status === 400) {
            dispatch(finishLoading('offer-product-put'));
            dispatch(addMessage(t(`${translations.common}:Fill all fields`)));
          }
          return;
        }
      } else {
        try {
          await apiOffers.products.put(
            parseInt(id, 10),
            formatToApiFormat(formState)
          );
        } catch (error) {
          console.log(error);
          if (error.response.status === 400) {
            dispatch(finishLoading('offer-product-put'));
            dispatch(addMessage(t(`${translations.common}:Fill all fields`)));
          }
          return;
        }
      }

      dispatch(finishLoading('offer-product-put'));
      if (!preventRedirect) {
        cancel();
      }
    };

    postProduct();
  };

  return (
    <OfferProduct
      action="edit"
      cancel={cancel}
      formState={formState}
      remove={remove}
      save={save}
      setFormState={setFormState}
      initialStatus={initialStatus}
    />
  );
};

export default EditOfferProduct;
