const WRITE_OFFERS = 'write_offers';
const WRITE_PRODUCT_CONFIGURATIONS = 'write_product_configurations';
const DELETE_CONFIGURATIONS = 'glass_configurator/delete_glass_configurations';
const DELETE_PRODUCT_CONFIGURATIONS = 'delete_product_configurations';
const WRITE_CONFIGURATIONS = 'glass_configurator/write_glass_configurations';

export {
  WRITE_OFFERS,
  WRITE_PRODUCT_CONFIGURATIONS,
  DELETE_CONFIGURATIONS,
  DELETE_PRODUCT_CONFIGURATIONS,
  WRITE_CONFIGURATIONS,
};
