import {
  ADD_CONFIRM,
  ADD_MESSAGE,
  CLOSE_CONFIRM,
  CLOSE_MESSAGE,
} from './types';

export const addConfirm = (confirm: {
  callback: () => void;
  header?: string;
  message: string;
}): {
  type: string;
  confirm: {
    callback: () => void;
    header?: string;
    message: string;
  };
} => ({
  type: ADD_CONFIRM,
  confirm,
});

export const addMessage = (
  message: string
): {
  type: string;
  message: string;
} => ({
  type: ADD_MESSAGE,
  message,
});

export const closeConfirm = (): { type: string } => ({
  type: CLOSE_CONFIRM,
});

export const closeMessage = (): { type: string } => ({
  type: CLOSE_MESSAGE,
});
