export const CALCULATE_PRICE_FROM_DETAILS = 'calculate-price-from-details';
export const CATEGORY = 'category';
export const CUSTOM_INSTALLATION = 'custom-installation';
export const CUSTOM_PRODUCT = 'custom-product';
export const DETAIL_CUSTOM_PRODUCT_NAME = 'custom-product-name';
export const DETAIL_HAS_CUSTOM_PRODUCT_NAME = 'has-custom-product-name';
export const EXPENSE = 'expense';
export const EXPENSE_UNIT = 'expense-unit';
export const INSTALLATION_ID = 'installation-id';
export const INSTALLATION_PRICE = 'installation-price';
export const INSTALLATION_PRICE_UNIT = 'installation-price-unit';
export const IS_CUSTOM_INSTALLATION = 'is-custom-installation';
export const IS_CUSTOM_SUBPRODUCT = 'is-custom-subproduct';
export const NAME = 'name';
export const PRODUCT_ID = 'product-id';
export const QUANTITY = 'quantity';
export const QUANTITY_UNIT = 'quantity-unit';
export const SELLING_PRICE = 'selling-price';
export const SELLING_PRICE_UNIT = 'selling-price-unit';
export const WASTE_PERCENTAGE = 'waste-percentage';

export const SOCIAL_SECURITY_COST_PERCENTAGE =
  'social-security-cost-percentage';
