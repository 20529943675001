import IOrganizationUnit from 'interfaces/users/IOrganizationUnit';

const showOrganizationUnits = (
  organizationUnits: IOrganizationUnit[]
): { label: string; value: number }[] =>
  organizationUnits.map((x: IOrganizationUnit) => ({
    value: x.id,
    label: x.name,
  }));

export default showOrganizationUnits;
