import React from 'react';

import SelectInputTranslated from 'components/layout/forms/SelectInputTranslated';

const Empty = (): JSX.Element => {
  const onChange = function emptyOnChange() {
    //
  };

  return (
    <SelectInputTranslated
      key="permission-target-empty"
      onChange={onChange}
      options={['']}
      translationPath=""
      value=""
    />
  );
};

export default Empty;
