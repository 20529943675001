import React from 'react';

import translations from 'services/translations/translations';
import sortByTranslation from 'services/sort/sortByTranslation';

const ProductCategoryOptions = (
  categories: string[],
  t: any
): JSX.Element[] => {
  const categoriesTranslationLocation = `${translations.products}:categories`;

  const getOption = (category: null | string) => {
    if (category === null) {
      return (
        <option key="product-category-empty" value="">
          -
        </option>
      );
    }

    return (
      <option key={`product-category-${category}`} value={category}>
        {t(`${categoriesTranslationLocation}:${category}`)}
      </option>
    );
  };

  const categoriesSortedByTranslation = [...categories].sort(
    sortByTranslation(t, categoriesTranslationLocation)
  );

  const categoriesWithEmptyOption = [null, ...categoriesSortedByTranslation];

  return categoriesWithEmptyOption.map((category: null | string) =>
    getOption(category)
  );
};

export default ProductCategoryOptions;
