import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import apiProjects from 'api/projects';
import IPlaceStatus from 'api/interfaces/projects/IPlaceStatus';
import ISuccessResult from 'api/interfaces/results/ISuccessResult';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import SelectInputFieldNoLabel from 'components/layout/forms/SelectInputFieldNoLabel';
import getTargetValue from 'services/forms/getTargetValue';
import ModalAccept from 'components/layout/modals/ModalAccept';
import translations from 'services/translations/translations';
import { fetchPlaceStatusTypes } from 'store/projects/actions';

import 'style/layout/modal/ModalConfirm.css';
import 'style/projects/projectMeasurementTab/measurementList/Status.css';

const Status = ({
  statusList,
  fetchStatusList,
  selectedIds,
  projectId,
  setModalSaved,
  modalSaved,
}: {
  statusList: string[];
  fetchStatusList: () => void;
  selectedIds: number[];
  projectId: number;
  setModalSaved: any;
  modalSaved: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.projects, translations.common]);
  const [status, setStatus] = useState<string>('');
  const [saveSuccess, setSaveSuccess] = useState(0);
  const modalMessage = saveSuccess
    ? t(`${translations.projects}:status.Status updated`)
    : t(`${translations.projects}:status.Status failed`);

  const selectStatus = (e: any) => {
    setStatus(getTargetValue(e));
  };

  useEffect(() => {
    fetchStatusList();
  }, [fetchStatusList]);

  useEffect(() => {
    setStatus(statusList[0]);
  }, [statusList]);

  const submitStatus = () => {
    const statuses: IPlaceStatus[] = selectedIds.map((id) => ({
      placeId: id,
      status,
    }));
    const submit = async () => {
      try {
        const list: ISuccessResult = await apiProjects.status.put(
          projectId,
          statuses
        );
        if (list.success === true) {
          setSaveSuccess(1);
          setModalSaved(true);
        } else {
          setSaveSuccess(0);
          setModalSaved(true);
        }
      } catch (error) {
        setSaveSuccess(0);
        setModalSaved(true);
      }
    };
    submit();
  };

  return (
    <>
      <Row>
        <Col xs={5} className="line-elements">
          <SelectInputFieldNoLabel
            id="status"
            name="status"
            required
            onChange={selectStatus}
            options={
              statusList
                ? statusList.map((st) => {
                    return (
                      <option key={`st ${st}`} value={st}>
                        {t(`${translations.projects}:status.${st}`)}
                      </option>
                    );
                  })
                : ''
            }
          />
          <PrimaryButton
            className="squares-edit "
            onClick={() => submitStatus()}
          >
            {t(`${translations.common}:Confirm`).toUpperCase()}
          </PrimaryButton>
        </Col>
      </Row>
      <ModalAccept
        showing={modalSaved}
        setShowing={setModalSaved}
        header=""
        text={modalMessage}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  statusList: state.projects.placeStatusTypes,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchStatusList: () => dispatch(fetchPlaceStatusTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Status);
