import { AxiosError } from 'axios';

import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  takeEvery,
} from 'redux-saga/effects';

import apiProjects from 'api/projects';

import { finishLoading, startLoading } from '../loading/actions';

import {
  FETCH_CONTRACT_TYPES,
  FETCH_WORK_TYPES,
  FETCH_BUILDING_TYPES,
  FETCH_COLLATERAL_TYPES,
  FETCH_EMPLOYEE_INFORMATION_TYPES,
  FETCH_WAY_OF_RECEIVING_TYPES,
  FETCH_TAX_RATE_TYPES,
  FETCH_TAB_TYPES,
  FETCH_HINDRANCE_TYPES,
  FETCH_PLACE_STATUS_TYPES,
} from './types';

function* fetchContractTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_CONTRACT_TYPES.PENDING));
    const contractTypes = yield call(apiProjects.contractTypes.get);
    yield put({ type: FETCH_CONTRACT_TYPES.SUCCESS, contractTypes });
    yield put(finishLoading(FETCH_CONTRACT_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({
      type: FETCH_CONTRACT_TYPES.FAILURE,
      message: axiosError.message,
    });
  }
}

function* fetchBuildingTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_BUILDING_TYPES.PENDING));
    const buildingTypes = yield call(apiProjects.buildingTypes.get);
    yield put({ type: FETCH_BUILDING_TYPES.SUCCESS, buildingTypes });
    yield put(finishLoading(FETCH_BUILDING_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({
      type: FETCH_BUILDING_TYPES.FAILURE,
      message: axiosError.message,
    });
  }
}

function* fetchWorkTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_WORK_TYPES.PENDING));
    const workTypes = yield call(apiProjects.workTypes.get);
    yield put({ type: FETCH_WORK_TYPES.SUCCESS, workTypes });
    yield put(finishLoading(FETCH_WORK_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({ type: FETCH_WORK_TYPES.FAILURE, message: axiosError.message });
  }
}

function* fetchCollateralTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_COLLATERAL_TYPES.PENDING));
    const collateralTypes = yield call(apiProjects.collateralTypes.get);
    yield put({ type: FETCH_COLLATERAL_TYPES.SUCCESS, collateralTypes });
    yield put(finishLoading(FETCH_COLLATERAL_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({
      type: FETCH_COLLATERAL_TYPES.FAILURE,
      message: axiosError.message,
    });
  }
}

function* fetchEmployeeInformationTypes(): IterableIterator<
  CallEffect | PutEffect
> {
  try {
    yield put(startLoading(FETCH_EMPLOYEE_INFORMATION_TYPES.PENDING));
    const employeeInformationTypes = yield call(
      apiProjects.employeeInformationTypes.get
    );
    yield put({
      type: FETCH_EMPLOYEE_INFORMATION_TYPES.SUCCESS,
      employeeInformationTypes,
    });
    yield put(finishLoading(FETCH_EMPLOYEE_INFORMATION_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({
      type: FETCH_EMPLOYEE_INFORMATION_TYPES.FAILURE,
      message: axiosError.message,
    });
  }
}

function* fetchWayOfReceivingTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_WAY_OF_RECEIVING_TYPES.PENDING));
    const wayOfReceivingTypes = yield call(apiProjects.wayOfReceivingTypes.get);
    yield put({
      type: FETCH_WAY_OF_RECEIVING_TYPES.SUCCESS,
      wayOfReceivingTypes,
    });
    yield put(finishLoading(FETCH_WAY_OF_RECEIVING_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({
      type: FETCH_WAY_OF_RECEIVING_TYPES.FAILURE,
      message: axiosError.message,
    });
  }
}

function* fetchTaxRateTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_TAX_RATE_TYPES.PENDING));
    const taxRateTypes = yield call(apiProjects.taxRateTypes.get);
    yield put({
      type: FETCH_TAX_RATE_TYPES.SUCCESS,
      taxRateTypes,
    });
    yield put(finishLoading(FETCH_TAX_RATE_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({
      type: FETCH_TAX_RATE_TYPES.FAILURE,
      message: axiosError.message,
    });
  }
}

function* fetchTabTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_TAB_TYPES.PENDING));
    const tabTypes = yield call(apiProjects.tabTypes.get);
    yield put({
      type: FETCH_TAB_TYPES.SUCCESS,
      tabTypes,
    });
    yield put(finishLoading(FETCH_TAB_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({
      type: FETCH_TAB_TYPES.FAILURE,
      message: axiosError.message,
    });
  }
}

function* fetchHindranceTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_HINDRANCE_TYPES.PENDING));
    const hindranceTypes = yield call(apiProjects.hindrances);
    yield put({
      type: FETCH_HINDRANCE_TYPES.SUCCESS,
      hindranceTypes,
    });
    yield put(finishLoading(FETCH_HINDRANCE_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({
      type: FETCH_HINDRANCE_TYPES.FAILURE,
      message: axiosError.message,
    });
  }
}

function* fetchStatusTypes(): IterableIterator<CallEffect | PutEffect> {
  try {
    yield put(startLoading(FETCH_PLACE_STATUS_TYPES.PENDING));
    const placeStatusTypes = yield call(apiProjects.statusTypes.get);
    yield put({
      type: FETCH_PLACE_STATUS_TYPES.SUCCESS,
      placeStatusTypes,
    });
    yield put(finishLoading(FETCH_PLACE_STATUS_TYPES.PENDING));
  } catch (error) {
    const axiosError = error as AxiosError;
    yield put({
      type: FETCH_PLACE_STATUS_TYPES.FAILURE,
      message: axiosError.message,
    });
  }
}

function* saga(): IterableIterator<ForkEffect> {
  yield takeEvery(FETCH_CONTRACT_TYPES.PENDING, fetchContractTypes);
  yield takeEvery(FETCH_BUILDING_TYPES.PENDING, fetchBuildingTypes);
  yield takeEvery(FETCH_WORK_TYPES.PENDING, fetchWorkTypes);
  yield takeEvery(FETCH_COLLATERAL_TYPES.PENDING, fetchCollateralTypes);
  yield takeEvery(
    FETCH_EMPLOYEE_INFORMATION_TYPES.PENDING,
    fetchEmployeeInformationTypes
  );
  yield takeEvery(
    FETCH_WAY_OF_RECEIVING_TYPES.PENDING,
    fetchWayOfReceivingTypes
  );
  yield takeEvery(FETCH_TAX_RATE_TYPES.PENDING, fetchTaxRateTypes);
  yield takeEvery(FETCH_TAB_TYPES.PENDING, fetchTabTypes);
  yield takeEvery(FETCH_HINDRANCE_TYPES.PENDING, fetchHindranceTypes);
  yield takeEvery(FETCH_PLACE_STATUS_TYPES.PENDING, fetchStatusTypes);
}

export default saga;
