import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

import offersSaga from './offers/saga';
import productsSaga from './products/saga';
import projectsSaga from './projects/saga';
import settingsSaga from './settings/saga';
import usersSaga from './users/saga';

const rootSaga = function* root(): IterableIterator<AllEffect<ForkEffect>> {
  yield all([
    fork(offersSaga),
    fork(productsSaga),
    fork(projectsSaga),
    fork(settingsSaga),
    fork(usersSaga),
  ]);
};

export default rootSaga;
