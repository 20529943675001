const getProductColumns = (t: any) => {
  const colTitles = {
    displayName: t('Display Name'),
    inlook: t('inlook'),
    inlookOld: t('inlook-old'),
    name: t('Name'),
  };

  return [
    {
      dataField: 'name',
      text: colTitles.name,
    },
    {
      dataField: 'displayName',
      text: colTitles.displayName,
    },
    {
      dataField: 'productIds',
      text: colTitles.inlookOld,
      formatter: (cell: any) =>
        cell.find((productId: any) => productId.type === 'inlook-old')?.value,
    },
    {
      dataField: 'productIds',
      text: colTitles.inlook,
      formatter: (cell: any) =>
        cell.find((productId: any) => productId.type === 'inlook')?.value,
    },
  ];
};

export default getProductColumns;
