import IProductConfigurationAttribute from 'api/interfaces/products/IProductConfigurationAttribute';
import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';

import {
  INSTALLATION_PRICE_UNIT,
  QUANTITY_UNIT,
  SELLING_PRICE_UNIT,
} from 'services/products/productConfigurationAttributeTypes';

import getAttributeValue from '../getAttributeValue';

const updateInstallationUnitsToValidValues = (
  item: IProductConfigurationItem,
  updateAttributeValues: (attribute: IProductConfigurationAttribute[]) => void
): void => {
  const currentInstallationPriceUnit = getAttributeValue(
    item,
    INSTALLATION_PRICE_UNIT
  );

  const currentQuantityUnit = getAttributeValue(item, QUANTITY_UNIT);
  const currentSellingPriceUnit = getAttributeValue(item, SELLING_PRICE_UNIT);

  const newInstallationPriceUnit =
    currentInstallationPriceUnit === '' ||
    currentInstallationPriceUnit === currentQuantityUnit
      ? currentInstallationPriceUnit
      : currentQuantityUnit;

  const newSellingPriceUnit =
    currentSellingPriceUnit === '' ||
    currentSellingPriceUnit === currentQuantityUnit
      ? currentSellingPriceUnit
      : currentQuantityUnit;

  if (currentInstallationPriceUnit !== newInstallationPriceUnit) {
    updateAttributeValues([
      {
        type: INSTALLATION_PRICE_UNIT,
        value: newInstallationPriceUnit,
      },
    ]);
  }

  if (currentSellingPriceUnit !== newSellingPriceUnit) {
    updateAttributeValues([
      {
        type: SELLING_PRICE_UNIT,
        value: newSellingPriceUnit,
      },
    ]);
  }
};

export default updateInstallationUnitsToValidValues;
