import numericStringToFloat from 'services/numbers/numericStringToFloat';
import IInstallation from './IInstallation';

export default (installation: IInstallation): number => {
  const expense =
    typeof installation.installationPrice === 'number'
      ? installation.installationPrice
      : numericStringToFloat(installation.installationPrice);

  const socialSecurityCostPercentage: number =
    typeof installation.socialSecurityCostPercentage === 'number'
      ? installation.socialSecurityCostPercentage
      : numericStringToFloat(installation.socialSecurityCostPercentage);

  return expense * (1 + socialSecurityCostPercentage / 100);
};
