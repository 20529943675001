import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import translations from 'services/translations/translations';
import getCssVariable, { THEME_GREY } from 'services/getCssVariable';

const CreatableDropdown = ({
  value,
  name,
  onChange,
  required,
  options,
  className,
  placeholder,
  disabled,
}: {
  value: any;
  name: string;
  onChange: any;
  required: boolean;
  options: any;
  className: string;
  placeholder: string;
  disabled?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.offers]);
  const formatCreateLabelCreator = (inputValue: any) => {
    return t(`${translations.offers}:Create`) + inputValue;
  };
  return (
    <>
      <CreatableSelect
        className={className}
        placeholder={placeholder}
        required={required}
        name={name}
        value={value}
        onChange={onChange}
        options={options}
        formatCreateLabel={formatCreateLabelCreator}
        isDisabled={disabled}
        styles={{
          control: (provided, state) =>
            state.isDisabled
              ? {
                  ...provided,
                  backgroundColor: getCssVariable(THEME_GREY),
                }
              : provided,
        }}
      />
    </>
  );
};

export default CreatableDropdown;
