import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import IUserGet from 'api/interfaces/users/IUserGetSingle';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
  Button,
} from 'reactstrap';
import TextInputFieldNoLabel from 'components/layout/forms/TextInputFieldNoLabel';
import MultiSelectDropdown from 'components/layout/forms/MultiSelectDropdown';
import IWorkGroupPost from 'interfaces/projects/IWorkGroupPost';
import IPosition from 'interfaces/projects/IPosition';
import translations from 'services/translations/translations';
import showPositionOptions from './showPositionOptions';
import showUserOptions from './showUserOptions';

import 'style/projects/projectMeasurementTab/basicInfo/ModalEditWorkGroup.css';

const ModalEditWorkGroup = ({
  showing,
  setShowing,
  updateElement,
  workGroup,
  users,
  positions,
}: {
  showing: boolean;
  setShowing: (showing: boolean) => void;
  updateElement: (name: string, positions: any, users: any) => void;
  workGroup: IWorkGroupPost | undefined;
  users: IUserGet[];
  positions: IPosition[];
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common]);
  const toggle = () => setShowing(!showing);
  const [positionRefs, setPositionRefs] = useState<
    { label: string; value: string }[]
  >([]);
  const [userIds, setUserIds] = useState<{ label: string; value: string }[]>(
    []
  );
  const [workGroupName, setWorkGroupName] = useState<string>('');

  const mapToLabelValue = (refs: any[]): { label: string; value: string }[] => {
    return refs.map((ref) => {
      return { label: ref.toString(), value: ref };
    });
  };

  const mapUsers = (ids: string[]): { label: string; value: string }[] => {
    const usrs = [];
    for (let index = 0; index < users.length; index++) {
      const userId = users[index].id ? users[index].id : '';
      if (userId !== undefined && ids.includes(userId)) {
        const name = `${users[index].firstName} ${users[index].lastName}`;
        usrs.push({ label: name, value: userId });
      }
    }
    return usrs;
  };

  useEffect(() => {
    if (workGroup !== undefined) {
      setPositionRefs(mapToLabelValue(workGroup.positions));
      setUserIds(mapUsers(workGroup.users));
      setWorkGroupName(workGroup.name);
    }
  }, [workGroup]);

  const handleUserIds = (e: any) => {
    setUserIds(e);
  };

  const handlePositionRefs = (e: any) => {
    setPositionRefs(e);
  };

  const submitWorkGroup = (e: any) => {
    updateElement(workGroupName, positionRefs, userIds);
  };

  return (
    <>
      <Modal size="lg" isOpen={showing} toggle={toggle} centered>
        <ModalHeader className="modal-header" toggle={toggle} />
        <ModalBody className="basic-table">
          <p className="label">{t(`${translations.projects}:Work Groups`)}</p>
          <Row>
            <Col>
              <TextInputFieldNoLabel
                value={workGroupName}
                required
                id="workGroupName"
                name="workGroupName"
                onChange={(e: any) => setWorkGroupName(e.target.value)}
              />
            </Col>
            <Col>
              <MultiSelectDropdown
                placeholder={t(`${translations.products}:Select`)}
                required
                name="users"
                value={userIds}
                onChange={handleUserIds}
                options={showUserOptions(users)}
              />
            </Col>
            <Col>
              <MultiSelectDropdown
                placeholder={t(`${translations.products}:Select`)}
                required
                name="positions"
                value={positionRefs}
                onChange={handlePositionRefs}
                options={showPositionOptions(positions)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={(e: any) => submitWorkGroup(e)}
            className="modal-button"
          >
            {t(`${translations.common}:Save`)}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalEditWorkGroup;
