import IProductId from 'interfaces/products/IProductId';

const getIdValues = (idTypesList: string[], p: IProductId[]) => {
  const tempIdValues: IProductId[] = [];
  if (idTypesList !== undefined) {
    for (let index = 0; index < idTypesList.length; index++) {
      const tempValue = p?.find((x) => x.type === idTypesList[index])?.value;
      const idValue: IProductId = {
        type: idTypesList[index],
        value: tempValue !== undefined ? tempValue : '',
      };
      tempIdValues.push(idValue);
    }
  }
  return tempIdValues;
};

export default getIdValues;
