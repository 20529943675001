const orderOfferTypes = (offerTypes: any): string[] => {
  if (offerTypes !== undefined && offerTypes.length !== 0) {
    return [
      offerTypes.find((offerType: string) => offerType === 'offer'),
      offerTypes.find((offerType: string) => offerType === 'preliminary-offer'),
      offerTypes.find((offerType: string) => offerType === 'additional-offer'),
      offerTypes.find((offerType: string) => offerType === 'material-offer'),
    ];
  }
  return [];
};

export default orderOfferTypes;
