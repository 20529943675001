import IProductConfigurationAttribute from 'interfaces/products/IProductConfigurationAttribute';
import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';

const getAttribute = (
  item: IProductConfigurationItem,
  type: string
): IProductConfigurationAttribute | undefined =>
  item.attributes.find((attribute) => attribute.type === type);

export default getAttribute;
