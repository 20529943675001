import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, Button } from 'reactstrap';
import IUserGet from 'api/interfaces/users/IUserGet';
import EditIcon from 'components/layout/icons/EditIcon';
import MultiSelectDropdown from 'components/layout/forms/MultiSelectDropdown';
import TextInputFieldNoLabel from 'components/layout/forms/TextInputFieldNoLabel';
import IWorkGroupPost from 'interfaces/projects/IWorkGroupPost';
import IPosition from 'interfaces/projects/IPosition';
import translations from 'services/translations/translations';
import showUserOptions from './showUserOptions';
import showPositionOptions from './showPositionOptions';
import TrashIcon from '../../../layout/icons/TrashIcon';

import 'style/projects/projectMeasurementTab/basicInfo/WorkGroups.css';

const WorkGroups = ({
  workGroups,
  addWorkGroup,
  removeElement,
  positions,
  users,
  updateElement,
}: {
  workGroups: IWorkGroupPost[];
  addWorkGroup: (name: string, positionRefs: any, userIds: any, e: any) => void;
  removeElement: (e: string) => void;
  positions: IPosition[];
  users: IUserGet[];
  updateElement: (e: string) => void;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common]);
  const [positionRefs, setPositionRefs] = useState<
    { label: string; value: string }[]
  >([]);
  const [userIds, setUserIds] = useState<{ label: string; value: string }[]>(
    []
  );
  const [workGroupName, setWorkGroupName] = useState<string>('');
  const showWorkGroups = () => {
    return workGroups.map((workGroup) => {
      return (
        <Row key={workGroup.name}>
          <Col className="grey-text">{workGroup.name}</Col>
          <Col className="grey-text">
            {workGroup.users.map((userId) => {
              const userObject = users.find((usr) => usr.id === userId);
              if (userObject !== undefined) {
                return (
                  <p key={`${workGroup.name}${userId}`}>
                    {userObject.firstName} {userObject?.lastName}
                  </p>
                );
              }
            })}
          </Col>
          <Col className="grey-text">
            {workGroup.positions.map((position) => (
              <p key={`${workGroup.name}${position}`}>{position}</p>
            ))}
          </Col>
          <Col xs={1}>
            <EditIcon
              onClick={() => {
                updateElement(workGroup.name);
              }}
            />
          </Col>
          <Col>
            <TrashIcon
              onClick={() => {
                removeElement(workGroup.name);
              }}
            />
          </Col>
        </Row>
      );
    });
  };

  const handleUserIds = (e: any) => {
    setUserIds(e);
  };

  const handlePositionRefs = (e: any) => {
    setPositionRefs(e);
  };

  const submitWorkGroup = (e: any) => {
    setPositionRefs([]);
    setUserIds([]);
    setWorkGroupName('');
    addWorkGroup(workGroupName, positionRefs, userIds, e);
  };

  return (
    <>
      <Row className="button-margin-top">
        <Col>
          <Label className="label">
            {t(`${translations.projects}:Work Groups`)}
          </Label>
        </Col>
      </Row>
      {showWorkGroups()}

      <Row>
        <Col xs={10}>
          <Row className="button-margin-top">
            <Col className="no-padding" xs={12}>
              <form
                id="workGroup"
                className="flex-form"
                onSubmit={(e: any) => submitWorkGroup(e)}
              >
                <Col>
                  <TextInputFieldNoLabel
                    value={workGroupName}
                    required
                    id="workGroupName"
                    name="workGroupName"
                    onChange={(e: any) => setWorkGroupName(e.target.value)}
                  />
                </Col>
                <Col>
                  <MultiSelectDropdown
                    placeholder={t(`${translations.products}:Select`)}
                    required
                    name="users"
                    value={userIds}
                    onChange={handleUserIds}
                    options={showUserOptions(users)}
                  />
                </Col>
                <Col>
                  <MultiSelectDropdown
                    placeholder={t(`${translations.products}:Select`)}
                    required
                    name="positions"
                    value={positionRefs}
                    onChange={handlePositionRefs}
                    options={showPositionOptions(positions)}
                  />
                </Col>
                <Col>
                  <Button>
                    {t(`${translations.common}:Add`).toUpperCase()}
                  </Button>
                </Col>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default WorkGroups;
